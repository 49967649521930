<i18n src='../../locales.json'></i18n>

<template>
  <transition-group
    v-if="!loading"
    name="card-slide"
    mode="in-out"
    tag="div"
    class="card-slide-container"
  >
    <div
      v-for="(item) in policiesForCardStack"
      :key="item.contractId"
      class="card-slide-item"
    >
      <div :class="item._contractId === policyData._contractId? 'show-card': 'hide-card'">
        <PolicyOverview ref="view_policy"
                        v-if="policyData"
                        :policy-data="policyData"
                        :restrict-code-message="restrictCodeMessage"
                        :client-communications-phone="clientCommunicationsPhone"
                        role="tabpanel"
                        aria-labelledby="my_policy_tab"
                        id="policy-panel"
                        tabindex="-1"
        />

        <PaymentInformationCard ref="payment" id="payment"
                                v-if="policyData && policyData.active"
        />

        <Annuities
          v-if="policyData && hasAnnuity"
        />

        <PolicyOwner ref="contact_info" id="contact_info"
                     v-if="policyData && policyData.owner"
                     :allow-edit="policyData.allowContactInfoUpdates"
        />
        <Beneficiaries ref="beneficiary" id="beneficiary"
                       :is-restricted-policy="isRestricted"
                       :is-us-policy-code="isUsPolicy"
                       :is-ny-policy-code="isNyPolicy"
                       :is-canada-policy-code="isCanadaPolicy"
                       :is-canada-quebec="isCanadaQuebecPolicy"
        />
        <Representative
          :agents="agents"
          :restricted="policyData.restricted"
        />
      </div>
    </div>
  </transition-group>
</template>

<script>
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import Alert from '@/components/Alert.vue';
import Representative from '@/components/Representative.vue';
import PolicyDetailsInfo from '../../PolicyDetailsInfo.vue';
import Annuities from '../Annuities/Annuities.vue';
import PolicyOverview from '../PolicyOverview/PolicyOverview.vue';
import PolicyOwner from '../PolicyOwner/PolicyOwner.vue';
import PaymentInformationCard from '../PaymentInformation/PaymentInformationCard.vue';
import Beneficiaries from '../Beneficiaries/Beneficiaries.vue';
import __t from '../../translate';
import GuidanceCalculator from '../../../guidance/GuidanceCalculator.vue';

export default {
  name: 'PolicyDetailsCards',
  data() {
    return {
      activeTab: 1,
      activePolicyTab: 0,
    };
  },
  computed: {
    ...mapGetters(['getAgent']),
    ...mapGetters('policy', {
      policyData: 'currentPolicy',
      selectedPolicyIndex: 'selectedPolicyIndex',
      policies: 'policies',
      restrictCodeMessage: 'restrictCodeMessage',
      clientCommunicationsPhone: 'clientCommunicationsPhone',
      scrollToPolicyDetails: 'scrollToPolicyDetails',
      loading: 'policyInfoLoading',
    }),
    agents() {
      return [this.getAgent(this?.policyData?.agentId)];
    },
    policiesForCardStack() {
      const allPolicies = this?.policies?.slice(this.selectedPolicyIndex, this.policies.length)?.reverse();
      return allPolicies.filter(policy => !policy.restricted);
    },
    policyAnnuity() {
      return this?.policyData?.details?.annuity;
    },
    isUsPolicy() {
      return this?.policyData?.isUSPolicy;
    },
    isNyPolicy() {
      return this?.policyData?.isNewYorkPolicy;
    },
    isCanadaPolicy() {
      return this?.policyData?.isCanadaPolicy;
    },
    isCanadaQuebecPolicy() {
      return this?.policyData?.isCanadaQuebecPolicy;
    },
    isPending() {
      return this?.policyData?.pending;
    },
    isRestricted() {
      return this?.policyData?.restricted;
    },
    formattedPaymentDueDate() {
      return formatDate(this.policyData?.nextPaymentDueDate, 'll');
    },
    hasAnnuity() {
      return this?.policyData?.paynowInfo?.hasAnnuity;
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    ...mapActions('policy', ['setPaymentStep', 'setScrollToPolicyDetails']),
    __t,
    cardClass(index) {
      const reverseIndex = this.policiesForCardStack.length - (index + 1);
      if (reverseIndex >= 3) {
        return 'last-cards';
      }
      return `card-${reverseIndex}${index === this.selectedPolicyIndex ? ' policy-active' : ''}`;
    },
  },
  watch: {
    policyData(newVal, oldVal) {
      if (newVal._contractId !== oldVal._contractId) {
        this.removeAlerts('payment');
      }
    },
  },
  mounted() {
    const scrollToLocation = this.scrollToPolicyDetails;
    if (this?.$route?.name === 'GuidanceCalculator') {
      this.activePolicyTab = 1;
    }
    if (this?.$route?.name === 'TermLifeBenefits') {
      this.activePolicyTab = 2;
    }
    // Clearing out the Alerts for payment when they come in here
    this.removeAlerts('payment');

    if (scrollToLocation) {
      if (scrollToLocation === 'policy-panel') {
        window.scrollTo(0, 0);
      } else {
        // Everytime there is a change in size or reordering of cards the following needs to be looked at
        window.setTimeout(() => {
          // Add Child is the first section (coverage) so we don't have to worry about it
          if (scrollToLocation === 'contact_info') {
            if (this.$vuetify.breakpoint.lgAndUp) {
              window.scrollTo(0, 920);
            } else if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm) {
              window.scrollTo(0, 920);
            } else {
              window.scrollTo(0, 1260);
            }
          } else if (scrollToLocation === 'beneficiary') {
            if (this.$vuetify.breakpoint.lgAndUp) {
              window.scrollTo(0, 1200);
            } else if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm) {
              window.scrollTo(0, 1200);
            } else {
              window.scrollTo(0, 1670);
            }
          } else if (scrollToLocation === 'payment') { // Stick with this
            if (this.$vuetify.breakpoint.lgAndUp) {
              window.scrollTo(0, 390);
            } else if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm) {
              window.scrollTo(0, 390);
            } else {
              window.scrollTo(0, 470);
            }
          }
        }, 200);
      }
    }
  },
  destroyed() {
    // resetting the scroll to top
    this.setScrollToPolicyDetails('policy-panel');
  },
  components: {
    GuidanceCalculator,
    Beneficiaries,
    PolicyOwner,
    PolicyOverview,
    Annuities,
    PolicyDetailsInfo,
    ResponsiveTable,
    Representative,
    PaymentInformationCard,
    Alert,
  },
};
</script>

<style lang="stylus" scoped>
.show-card {
  visibility: visible;
}
.hide-card {
  visibility: hidden !important;
}
.v-icon.guidance-icon
  background-color rgba(0,0,0,0) !important
  height: 24px
  width: 24px
  background-image: url(../../assets/guidance-icon.svg)
  &.white
    background-image: url(../../assets/guidance-icon-white.svg)

/deep/ .v-tabs__bar
  background-color: transparent !important
  box-shadow: none;
  z-index: 2;
  >.v-tabs__wrapper
    > .v-tabs__container
      width: 100%
      @media only screen and (max-width: 767px)
        height 64px;
/deep/ div.v-tabs__div {
  width: 33.3333333333% !important;
}
/* Expermental */
@require '~vuetify/src/stylus/settings/_variables'
@media $display-breakpoints.lg-and-up, $display-breakpoints.sm-and-down
  .card-slide-container {
    position: relative;
    overflow: hidden !important;
  }

  .card-slide-item {
    perspective: 500px;
    transition: all .5s;
    position: absolute;

    &:last-child {
      position: relative;
    }

    &.last-cards {
      filter: blur(3px);
      transform: translate3d(100px, 0, 0) scaleX(0.8) scaleY(0.8);
      opacity: 0;
    }
  }

.card-slide-enter, .card-slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

@media $display-breakpoints.lg-and-up
  .card-slide-container
    perspective-origin: 0 0;

@media $display-breakpoints.sm-and-down
  .card-slide-container
    perspective-origin: 50% 0;

    .card-slide-item {
      &.card-1 {
        transform: translate3d(0, -15px, -20px);
      }

      &.card-2 {
        transform: translate3d(0, -30px, -40px);
      }

      &.last-cards {
        transform: translate3d(0, -45px, -60px);
      }
    }

@media $display-breakpoints.md-only {
  .card-slide-item:not(:last-child) {
    display: none;
  }
}
.policy-alert
  /deep/ .v-alert
    margin-top: 0;
    margin-bottom: 0;
</style>

<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <v-layout
      class="justify-center"
    >
      <v-flex
        xs11
      >
        <v-card class="card-ach-wrapper">
          <v-card-title
            class="justify-space-around text-xs-center"
          >
            <h2 class="heading_two">
              {{ $t('L_confirm_your_premium_payment_info') }}
            </h2>
          </v-card-title>
          <v-card-text
            class="background-light-grey pl-4"
          >
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ paymentType }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountType }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Two -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_holder_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountHolder }}</span>
                </div>
              </v-flex> -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ accountNumber | hideNums }}</span>
                </div>
              </v-flex>
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ routingNumber | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Three -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Close rows -->
            <!-- Disclaimer -->
          </v-card-text>
          <v-layout
            row
            wrap
            mt-4
            pt2
          >
            <v-flex xs12>
              <div class="disclaimer-wrapper">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    mb-4
                  >
                    <span class="font-weight-medium"
                          v-html="$t(disclaimerTitle)"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    class="context"
                    v-html="$t(disclaimerBody)"
                  />
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                mt-3
                class="text-xs-center"
              >
                <v-checkbox
                  color="primary"
                  v-model="accepted"
                  :label="$t('L_by_checking_the_box_and_authorizing')"
                />
              </v-flex>
              <v-flex
                class="text-sm-right text-xs-center"
              >
                <v-layout
                  row
                  wrap
                  class="justify-end"
                >
                  <v-flex
                    xs12
                    sm2
                    :class="$vuetify.breakpoint.smAndUp ? 'mr-4':''"
                  >
                    <v-btn
                      block
                      class="mb-3"
                      color="secondary"
                      @click="cancel"
                    >
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    sm2
                  >
                    <v-btn
                      block
                      :loading="achSubmissionLoading"
                      class="white--text mb-3"
                      :disabled="!accepted"
                      @click="makePayment"
                      color="primary"
                    >
                      {{ $t('L_i_authorize') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import { filters } from '@/mixins/filters';

export const giveDate = (date, isCanada) => {
  if (date.length !== 6) {
    return;
  }
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  const year = `20${date.slice(4, 6)}`;
  // eslint-disable-next-line consistent-return
  return isCanada ? `${year}/${month}/${day}` : `${month}/${day}/${year}`;
};

export const formattedName = (name) => {
  const fullName = name.split(' ');
  const fname = fullName.slice(fullName.length - 1);
  const sname = fullName.slice(0, fullName.length - 1).join(' ');
  return `${fname}, ${sname}`;
};

export default {
  name: 'AchStepTwo',
  mixins: [filters],
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
    paymentAmount: {
      type: String,
      required: true,
      default: '0',
    },
  },
  data: () => ({
    accepted: false,
    disclaimer: '',
  }),
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      locale: 'getLocale',
    }),
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['paymentData', 'currentPolicy', 'achSubmissionLoading']),
    disclaimerTitle() {
      return `L_disclaimer_title_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    disclaimerBody() {
      return `L_disclaimer_body_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    isCanadaPolicy() {
      return this.policyData.isCanadaPolicy;
    },
    nextDraftDate() {
      return formatDate(this.policyData?.paymentInfo?.paidToDate, this.dateFormat);
    },
    paymentType() {
      return this?.paymentData?.paymentType;
    },
    accountType() {
      return this?.paymentData?.accountType;
    },
    accountHolder() {
      return this?.paymentData?.accountHolder;
    },
    routingNumber() {
      return this?.paymentData?.routingNumber || '';
    },
    accountNumber() {
      return this?.paymentData?.accountNumber || '';
    },
    bankName() {
      return this?.paymentData?.bankName;
    },
    isB2BillingForm() {
      return this?.currentPolicy?.billingForm === 'B2';
    },
  },
  methods: {
    ...mapActions('policy', ['submitAch']),
    ...mapMutations('policy', ['setAchSubmissionLoading', 'setPaymentInfoShowPaymentAmountDateBillingMethod']),
    checked() {
      if (this.accepted) {
        this.showSuccessBanner();
      } else {
        this.showErrorBanner('L_you_must_check_box_to_authorize');
      }
    },
    showSuccessBanner() {
      this.$store.commit('addAlert', {
        type: 'info',
        details: { message: this.$t('L_there_will_be_a_monthly_premium_notice', { amount: `$${this.paymentAmount}` }) },
        context: 'payment',
        autoDismiss: false,
      });
    },
    showErrorBanner(error) {
      this.$store.commit('addError', { error: { message: this.$t(error) }, context: 'payment' });
    },
    async makePayment() {
      try {
        // This shows the banner
        this.checked();
        this.setAchSubmissionLoading(true);
        const name = formattedName(this.paymentData?.accountHolder);
        const paymentInfo = {
          draftAmount: this?.paymentAmount,
          emailAddress: this.customerProfile.email,
          language: this.locale,
          bankAccount: {
            accountName: name,
            routingNumber: this.routingNumber,
            accountNumber: this.accountNumber,
            accountType: this?.paymentData?.accountCode === 1 ? '3' : ' ',
          },
        };
        const response = await this.submitAch({
          paymentInfo,
          policyNumber: this?.currentPolicy?.contractId,
        });
        if (response) {
          try {
            const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentSubmission_Succeeded');
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
          /*
            * Emit a custom action when a user successfully submits a Pay Now payment
            * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
          */
          const i = this.$dynatrace.enterAction('pay_now_succesful_submission');
          this.$dynatrace.leaveAction(i);
          this.$emit('goToStepThree');
        } else {
          try {
            const i = this.$dynatrace.enterAction('OneTimePayment_ACH_PaymentSubmission_Error');
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
          this.showErrorBanner('L_error_occurred_while_processing_ach_payment');
        }
      } finally {
        this.setAchSubmissionLoading(false);
        this.setPaymentInfoShowPaymentAmountDateBillingMethod(true);
      }
    },
    cancel() {
      this.$emit('goToStepOne');
      this.clearBank();
    },
    clearBank() {
      this.$store.commit('policy/clearBank');
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="stylus">
  .grey-ada-text
    color: #737171
  .disclaimer-wrapper
    overflow-y: scroll;
    max-height: 263px;
    min-height: 250px;
    border-radius: 2px;
    border: solid 1px #979797;
    padding: 20px;
  .card-ach-wrapper
    position: relative;
  .fixed-top-sm-message-banner
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    z-index: 1;
</style>

<template>
  <router-view />
</template>

<script>
import { mapMutations } from 'vuex';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';
import { unsupportedBrowser } from '@/shared/util/helpers';
import store from '@/store/index';
import { getUnlockModePreference, canUseBiometrics, hasStrongBiometricsStrengthLevel, getVaultConfig } from '@/plugins/auth/utils/sessionManager';

export default {
  name: 'PreLoginLobby',
  data() {
    return {
      error: '',
    };
  },
  methods: {
    ...mapMutations({
      addError: 'addError',
    }),
  },
  created() { },
  async beforeRouteEnter(to, from, next) {
    await Promise.all([
      getUnlockModePreference(),
      canUseBiometrics(),
      hasStrongBiometricsStrengthLevel(),
      getVaultConfig(),
    ])
      .then(([_unlockModePreference, _canUseBiometrics, _hasStrongBiometricsStrengthLevel, _vaultConfig]) => {
        // eslint-disable-next-line
        console.info(`[PreLoginLobby.vue] _unlockModePreference ${_unlockModePreference}`);
        // eslint-disable-next-line
        console.info(`[PreLoginLobby.vue] _canUseBiometrics ${_canUseBiometrics}`);
        // eslint-disable-next-line
        console.info(`[PreLoginLobby.vue] _hasStrongBiometricsStrengthLevel ${_hasStrongBiometricsStrengthLevel}\n\n`);
        // eslint-disable-next-line
        console.info(`[PreLoginLobby.vue] _vaultConfig ${JSON.stringify(_vaultConfig, null, 2)}\n\n`);
      });

    // TODO: handle when user is logged in already
    if (to.name === 'PreLoginLobby') {
      await store.dispatch('getAppInfo');
    }
    if (CapacitorFacade.isNativePlatform() && (store.getters.isOptionalUpdateAvailable || store.getters.isCriticalUpdateAvailable)) {
      return ((to.name === 'AppUpdate') ? next() : next({ name: 'AppUpdate' }));
    } else if (CapacitorFacade.isWeb() && unsupportedBrowser()) {
      return ((to.name === 'UnsupportedBrowser') ? next() : next({ name: 'UnsupportedBrowser' }));
    } else if (store.getters.isMaintenanceMode) {
      return ((to.name === 'MaintenanceMode') ? next() : next({ name: 'MaintenanceMode' }));
    } else {
      return (next({ name: 'Dashboard' }));
    }
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./StepThree.vue?vue&type=template&id=34185824&scoped=true"
import script from "./StepThree.vue?vue&type=script&lang=js"
export * from "./StepThree.vue?vue&type=script&lang=js"
import style0 from "./StepThree.vue?vue&type=style&index=0&id=34185824&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34185824",
  null
  
)

/* custom blocks */
import block0 from "../../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2Fcomponents%2FPaymentInformation%2Fcomponents%2FChangePaymentMethod%2Fcomponents%2FEditPaymentMethod%2FStepThree%2FStepThree.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
/* eslint-disable no-alert */
import {
  Device,
  SupportedBiometricType,
} from '@ionic-enterprise/identity-vault';
import { createVault } from './vaultFactory';
import { DEVICE_SECURITY_TYPE, VAULT_TYPE, UNLOCK_MODE, BIOMETRIC_PERMISSION_STATE, DEVICE_ERROR_CODES } from './definitions';
import { CapacitorFacade } from '../../capacitorPlugin';

const sessionKey = 'session';
const unlockModePreferenceKey = 'LastUnlockMode';
const isNative = CapacitorFacade.isNativePlatform();
const defaultVaultConfig = {
  key: 'com.primerica.myprimerica',
  type: VAULT_TYPE.SECURE_STORAGE,
  deviceSecurityType: DEVICE_SECURITY_TYPE.NONE,
  lockAfterBackgrounded: 240000, // 300000,
  unlockVaultOnLoad: true,
};
let vault;

// Create the vault;
try {
  vault = createVault();
} catch (error) {
  throw new Error(`CANNOT CREATE VAULT: ${error}`);
}

// see https://ionic.io/docs/identity-vault/classes/device#sethidescreenonbackground
try {
  Device.setHideScreenOnBackground(true);
} catch (error) { }

const _provision = async () => {
  try {
    // Check biometric permissions states on iOS (always granted on Android and on iOS with TouchID).
    // User has not yet been prompted to allow FaceID, so show biometrics prompts
    if ((await Device.isBiometricsAllowed()) === BIOMETRIC_PERMISSION_STATE.PROMPT) {
      await Device.showBiometricPrompt({ iosBiometricsLocalizedReason: 'Authenticate to continue', androidBiometricsPromptSubtitle: '', androidBiometricsPromptDescription: '' });
    }
  } catch (e) {
    switch (e.code) {
      case DEVICE_ERROR_CODES.Unknown:
        // eslint-disable-next-line no-console
        console.error('Unknown error has occurred!');
        break;
      case DEVICE_ERROR_CODES.InvalidArguments:
        // eslint-disable-next-line no-console
        console.error('Arguments were invalid!');
        break;
      case DEVICE_ERROR_CODES.UserCanceledInteraction:
        // eslint-disable-next-line no-console
        console.error('You cancelled the authentication dialog!');
        break;
      case DEVICE_ERROR_CODES.SecurityNotAvailable:
        // eslint-disable-next-line no-console
        console.error('You need to set up system level passcode!');
        break;
      case DEVICE_ERROR_CODES.AuthFailed:
        // eslint-disable-next-line no-console
        console.error('User Authentication failed!');
        break;
      case DEVICE_ERROR_CODES.BiometricsLockedOut:
        // eslint-disable-next-line no-console
        console.error('Biometrics has been locked out, too many failed attempts!');
        break;
      case DEVICE_ERROR_CODES.BiometricsNotEnabled:
        // eslint-disable-next-line no-console
        console.error('Biometrics authentication is not enabled or supported on this device!');
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('Biometrics authentication is not enabled or supported on this device!');
        throw e;
    }
    throw e;
  }
};

export const initializeSessionVault = async () => {
  try {
    await vault.initialize(defaultVaultConfig);
  } catch (e) {
    await vault.clear();
    throw e; // rethrow the error
  }
};

export const setVaultErrorHandler = (cb) => {
  vault.onError(async (err) => {
    await cb(err);
  });
};

export const getVaultConfig = async () => {
  const exists = !(await vault.isEmpty());
  if (exists) {
    return vault.config;
  }
  return null;
};

export const getVaultType = async () => {
  const exists = !(await vault.isEmpty());
  if (exists) {
    return vault.config.type;
  }
  return null;
};

export const isVaultEmpty = async () => !(await vault.isEmpty());

export const canUseLocking = () => isNative;

// TODO: review usage because logic is wrong
// TODO: delete this
export const canUnlock = async () => {
  // ? consider Promise.all
  const exists = !(await vault.isEmpty());
  const isLocked = !(await vault.isLocked());
  return isNative && exists && isLocked;
};

// TODO: add unit test
export const isVaultLocked = async () => {
  // ? consider Promise.all
  const exists = !(await vault.isEmpty());
  const isLocked = (await vault.isLocked());
  return isNative && exists && isLocked;
};

export const lockVault = async () => vault.lock();

export const unlockVault = async () => vault.unlock();

/*
* SESSION RELATED
*/

export const clearSession = async () => vault.clear();

export const getSession = async () => vault.getValue(sessionKey);

export const setSession = async authResult => vault.setValue(sessionKey, authResult);

/*
* DEVICE RELATED
*/

export const canUseBiometrics = async () => isNative && (Device.isBiometricsEnabled());

export const getAvailableHardware = async () => Device.getAvailableHardware();

export const getBiometricStrengthLevel = async () => Device.getBiometricStrengthLevel();

export const hasStrongBiometricsStrengthLevel = async () => (await Device.getBiometricStrengthLevel()) === 'strong';

export const hasFaceId = async () => (await Device.getAvailableHardware()).filter(biometricType => biometricType === SupportedBiometricType.Face).length > 0;

export const hasTouchId = async () => (await Device.getAvailableHardware()).filter(biometricType => biometricType === SupportedBiometricType.Fingerprint).length > 0;

/*
* PREFERENCE RELATED AND UNLOCK MODE RELATED
*/

// TODO: add unit test
// see https://ionic.io/docs/identity-vault/vue/getting-started#why-the-unlockmode
export const getUnlockMode = async () => {
  const vaultConfig = !(await vault.isEmpty()) ? vault.config : null;
  const { type, deviceSecurityType } = vaultConfig;
  if (type === VAULT_TYPE.DEVICE_SECURITY && deviceSecurityType === DEVICE_SECURITY_TYPE.BIOMETRICS) {
    return UNLOCK_MODE.BIOMETRICS;
  } else if (type === VAULT_TYPE.DEVICE_SECURITY && deviceSecurityType === DEVICE_SECURITY_TYPE.BOTH) {
    return UNLOCK_MODE.BIOMETRICS_WITH_PASSCODE;
  } else if (type === VAULT_TYPE.DEVICE_SECURITY && deviceSecurityType === DEVICE_SECURITY_TYPE.SYSTEM_PASSCODE) {
    return UNLOCK_MODE.SYSTEM_PASSCODE;
  } else if (type === VAULT_TYPE.SECURE_STORAGE && deviceSecurityType === DEVICE_SECURITY_TYPE.NONE) {
    return UNLOCK_MODE.SECURE_STORAGE;
  }
};

// see https://ionic.io/docs/identity-vault/vue/getting-started#why-the-unlockmode
export const setUnlockMode = async (unlockMode) => {
  let type;
  let deviceSecurityType;

  switch (unlockMode) {
    case UNLOCK_MODE.BIOMETRICS:
      await _provision();
      type = VAULT_TYPE.DEVICE_SECURITY;
      deviceSecurityType = DEVICE_SECURITY_TYPE.BIOMETRICS;
      break;

    case UNLOCK_MODE.BIOMETRICS_WITH_PASSCODE:
      await _provision();
      type = VAULT_TYPE.DEVICE_SECURITY;
      deviceSecurityType = DEVICE_SECURITY_TYPE.BOTH;
      break;

    case UNLOCK_MODE.SYSTEM_PASSCODE:
      type = VAULT_TYPE.DEVICE_SECURITY;
      deviceSecurityType = DEVICE_SECURITY_TYPE.SYSTEM_PASSCODE;
      break;

    case UNLOCK_MODE.SECURE_STORAGE:
      type = VAULT_TYPE.SECURE_STORAGE;
      deviceSecurityType = DEVICE_SECURITY_TYPE.NONE;
      break;

    default:
      type = VAULT_TYPE.SECURE_STORAGE;
      deviceSecurityType = DEVICE_SECURITY_TYPE.NONE;
  }

  await vault.updateConfig({
    ...vault.config,
    type,
    deviceSecurityType,
  });

  await CapacitorFacade.setPreference({ key: unlockModePreferenceKey, value: unlockMode });
};

// see https://ionic.io/docs/identity-vault/vue/getting-started#why-the-unlockmode
export const setUnlockModeToBiometrics = () => setUnlockMode(UNLOCK_MODE.BIOMETRICS);

// see https://ionic.io/docs/identity-vault/vue/getting-started#why-the-unlockmode
export const setUnlockModeToSecureStorage = () => setUnlockMode(UNLOCK_MODE.SECURE_STORAGE); // TODO: add unit test

export const getUnlockModePreference = async () => CapacitorFacade.getPreference(unlockModePreferenceKey); // TODO: add unit test

export const isUnlockModePreferenceSet = async () => !!(await CapacitorFacade.getPreference(unlockModePreferenceKey));

export const isUnlockModePreferenceSetToBiometrics = async () => ((await CapacitorFacade.getPreference(unlockModePreferenceKey)) === UNLOCK_MODE.BIOMETRICS); // TODO: add unit test

/*
* BUSINESS RELATED
*/

// TODO: add unit test
export const unlockVaultWithBiometrics = async () => {
  const _getUnlockMode = await getUnlockMode();
  if (_getUnlockMode === UNLOCK_MODE.BIOMETRICS || _getUnlockMode === UNLOCK_MODE.BIOMETRICS_WITH_PASSCODE) {
    return _provision().then(() => vault.unlock());
  } else {
    return null;
  }
};

/*
This is an adapter service to encapsulate operations on the vault and
expose an API for managing user session
 */
export default {
  // * VAULT RELATED
  initializeSessionVault,
  setVaultErrorHandler,
  getVaultConfig,
  getVaultType,

  isVaultEmpty,
  canUseLocking,
  canUnlock,
  isVaultLocked,

  lockVault,
  unlockVault,

  // * SESSION RELATED
  clearSession, // ?
  getSession, // ?
  setSession, // ?

  // * DEVICE RELATED
  canUseBiometrics,
  getAvailableHardware,
  getBiometricStrengthLevel,
  hasStrongBiometricsStrengthLevel,
  hasFaceId,
  hasTouchId,

  // * PREFERENCE RELATED AND UNLOCK MODE RELATED
  // see https://ionic.io/docs/identity-vault/vue/getting-started#why-the-unlockmode
  getUnlockMode,
  setUnlockMode,
  setUnlockModeToBiometrics,
  setUnlockModeToSecureStorage,
  getUnlockModePreference,
  isUnlockModePreferenceSet,
  isUnlockModePreferenceSetToBiometrics,

  // * BUSINESS RELATED
  unlockVaultWithBiometrics,
};

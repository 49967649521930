<i18n src="./locales.json"></i18n>
<template>
  <v-card>
    <MessageDialog
      :show-dialog="showMessageDialog"
      :has-button-two="true"
      type="warning"
      :title="$t('L_cancel_changes')"
      :message="$t('L_by_cancelling_no_change')"
      :button-two-label="$t('L_yes_cancel')"
      :button-one-label="$t('L_no_continue')"
      :is-persistent="true"
      @buttonTwoAction="closeThisPage"
      @buttonOneAction="showMessageDialog = false"
    />
    <WarningDialogBeneficiary v-if="showDeleteWarning" @hide-warning="hideWarningDialog" :full-name="beneFullNameToDelete" :delete-beneficiary="deleteBeneficiary" />
    <v-dialog v-model="displayErrorDialogDuringSubmitClick" max-width="700" :persistent="persistent" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-layout row wrap>
          <v-flex class="redbar">
            &nbsp;
          </v-flex>
        </v-layout>
        <div class="ml-4 mr-4">
          <v-layout row wrap>
            <!-- no primary beneficiary or if spouse is there then no spouse primary beneficiary-->
            <v-flex xs12 text-xs-right mt-4 mb-4 font-size--18>
              <v-icon @click="closeDialogForSubmitErrors" color="#000000">
                close
              </v-icon>
            </v-flex>
            <v-flex font-size--18 text-xs-left font-weight-bold xs12 mb-4>
              <v-icon color="#ec5555">
                info
              </v-icon>
              {{ $t('L_please_correct_all_issues_noted_below') }}
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="(primaryInsured && beneficiaryPrimary.length === 0) || (showSpouseSection && beneficiarySpouse.length === 0)">
            <v-flex xs12 font-size--16 mb-4 font-weight-regular text-xs-left>
              {{ $t('L_please_add_at_least_one_primary_beneficiary_for_the') }}
            </v-flex>
            <v-flex xs12 font-size--16 font-weight-regular text-xs-left mb-4>
              <ul>
                <li v-if="beneficiaryPrimary.length === 0">
                  {{ primaryInsured }}
                </li>
                <li v-if="showSpouseSection && beneficiarySpouse.length === 0">
                  {{ spouseInsured }}
                </li>
              </ul>
            </v-flex>
          </v-layout>
          <v-divider v-if="(primaryInsured && beneficiaryPrimary.length === 0) || (showSpouseSection && beneficiarySpouse.length === 0)" class="heavyDivider" />
          <v-layout row wrap>
            <!-- Any of the 4 beneficiary is not 100% -->
            <v-flex
              xs12
              mt-4
              mb-4
              v-if="
                beneficiariesTotalPrimaryContingent !== 100 ||
                  (beneficiaryPrimaryContingent.length > 0 && beneficiariesTotalPrimaryPrimary !== 100) ||
                  (showSpouseSection && beneficiariesTotalSpousePrimary !== 100) ||
                  (showSpouseSection && beneficiarySpouseContingent.length > 0 && beneficiariesTotalSpouseContingent !== 100)
              "
            >
              {{ $t('L_the_total_beneficiary_allocation_must_be') }}
            </v-flex>
            <v-flex xs12 mt-4 mb-4
                    v-if="invalidBeneficiaryPrimary || invalidBeneficiaryPrimaryContingent ||
                      invalidBeneficiarySpouse || invalidBeneficiarySpouseContingent"
            >
              <div>{{ $t('L_allocation_percentage_required') }}</div>
              <div>{{ $t('L_allocation_percentage_zero_or_less') }}</div>
              <div>{{ $t('L_allocation_percentage_over_hundred') }}</div>
              <div>{{ $t('L_allocation_percentage_invalid_character') }}</div>
            </v-flex>
            <v-flex xs12 mt-2 mb-4 text-xs-center class="justify-center">
              <v-flex xs12 text-xs-center>
                <v-btn
                  id="closeErrorDialogShownForSubmit"
                  class="pl-4 pr-4 "
                  :class="$vuetify.breakpoint.xsOnly ? 'buttonFormat_mobile' : 'buttonFormat'"
                  color="primary"
                  @click="closeDialogForSubmitErrors"
                >
                  <h3 class="heading_three font-weight-bold font-size--18">
                    {{ $t('L_OK') }}
                  </h3>
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
    </v-dialog>
    <!-- This is for the V or T without Irrevocable-->
    <v-dialog v-model="showOriginVorTwithNoIrrevocable" max-width="700" :persistent="true" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-layout row wrap>
          <v-flex class="bluebar">
            &nbsp;
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 text-xs-right mt-2 mr-2 mb-4 font-size--18>
            <v-icon @click="hideOriginVorTwithNoIrrevocable" color="#000000">
              close
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout row wrap ml-4 mr-4>
          <v-flex font-size--18 font-weight-bold xs12 text-xs-center mb-4>
            <v-icon color="#3399ff">
              info
            </v-icon>
            {{ $t('L_important_information_about_making_beneficiary_changes') }}
          </v-flex>
          <v-flex xs12 font-size--16 text-xs-left>
            {{ $t('L_to_ensure_we_that_have_the_most_current_beneficiary_information_and_to_better') }}
          </v-flex>
          <v-flex xs12 font-weight-bold font-size--16 mt-4 mb-4 text-xs-left>
            {{ $t('L_keep_these_key_points_in_mind') }}
          </v-flex>
          <v-flex xs12 font-size--16 text-xs-left>
            <ul>
              <li class="mb-4">
                {{ $t('L_if_you_plan_to_keep_any_of_the_existing_beneficiaries_you_must') }}
              </li>
              <li class="mb-4">
                {{ $t('L_if_you_do_not_enter_any_of_your_current_beneficiaries_they_will_be_removed') }}
              </li>
            </ul>
          </v-flex>
          <v-flex xs12 mt-8>
            <v-card-actions class="justify-center">
              <v-flex xs8 text-xs-center>
                <v-btn class="pl-4 pr-4 mb-4" :class="$vuetify.breakpoint.xsOnly ? 'buttonFormat_mobile' : 'buttonFormat'" color="primary" @click="hideOriginVorTwithNoIrrevocable">
                  <h3 class="heading_three font-weight-bold font-size--18">
                    {{ $t('L_ok_got_it') }}
                  </h3>
                </v-btn>
              </v-flex>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-flex>
        <span role="heading" aria-level="2" class="pl-2 pr-2 card-title font-size--24">
          {{ $t('L_update_beneficiaries') }}
        </span>
      </v-flex>
    </v-card-title>
    <v-divider pb-2 />
    <!-- <v-layout>
      <v-flex>
        {{ beneficiaryPrimary }}
      </v-flex>
      <v-flex>
        {{ beneficiaryPrimaryContingent }}
      </v-flex>
      <v-flex>
        {{ beneficiarySpouse }}
      </v-flex>
      <v-flex>
        {{ beneficiarySpouseContingent }}
      </v-flex>
    </v-layout> -->
    <div>
      <v-layout row wrap v-if="beneficiaryPrimary && fetchDataForVAndTPrimaryPrimary.length === 0">
        <v-flex class="paddingLRDesktop mt-4">
          <span font-size--16>
            {{ $t('L_you_can_review_add_or_modify_v2') }}
          </span>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="fetchDataForVAndTPrimaryPrimary && fetchDataForVAndTPrimaryPrimary.length > 0">
        <v-flex xs12 class="paddingLRDesktop mt-4 mb-4">
          {{ $t('L_for_your_reference_below_is_a_list_of_the_current') }}
        </v-flex>
        <v-flex xs12 class="paddingLRDesktop">
          <span><img alt="icon report" src="../../../../assets/tkn-icons-md-filled-ic-report.png"></span>
          <span class="ml-2" v-html="$t('L_remember_if_your_would_like_to_retain_any_of_the_beneficiaries')" />
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="locale === 'en_US'">
        <v-flex class="paddingLRDesktop mt-4">
          <span @click="openGuide" font-size--16 font-weight-bold v-html="$t('L_check_out_this_guide_for_key_considerations_when_naming_beneficiaries')" />
          <span><v-icon @click="openGuide" class="cursor_pointer" color="primary">help</v-icon></span>
        </v-flex>
      </v-layout>
    </div>
    <!-- Click to Show v    Displayed only for Data in T or V.  -->
    <div v-if="fetchDataForVAndTPrimaryPrimary && fetchDataForVAndTPrimaryPrimary.length > 0">
      <!-- <v-layout pt-2 pb-2 row wrap>
        <v-flex class="paddingLRDesktop bold600">
          {{ $t('L_the_following_are_currently_listed_as_beneficiaries') }}
          <span v-if="!showCurrentListForTandV">
            <span class="linkTextWOUnderline bold600 paddingLRDesktop" @click="showCurrentListForTandV = true"> {{ $t('L_click_to_show') }} <v-icon color="#02579e">expand_more</v-icon> </span>
          </span>
          <span v-if="showCurrentListForTandV">
            <span class="linkTextWOUnderline bold600 paddingLRDesktop" @click="showCurrentListForTandV = false"> {{ $t('L_click_to_hide') }} <v-icon color="#02579e">expand_less</v-icon> </span>
          </span>
        </v-flex>
      </v-layout> -->
      <v-layout row wrap pb-4 mt-4 mb-4 zv-if="showCurrentListForTandV" class="paddingLRDesktop grayBackground">
        <v-flex xs12 sm6>
          <v-layout row wrap>
            <v-flex xs12 font-size--16 font-weight-bold mt-4>
              {{ primaryInsured }}
            </v-flex>
            <v-flex xs12 font-size-14 font-weight-bold mt-2>
              {{ $t('L_Primary') }}
            </v-flex>
            <v-flex xs12 font-size-14 font-weight-regular mt-2 v-for="(obj, index) in fetchDataForVAndTPrimaryPrimary" :key="index + 'VTPrimaryPrimary'">
              <span> {{ obj.fullName }} </span>
            </v-flex>
            <v-flex xs12 font-size-14 font-weight-bold mt-4>
              {{ $t('L_Contingent') }}
            </v-flex>
            <v-flex xs12 v-for="(obj, index) in fetchDataForVAndTPrimaryContingent" :key="index + 'VTPrimaryContingent'">
              <span> {{ obj.fullName }} </span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm6>
          <v-layout row wrap>
            <div v-if="fetchDataForVAndTSpousePrimary && fetchDataForVAndTSpousePrimary.length > 0">
              <v-flex xs12 font-size--16 font-weight-bold mt-4 pt-4>
                {{ spouseInsured }}
              </v-flex>
              <v-flex xs12 font-size-14 font-weight-bold mt-2>
                {{ $t('L_Primary') }}
              </v-flex>
              <v-flex xs12 font-size-14 font-weight-regular mt-2 v-for="(obj, index) in fetchDataForVAndTSpousePrimary" :key="index + 'VTSpousePrimary'">
                <span> {{ obj.fullName }} </span>
              </v-flex>
              <v-flex xs12 font-size-14 font-weight-bold mt-4>
                {{ $t('L_Contingent') }}
              </v-flex>
              <v-flex xs12 v-for="(obj, index) in fetchDataForVAndTSpouseContingent" :key="index + 'VTSpouseContingent'">
                <span> {{ obj.fullName }} </span>
              </v-flex>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
    <!-- <v-layout>
      <v-flex xs12>
        <div>beneficiaryHasOriginVorTWithIrrevocable - {{ beneficiaryHasOriginVorTWithIrrevocable }}</div>
        <div>beneficiaryHasOriginVorT - {{ beneficiaryHasOriginVorT }}</div>
        <div>isBeneficiaryDataDirty - {{ isBeneficiaryDataDirty }} </div>
      </v-flex>
    </v-layout> -->
    <!--Primary - Primary Beneficiary Section -->
    <div v-if="primaryInsured && primaryInsured.length > 0" class="mt-4 paddingLRDesktop">
      <v-layout row wrap>
        <!-- <v-flex xs12>
          REMOVE ME
          <div>primaryInsured {{ primaryInsured }}</div>
          <div>beneficiaryPrimary {{ beneficiaryPrimary }}</div>
          <div>beneficiaryPrimaryContingent {{ beneficiaryPrimaryContingent }}</div>
          <div>-----------------------------------------------------------</div>
          <div>beneficiariesTotalPrimaryPrimary {{ beneficiariesTotalPrimaryPrimary }}</div>
          <div>beneficiariesTotalPrimaryContingent {{ beneficiariesTotalPrimaryContingent }}</div>
          <div>-----------------------------------------------------------</div>
          <div>showSpouseSection {{ showSpouseSection === true }}</div>
          <div>spouseInsured {{ spouseInsured }}</div>
          <div>beneficiarySpouse {{ beneficiarySpouse }}</div>
          <div>beneficiarySpouseContingent {{ beneficiarySpouseContingent }}</div>
          <div>-----------------------------------------------------------</div>
          <div>beneficiariesTotalSpousePrimary {{ beneficiariesTotalSpousePrimary }}</div>
          <div>beneficiariesTotalSpouseContingent {{ beneficiariesTotalSpouseContingent }}</div>
          REMOVE ME
        </v-flex> -->
        <v-flex xs12 font-size--24>
          <span class="font-weight-medium">{{ $t('L_policy_insured') }}</span><span class="font-weight-bold"> {{ primaryInsured }}</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="bottomBorderGray">
        <v-flex xs6 pt-2 pb-0 font-size--16 font-weight-bold text-xs-left>
          {{ $t('L_primary_beneficiary') }}
          <v-icon
            tabindex="0"
            color="#02579E"
            small
            class="pri-inline-icon pl-1 cursor-pointer"
            size="18"
            style="margin-bottom: 2px;"
            aria-label="coverage amount information"
            @blur="primaryBeneficiaryTooltip = !primaryBeneficiaryTooltip"
            @focus="primaryBeneficiaryTooltip = !primaryBeneficiaryTooltip"
          >
            info
          </v-icon>
          <v-tooltip
            slot="append"
            content-class="text-xs-left"
            nudge-left="60"
            right
            :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50' : '70'"
            :max-width="this.$vuetify.breakpoint.mdAndUp ? '650' : '162'"
            v-model="primaryBeneficiaryTooltip"
            color="primary"
          >
            <v-icon slot="activator" />
            <span role="tooltip" aria-live="polite" id="policy_number_tooltip">
              <ul v-if="isCanadian">{{ $t('L_the_primary_beneficiary_is_CA') }}</ul>
              <ul v-else>{{ $t('L_the_primary_beneficiary_is_US') }}</ul>
              <ul v-if="isCanadian">{{ $t('L_an_assignee_will_take_priority_CA') }}</ul>
            </span>
          </v-tooltip>
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-right>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-divider pb-2 />
      <v-layout row wrap v-if="beneficiaryPrimary && beneficiaryPrimary.length === 0">
        <v-flex xs12 pt-2 pb-2 pl-2 pr-2 font-size--14 class="red-bkgrnd">
          <v-icon color="red">
            info
          </v-icon><span class="pl-2" v-html="$t('L_note_there_must_be_at_lease_one_designated_primary_beneficiary')" />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiaryPrimary"
        :key="beneficiary.beneKeyId + 'primaryBeneficiary' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData"
      >
        <v-flex xs6 font-size--16 text-xs-left class="paddingLRDesktop textOverflow marginTop15">
          <span v-if="beneficiary.irrevocable" class="iconSpace">
            {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
          </span>
          <span v-else @click="editBeneficiary(beneficiary)">
            <v-icon color="#02579e" class="noLinkText">edit</v-icon>
            <span class="linkText">
              {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
            </span>
          </span>
        </v-flex>
        <v-flex xs3 font-size--18 text-xs-center zclass="paddingLRDesktop">
          <span v-if="!beneficiary.irrevocable && (beneficiary.beneficiaryEntityType === 'X' || beneficiary.beneficiaryEntityType === 'Y')">
            <v-text-field
              class="cssBeneficiaryPercent"
              v-model="beneficiary.beneficiaryPercent"
              :autocomplete="'off'"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="3"
              :error-messages="(beneficiary.beneficiaryPercent === '0' || beneficiary.beneficiaryPercent === '' ||
                parseInt(beneficiary.beneficiaryPercent)>100 || parseInt(beneficiary.beneficiaryPercent)< 0) ? $t('L_number_between_1_and_100'): ''"
              @keydown="onKeyDown"
              solo
              suffix="%"
            />
          </span>
          <span v-else class="fixedAllocationPercentMargin">
            {{ beneficiary.beneficiaryPercent }} %
          </span>
        </v-flex>
        <v-flex xs3 font-size--16 text-xs-right class="paddingLRMobile marginTop15">
          <span v-if="beneficiary.irrevocable">
            <v-icon>lock</v-icon>
            <v-tooltip
              left
              html="true"
              open-on-click
              close-delay="2000"
            >
              <template v-slot:activator="{ on }">
                <sup>
                  <v-icon v-on="on" color="#02579E" small class="pri-inline-icon pl-1 cursor-pointer" size="18">
                    info
                  </v-icon>
                </sup>
              </template>
              <span>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_one') }}</ul>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_two') }}</ul>
                <ul v-if="isCanadian">{{ $t('L_irrevocable_beneficiary_tooltip_three') }}</ul>
              </span>
            </v-tooltip>
          </span>
          <span
            v-else
            class="delete_right_space"
            @click="
              beneKeyIdToDelete = beneficiary.beneKeyId;
              beneTypeToDelete = 'P';
              showDeleteWarning = true;
              beneFullNameToDelete = beneficiary.fullName;
            "
          >
            <v-icon color="#04549c">delete</v-icon>
          </span>
        </v-flex>
      </v-layout>
      <div class="dividerAboveTotal" />
      <v-layout
        row
        wrap
        v-if="beneficiaryPrimary && beneficiaryPrimary.length > 0"
        :class="beneficiariesTotalPrimaryPrimary === 100 ? 'blue-bkgrnd' : 'red-bkgrnd'"
        class="layoutHeightVertCenterTotal"
      >
        <v-flex xs6 font-size--20 font-weight-bold class="paddingLRDesktop">
          {{ $t('L_total') }}
          <span class="font-size--14 font-weight-bold mustEqual100Error" v-if="beneficiariesTotalPrimaryPrimary !== 100"> {{ $t('L_must_equal_hundred') }}</span>
        </v-flex>
        <v-flex xs3 font-size--20 font-weight-bold text-xs-center :class="beneficiariesTotalPrimaryPrimary === 100 ? 'blackText' : 'redText'" class="pl-0 pr-0">
          {{ beneficiariesTotalPrimaryPrimary }}% <span v-if="beneficiariesTotalPrimaryPrimary !== 100"><v-icon color="#bc1f1f">error</v-icon></span>
        </v-flex>
        <v-flex xs3>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <div class="font-size--16 pt-2 pb-4 link_text" @click="addBeneficiaryDetails('P')">
          <v-icon color="#04549c" pr-2>
            add_circle
          </v-icon>
        </div>
        <div color="#04549c" class="pt-2 pb-4 linkToAddBeneficiary font-size--16 link_text" @click="addBeneficiaryDetails('P')">
          {{ $t('L_add_primary_beneficiary') }}
        </div>
      </v-layout>
    </div>
    <!-- Primary - Contingent Beneficiary -->
    <div v-if="primaryInsured && primaryInsured.length > 0" class="mt-2 paddingLRDesktop">
      <v-layout row wrap class="bottomBorderGray">
        <v-flex xs6 pt-2 pb-0 font-size--16 font-weight-bold text-xs-left>
          {{ $t('L_contingent_beneficiary') }}
          <v-icon
            tabindex="0"
            color="#02579E"
            small
            class="pri-inline-icon pl-1 cursor-pointer"
            size="18"
            style="margin-bottom: 2px;"
            aria-label="coverage amount information"
            @blur="primaryContingentBeneficiaryTooltip = !primaryContingentBeneficiaryTooltip"
            @focus="primaryContingentBeneficiaryTooltip = !primaryContingentBeneficiaryTooltip"
          >
            info
          </v-icon>
          <v-tooltip
            slot="append"
            content-class="text-xs-left"
            nudge-left="60"
            right
            :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50' : '70'"
            :max-width="this.$vuetify.breakpoint.mdAndUp ? '650' : '162'"
            v-model="primaryContingentBeneficiaryTooltip"
            color="primary"
          >
            <v-icon slot="activator" />
            <span role="tooltip" aria-live="polite" id="policy_number_tooltip">
              <ul v-if="isCanadian">{{ $t('L_the_contingent_beneficiary_is_CA') }}</ul>
              <ul v-else>{{ $t('L_the_contingent_beneficiary_is_US') }}</ul>
              <ul v-if="isCanadian">{{ $t('L_contingent_beneficiaries_will_be_CA') }}</ul>
            </span>
          </v-tooltip>
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-right>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-divider pb-2 />
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiaryPrimaryContingent"
        :key="beneficiary.beneKeyId + 'primaryContingentBeneficiary' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData"
      >
        <v-flex xs6 font-size--16 text-xs-left class="paddingLRDesktop textOverflow marginTop15">
          <span v-if="beneficiary.irrevocable" class="iconSpace">
            {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
          </span>
          <span v-else @click="editBeneficiary(beneficiary)">
            <v-icon color="#02579e" class="noLinkText">edit</v-icon>
            <span class="linkText">
              {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
            </span>
          </span>
        </v-flex>
        <v-flex xs3 font-size--18 text-xs-center zclass="paddingLRDesktop">
          <span v-if="!beneficiary.irrevocable && (beneficiary.beneficiaryEntityType === 'X' || beneficiary.beneficiaryEntityType === 'Y')">
            <v-text-field
              class="cssBeneficiaryPercent"
              v-model="beneficiary.beneficiaryPercent"
              :autocomplete="'off'"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="3"
              :error-messages="(beneficiary.beneficiaryPercent === '0' || beneficiary.beneficiaryPercent === '' ||
                parseInt(beneficiary.beneficiaryPercent)>100 || parseInt(beneficiary.beneficiaryPercent)< 0) ? $t('L_number_between_1_and_100'): ''"
              @keydown="onKeyDown"
              persistent-hint
              solo
              suffix="%"
            />
          </span>
          <span v-else class="fixedAllocationPercentMargin">
            {{ beneficiary.beneficiaryPercent }} %
          </span>
        </v-flex>
        <v-flex xs3 font-size--16 text-xs-right class="paddingLRMobile marginTop15">
          <span v-if="beneficiary.irrevocable">
            <v-icon>lock</v-icon>
            <v-tooltip
              left
              html="true"
              open-on-click
              close-delay="2000"
            >
              <template v-slot:activator="{ on }">
                <sup>
                  <v-icon v-on="on" color="#02579E" small class="pri-inline-icon pl-1 cursor-pointer" size="18">
                    info
                  </v-icon>
                </sup>
              </template>
              <span>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_one') }}</ul>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_two') }}</ul>
                <ul v-if="isCanadian">{{ $t('L_irrevocable_beneficiary_tooltip_three') }}</ul>
              </span>
            </v-tooltip>
          </span>
          <span
            v-else
            class="delete_right_space"
            @click="
              beneKeyIdToDelete = beneficiary.beneKeyId;
              beneTypeToDelete = 'C';
              showDeleteWarning = true;
              beneFullNameToDelete = beneficiary.fullName;
            "
          >
            <v-icon color="#04549c">delete</v-icon>
          </span>
        </v-flex>
      </v-layout>
      <div class="dividerAboveTotal" />
      <v-layout
        row
        wrap
        v-if="beneficiaryPrimaryContingent && beneficiaryPrimaryContingent.length > 0"
        :class="beneficiariesTotalPrimaryContingent === 100 ? 'blue-bkgrnd' : 'red-bkgrnd'"
        class="layoutHeightVertCenterTotal"
      >
        <v-flex xs6 font-size--20 font-weight-bold class="paddingLRDesktop">
          {{ $t('L_total') }}
          <span class="font-size--14 font-weight-bold mustEqual100Error" v-if="beneficiariesTotalPrimaryContingent !== 100"> {{ $t('L_must_equal_hundred') }}</span>
        </v-flex>
        <v-flex xs3 font-size--20 font-weight-bold text-xs-center :class="beneficiariesTotalPrimaryContingent === 100 ? 'blackText' : 'redText'" class="pl-0 pr-0">
          {{ beneficiariesTotalPrimaryContingent }}% <span v-if="beneficiariesTotalPrimaryContingent !== 100"><v-icon color="#bc1f1f">error</v-icon></span>
        </v-flex>
        <v-flex xs3>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <div class="font-size--16 pt-2 pb-4 link_text" @click="addBeneficiaryDetails('C')">
          <v-icon color="#04549c" pr-2>
            add_circle
          </v-icon>
        </div>
        <div color="#04549c" class="pt-2 pb-4 linkToAddBeneficiary font-size--16 link_text" @click="addBeneficiaryDetails('C')">
          {{ $t('L_add_contingent_beneficiary') }}
        </div>
      </v-layout>
    </div>
    <!--Spouse - Primary Beneficiary Section -->
    <div v-if="showSpouseSection" class="mt-4 paddingLRDesktop">
      <v-layout row wrap>
        <v-flex xs12 font-size--24 font-weight-bold>
          <span class="font-weight-medium">{{ $t('L_policy_spouse_insured') }}</span><span class="font-weight-bold"> {{ spouseInsured }}</span>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="bottomBorderGray">
        <v-flex xs6 pt-2 pb-0 font-size--16 font-weight-bold text-xs-left>
          {{ $t('L_primary_beneficiary') }}
          <v-icon
            tabindex="0"
            color="#02579E"
            small
            class="pri-inline-icon pl-1 cursor-pointer"
            size="18"
            style="margin-bottom: 2px;"
            aria-label="coverage amount information"
            @blur="spousePrimaryBeneficiaryTooltip = !spousePrimaryBeneficiaryTooltip"
            @focus="spousePrimaryBeneficiaryTooltip = !spousePrimaryBeneficiaryTooltip"
          >
            info
          </v-icon>
          <v-tooltip
            slot="append"
            content-class="text-xs-left"
            nudge-left="60"
            right
            :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50' : '70'"
            :max-width="this.$vuetify.breakpoint.mdAndUp ? '650' : '162'"
            v-model="spousePrimaryBeneficiaryTooltip"
            color="primary"
          >
            <v-icon slot="activator" />
            <span role="tooltip" aria-live="polite" id="policy_number_tooltip">
              <ul v-if="isCanadian">{{ $t('L_the_primary_beneficiary_is_CA') }}</ul>
              <ul v-else>{{ $t('L_the_primary_beneficiary_is_US') }}</ul>
              <ul v-if="isCanadian">{{ $t('L_an_assignee_will_take_priority_CA') }}</ul>
            </span>
          </v-tooltip>
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-right>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-divider pb-2 />
      <v-layout row wrap v-if="beneficiarySpouse && beneficiarySpouse.length === 0">
        <v-flex xs12 pt-2 pb-2 pl-2 pr-2 font-size--14 class="red-bkgrnd">
          <v-icon color="red">
            info
          </v-icon><span class="pl-2" v-html="$t('L_note_there_must_be_at_lease_one_designated_primary_beneficiary')" />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiarySpouse"
        :key="beneficiary.beneKeyId + 'primaryBeneficiary' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData"
      >
        <v-flex xs6 font-size--16 text-xs-left class="paddingLRDesktop textOverflow marginTop15">
          <span v-if="beneficiary.irrevocable" class="iconSpace">
            {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
          </span>
          <span v-else @click="editBeneficiary(beneficiary)">
            <v-icon color="#02579e" class="noLinkText">edit</v-icon>
            <span class="linkText">
              {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
            </span>
          </span>
        </v-flex>
        <v-flex xs3 font-size--18 text-xs-center zclass="paddingLRDesktop">
          <span v-if="!beneficiary.irrevocable && (beneficiary.beneficiaryEntityType === 'X' || beneficiary.beneficiaryEntityType === 'Y')">
            <v-text-field
              class="cssBeneficiaryPercent"
              v-model="beneficiary.beneficiaryPercent"
              :autocomplete="'off'"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="3"
              :error-messages="(beneficiary.beneficiaryPercent === '0' || beneficiary.beneficiaryPercent === '' ||
                parseInt(beneficiary.beneficiaryPercent)>100 || parseInt(beneficiary.beneficiaryPercent)< 0) ? $t('L_number_between_1_and_100'): ''"
              @keydown="onKeyDown"
              persistent-hint
              solo
              suffix="%"
            />
          </span>
          <span v-else class="fixedAllocationPercentMargin">
            {{ beneficiary.beneficiaryPercent }} %
          </span>
        </v-flex>
        <v-flex xs3 font-size--16 text-xs-right class="paddingLRMobile marginTop15">
          <span v-if="beneficiary.irrevocable">
            <v-icon>lock</v-icon>
            <v-tooltip
              left
              html="true"
              open-on-click
              close-delay="2000"
            >
              <template v-slot:activator="{ on }">
                <sup>
                  <v-icon v-on="on" color="#02579E" small class="pri-inline-icon pl-1 cursor-pointer" size="18">
                    info
                  </v-icon>
                </sup>
              </template>
              <span>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_one') }}</ul>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_two') }}</ul>
                <ul v-if="isCanadian">{{ $t('L_irrevocable_beneficiary_tooltip_three') }}</ul>
              </span>
            </v-tooltip>
          </span>
          <span
            v-else
            class="delete_right_space"
            @click="
              beneKeyIdToDelete = beneficiary.beneKeyId;
              beneTypeToDelete = 'S';
              showDeleteWarning = true;
              beneFullNameToDelete = beneficiary.fullName;
            "
          >
            <v-icon color="#04549c">delete</v-icon>
          </span>
        </v-flex>
      </v-layout>
      <div class="dividerAboveTotal" />
      <v-layout row wrap v-if="beneficiarySpouse && beneficiarySpouse.length > 0" :class="beneficiariesTotalSpousePrimary === 100 ? 'blue-bkgrnd' : 'red-bkgrnd'" class="layoutHeightVertCenterTotal">
        <v-flex xs6 font-size--20 font-weight-bold class="paddingLRDesktop">
          {{ $t('L_total') }}
          <span class="font-size--14 font-weight-bold mustEqual100Error" v-if="beneficiariesTotalSpousePrimary !== 100"> {{ $t('L_must_equal_hundred') }}</span>
        </v-flex>
        <v-flex xs3 font-size--20 font-weight-bold text-xs-center :class="beneficiariesTotalSpousePrimary === 100 ? 'blackText' : 'redText'" class="pl-0 pr-0">
          {{ beneficiariesTotalSpousePrimary }}% <span v-if="beneficiariesTotalSpousePrimary !== 100"><v-icon color="#bc1f1f">error</v-icon></span>
        </v-flex>
        <v-flex xs3>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <div class="font-size--16 pt-2 pb-4 link_text" @click="addBeneficiaryDetails('S')">
          <v-icon color="#04549c" pr-2>
            add_circle
          </v-icon>
        </div>
        <div color="#04549c" class=" pt-2 pb-4 linkToAddBeneficiary font-size--16 link_text" @click="addBeneficiaryDetails('S')">
          {{ $t('L_add_primary_beneficiary') }}
        </div>
      </v-layout>
    </div>
    <!-- Spouse - Contingent Beneficiary -->
    <div v-if="showSpouseSection" class="mt-2 paddingLRDesktop">
      <v-layout row wrap class="bottomBorderGray">
        <v-flex xs6 pt-2 pb-0 font-size--16 font-weight-bold text-xs-left>
          {{ $t('L_contingent_beneficiary') }}
          <v-icon
            tabindex="0"
            color="#02579E"
            small
            class="pri-inline-icon pl-1 cursor-pointer"
            size="18"
            style="margin-bottom: 2px;"
            aria-label="coverage amount information"
            @blur="spouseContingentBeneficiaryTooltip = !spouseContingentBeneficiaryTooltip"
            @focus="spouseContingentBeneficiaryTooltip = !spouseContingentBeneficiaryTooltip"
          >
            info
          </v-icon>
          <v-tooltip
            slot="append"
            content-class="text-xs-left"
            nudge-left="60"
            right
            :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50' : '70'"
            :max-width="this.$vuetify.breakpoint.mdAndUp ? '650' : '162'"
            v-model="spouseContingentBeneficiaryTooltip"
            color="primary"
          >
            <v-icon slot="activator" />
            <span role="tooltip" aria-live="polite" id="policy_number_tooltip">
              <ul v-if="isCanadian">{{ $t('L_the_contingent_beneficiary_is_CA') }}</ul>
              <ul v-else>{{ $t('L_the_contingent_beneficiary_is_US') }}</ul>
              <ul v-if="isCanadian">{{ $t('L_contingent_beneficiaries_will_be_CA') }}</ul>
            </span>
          </v-tooltip>
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
        <v-flex xs3 pt-2 pb-0 font-size--16 font-weight-bold text-xs-right>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-divider pb-2 />
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiarySpouseContingent"
        :key="beneficiary.beneKeyId + 'primaryContingentBeneficiary' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData"
      >
        <v-flex xs6 font-size--16 text-xs-left class="paddingLRDesktop textOverflow marginTop15">
          <span v-if="beneficiary.irrevocable" class="iconSpace">
            {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
          </span>
          <span v-else @click="editBeneficiary(beneficiary)">
            <v-icon color="#02579e" class="noLinkText">edit</v-icon>
            <span class="linkText">
              {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
            </span>
          </span>
        </v-flex>
        <v-flex xs3 font-size--18 text-xs-center zclass="paddingLRDesktop">
          <span v-if="!beneficiary.irrevocable && (beneficiary.beneficiaryEntityType === 'X' || beneficiary.beneficiaryEntityType === 'Y')">
            <v-text-field
              class="cssBeneficiaryPercent"
              v-model="beneficiary.beneficiaryPercent"
              :autocomplete="'off'"
              inputmode="numeric"
              pattern="[0-9]*"
              maxlength="3"
              :error-messages="(beneficiary.beneficiaryPercent === '0' || beneficiary.beneficiaryPercent === '' ||
                parseInt(beneficiary.beneficiaryPercent)>100 || parseInt(beneficiary.beneficiaryPercent)< 0) ? $t('L_number_between_1_and_100'): ''"
              @keydown="onKeyDown"
              persistent-hint
              solo
              suffix="%"
            />
          </span>
          <span v-else class="fixedAllocationPercentMargin">
            {{ beneficiary.beneficiaryPercent }} %
          </span>
        </v-flex>
        <v-flex xs3 font-size--16 text-xs-right class="paddingLRMobile marginTop15">
          <span v-if="beneficiary.irrevocable">
            <v-icon>lock</v-icon>
            <v-tooltip
              left
              html="true"
              open-on-click
              close-delay="2000"
            >
              <template v-slot:activator="{ on }">
                <sup>
                  <v-icon v-on="on" color="#02579E" small class="pri-inline-icon pl-1 cursor-pointer" size="18">
                    info
                  </v-icon>
                </sup>
              </template>
              <span>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_one') }}</ul>
                <ul>{{ $t('L_irrevocable_beneficiary_tooltip_two') }}</ul>
                <ul v-if="isCanadian">{{ $t('L_irrevocable_beneficiary_tooltip_three') }}</ul>
              </span>
            </v-tooltip>
          </span>
          <span
            v-else
            class="delete_right_space"
            @click="
              beneKeyIdToDelete = beneficiary.beneKeyId;
              beneTypeToDelete = 'H';
              showDeleteWarning = true;
              beneFullNameToDelete = beneficiary.fullName;
            "
          >
            <v-icon color="#04549c">delete</v-icon>
          </span>
        </v-flex>
      </v-layout>
      <div class="dividerAboveTotal" />
      <v-layout
        row
        wrap
        v-if="beneficiarySpouseContingent && beneficiarySpouseContingent.length > 0"
        :class="beneficiariesTotalSpouseContingent === 100 ? 'blue-bkgrnd' : 'red-bkgrnd'"
        class="layoutHeightVertCenterTotal"
      >
        <v-flex xs6 font-size--20 font-weight-bold class="paddingLRDesktop">
          {{ $t('L_total') }}
          <span class="font-size--14 font-weight-bold mustEqual100Error" v-if="beneficiariesTotalSpouseContingent !== 100"> {{ $t('L_must_equal_hundred') }}</span>
        </v-flex>
        <v-flex xs3 font-size--20 font-weight-bold text-xs-center :class="beneficiariesTotalSpouseContingent === 100 ? 'blackText' : 'redText'" class="pl-0 pr-0">
          {{ beneficiariesTotalSpouseContingent }}% <span v-if="beneficiariesTotalSpouseContingent !== 100"><v-icon color="#bc1f1f">error</v-icon></span>
        </v-flex>
        <v-flex xs3>
          &nbsp;
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <div class="font-size--16 pt-2 pb-4 link_text" @click="addBeneficiaryDetails('H')">
          <v-icon color="#04549c" pr-2>
            add_circle
          </v-icon>
        </div>
        <div color="#04549c" class="pt-2 pb-4 linkToAddBeneficiary font-size--16 link_text" @click="addBeneficiaryDetails('H')">
          {{ $t('L_add_contingent_beneficiary') }}
        </div>
      </v-layout>
    </div>
    <!-- Disclaimer below Beneficiary Section -->
    <v-divider color="#ffffff" />
    <div class="paddingLRDesktop">
      <v-layout row wrap mt-4 mb-4>
        <v-flex font-size--14 zclass="max-width-40">
          <v-icon color="rgba(0,0,0,0.34)">
            lock
          </v-icon>
          <span class="font-weight-bold" v-html="$t('L_Note')" />
          <span>
            {{ $t('L_beneficiaries_with_this_icon_represent_an_irrevocable_beneficiary') }}
            {{ $t("L_an_irrevocable_beneficiary") }}
            {{ $t("L_the_only_time_rights_can") }}
            {{ $t("L_the_irrevocable_beneficiary_is") }}
            {{ $t("L_to_change_the_status_of_an_irrevocable_beneficiary_please_complete") }}
            <span @click="openIrrevocableChangeForm" class="help-link">{{ $t("L_irrevocable_beneficiary_designation_and_change_form") }}</span>
          </span>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 pt-4 pb-4>
          <span class="font-size--14 font-weight-regular">
            {{ $t('L_the_above_beneficiaries_will_be_recorded') }}
            <span @click="openIrrevocableChangeForm" class="help-link">{{ $t("L_irrevocable_beneficiary_designation_and_change_form") }}</span>
          </span>
          <div v-if="locale==='fr_CA' || locale=== 'en_CA'" class="font-size--14 pt-4">
            {{ $t('L_in_quebec_all_spouses_who_are_designated_a_beneficiary_are') }}
          </div>
        </v-flex>
      </v-layout>
      <!-- <v-layout row wrap>
        <v-flex pt-4 pb-4 text-xs-center font-size--14>
          <span>{{ $t('L_if_you_need_assistance_please_visit') }}</span>
          <span @click="openHelp" class="help-link">{{ $t('L_help') }}</span>
          <span>{{ $t('L_section') }}</span>
        </v-flex>
      </v-layout> -->
      <!-- <v-layout row wrap>
        <v-flex pb-4 mt-4 mb-4 :class="$vuetify.breakpoint.xsOnly?'':'padding-space-left-40'">
          <span v-html="$t('L_please_request_a_paper_beneficiary_designation_from_your_servicing_representative', {custPhone:customerServicePhoneNum})">&nbsp;</span>
        </v-flex>
      </v-layout> -->
    </div>
    <StickyContainer>
      <span slot="stickycontent">
        <v-layout row wrap justify-center>
          <v-flex xs12 text-xs-center>
            <v-btn id="mybeneficiary_cancel" class="pl-4 pr-4 buttonFormat" color="secondary" @click="showMessageDialog = true">
              <h3 class="heading_three font-weight-bold font-size--18">
                {{ $t('L_cancel') }}
              </h3>
            </v-btn>
            <v-btn id="mybeneficiary_submit" class="pl-4 pr-4 buttonFormat" color="primary" @click="submitChanges">
              <h3 class="heading_three font-weight-bold font-size--18">
                {{ $t('L_review_changes') }}
              </h3>
            </v-btn>
          </v-flex>
        </v-layout>
      </span>
    </StickyContainer>
  </v-card>
</template>

<script>
// import { required, maxLength } from 'vuelidate/lib/validators';
import { Config } from '@primerica/vue-components';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { openInNewWindow } from '@/mixins/openNewWindow';
import bff from '@/shared/api/bff';
import MessageDialog from '@/components/MessageDialog.vue';
import WarningDialogBeneficiary from './WarningDialogBeneficiary.vue';
import StickyContainer from '../../../../../../../components/StickyContainer/StickyContainer.vue';

export default {
  name: 'MyBeneficiary',
  components: {
    WarningDialogBeneficiary,
    bff,
    MessageDialog,
    StickyContainer,
    /* EmailInput,
    Alert,
    PhoneNumberInput,
    AddressInput,
    PostalCodeInput,
    CityInput,
    StateInput,
    TerritoryInput,
    ZipCodeInput,
    EditContactInfo, */
  },
  data: () => ({
    showCurrentListForTandV: false,
    editing: false,
    showEditContactInfo: false,
    showDeleteWarning: false,
    beneKeyIdToDelete: 0,
    beneTypeToDelete: '',
    beneFullNameToDelete: '',
    displayErrorDialogDuringSubmitClick: false,
    persistent: true,
    showOriginVorTwithNoIrrevocable: false,
    showMessageDialog: false,
    primaryBeneficiaryTooltip: false,
    primaryContingentBeneficiaryTooltip: false,
    spousePrimaryBeneficiaryTooltip: false,
    spouseContingentBeneficiaryTooltip: false,
  }),
  mixins: [openInNewWindow],
  props: {
    comingFromEditBeneficiary: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('policy', {
      policyData: 'currentPolicy',
      beneficiaries: 'beneficiaries',
      beneficiaryPrimary: 'beneficiaryPrimary',
      beneficiaryPrimaryContingent: 'beneficiaryPrimaryContingent',
      beneficiarySpouse: 'beneficiarySpouse',
      beneficiarySpouseContingent: 'beneficiarySpouseContingent',
      beneficiariesTotalPrimaryPrimary: 'beneficiariesTotalPrimaryPrimary',
      beneficiariesTotalPrimaryContingent: 'beneficiariesTotalPrimaryContingent',
      beneficiariesTotalSpousePrimary: 'beneficiariesTotalSpousePrimary',
      beneficiariesTotalSpouseContingent: 'beneficiariesTotalSpouseContingent',
      // showSpouseSection: 'showSpouseSection',
      fetchDataForVAndTPrimaryPrimary: 'fetchDataForVAndTPrimaryPrimary',
      fetchDataForVAndTPrimaryContingent: 'fetchDataForVAndTPrimaryContingent',
      fetchDataForVAndTSpousePrimary: 'fetchDataForVAndTSpousePrimary',
      fetchDataForVAndTSpouseContingent: 'fetchDataForVAndTSpouseContingent',

      /*
      addressVerification: 'addressVerification',
      addressUpdateSuccess: 'addressUpdateSuccess',
      */
      policyOwner: 'policyOwner',
      currentPolicy: 'currentPolicy',
      contactDetailsPanel: 'contactDetailsPanel',
      editContactInfo: 'editContactInfo',
      policies: 'policies',
      beneficiaryStep: 'beneficiaryStep',
      beneficiaryHasOriginVorTWithIrrevocable: 'beneficiaryHasOriginVorTWithIrrevocable',
      beneficiaryHasOriginVorT: 'beneficiaryHasOriginVorT',
      isBeneficiaryDataDirty: 'isBeneficiaryDataDirty',
    }),
    isCanadian() {
      return this.currentPolicy?.isCanadaPolicy;
    },
    invalidBeneficiaryPrimary() {
      if (this.beneficiaryPrimary && this.beneficiaryPrimary.length > 0) {
        return this.beneficiaryPrimary.filter(obj => obj.beneficiaryPercent === '' || obj.beneficiaryPercent === '0' ||
                parseInt(obj.beneficiaryPercent, 10) <= 0 || parseInt(obj.beneficiaryPercent, 10) > 100).length > 0;
      } return false;
    },
    invalidBeneficiaryPrimaryContingent() {
      if (this.beneficiaryPrimaryContingent && this.beneficiaryPrimaryContingent.length > 0) {
        return this.beneficiaryPrimaryContingent.filter(obj => obj.beneficiaryPercent === '' || obj.beneficiaryPercent === '0' ||
                parseInt(obj.beneficiaryPercent, 10) <= 0 || parseInt(obj.beneficiaryPercent, 10) > 100).length > 0;
      } return false;
    },
    invalidBeneficiarySpouse() {
      if (this.showSpouseSection) {
        return this.beneficiarySpouse.filter(obj => obj.beneficiaryPercent === '' || obj.beneficiaryPercent === '0' ||
                parseInt(obj.beneficiaryPercent, 10) <= 0 || parseInt(obj.beneficiaryPercent, 10) > 100).length > 0;
      } return false;
    },
    invalidBeneficiarySpouseContingent() {
      if (this.beneficiarySpouseContingent && this.beneficiarySpouseContingent.length > 0) {
        return this.beneficiarySpouseContingent.filter(obj => obj.beneficiaryPercent === '' || obj.beneficiaryPercent === '0' ||
                  parseInt(obj.beneficiaryPercent, 10) <= 0 || parseInt(obj.beneficiaryPercent, 10) > 100).length > 0;
      } return false;
    },
    showSpouseSection() {
      if (this.policyData?.data?.spouseFirstName) {
        return `${this.policyData?.data?.spouseFirstName} ${this.policyData?.data?.spouseLastName}`;
      }
      return '';
    },
    primaryInsured() {
      return `${this.currentPolicy?.primaryInsured?.firstName} ${this.currentPolicy?.primaryInsured?.lastName}` || '';
    },
    spouseInsured() {
      if (this.currentPolicy?.data?.spouseLastName) {
        return `${this.currentPolicy?.data?.spouseFirstName} ${this.currentPolicy?.data?.spouseLastName}`;
      }
      return this.$t('L_policy_beneficiaries_spouse');
    },
    disablebutton() {
      const disableButton = false;
      // if (this.beneficiariesTotalPrimaryPrimary !== 100) {
      //   // Primary Primary check
      //   disableButton = true;
      // }
      // if (this.beneficiaryPrimaryContingent && this.beneficiaryPrimaryContingent.length > 0) {
      //   // Primary Contingent Data check
      //   if (this.beneficiariesTotalPrimaryContingent !== 100) {
      //     // Primary Contingent sum not 100 check
      //     disableButton = true;
      //   }
      // }
      // if (this.showSpouseSection) {
      //   // We check in beneficiary response if there was spouse
      //   if (this.beneficiariesTotalSpousePrimary !== 100) {
      //     // Spouse Primary sum not 100 check
      //     disableButton = true;
      //   }
      //   if (this.beneficiarySpouseContingent && this.beneficiarySpouseContingent.length > 0) {
      //     // Spouse contingent data check
      //     if (this.beneficiariesTotalSpouseContingent !== 100) {
      //       // Spouse Contingent sum not 100 check
      //       disableButton = true;
      //     }
      //   }
      // }
      return disableButton;
    },
    customerServicePhoneNum() {
      if (this.locale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.locale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.locale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
    howToChooseBeneficiariesLetter() {
      return 'https://assets.contentstack.io/v3/assets/blta73ea17d36c39575';
    },
  },
  methods: {
    ...mapActions('policy', {
      setBeneficiaryStep: 'setBeneficiaryStep',
      setBeneficiaries: 'setBeneficiaries',
    }),
    ...mapMutations('policy', {
      setAddressUpdateSuccess: 'setAddressUpdateSuccess',
      setAddressVerification: 'setAddressVerification',
      setContactInfoEdit: 'setContactInfoEdit',
      setBeneficiaryPrimary: 'setBeneficiaryPrimary',
      setBeneficiaryPrimaryContingent: 'setBeneficiaryPrimaryContingent',
      setBeneficiarySpouse: 'setBeneficiarySpouse',
      setBeneficiarySpouseContingent: 'setBeneficiarySpouseContingent',
    }),
    onKeyDown(e) {
      // backspace, tab, arrow keys, insert, delete,
      // number on top of keyboard,  number on numpad
      if ((e.keyCode === 8) || (e.keyCode === 9) || (e.keyCode >= 35 && e.keyCode <= 46) ||
      (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.key !== '!' && e.key !== '@' && e.key !== '#' && e.key !== '$'
        && e.key !== '%' && e.key !== '^' && e.key !== '&' && e.key !== '*'
        && e.key !== '(' && e.key !== ')') {
          // Above is to ensure that when space is being pressed it returns same keycode as numbers so we need
          // to look at the key to make sure that the keys are not special characters laid on top of the numbers
          return; // 0-9 only
        }
        e.preventDefault();
      }
      e.preventDefault();
    },
    openIrrevocableChangeForm() {
      if (this.locale === 'fr_CA') {
        window.open(Config.get('life.Canada_French_Irrevocable_change_form'), '_blank');
      } else if (this.locale === 'en_CA') {
        window.open(Config.get('life.Canada_English_Irrevocable_change_form'), '_blank');
      } else {
        window.open(Config.get('life.US_Irrevocable_change_form'), '_blank');
      }
    },
    async openGuide() {
      if (this.locale === 'fr_CA') {
        await this.openInNewWindow({ url: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt972a3c22b7e1ff57/How_to_choose_beneficiaries_letter.pdf', target: '_blank' });
      } else {
        await this.openInNewWindow({ url: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt453e8a85ee22ed66/How_to_Choose_Beneficiaries_Letter.pdf', target: '_blank' });
      }
    },
    getFullNameForDisplayWithLocale(fullName) {
      let retFullName = fullName;
      if (this.locale === 'fr_CA' || this.locale === 'es_US') {
        if (fullName === 'Estate') {
          retFullName = this.$t('L_estate');
        } else if (fullName === 'All Children Equally') {
          retFullName = this.$t('L_all_children_equally');
        } else {
          return retFullName;
        }
      }
      return retFullName;
    },
    closeDialogForSubmitErrors() {
      this.displayErrorDialogDuringSubmitClick = false;
    },
    async openHelp() {
      const helpUrl = await bff.getRightAnswersHelpUrl();
      const url = 'https://primerica.rightanswers.com/portal/app/portlets/results/viewsolution.jsp?solutionid=220328133937970&';
      // Current data returned as we are grabbing everything after ss? and appending to our url above which should end in '&'
      //  "URL":"https://primericastg.rightanswers.com/portal/ss?companycode=primerica&tabid=2&authID=primericaJwt&group=Client Portal&jwtoken=eyJ0eXAiOiJKV1QiLA0KICJhbGc"
      const finalDestination = encodeURI(url + helpUrl?.data?.URL.split('ss?')[1]);
      await this.openInNewWindow({ url: finalDestination, target: 'RADashboardWinName' });
    },
    addBeneficiaryDetails(beneficiaryType) {
      this.$emit('set-type', beneficiaryType);
      this.setBeneficiaryStep(2);
      try {
        const tempDynatraceString = `PC2_UpdateBenf_Add_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${this.beneTypeToDelete}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
    },
    deleteBeneficiary() {
      const beneId = this.beneKeyIdToDelete;
      const beneType = this.beneTypeToDelete;
      let beneObjToDel = null;
      let currBenef = null;
      if (beneType === 'P') {
        currBenef = this.beneficiaryPrimary.filter(ben => ben.beneKeyId !== beneId);
        beneObjToDel = this.beneficiaryPrimary.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'C') {
        currBenef = this.beneficiaryPrimaryContingent.filter(ben => ben.beneKeyId !== beneId);
        beneObjToDel = this.beneficiaryPrimaryContingent.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'S') {
        currBenef = this.beneficiarySpouse.filter(ben => ben.beneKeyId !== beneId);
        beneObjToDel = this.beneficiarySpouse.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'H') {
        currBenef = this.beneficiarySpouseContingent.filter(ben => ben.beneKeyId !== beneId);
        beneObjToDel = this.beneficiarySpouseContingent.filter(ben => ben.beneKeyId === beneId);
      }
      if (currBenef !== null) {
        if (beneType === 'P') {
          this.setBeneficiaryPrimary(currBenef);
        } else if (beneType === 'C') {
          this.setBeneficiaryPrimaryContingent(currBenef);
        } else if (beneType === 'S') {
          this.setBeneficiarySpouse(currBenef);
        } else if (beneType === 'H') {
          this.setBeneficiarySpouseContingent(currBenef);
        }
      }
      this.beneKeyIdToDelete = 0;
      this.beneTypeToDelete = '';
      this.beneFullNameToDelete = '';
      this.showDeleteWarning = false;
      try {
        const fullName = beneObjToDel[0].fullName?.replaceAll(' ', '_');
        const tempDynatraceString = `PC2_UpdateBenf_Delete_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${this.beneTypeToDelete}_Name-${fullName}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
    },
    editBeneficiary(bene) {
      this.$emit('set-beneficiary', bene);
      this.setBeneficiaryStep(3);
      try {
        const fullName = bene.fullName.replaceAll(' ', '_');
        const tempDynatraceString = `PC2_UpdateBenf_Edit_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${bene.beneficiaryType}_Name-${fullName}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
    },
    hideWarningDialog() {
      this.showDeleteWarning = false;
      const beneId = this.beneKeyIdToDelete;
      const beneType = this.beneTypeToDelete;
      let beneObjToDel = null;
      if (beneType === 'P') {
        beneObjToDel = this.beneficiaryPrimary.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'C') {
        beneObjToDel = this.beneficiaryPrimaryContingent.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'S') {
        beneObjToDel = this.beneficiarySpouse.filter(ben => ben.beneKeyId === beneId);
      } else if (beneType === 'H') {
        beneObjToDel = this.beneficiarySpouseContingent.filter(ben => ben.beneKeyId === beneId);
      }
      try {
        const fullName = beneObjToDel[0].fullName?.replaceAll(' ', '_');
        const tempDynatraceString = `PC2_UpdateBenf_CancelDelete_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${this.beneTypeToDelete}_Name-${fullName}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
    },
    closeThisPage() {
      this.setBeneficiaryStep(-1);
      try {
        const tempDynatraceString = `PC2_UpdateBenf_Cancel_Policynum-${this.currentPolicy?.contractId}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
    },
    async goToGuide() {
      await this.openInNewWindow({ url: 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt453e8a85ee22ed66/How_to_Choose_Beneficiaries_Letter.pdf', target: '_blank' });
    },
    async submitChanges() {
      if (this.beneficiariesTotalPrimaryPrimary !== 100) {
        // Primary Primary check
        this.displayErrorDialogDuringSubmitClick = true;
      }
      if (this.beneficiaryPrimaryContingent && this.beneficiaryPrimaryContingent.length > 0) {
        // Primary Contingent Data check
        if (this.beneficiariesTotalPrimaryContingent !== 100) {
          // Primary Contingent sum not 100 check
          this.displayErrorDialogDuringSubmitClick = true;
        }
      }
      if (this.showSpouseSection) {
        // We check in beneficiary response if there was spouse
        if (this.beneficiariesTotalSpousePrimary !== 100) {
          // Spouse Primary sum not 100 check
          this.displayErrorDialogDuringSubmitClick = true;
        }
        if (this.beneficiarySpouseContingent && this.beneficiarySpouseContingent.length > 0) {
          // Spouse contingent data check
          if (this.beneficiariesTotalSpouseContingent !== 100) {
            // Spouse Contingent sum not 100 check
            this.displayErrorDialogDuringSubmitClick = true;
          }
        }
      }
      if (this.invalidBeneficiaryPrimary || this.invalidBeneficiaryPrimaryContingent || this.invalidBeneficiarySpouse || this.invalidBeneficiarySpouseContingent) {
        this.displayErrorDialogDuringSubmitClick = true;
      }
      if (this.displayErrorDialogDuringSubmitClick === true) {
        return;
      }
      // Show confirmation page
      this.setBeneficiaryStep(4);
      try {
        const i = this.$dynatrace.enterAction('updateBeneficiaries_submit');
        this.$dynatrace.leaveAction(i);
        const tempDynatraceString = `PC2_UpdateBenf_Submit_Policynum-${this.currentPolicy?.contractId}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const j = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(j);
      } catch (error) {
        /*  */
      }
    },
    hideOriginVorTwithNoIrrevocable() {
      this.showOriginVorTwithNoIrrevocable = false;
    },
  },
  mounted() {
    try {
      window.scrollTo(0, 0);
      if (this.beneficiaryStep === 1) {
        if (!this.beneficiaryHasOriginVorTWithIrrevocable && this.beneficiaryHasOriginVorT && !this.isBeneficiaryDataDirty && !this.comingFromEditBeneficiary) {
          this.showOriginVorTwithNoIrrevocable = true;
        }
      }
    } catch (e) {
      throw e;
    }
  },
};
</script>


<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main'
.cursor_pointer {
  cursor: pointer;
}
.redbar {
  border-top: 8px solid #ec5555;
}
.bluebar {
  border-top: 8px solid #3399ff;
}
.grayBackground {
  background-color: #f9f9f9;
}
.dialog_header_label {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
}
.dialog_header_label_mobile {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.dialog_close_icon {
  font-size: 40px !important;
}
.dialog_close_icon_mobile {
  font-size: 25px !important;
  margin-top: -5px;
}
.dialog_body_text {
  font-size: 18px;
  font-weight: regular;
  padding-bottom: 80px;
}
.dialog_body_text_mobile {
  font-size: 13px;
  font-weight: regular;
  padding-bottom: 40px;
}
.dialog_button {
  margin-top: 50px;
  font-size: 20px;
  font-weight: bold;
}
.dialog_button_mobile {
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
}
.help-link {
  font-weight: 600;
  color: #02579e !important;
  text-decoration: none;
  cursor: pointer;
}
.dividerAboveTotal {
  height: 20px;
  border-top: 1px solid #d4d4d4;
}
.bottomBorderGray {
  border-bottom: 1px solid #cccccc;
}
.layoutHeightVertCenterData {
  line-height: 3;
  height: 80px;
}
.layoutHeightVertCenterTotal {
  line-height: 1.2;
  zheight: 56px;
  padding-top:10px;
  padding-bottom: 10px;
}
.delete_right_space {
  padding-right: 17px;
  cursor: pointer;
}
.link_text {
  cursor: pointer;
}
.max-width-40 {
  max-width: 40px !important;
}
.padding-space-left-40 {
  padding-left: 40px;
}
.marginTop15 {
  margin: 15px 0 0 0;
}
.paddingLRMobile {
 padding-left: 10px;
 padding-right:10px;
}
.paddingLRDesktop {
  padding-left: 20px;
  padding-right: 20px;
}
.paddingLDesktop {
  padding-left: 20px;
}
.mustEqual100{
  color: #666666;
}
.mustEqual100Error{
  color: red;
}
.bkgrndColorDarkGray {
  background-color: #f5f5f5;
}
.bkgrndColorLightGray {
  background-color: #f9f9f9;
}
.bkgrndColorLightWhite {
  background-color: #ffffff;
}
.bold600{
  font-weight: 600;
}
.tdecor {
  text-decoration: underline;
}
.linkToAddBeneficiary {
  font-size: 14px;
  font-weight: bold;
  color: #02579e;
  zmargin: auto;
  margin-left: 5px;
}
.linkTextWOUnderline
  color: #02579e;
  cursor: pointer;
.linkText
  color: #02579e;
  cursor: pointer;
  text-decoration: underline;
.noLinkText
  text-decoration: none;
.move-right
  float: right;
.bkgrnd-color-white
  background-color: #ffffff;
  zheight: 48px;
.bkgrnd-color-blue-white
  background-color: #f5f7fa;
  zheight: 48px;
.blue-bkgrnd
  background-color: #e2f0fe;
  border-top: 1px solid #d4d4d4;
  zheight: 48px;
.red-bkgrnd
  background-color: #fdecec;
  border-top: 1px solid #dd3333;
  zheight: 48px;
.redText
  color: #dd3333;
.blackText
  color: #424242;
.buttonFormat
  width: 300px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
.buttonFormat_mobile
  width: 180px;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
.sm-pl-5
  @media $display-breakpoints.sm-and-up
    padding-left: 40px;

@media $display-breakpoints.md-and-up
  .pad-left-md
    padding-left: 12px
  .pad-right-md
    padding-right: 12px

.blue-border {
  border: solid 2px #2196f3;
  cursor pointer;
}

.select-address {
  color: #2196f3 !important;
}

/deep/ label.v-label--active {
  font-weight: 500;
  color: #333;
}

/deep/ div.v-input {
  padding-bottom: 12px;
}

h3.heading_three.font-weight-medium {
  font-weight: 500 !important;
}
.font-size-md {
  font-size: 16px !important;
}
.inlineText {
  display: inline-text;
}
button.short {
  height: 40px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px #063562 solid;
  width: auto !important;
}
.textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.heavyDivider {
  border: 2px solid #adadad;
}
.cssBeneficiaryPercent {
  font-size: 18px !important;
  padding: 5px 0 5px 0 !important;
  line-height: 1 !important;
  width: 52px;
  position: relative;
  margin: auto !important;
  margin-top: 5px !important;
  text-align: right !important;
  display: inline-block !important;
}
.cssBeneficiaryPercent >>> input {
  text-align: right !important;
  padding-right: 5px !important;
  color: #02579e !important;
}

.cssBeneficiaryPercent >>> .v-input__slot {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cssBeneficiaryPercent >>> .v-text-field__details {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.fixedAllocationPercentMargin {
  margin-top: 10px;
  display: block;
}
.iconSpace {
  padding-left: 29px;
}
</style>

<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <v-layout
      class="justify-center"
    >
      <v-flex
        xs11
      >
        <Alerts context="payment" />
        <v-card class="card-ach-wrapper">
          <v-card-title
            class="justify-space-around text-xs-center"
          >
            <h2 class="heading_two">
              {{ $t('L_confirm_your_premium_payment_info') }}
            </h2>
          </v-card-title>
          <v-card-text
            class="background-light-grey pl-4"
          >
            <v-layout
              class="mb-4"
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ $t('L_monthly_bank_draft') }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_amount') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ currency(bankInfo.draftAmount) }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountTypeString }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Two -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_holder_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountName }}</span>
                </div>
              </v-flex> -->
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountNumber | hideNums }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.routingNumber | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Three -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Four -->
            <v-layout
              v-if="!!dateFormatted"
              row
              wrap
              mb-2
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_next_payment_draft_date') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ dateFormatted }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_future_payment_day_heading') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ draftDay(bankInfo.draftDay) }} {{ $t('L_future_payment_day_content') }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-layout
            row
            wrap
            mt-4
            pt2
          >
            <v-flex xs12>
              <div class="disclaimer-wrapper">
                <v-layout
                  row
                  wrap
                >
                  <V-flex
                    xs12
                    mb-4
                  >
                    <span class="font-weight-medium">{{ $t(disclaimerTitle) }}</span>
                  </V-flex>
                  <v-flex
                    xs12
                    class="context"
                    v-html="$t(disclaimerBody)"
                  />
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                mt-3
                class="text-xs-center"
              >
                <v-checkbox
                  color="primary"
                  v-model="accepted"
                  :label="$t('L_by_checking_the_box_and_authorizing')"
                />
              </v-flex>
              <v-flex
                class="text-sm-right text-xs-center"
              >
                <v-layout
                  row
                  wrap
                  class="justify-end"
                >
                  <v-flex
                    xs12
                    sm2
                    :class="$vuetify.breakpoint.smAndUp ? 'mr-4':''"
                  >
                    <v-btn
                      block
                      class="mb-3"
                      color="secondary"
                      @click="cancel"
                    >
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    sm2
                  >
                    <v-btn
                      block
                      class="white--text mb-3"
                      :disabled="!accepted"
                      @click="updateBank"
                      color="primary"
                    >
                      {{ $t('L_i_authorize') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Alerts from '@/components/Alerts.vue';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import { filters } from '@/mixins/filters';

export default {
  name: 'UpdateBankTwo',
  mixins: [filters],
  components: { Alerts },
  props: {
    bankInfo: {
      type: Object,
      required: true,
      default: () => {},
    },
    isUpdateBank: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    accepted: false,
    disclaimer: '',
  }),
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      locale: 'getLocale',
      policy: 'policy/currentPolicy',
    }),
    ...mapGetters('customer', ['customerProfile']),
    successMessage() {
      if (this.isUpdateBank) {
        return 'L_update_bank_success';
      }
      return 'L_direct_to_ach_success';
    },
    disclaimerTitle() {
      return `L_disclaimer_title_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    disclaimerBody() {
      return `L_disclaimer_body_${this.isCanadaPolicy ? 'ca' : 'us'}`;
    },
    isCanadaPolicy() {
      return this.policy?.isCanadaPolicy;
    },
    dateFormatted() {
      return formatDate(this.policy?.paymentInfo?.paidToDate, this.dateFormat);
    },
  },
  methods: {
    ...mapMutations({
      setPaymentInfoShowPaymentAmountDateBillingMethod: 'policy/setPaymentInfoShowPaymentAmountDateBillingMethod',
    }),
    updateBank() {
      try {
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_ConfirmChange_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      // Banner
      this.checked();
      const paymentInfo = {
        nextCycleDate: this.bankInfo.nextCycleDate,
        draftAmount: this.bankInfo.draftAmount.replace(',', ''), // Remove comma
        reqMode: this.bankInfo.reqMode,
        draftDay: this.bankInfo.draftDay,
        emailAddress: this.customerProfile.email,
        language: this.locale,
        bankAccount: {
          accountName: this.bankInfo.accountName,
          routingNumber: this.bankInfo.routingNumber,
          accountNumber: this.bankInfo.accountNumber,
          accountType: this.bankInfo.accountType === 1 ? '3' : ' ',
        },
      };
      this.$store.dispatch('policy/updateBillingMode', {
        paymentInfo,
        policyNumber: this.bankInfo.policyNumber,
      }).then(async (response) => {
        if (response) {
          try {
            const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_ChangesSubmitted_Success`);
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
          /*
            * Emit a custom action when a user successfully submits a bank/payment info change
            * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
          */
          const m = (this.isUpdateBank ? 'bank_info_changes_saved' : 'payment_info_changes_saved');
          const i = this.$dynatrace.enterAction(m);
          this.$dynatrace.leaveAction(i);
          this.$emit('goToStepThree', this.bankInfo);
          this.showCompleteBanner();
          const resStatus = await this.refreshPolicies();
          if (resStatus === 200) {
            await this.refreshPaymentData();
          }
        } else {
          try {
            const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_ChangesSubmitted_Failure_updateBillingModeError`);
            this.$dynatrace.leaveAction(i);
          } catch (error) { /*  */ }
        }
      }).catch((err) => {
        try {
          const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_ChangesSubmitted_Failure_${err}`);
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
      });
      this.setPaymentInfoShowPaymentAmountDateBillingMethod(true);
    },
    async refreshPolicies() {
      return this.$store.dispatch('policy/getPolicies');
    },
    async refreshPaymentData() {
      this.$store.dispatch('policy/getPaymentInfo', this.bankInfo.policyNumber);
    },
    checked() {
      if (this.accepted) {
        this.showSuccessBanner();
      } else {
        this.showErrorBanner('L_you_must_check_box_to_authorize');
      }
    },
    showSuccessBanner() {
      this.$store.commit('addAlert', {
        type: 'success',
        details: { message: this.$t('L_a_monthly_payment_will_be_deducted', { amount: `${this.bankInfo.draftAmount}` }) },
        context: 'payment',
        autoDismiss: false,
      });
    },
    showCompleteBanner() {
      this.$store.commit('addAlert', {
        type: 'success',
        details: { message: this.$t(this.successMessage, { email: this.customerProfile.email }) },
        context: 'payment',
        autoDismiss: false,
      });
    },
    showErrorBanner(error) {
      this.$store.commit('addError', { error: { key: this.$t(error) }, context: 'payment' });
    },
    cancel() {
      try {
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_CancelChange_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goToStepOne');
      this.clearBank();
    },
    clearBank() {
      this.$store.commit('policy/clearBank');
    },
    draftDay(value) {
      return value.charAt(0) === '1' ? `${value}th` : this.addExt(value);
    },
    addExt(val) {
      let ext;
      switch (val.charAt(1)) {
        case '1':
        case '21':
          ext = 'st';
          break;
        case '2':
        case '22':
          ext = 'nd';
          break;
        case '3':
        case '23':
          ext = 'rd';
          break;
        default:
          ext = 'th';
      }
      const num = val.charAt(0) === '0' ? val.slice(1) : val;
      return `${num}${ext}`;
    },
    currency(value) {
      return `$${value}`;
    },
  },
};
</script>

<style scoped lang="stylus">
  .grey-ada-text
    color: #737171
  .disclaimer-wrapper
    overflow-y: scroll;
    max-height: 263px;
    min-height: 250px;
    border-radius: 2px;
    border: solid 1px #979797;
    padding: 20px;
  .card-ach-wrapper
    position: relative;
  .fixed-top-sm-message-banner
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    z-index: 1;

  #alerts {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 1;
  }

</style>

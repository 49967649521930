import NoLeftSpaceLayout from '../../layouts/NoLeftSpaceLayout.vue';
import PostLoginLobby from './PostLoginLobby.vue';
import PreLoginLobby from './PreLoginLobby.vue';
import AppUpdate from './preLogin/appupdate/AppUpdate.vue';
import UnsupportedBrowser from './preLogin/unsupportedbrowser/UnsupportedBrowser.vue';
import MaintenanceMode from './preLogin/maintenance/Maintenance.vue';
import BiometricsSplash from './postLogin/biometrics/Biometrics.vue';
import PolicyAcknowledgement from './postLogin/policyacknowledgement/PolicyAcknowledgementAction.vue';
import TermsOfUse from './postLogin/termsofuse/TermsOfUseModal.vue';
import PostLogoutPorch from './PostLogoutPorch.vue';
import LoginLandingPage from './loginLandingPage/LoginLandingPage.vue';

export default {
  path: '/app',
  name: 'LoginShell',
  component: NoLeftSpaceLayout,
  children: [
    {
      name: 'PostLoginLobby',
      path: '',
      component: PostLoginLobby,
      meta: {
        pageTitle: 'Landing.PostLoginLobby',
      },
      children: [
        {
          name: 'BiometricsSplash',
          path: 'biometricsSplash',
          component: BiometricsSplash,
        },
        {
          name: 'PolicyAcknowledgement',
          path: 'policyAcknowledgement',
          component: PolicyAcknowledgement,
        },
        {
          name: 'TermsOfUse',
          path: 'termsOfUse',
          component: TermsOfUse,
        },
      ],
    },
    {
      name: 'PreLoginLobby',
      path: 'pre',
      component: PreLoginLobby,
      meta: {
        pageTitle: 'Landing.PreLoginLobby',
      },
      children: [
        {
          path: '',
        },
        {
          name: 'AppUpdate',
          path: 'appupdate',
          component: AppUpdate,
        },
        {
          name: 'UnsupportedBrowser',
          path: 'unsupportedBrowser',
          component: UnsupportedBrowser,
        },
        {
          name: 'MaintenanceMode',
          path: 'maintenanceMode',
          component: MaintenanceMode,
        },
      ],
    },
    {
      name: 'PostLogoutPorch',
      path: 'postLogoutPorch',
      component: PostLogoutPorch,
    },
    {
      name: 'LoginLandingPage',
      path: 'loginLandingPage',
      component: LoginLandingPage,
    },
  ],
};

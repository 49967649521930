<i18n src='./locales.json'></i18n>

<template>
  <v-content
    class="policy-overview-content pt-0"
    role="presentation"
  >
    <v-container>
      <v-layout
        row
        wrap
        fill-height
        class="white--text"
        :class="{ 'mb-4': $vuetify.breakpoint.mdAndUp }"
      >
        <DashboardMessageSlider />
        <TermLife />

        <InvestmentsCard />
        <DebtSolutionsCard />
        <!-- My Support -->
        <v-flex xs12>
          <MySupport
            v-if="agent"
            class="my-support-dashboard"
            @contact-rep="sendEmail"
            :sender="sender"
            :agent="agent"
          />
        </v-flex>
        <WhyPrimerica
          :is-canada-policy="isCanadian"
        />
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>

import { MySupport, sendRepEmail } from '@primerica/vue-components';
import { mapGetters } from 'vuex';
import bff from '../../../shared/api/bff';
import TermLife from './components/TermLife/TermLife.vue';
import WhyPrimerica from './components/WhyPrimerica/WhyPrimerica.vue';
import InvestmentsCard from './components/Investments/InvestmentsCard.vue';
import DebtSolutionsCard from '../../debtsolutions/components/DebtSolutionsCard/DebtSolutionsCard.vue';
import DashboardMessageSlider from './components/DashboardMessageSlider.vue';
import { COUNTRIES } from '../../../shared/constants';

export default {
  name: 'Dashboard',
  components: {
    TermLife,
    WhyPrimerica,
    InvestmentsCard,
    DebtSolutionsCard,
    DashboardMessageSlider,
    MySupport,
  },
  computed: {
    ...mapGetters('customer', ['customerProfile', 'countryOfOrigin', 'investmentsSortedByDate']),
    ...mapGetters(['agents', 'getAgent']),
    ...mapGetters('policy', {
      policyData: 'currentPolicy',
      policies: 'policies',
      selectedPolicyIndex: 'selectedPolicyIndex',
      policiesSortedByDate: 'policiesSortedByDate',
    }),
    isCanadian() {
      return this.countryOfOrigin === COUNTRIES.CANADA;
    },
    sender() {
      return {
        email: this.customerProfile?.email ? this.customerProfile?.email : '',
        phone: this.customerProfile?.email ? this.customerProfile?.mobilePhone : '',
        firstName: this.customerProfile?.firstName ? this.customerProfile?.firstName : '',
        lastName: this.customerProfile?.lastName ? this.customerProfile?.lastName : '',
      };
    },
    agent() {
      return (this.agents?.length === 1) ? this.agents[0] : null;
    },
  },
  methods: {
    sendEmail(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .container {
    max-width: 960px !important;
  }
  .my-support-dashboard {
    display: block;
    @media only screen and (min-width: 1264px) {
      display: none;
    }
  }
</style>

<i18n src='../../locales.json'></i18n>
<template>
  <v-card
    class="canada-beneficiary-card"
  >
    <v-card-text
      class="text-xs-center"
    >
      <v-layout
        class="font-size--16 pb-4"
        row
        wrap
        justify-center
      >
        <v-flex xs12>
          <span>
            {{ $t('L_to_change_your_beneficiaries_first') }}
          </span>
          <span>
            <a
              href="javascript:void(0)"
              id="ca_doc_multi-purpose"
              @click="openDocument(formLinks['multiPurposeForm'], $t('L_multi_purpose_form'))"
              class="subheading font-weight-medium"
            >
              {{ $t('L_to_change_your_beneficiaries_multi_purpose_change_form') }}
            </a>
          </span>
          <span>{{ $t('L_to_change_your_beneficiaries_last') }}</span>
        </v-flex>
        <EmbeddedViewer
          :title="document.title"
          :loading="document.loading"
          content-type="application/pdf"
          :content-url="document.content"
          @close="document.content = null"
        />
      </v-layout>
      <v-layout
        class="font-size--14"
        row
        wrap
        justify-center
      >
        <!-- <v-flex xs12>
          <span class="font-weight-medium" >{{ $t('L_policy_submit_written_request') }}</span>
        </v-flex> -->
        <v-flex
          xs12
        >
          <span>
            {{ $t('L_policy_beneficiaries_company_CA') }}
          </span>
        </v-flex>
        <v-flex
          xs12
        >
          <span class="beneficiaries-submit-address">
            {{ $t('L_policy_submit_written_request_address_ca') }}
          </span>
        </v-flex>
        <v-flex
          xs12
          md12
          pb-4
        >
          <span class="beneficiaries-submit-note">
            {{ $t('L_policy_submit_written_request_include_note') }}
          </span>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Config from '@/shared/util/config';
import { FEATURE_FLAGS } from '@/shared/constants';
import { mapGetters } from 'vuex';
import EmbeddedViewer from '@/components/EmbeddedViewer.vue';

const phone = [
  { href: '18002654804', num: '(800) 265-4804' },
  { href: '18003877876', num: '(800) 387-7876' },
];
export default {
  name: 'CABeneficiaryCard',
  components: {
    EmbeddedViewer,
  },
  props: {
    isQuebec: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    document: {
      title: '',
      content: '',
      loading: false,
    },
  }),
  computed: {
    ...mapGetters(['containsFeature', 'features']),
    ...mapGetters({
      beneficiaries: 'policy/beneficiaries',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    displayBeneficiaries() {
      return this.containsFeature(FEATURE_FLAGS.BENEFICIARIES) && (this.beneficiaries && this.beneficiaries?.length > 0);
    },
    phone() {
      return this.isQuebec ? phone[0] : phone[1];
    },
    formLinks() {
      if (this.locale === 'en_CA') {
        return { ...Config.get('policyDocumentForms.en_CA') };
      }
      return { ...Config.get('policyDocumentForms.fr_CA') };
    },
  },
  methods: {
    openDocument(content, title) {
      this.document.title = title;
      this.document.content = `${Config.get('content_stack')}${content}`;
    },
  },
};
</script>


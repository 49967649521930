import content from '@/shared/api/content';
import bff from '@/shared/api/bff';
import { FEATURE_FLAGS } from '@/shared/constants';

export const getInvestments = async ({ commit, getters, rootGetters }) => {
  try {
    // Check the feature flag
    const investmentsFlag = rootGetters.containsFeature(FEATURE_FLAGS.INVESTMENTS);
    const calculatorFlag = rootGetters.containsFeature(FEATURE_FLAGS.RETIREMENT_CALCULATOR);
    if (investmentsFlag || calculatorFlag) {
      let features;
      if (investmentsFlag && !calculatorFlag) {
        features = FEATURE_FLAGS.INVESTMENTS;
      } else if (!investmentsFlag && calculatorFlag) {
        features = FEATURE_FLAGS.RETIREMENT_CALCULATOR;
      }
      commit('setLoading', { investments: true });
      const investments = await bff.getInvestments(getters.customerProfile.country, features);
      commit('setInvestments', investments);

      // Get agents for investments
      if (investments?.groups?.length > 0) {
        const ids = [];
        investments.groups.forEach((group) => {
          group.accounts.forEach((a) => {
            if (a.agentId && !rootGetters.getAgent(a.agentId) && !ids.includes(a.agentId)) {
              ids.push(a.agentId);
            }
          });
        });
        if (ids.length > 0) {
          const agents = await bff.getAgents(ids);
          commit('addAgents', agents, { root: true });
        }
      }
    }
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setLoading', { investments: false });
  }
};

export const getInvestmentStatements = async ({ commit }) => {
  try {
    commit('setLoading', { investments: true });
    const response = await bff.getInvestmentStatementMetadata();
    commit('setInvestmentStatements', response.statements);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setLoading', { investments: false });
  }
};

// ***
// calls content manager
// TODO: move this to a common store module based on usage
export const openStatement = async ({ commit }, statementMetadata) => {
  try {
    commit('setLoading', { investmentStatements: true });
    const binaryStatement = await content.streamDocument(statementMetadata);
    commit('setBinaryInvestmentStatement', binaryStatement);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setLoading', { investmentStatements: false });
  }
};

export const updateCustomerProfile = ({ commit, state }, profile) => {
  commit('updateCustomerProfile', Object.assign({}, state.customerProfile, profile));
};

export const getInvestmentCalculator = async ({ commit }, scenario) => {
  try {
    commit('setLoading', { retirementCalculator: true });
    const res = await bff.getInvestmentCalculator(scenario);
    commit('setInvestmentCalculator', res);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    commit('setLoading', { retirementCalculator: false });
  }
};

export const getSamlToken = async ({ commit }, scenario) => {
  try {
    // commit('setLoading', { retirementCalculator: true });
    const res = await bff.getSamlToken(scenario);
    commit('setSamlToken', res?.samltoken);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  } finally {
    // commit('setLoading', { retirementCalculator: false });
  }
};

export const contactRepresentative = async ({ commit }, emailPayload) => {
  try {
    await bff.sendRepEmail(emailPayload);
  } catch (e) {
    commit('addError', { error: e, context: 'app' }, { root: true });
  }
};

<i18n src='../../locales.json'></i18n>

<template>
  <v-flex
    xs12
    class="stationary-sidebar"
    :style="{
      'padding-top': (padding) + 'px',
    }"
  >
    <SidebarDesktop
      :get-progress-prop="getProgress"
      :include-spouse-prop="includeSpouse"
      :formatted-current-coverage-prop="formattedCurrentCoverage"
      :formatted-need-prop="formattedNeed"
      :additional-need-prop="additionalNeed"
      :formatted-additional-prop="formattedAdditional"
      @updateSelectedTab="updateSelectedTabValue"
      @contact-rep="contactRep = true"
      :display-contact-rep="hasReps"
    />
    <SidebarTablet
      :get-progress-prop="getProgress"
      :include-spouse-prop="includeSpouse"
      :formatted-current-coverage-prop="formattedCurrentCoverage"
      :formatted-need-prop="formattedNeed"
      :additional-need-prop="additionalNeed"
      :formatted-additional-prop="formattedAdditional"
      @updateSelectedTab="updateSelectedTabValue"
      @contact-rep="contactRep = true"
      :display-contact-rep="hasReps"
    />
    <SidebarMobile
      :get-progress-prop="getProgress"
      :include-spouse-prop="includeSpouse"
      :formatted-current-coverage-prop="formattedCurrentCoverage"
      :formatted-need-prop="formattedNeed"
      :additional-need-prop="additionalNeed"
      :formatted-additional-prop="formattedAdditional"
      @updateSelectedTab="updateSelectedTabValue"
      @contact-rep="contactRep = true"
      :display-contact-rep="hasReps"
    />
    <ContactRep
      v-if="hasReps"
      v-model="contactRep"
      :agents="agents"
      @close="contactRep = false"
      close-selector="contact_your_rep_guidance_close"
      modal-class="contact_your_rep_guidance_modal"
      @send-message="sendEmail"
      :sender="sender"
    />
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContactRep, sendRepEmail } from '@primerica/vue-components';
import { formatNumber } from '../../GuidanceCalculator.vue';
import SidebarDesktop from './components/SidebarDesktop/SidebarDesktop.vue';
import SidebarTablet from './components/SidebarTablet/SidebarTablet.vue';
import SidebarMobile from './components/SidebarMobile/SidebarMobile.vue';
import bff from '../../../../../shared/api/bff';

export default {
  name: 'Sidebar',
  components: {
    SidebarDesktop,
    SidebarTablet,
    SidebarMobile,
    ContactRep,
  },
  props: {
    currentCoverage: {
      type: Object,
      required: true,
      default: () => ({
        you: 0,
        spouse: 0,
      }),
    },
  },
  data: () => ({
    padding: 0,
    activeSidebarTab: 0,
    sidebarElement: null,
    scrollListener: undefined,
    topOffset: undefined,
    maxOffset: undefined,
    selectedTab: 0,
    contactRep: false,
  }),
  computed: {
    ...mapGetters('dime', {
      includeSpouse: 'includeSpouse',
      dimeCalculation: 'dimeCalculation',
    }),
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    hasReps() {
      return this.agents?.length > 0;
    },
    currentCoverageAmount() {
      return (this.selectedTab === 0) ? this.currentCoverage.you : this.currentCoverage.spouse;
    },
    formattedCurrentCoverage() {
      return formatNumber(this.currentCoverageAmount);
    },
    formattedNeed() {
      return formatNumber((this.selectedTab === 0) ? this.dimeCalculation.you.totalProtectionNeeded : this.dimeCalculation.spouse.totalProtectionNeeded);
    },
    additionalNeed() {
      return ((this.selectedTab === 0) ? this.dimeCalculation.you.totalProtectionNeeded : this.dimeCalculation.spouse.totalProtectionNeeded) - this.currentCoverageAmount;
    },
    formattedAdditional() {
      return this.additionalNeed > 0 ? formatNumber(Math.ceil(this.additionalNeed / 1000) * 1000) : 0;
    },
    getProgress() {
      if (this.currentCoverageAmount > 0) {
        return (this.currentCoverageAmount / ((this.selectedTab === 0) ? this.dimeCalculation.you.totalProtectionNeeded : this.dimeCalculation.spouse.totalProtectionNeeded)) * 100;
      }
      return 0;
    },
  },
  methods: {
    scrollCallback(sidebarHeight, rect, guidance) {
      return () => {
        const parentHeight = guidance.offsetHeight;
        if (this.$vuetify.breakpoint.smAndDown) {
          this.padding = 0;
          return;
        }
        if (typeof (this.topOffset) === 'undefined' && rect.height > 0) {
          this.topOffset = rect.top - document.querySelector('nav div.v-toolbar__content').offsetHeight;
        }
        this.maxOffset = parentHeight - sidebarHeight;
        if (this.maxOffset <= 0) {
          return;
        }
        const scrollAmount = window.pageYOffset;
        this.padding = scrollAmount < this.topOffset ? 0 : Math.min((scrollAmount - this.topOffset), this.maxOffset);
      };
    },
    resizeCallback() {
      this.topOffset = undefined;
    },
    updateSelectedTabValue(value) {
      this.selectedTab = value;
    },
    sendEmail(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
  },
  mounted() {
    // window.addEventListener('resize', this.resizeCallback);
    // const sidebarHeight = this.$el.offsetHeight;
    // const rect = this.$el.getBoundingClientRect();
    // const guidance = this.$el.parentElement.parentElement.children[0].children[0];
    // this.scrollListener = document.addEventListener('scroll', this.scrollCallback(
    //   sidebarHeight,
    //   rect,
    //   guidance,
    // ).bind(this));
  },
  beforeDestroy() {
    if (this.scrollListener) {
      window.removeEventListener('scroll', this.scrollListener);
    }
  },
};
</script>

<style lang="stylus">
.footer-wrapper
  @media only screen and (min-width: 1023px)
    display none !important
</style>

<i18n src='../../locales.json'></i18n>

<template>
  <v-layout
    row
    wrap
  >
    <v-flex
      d-flex
      align-center
      xs12
      mb-2
    >
      <v-card
        class="quick-info"
        dark
      >
        <v-layout
          row
          mt-4
          mb-4
          class="column-at-xs"
        >
          <v-flex
            grow
            class="what-do-i-have"
          >
            <p
              class="font-weight-medium"
            >
              {{ __t('L_policy_what_do_i_have') }}
            </p>
            <h2
              class="heading_two"
            >
              {{ formattedCoverageAmount }}
            </h2>
            <p
              class="mb-0"
            >
              {{ __t('L_policy_term_life_insurance_coverage') }}
            </p>
          </v-flex>
          <v-divider
            :vertical="$vuetify.breakpoint.smAndUp"
          />
          <v-flex
            class="what-do-i-need"
          >
            <p
              class="font-weight-medium"
            >
              {{ $t('L_policy_what_do_i_need') }}
            </p>
            <h2
              class="heading_two"
            >
              {{ $t('L_policy_keep_up_good_work') }}
            </h2>
            <h4
              class="heading_four"
            >
              {{ $t('L_policy_life_changes') }}
            </h4>
            <h4
              class="heading_four font-weight-medium"
            >
              {{ $t('L_policy_contact_rep') }}
            </h4>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { currency } from '@primerica/vue-components/src/util/formatters';
import __t from '../../translate';

export default {
  props: {
    coverageAmount: {
      type: Number,
      required: false,
      default: undefined,
    },
    isPending: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedCoverageAmount() {
      return currency(this.coverageAmount, 0);
    },
  },
  methods: {
    __t,
  },
};
</script>


<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'

.quick-info
  background-image: linear-gradient(to right,
    rgba(24, 112, 195, 0.57),
    rgba(45, 128, 206, 0.57) 49%,
    rgba(126, 191, 250, 0.57) 77%,
    rgba(0, 88, 171, 0.57));
  .what-do-i-have
    margin-left: $spacers.three.x;
    margin-right: $spacers.three.x;
    @media $display-breakpoints.sm-and-up
      margin-right: $spacers.five.x;
      margin-left: $spacers.four.x;
      max-width: 135px;
    @media $display-breakpoints.md-and-up
      max-width: 207px;
  .what-do-i-need
    margin-left: $spacers.three.x;
    margin-right: $spacers.three.x;
    @media $display-breakpoints.sm-and-up
      margin-left: $spacers.five.x;
      margin-right: 90px;
  .v-divider:not(.v-divider--vertical) {
    margin: $spacers.four.y $spacers.three.x;
  }
</style>

<i18n src='./locales.json'></i18n>
<template>
  <v-card
    id="tou-card"
    class="text-xs-center space_to_go_under_bottom_button term_life_card_css"
  >
    <Alerts class="fixed-top-alert" context="TermsOfUse" />
    <v-card-text class="text_align_center pb-0">
      <v-layout justify-center>
        <v-flex xs12 mb-2 class="pl-0 pr-0 text-xs-left" :class="$vuetify.breakpoint.xsOnly?'font-size--20':'font-size--24'">
          {{ $t('L_our_terms_of_use_have_been_updated') }}
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider pt-4 pb-4 class="line_separator" />
    <v-card-text>
      <v-layout row wrap class="text_align_left pt-4 pb-4">
        <v-flex xs12 font-size--16 mb-2 font-weight-bold>
          {{ $t('L_you_must_accept_the_updated_terms_to_continue_to_use_the_site') }}
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 pb-4 pl-4 pr-4 font-size--16 :class="$vuetify.breakpoint.xsOnly?'iframeContentHeightMobile':'iframeContentHeightDesktop'">
          <iframe
            id="myiFrame"
            :class="$vuetify.breakpoint.xsOnly?'iframe_mobile':'iframe_desktop'"
            frameBorder="0"
            :src="urlForTOU"
            title="Terms of Use"
          >Browser not compatible</iframe>
          <div class="gradientHover pl-4 pr-4">
            &nbsp;
          </div>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12 font-size-18 justify-center>
          <v-divider class=" mb-2 no_border_color mt-4" />
          <v-btn :class="$vuetify.breakpoint.xsOnly?'fixed_bottom_button_mobile':'fixed_bottom_button_desktop'" block color="primary" @click="acceptTOU">
            {{ $t('L_i_accept') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
// import bff from '@/shared/api/bff';
import Alerts from '../Alerts.vue';

export default {
  name: 'TermsOfUseModal',
  components: {
    Alerts,
  },
  props: {
    allowEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    // editIsDisabled: true,
  }),
  computed: {
    ...mapGetters(['tasks']),
    urlForTOU() {
      return this.tasks?.filter(task => task.name === 'TERMS_OF_USE')[0].info.agreementLink;
    },
  },
  mounted() {
    const iframe = document.getElementById('myiFrame');
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
  },
  methods: {
    ...mapActions([
      'updateTermsOfUse',
    ]),
    ...mapMutations(['setTasks']),
    ...mapMutations({
      addError: 'addError',
      addAlert: 'addAlert',
    }),
    async acceptTOU() {
      const tempAgreementId = this.tasks.filter(task => task.name === 'TERMS_OF_USE')[0].info.agreementId;
      const resp = await this.updateTermsOfUse(tempAgreementId);
      if (resp) {
        const origTasks = this.tasks;
        const modTasksWOTOU = origTasks.filter(task => task.name !== 'TERMS_OF_USE');
        this.setTasks(modTasksWOTOU);
      } else {
        // this.addError({ error, context: 'TermsOfUse' });
        this.addAlert({
          type: 'error',
          details: { message: this.$t('L_A_system_error_is_preventing_the_acceptance_for_the_updated_Terms_of_Use_Please_close') },
          context: 'TermsOfUse',
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.term_life_card_css {
  max-width: 960px;
  margin: auto;
}
.line_separator {
  border: 1px solid #bcbaba;
}
.text_align_left {
  text-align: left;
}
.text_align_center {
  text-align: center;
}
.fixed-top-alert {
  position: absolute;
  top: -4px;
  z-index: 1;
}
.iframe_mobile {
  width: 100%;
  border: 2px solid black;
  height: calc(100vh - 395px) !important;
}
.iframe_desktop {
  width: 100%;
  border: 2px solid black;
  height: calc(100vh - 395px);
}
.iframeContentHeightMobile{
  height: calc(100vh - 395px);
}
.iframeContentHeightDesktop{
  height: calc(100vh - 395px);
}
.fixed_bottom_button_mobile {
  bottom: 10px;
  width: 280px !important;
  margin: auto !important;
}
.fixed_bottom_button_desktop {
  bottom: 10px;
  left: 10px;
  right: 10px;
  width: 280px !important;
  margin: auto !important;
}
.space_to_go_under_bottom_button {
  padding-bottom: 150px;
}
.no_border_color {
  border-color: transparent;
}
.zgradientHover {
    position: relative;
    bottom: 51px;
    left: 0;
    background: linear-gradient(rgba);
    width: 100%;
    height: 50px;
    background: -webkit-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: -moz-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: -o-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
    background-image: linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
}
.dividerSpace{
  color: #8a8a8a
}

</style>

<template>
  <v-layout :id="id" class="primerica-benefits" row wrap>
    <v-flex xs12 class="hero">
      <slot name="hero" />
    </v-flex>
    <v-flex class="content" xs12>
      <slot name="content" />
    </v-flex>
    <v-layout v-if="displayContactRep">
      <ContactRepFooter />
    </v-layout>
    <v-flex class="footer" xs12 v-if="footerContent">
      <h2
        class="heading_two"
        :aria-label="footerContent.title"
      >
        {{ footerContent.title }}
      </h2>
      <div class="subtitle" v-html="footerContent.subtitle" />
      <v-layout class="body text-xs-center" row wrap>
        <v-flex xs12 sm6 md4 v-for="(card, c) in footerContent.cards" :key="c">
          <div class="card">
            <div class="icon">
              <img :src="card.icon" alt="Card Icon">
            </div>
            <div
              class="text"
              v-html="card.text"
            />
          </div>
        </v-flex>
      </v-layout>
      <div
        class="sub-text text-xs-left"
        v-html="footerContent.subtext"
      />
      <div
        class="sub-text pt-2 text-xs-left"
        v-html="footerContent.subtext2"
      />
      <div
        class="sub-text pt-2 text-xs-left"
        v-html="footerContent.subtext3"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import ContactRepFooter from './ContactRep/ContactRepFooter.vue';

export default {
  name: 'PrimericaBenefits',
  components: {
    ContactRepFooter,
  },
  props: {
    footerContent: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    displayContactRep: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables';

.primerica-benefits
  .heading_two
    color: #4a4a4a;
    font-size: 32px;
    font-weight: 600;
  .icon
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border-radius: 50%;
  .sub-text
    font-size: 12px;
  .hero
    position: relative
    h1
      color: #fff;
      bottom: 0;
      padding: 0 0 25px 25px;
      position: absolute;
      font-size: 26px;
      max-width: 60%;
    img
      width: 100%;
      height: 100%;
  .content
    padding: 40px 100px;
    .heading_three
      margin: 10px 0 0;
      font-size: 24px;
      font-weight: 600;
    .body .heading_two
      width: 100%;
      margin: 15px 0 40px;
      text-align: center;
    .text
      font-size: 20px;
    .right
      .text
        margin: 20px 0 40px;
      &.last .text
        margin: 25px 0 10px;

  .footer
    color: #fff;
    padding: 25px 0;
    text-align: center;
    background: #063562;
    .heading_two
      color: #fff;
    .body
      > div:nth-child(3n + 1) .card
        margin-left: 25px;
      > div:not(:nth-child(5)) .card .text
        padding: 0 0 40px;
      .flex
        margin: 0 0 25px;
        .card
          height: 100%;
          padding: 10px;
          margin-right: 25px;
          border-radius: 10px;
          background: rgba(255,255,255,.1);
          .text
            padding: 0 0 10px;
            font-size: 14px;
            font-weight: 600;
    .heading_two
      width: 100%;
      font-size: 26px;
    .icon
      margin: 10px auto 20px;
    .subtitle
      color: #96ceff;
      font-size: 20px;
      font-weight: 500;
      margin: 15px 0 40px;
    .sub-text
      padding: 0 75px;
  @media $display-breakpoints.sm-and-down
    .heading_two
      font-size: 24px;
    .hero h1
      padding: 10px;
      font-size: 20px;
      max-width: 100%;
    .content
      padding: 20px;
      .text
        font-size: 18px;

    .footer
      .body
        > div:nth-child(3n + 1) .card
          margin-left: 0;
        > div:not(:nth-child(5)) .card .text
          padding: 0;
        .flex
          margin: 0 25px 25px;
          .card
            margin-right: 0;
      .sub-text
        padding: 0 50px;
</style>

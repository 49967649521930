export const COUNTRIES = {
  CANADA: 'CA',
  UNITED_STATES: 'US',
};
export const LOCALES = ['en_US', 'es_US', 'fr_CA', 'en_CA']; // First element is default locale
export const FEATURE_FLAGS = {
  INVESTMENTS: 'INVESTMENTS',
  RETIREMENT_CALCULATOR: 'RETIREMENT-CALCULATOR',
  POL_CHILDCONVERT: 'POL-CHILDCONVERT',
  DEBT_SOLUTIONS: 'DEBT-SOLUTIONS',
  CUST_VERIFYPHONE: 'CUST-VERIFYPHONE',
  BENEFICIARIES: 'BENEFICIARIES',
  BENEFICIARIES_EDIT: 'BENEFICIARIES_EDIT',
  ENVESTNET_SSO: 'ENVESTNET-SSO',
  ECOMM: 'ECOMM',
};

export const ALERT_VIEW_CONTEXT = {
  VERIFY_PHONE: 'verify_phone',
};

export const CHANNELS = {
  SMS: 'SMS',
  Email: 'Email',
};

export const DOCUMENT_OBJECT_TYPES = {
  CRC: 'CP6',
};

<i18n src="./locales.json"></i18n>

<template>
  <div :v-if="showContinue">
    <v-dialog v-model="showContinue" max-width="480" :persistent="true">
      <v-card>
        <v-card-title class="headline warning--text">
          <v-icon large class="warning--text mr-3">
            alarm
          </v-icon>
          <span aria-live="assertive" role="heading" aria-level="2">
            {{ $t('L_timeout_warning') }}
          </span>
        </v-card-title>
        <v-card-text> {{ $t('L_your_session_will_expire_in_30_seconds') }} {{ $t('L_would_you_like_to_extend_your_session') }} </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn flat small @click="closeSession">
            {{ $t('L_end_session') }}
          </v-btn>
          <v-btn class="text--lighten-4" color="#02579e" small flat @click="continueSession">
            {{ $t('L_extend_session') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import { mapGetters } from 'vuex';
import { Session } from '@/shared/util/session';
import { lockVault, getVaultConfig } from '@/plugins/auth/utils/sessionManager';
import { Ada } from '@primerica/vue-components';

export default {
  name: 'SessionDialog',
  props: {
    externalPage: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    intervalId: null,
    showContinue: false,
  }),
  computed: {
    ...mapGetters({
      isMobileApp: 'isMobileApp',
    }),
  },
  mounted() {
    this.startTimer();
  },

  methods: {
    startTimer() {
      Session.resetExpiration();
      this.intervalId = window.setInterval(
        Session.timer(this.warnCallback, this.closeSession),
        5000, // check session status every 5 seconds
      );
    },
    async signOut() {
      this.stopTimer();
      if (this.isMobileApp) {
        await this.$auth.logout(); // ! clear browser session and revoke tokens
        await this.$router.push({ name: 'LoginLandingPage' });
      } else {
        this.$auth.logout(); // ! clear browser session and revoke tokens
      }
    },
    stopTimer() {
      if (this.intervalId !== null) {
        window.clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async closeSession() {
      this.stopTimer();
      const isNativePlatform = await this.$pc2capacitor.isNativePlatform();
      if (isNativePlatform) {
        try {
          const [_vaultConfig] = await Promise.all([
            getVaultConfig(),
          ]);
          // TODO: use sessionManager.getUnlockMode
          if (_vaultConfig?.type === 'DeviceSecurity' || _vaultConfig?.type === 'CustomPasscode') {
            await Promise.all([lockVault()]);
            await this.$router.push({ name: 'LoginLandingPage' });
          } else if (_vaultConfig?.type === 'SecureStorage') {
            await this.$auth.logout(); // ! clear browser session and revoke tokens
            this.$router.push({ name: 'LoginLandingPage' });
            this.$router.push({ name: 'Dashboard' }); // TODO: ??? routing in two different places ???
          }
        } catch (e) {
          // TODO: handle error or log in DT
        }
      } else {
        this.$auth.logout(); // ! clear browser session and revoke tokens
      }
    },
    warnCallback() {
      if (!this.externalPage) {
        this.showContinue = true;
        this.adaActions();
      }
    },
    adaActions() {
      setTimeout(() => {
        const dialog = document.querySelector('.v-dialog--active:not([style*="300px"])');
        if (dialog) {
          Ada.createTabLoopSelectors(dialog);
        }
      }, 250);
    },
    continueSession() {
      Session.resetExpiration();
      this.showContinue = false;
    },
  },
};
</script>

<style lang="stylus">
.v-dialog {
  .v-card__title {
    font-weight: 500;
  }
  .v-card__text {
    font-weight: 500;
  }
  .v-btn--small {
    font-size: 14px;
  }
}
</style>

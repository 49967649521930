import { render, staticRenderFns } from "./DebtSolutionsV03TopSingle.vue?vue&type=template&id=3c73fa20&scoped=true"
import script from "./DebtSolutionsV03TopSingle.vue?vue&type=script&lang=js"
export * from "./DebtSolutionsV03TopSingle.vue?vue&type=script&lang=js"
import style0 from "./DebtSolutionsV03TopSingle.vue?vue&type=style&index=0&id=3c73fa20&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c73fa20",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fdebtsolutions%2Fcomponents%2FDebtSolutionsCard%2Fcomponents%2FDebtSolutionsV03TopSingle.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
/* eslint-disable no-empty */
import Vue from 'vue';

let instance; // Dynatrace JavaScript API instance
export const getInstance = () => instance;

/** Define a default action to perform after authentication */
const DEFAULT_ACTION_TYPE = 'user-level analytics';

// Dynatrace Initialization
export const useDynatrace = () => {
  // local instance has been initialize, return that instance.
  if (instance) return instance;

  // initialize instance with the value of a new Vue instance, which can take a configuration object
  instance = new Vue({
    data() {
      return {
        dtrum: (window && window.dtrum) || null,
        dt: (window && window.dT_) || null,
      };
    },
    methods: {
      enterAction(actionName, actionType) {
        try {
          if (this.dtrum && this.dtrum.enterAction) {
            return this.dtrum.enterAction(actionName, actionType || DEFAULT_ACTION_TYPE);
          }
        } catch (error) {}
        return -1;
      },
      leaveAction(actionId) {
        try {
          if (this.dtrum && this.dtrum.leaveAction) this.dtrum.leaveAction(actionId);
        } catch (error) {}
      },
      reportError(err, parentActionId) {
        try {
          if (this.dtrum && this.dtrum.reportError) this.dtrum.reportError(err, parentActionId);
        } catch (error) {}
      },
      reportCustomError(key, value, hint, parentingInfo) {
        // reportCustomError(key: string, value: string, hint?: undefined | string, parentingInfo?: number | boolean): void
        try {
          if (this.dtrum && this.dtrum.reportCustomError) this.dtrum.reportCustomError(key, value, hint, parentingInfo);
        } catch (error) {}
      },
    },
    created() {
      try {
        this.dtrum = (window && window.dtrum) || null;
        this.dt = (window && window.dT_) || null;
      } catch (e) {}
    },
  });

  // return the initialized instance
  return instance;
};

// Plugin Definition
export const DynatracePlugin = {
  install(_vue) {
    _vue.prototype.$dynatrace = useDynatrace();
  },
  getInstance,
};

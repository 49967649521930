var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"justify-center":"","row":"","wrap":""}},[_c('v-flex',{staticClass:"text-xs-center",attrs:{"xs12":"","md9":""}},[_c('h2',{staticClass:"font-weight-regular",attrs:{"aria-live":"assertive"}},[_vm._v(" "+_vm._s(_vm.$t('L_start_new_policy'))+" ")]),_c('h4',{staticClass:"mt-3 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('L_you_can_lock_in'))+" ")]),_c('h4',{staticClass:"mt-3 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.custServNum)+" ")])]),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","mt-4":"","pt-3":""}},[_c('FirstNameInput',{attrs:{"form-input":_vm.$v.firstName,"input-id":"first_name","label":_vm.$t('L_firstname'),"auto-capitalize":true},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('FirstNameInput',{attrs:{"input-id":"middle_name","form-input":_vm.$v.middleName,"label":_vm.$t('L_middle_name'),"validation-messages":{
                name: 'Middle Name is Invalid.',
                maxLength: 'Middle Name can be a Maximum of 32 Characters in Length.',
              },"auto-capitalize":true},model:{value:(_vm.middleName),callback:function ($$v) {_vm.middleName=$$v},expression:"middleName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('LastNameInput',{attrs:{"input-id":"last_name","form-input":_vm.$v.lastName,"label":_vm.$t('L_last_name'),"auto-capitalize":true},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('DateInput',{attrs:{"disabled":true,"id":"birth_date","form-input":_vm.$v.birthDate,"label":_vm.$t('L_birth_date'),"validation-messages":{
                date: _vm.$t('L_invalid_date'),
                required: _vm.$t('L_birth_date_required'),
              }},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}})],1),(_vm.isUsPolicy)?_c('v-flex',{attrs:{"xs12":""}},[_c('SSNInput',{attrs:{"id":"ssn","enable-masking":"","form-input":_vm.$v.ssn,"label":_vm.$t('L_ssn')},model:{value:(_vm.ssn),callback:function ($$v) {_vm.ssn=$$v},expression:"ssn"}})],1):_vm._e(),(_vm.isCanadianPolicy)?_c('v-flex',{attrs:{"xs12":""}},[_c('SINInput',{attrs:{"id":"sin","enable-masking":"","form-input":_vm.$v.sin,"label":_vm.$t('L_sin'),"validation-messages":{
                ssn: _vm.$t('L_invalid_sin'),
              }},model:{value:(_vm.sin),callback:function ($$v) {_vm.sin=$$v},expression:"sin"}})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":""}},[_c('RadioButtonGroup',{attrs:{"options":_vm.genders,"form-input":_vm.$v.gender,"validation-messages":{
                required: 'Gender is required',
              }},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":_vm.$v.$anyError},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('L_next'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
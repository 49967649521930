<i18n src='./locales.json'></i18n>

<template>
  <v-content
    class="myrep"
    role="presentation"
  >
    <v-container>
      <v-layout
        row
        wrap
        class="main-body"
        justify-center
      >
        <v-flex xs12>
          <v-layout
            pt-2
            pb-4
          >
            <img
              src="./assets/group.svg"
              height="30px"
              role="presentation"
              alt=""
            >
            <v-flex shrink>
              <span class="pl-3 font-weight-regular font-size--24 white--text"
                    role="heading"
                    aria-level="1"
              >{{ $t(reps && reps.length > 1 ? 'L_my_rep_title_plural' : 'L_my_rep_title') }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex>
          <template v-if="reps && reps.length > 0">
            <v-card
              v-for="rep in reps"
              :key="rep.agentId"
              class="mb-4"
            >
              <v-card-text>
                <v-flex
                  pa-3
                >
                  <v-layout
                    row
                    align-start
                  >
                    <v-flex>
                      <v-layout
                        row
                        wrap
                      >
                        <v-flex
                          xs12
                          sm4
                          md2
                          :mb-3="$vuetify.breakpoint.xsOnly"
                        >
                          <v-layout
                            row
                            wrap
                          >
                            <v-flex
                              xs12
                              pb-2
                            >
                              <v-layout justify-center>
                                <img
                                  class="rep-image"
                                  style="width: 80px"
                                  :src="rep.displayPic"
                                  v-img-fallback="repFallbackImage"
                                  alt="rep photo"
                                >
                              </v-layout>
                            </v-flex>
                            <v-flex
                              xs12
                              pb-2
                            >
                              <v-layout justify-center v-if="rep.email">
                                <v-btn
                                  v-ada-modal
                                  id="contact_your_rep_myrep_trigger"
                                  class="contact-rep-trigger emailBtnWidth setHeightAuto"
                                  flat
                                  outline
                                  color="info"
                                  @click.prevent="emailRep(rep.agentId)"
                                >
                                  <v-layout align-center>
                                    <img
                                      src="./assets/email.svg"
                                      alt=""
                                      class="ml-1"
                                    >
                                    <div
                                      class="font-weight-regular ml-1 emailButtonCss"
                                      style="color: #02579e"
                                    >
                                      {{ $t('L_rep_email_me') }}
                                    </div>
                                  </v-layout>
                                </v-btn>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex
                          sm8
                          md10
                        >
                          <v-layout
                            row
                            wrap
                          >
                            <v-flex
                              md6
                              lg4
                              mb-4
                              :mb-3="$vuetify.breakpoint.xsOnly"
                            >
                              <h3
                                role="heading"
                                aria-level="2"
                                class="heading_three font-weight-medium mb-2"
                                :class="{
                                  'text-xs-center': $vuetify.breakpoint.xsOnly,
                                }"
                              >
                                {{ rep.displayName }}
                              </h3>
                              <h5
                                role="presentation"
                                v-if="rep.isRvp"
                                class="heading_five font-weight-medium mb-1 pb-1"
                                :class="{
                                  'text-xs-center': $vuetify.breakpoint.xsOnly,
                                }"
                              >
                                {{ $t('L_' + rep.title) }}
                              </h5>
                              <h5
                                role="presentation"
                                class="heading_five font-weight-medium mb-1 pb-1"
                                :class="{
                                  'text-xs-center': $vuetify.breakpoint.xsOnly,
                                }"
                              >
                                {{ $t(rep.products.length > 1 ? 'L_rep_servicing_product_plural' : 'L_rep_servicing_product') }}
                              </h5>
                              <div
                                v-for="(item, index) in rep.products"
                                :key="index"
                                class="font-size--14 mb-1"
                                :class="{
                                  'text-xs-center': $vuetify.breakpoint.xsOnly,
                                }"
                              >
                                {{ item }}
                              </div>
                            </v-flex>
                            <v-flex
                              md6
                              lg4
                              mb-4
                              :mb-3="$vuetify.breakpoint.xsOnly"
                            >
                              <v-layout
                                align-center
                                :justify-center="$vuetify.breakpoint.xsOnly"
                                class="mb-2 pb-1"
                              >
                                <v-icon>phone</v-icon>
                                <span class="font-size--14 font-weight-medium">{{ $t('L_rep_call_me') }}</span>
                              </v-layout>
                              <v-layout
                                row
                                wrap
                              >
                                <v-flex
                                  v-if="rep.useCellPhone && rep.cell"
                                  xs12
                                >
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout
                                        justify-end
                                      >
                                        <span
                                          class="font-size--14 font-weight-medium mr-1"
                                        >{{ $t('L_rep_mobile_phone_title') }}:</span>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a
                                        :href="'tel:+1' + rep.cell"
                                        class="ml-1"
                                      >{{ rep.cell | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex
                                  v-if="rep.office && rep.office.phone"
                                  xs12
                                >
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout
                                        justify-end
                                      >
                                        <span
                                          class="font-size--14 font-weight-medium mr-1"
                                        >{{ $t('L_rep_office_phone_title') }}:</span>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a
                                        :href="'tel:+1' + rep.office.phone"
                                        class="ml-1"
                                      >{{ rep.office.phone | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex
                                  v-if="rep.office && rep.office.fax"
                                  xs12
                                >
                                  <v-layout align-center>
                                    <v-flex xs3>
                                      <v-layout
                                        justify-end
                                      >
                                        <span
                                          class="font-size--14 font-weight-medium mr-1"
                                        >{{ $t('L_rep_fax_title') }}:</span>
                                      </v-layout>
                                    </v-flex>
                                    <v-flex xs9>
                                      <a
                                        :href="'tel:+1' + rep.office.fax"
                                        class="ml-1"
                                      >{{ rep.office.fax | phone }}</a>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex
                              lg4
                              sm12
                              v-if="$vuetify.breakpoint.mdAndUp"
                            >
                              <Address
                                :rep="rep"
                              />
                            </v-flex>
                          </v-layout>
                          <v-divider class="mt-4 mb-3 hidden-sm-and-down" />
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex
                          v-if="$vuetify.breakpoint.smAndDown"
                          xs12
                          pt-3
                        >
                          <Address
                            :rep="rep"
                          />
                        </v-flex>
                      </v-layout>
                      <v-divider class="mt-4 mb-3 hidden-md-and-up" />
                      <v-layout
                        align-start
                        justify-start
                      >
                        <v-flex
                          v-if="$vuetify.breakpoint.mdAndUp"
                          xs2
                        />
                        <v-flex
                          xs12
                          md4
                        >
                          <div class="font-size--14 font-weight-medium mb-2">
                            {{ $t('L_rep_help_with') }}:
                          </div>
                          <v-layout
                            v-for="(product, i) in rep.licensedProducts"
                            v-if="filterProducts(product)"
                            :key="i"
                            align-start
                            pb-2
                          >
                            <v-icon color="primary">
                              check_circle
                            </v-icon>
                            <span class="ml-2 font-size--16">{{ translatedProductName(product) }}</span>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-card-text>
            </v-card>
          </template>
        </v-flex>
      </v-layout>
      <ContactRep
        v-if="contactAgent"
        v-model="contactMe"
        :agents="[contactAgent]"
        @close="contactMe = false"
        @send-message="send($event)"
        close-selector="contact_your_rep_myrep_close"
        modal-class="contact_your_rep_myrep_modal"
        :sender="sender"
      />
    </v-container>
  </v-content>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContactRep, sendRepEmail } from '@primerica/vue-components';
import Config from '@/shared/util/config';
import { phoneNumber } from '@primerica/vue-components/src/util/formatters';

import Address from './components/Address.vue';
import bff from '../../../shared/api/bff';

const repFallbackImage = require('./assets/rep-thumb.svg');

export default {
  name: 'MyRep',
  components: {
    Address,
    ContactRep,
  },
  data: () => ({
    contactMe: false,
    contactAgent: {},
  }),
  computed: {
    ...mapGetters('policy', {
      policiesSortedByDate: 'policiesSortedByDate',
    }),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['agents', 'getAgent']),
    envUrl() {
      const env = Config.get('env');
      if (env === 'dev' || env === 'test') {
        return env;
      }
      return 'w';
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    reps() {
      const accounts = [];
      this.policiesSortedByDate.forEach((pol) => {
        if (pol.contractId?.length > 0) {
          accounts.push({
            product: `${this.$t('L_term_life_policy')} ${pol.contractId}`,
            agentId: pol.agentId,
          });
        }
      });
      this.investmentsSortedByDate.forEach((acct) => {
        if (acct.accountNumber?.length > 0) {
          accounts.push({
            product: `${acct.productType} ${acct.accountNumber}`,
            agentId: acct.agentId,
          });
        }
      });
      let reps = [];
      if (accounts?.length > 0) {
        accounts.forEach((acct) => {
          const found = reps.find(a => a.agentId === acct.agentId);
          if (!found) {
            const agent = this.getAgent(acct.agentId);
            if (agent && agent.agentId) {
              agent.products = [acct.product];
              agent.displayName = agent.name;
              reps.push(agent);
            }
          } else {
            reps = reps.map((rep) => {
              if (rep.agentId === acct.agentId) {
                rep.products.push(acct.product);
              }
              return rep;
            });
          }
        });
      }
      return reps;
    },
    repFallbackImage() {
      return repFallbackImage;
    },
  },
  methods: {
    ...mapActions('policy', {
      getPolicyOverview: 'getPolicyOverview',
    }),
    rvp(id) {
      return this.getAgent(id);
    },
    emailRep(agentId) {
      const agent = this.reps.find(a => a.agentId === agentId);
      this.contactAgent = agent;
      this.contactMe = true;
    },
    send(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
    filterProducts(product) {
      return /(Term Life Insurance|Securities|Annuities)/.test(product);
    },
    translatedProductName(product) {
      let resDisplayLabel;
      switch (product) {
        case 'Term Life Insurance':
          resDisplayLabel = this.$t('L_term_life_insurance');
          break;
        case 'Securities':
          resDisplayLabel = this.$t('L_securities');
          break;
        case 'Fixed Annuities':
          resDisplayLabel = this.$t('L_fixed_annuities');
          break;
        case 'Indexed Annuities':
          resDisplayLabel = this.$t('L_indexed_annuities');
          break;
        case 'Variable Annuities':
          resDisplayLabel = this.$t('L_variable_annuities');
          break;
        default:
          resDisplayLabel = product;
      }
      return resDisplayLabel;
    },
  },
  filters: {
    phone(number) {
      return phoneNumber(number);
    },
    camelCaseName: name => ((name && name.length > 0) ? name.split(' ').map(word => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''),
  },
};
</script>

<style scoped lang="stylus">
.myrep
  padding: 24px 0 0 !important;
.emailButtonCss
  zwidth: 85px;
  text-overflow: initial;
  white-space: normal;
  font-size: 13px;
.emailBtnWidth
  width: 115px;
.setHeightAuto
  height:auto !important;
</style>

<i18n src="../../../../locales.json"></i18n>

<template>
  <v-card class="mt-4 pb-4">
    <LoadingIndicator :loading="loading" />
    <MessageDialog
      :show-dialog="showMessageDialog"
      :has-button-two="false"
      type="warning"
      :title="$t('L_payment_in_process')"
      :message="$t('L_a_payment_is_currently_being_processed')"
      :button-one-label="$t('L_close')"
      :is-persistent="true"
      @buttonOneAction="showMessageDialog = false"
    />
    <v-layout row wrap v-if="indexPolicy !== -1 && !loading">
      <v-flex xs12>
        <Alert
          v-if="isH1BillingForm && !isAccessNone"
          type="error"
          :details="{ message: $t('L_your_policy_will_be_terminated', { date: formattedPaymentDueDate }) }"
          :dismissible="false"
          :expandable="false"
        />
      </v-flex>
      <!-- Policy Number and status -->
      <v-flex xs12 md4 pl-4 :class="$vuetify.breakpoint.smAndUp?'pt-4':'pt-2'" class="border_bottom_line">
        <v-icon
          class="right-icon font-size--28"
          color="#063562"
        >
          description
        </v-icon>
        <span class="font-size--16">
          {{ __t('L_policy_number') }}:
        </span>
        <span class="font-size--16 font-weight-bold">
          {{ policyList[indexPolicy].contractId }}
        </span>
      </v-flex>
      <v-flex xs6 md3 text-xs-left pr-4 pb-2 :class="$vuetify.breakpoint.smAndUp?'pt-4':'pt-1 pb-2'" class="border_bottom_line">
        <span @click="setPolicyRtPolicyDetails" class="view_details_class" v-if="!isAccessNone">
          {{ $t('L_policy_details') }}
        </span>
      </v-flex>
      <v-flex xs6 md5 text-xs-right pr-4 pb-2 :class="$vuetify.breakpoint.smAndUp?'pt-4':'pt-1 pb-2'" class="border_bottom_line">
        <span class="text-xs-right">
          <span>{{ $t('L_status') }}</span>
          <span class="ml-3">
            <v-icon
              class="policy-status-icon"
              :class="{
                active: !isPending,
                pending: isPending,
              }"
            >{{ selectedPolicy.pending ? 'access_time' : 'check_circle' }}</v-icon>
            <span class="font-size--14">
              {{ selectedPolicy.pending ? $t('L_active_application') : $t('L_active') }}
            </span>
          </span>
        </span>
      </v-flex>
      <!-- This is the pay now blue/yellow bar banner -->
      <v-flex xs12 align-self-center v-if="!showPayInProgress && showPayNowLinkThisCard && !isAccessNone">
        <Message
          :show-msg="(showPayNowLinkThisCard && !showPayInProgress)"
          :type="paynowType"
          :button-label="$t('L_pay_now')"
          :message="payNowLinkDesc"
          @click="payNow"
        />
      </v-flex>
      <v-flex xs12 v-else-if="!isPending && !isAccessNone" class="background_color_light_blue pl-4 pr-4"
              :class="$vuetify.breakpoint.smAndDown?'font-size--14': 'font-size--16'"
      >
        <span class="font-weight-regular">
          {{ one_of_billing_mode[0] }}
        </span>
        <span class="font-weight-bold pl-2 pr-2">
          {{ one_of_billing_mode[1] }}
        </span>
        <span class="pl-2 pr-2" v-if="$vuetify.breakpoint.smAndUp">
          <span v-if="one_of_billing_mode[3] !== 'Invalid date'">
            |
          </span>
        </span>
        <span v-else-if="isAccessNone">
          &nbsp;
        </span>
        <span v-else>
          <br>
        </span>
        <span class="font-weight-regular pr-2" :class="$vuetify.breakpoint.smAndUp?'':'pr-2'" v-if="one_of_billing_mode[3] !== 'Invalid date'">
          {{ one_of_billing_mode[2] }}
        </span>
        <span class="font-weight-bold" v-if="one_of_billing_mode[3] !== 'Invalid date'">
          {{ one_of_billing_mode[3] }}
        </span>
        <span v-if="showPayInProgress || showPayNowLinkThisCard" class="payment_in_process">
          <v-icon :class="$vuetify.breakpoint.smAndDown?'font-size--18': 'font-size--24'">attach_money</v-icon> {{ $t('L_payment_in_process') }}
        </span>
      </v-flex>
      <!-- ChildRider Banner -->
      <ChildRiderAlert v-if="hasCRCPackageForCurrentPolicy && !isAccessNone" :index-policy="indexPolicy" class="full_width" />


      <!-- Total Coverage section-->
      <v-layout row wrap v-if="isAccessNone">
        <!-- This is the We are sorry shown to user if access is NONE -->
        <v-flex
          xs12
          class="restricted-policy-message-box mt-3 pb-5 pt-5"
        >
          <h3
            class="heading_three text-xs-center mb-3"
          >
            {{ $t('L_restricted_policy_code_message') }}
          </h3>
          <h3
            v-html="custServNum"
            class="heading_three text-xs-center"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12 md6 :class="$vuetify.breakpoint.xsOnly?'pl-4 pr-4':'pl-4 pr-4'">
          <v-layout row wrap>
            <v-flex xs8 mt-4 pb-2 font-weight-bold class="underline_bottom">
              {{ $t('L_coverage') }}
              <v-icon
                tabindex="0"
                color="#02579E"
                small
                class="pri-inline-icon pl-1 cursor-pointer"
                size="18"
                style="margin-bottom: -3px;"
                aria-label="coverage amount information"
                @blur="status = !status"
                @focus="status = !status"
              >
                help
              </v-icon>
              <v-tooltip
                slot="append"
                content-class="text-xs-center"
                nudge-left="60"
                right
                :nudge-bottom="this.$vuetify.breakpoint.mdAndUp ? '50' : '70'"
                :max-width="this.$vuetify.breakpoint.mdAndUp ? '194' : '162'"
                v-model="status"
                color="primary"
              >
                <v-icon slot="activator" />
                <span role="tooltip" aria-live="polite" id="policy_number_tooltip">
                  {{ __t('L_coverage_amount_tooltip') }}
                </span>
              </v-tooltip>
            </v-flex>
            <v-flex xs4 mt-4 pb-2 text-xs-right font-weight-bold class="underline_bottom" :class="$vuetify.breakpoint.xsOnly?'font-size--14':'font-size--16'">
              &nbsp;
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="$vuetify.breakpoint.smAndUp || showCoverageDetails">
            <!-- Primary -->
            <v-flex text-xs-left pt-1 pb-1>
              {{ primaryName }}
            </v-flex>
            <v-flex text-xs-right pt-1 pb-1 :class="$vuetify.breakpoint.xsOnly?'font-size--14  pr-4':'font-size--16'">
              {{ formattedPrimaryCoverage }}
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="($vuetify.breakpoint.smAndUp || showCoverageDetails) && spouseName !== ''">
            <!-- Spouse-->
            <v-flex text-xs-left pt-1 pb-1>
              {{ spouseName }}
            </v-flex>
            <v-flex text-xs-right pt-1 pb-1 :class="$vuetify.breakpoint.xsOnly?'font-size--14 pr-4':'font-size--16'">
              {{ formattedSpouseCoverage }}
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="($vuetify.breakpoint.smAndUp || showCoverageDetails ) && hasChildren">
            <!-- Children-->
            <v-flex text-xs-left pt-1 pb-1>
              {{ $t('L_children') }}
            </v-flex>
            <v-flex text-xs-right pt-1 pb-1 :class="$vuetify.breakpoint.xsOnly?'font-size--14 pr-4':'font-size--16'">
              {{ formattedChildrenCoverage }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md6 mt-4 mb-4 :class="$vuetify.breakpoint.xsOnly?'pl-0 pr-0':'pl-4 pr-4'" v-if="!isPending">
          <v-layout row wrap class="blueBackground pt-4 pl-4 pr-4">
            <v-flex xs12 font-weight-bold>
              {{ $t('L_need_to_make_an_update_to_this_policy') }}
            </v-flex>
            <v-flex :class="$vuetify.breakpoint.xsOnly?'text-xs-center':'text-xs-left'">
              <!-- Dropdown -->
              <v-select
                :items="updateTypeList"
                single-line
                v-model="updateType"
                :label="$t('L_Beneficiary_Type')"
                class="selectMaxWidth"
                :menu-props="{maxHeight:'272px'}"
              />
            </v-flex>
            <v-flex class="text-xs-center">
              <v-btn role="link" class="mobile_go_button_mt" :class="$vuetify.breakpoint.mdAndUp?'go_mdAndUp': 'go_less_than_md'" color="primary" @click="navigateToPolicyDetails">
                {{ $t('L_go') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <!-- This is only being used for non data Life Card because the last one is a Are you Properly Protected verbiage with "Get Guidance" button -->
    <v-layout row wrap pt-4 pb-4 class="protected-box pa-3" v-if="indexPolicy === -1">
      <v-flex xs12 md7 pr-2 text-xs-left>
        <v-layout row wrap>
          <v-flex xs12 md2 text-center text-sm-center>
            <img src="../../assets/money-shield-icon.svg" alt="" role="presentation" :class="$vuetify.breakpoint.mdAndUp?'shield_image_mdAndUp':'shield_image_below_md'">
          </v-flex>
          <v-flex xs12 md10 pl-3 text-xs-left>
            <h1 role="heading" aria-level="3" class="font-size--14 font-weight-bold">
              {{ $t('L_are_you_protected') }}
            </h1>
            <span class="font-size--14 font-weight-regular" v-html="$t('L_when_life_changes_your_financial')" />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md5>
        <v-spacer class="hidden-md-and-up mt-3" />
        <v-layout row fill-height align-center justify-center v-if="showGuidanceCalculatorTab">
          <v-btn id="get_guidance_button" role="link" @click="$router.push({ name: 'GuidanceCalculator' })" class="btn-policy-details" color="secondary">
            {{ $t('L_get_guidance') }}
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { currency, formatDate } from '@primerica/vue-components/src/util/formatters';
import Alert from '@/components/Alert.vue';
import Message from '@/components/Message.vue';
import { Ada } from '@primerica/vue-components';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import moment from 'moment';

import stringValues from '@/shared/util/stringValues';
import MessageDialog from '../../../../../../../components/MessageDialog.vue';
import __t from '../../../../translate';
import ChildRiderAlert from '../../../../../../childrider/components/ChildRiderAlert/ChildRiderAlert.vue';


export default {
  name: 'TermLifeCard',
  components: {
    Alert,
    Message,
    ChildRiderAlert,
    LoadingIndicator,
    MessageDialog,
  },
  data: () => ({
    status: false,
    updateType: 0,
    showPayInProgressTooltip: false,
    showCoverageDetails: true,
    paynowType: 'info',
    showMessageDialog: false,
  }),
  props: {
    indexPolicy: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    ...mapGetters('childrider', {
      hasPackageGivenPolicyId: 'hasPackageGivenPolicyId',
    }),
    ...mapGetters('policy', {
      policies: 'policies',
      showPayNowFeatureFlagAndPayNowLinkToggle: 'showPayNowFeatureFlagAndPayNowLinkToggle',
      loading: 'policyInfoLoading',
    }),
    ...mapGetters('customer', {
      getCustServNumWDash: 'getCustServNumWDash',
      getCustServNumWODash: 'getCustServNumWODash',
    }),
    ...mapGetters({
      isMobileApp: 'isMobileApp',
    }),
    showGuidanceCalculatorTab() {
      return !this.isMobileApp;
    },
    custServNum() {
      if (this.locale?.length > 0) {
        const withDash = this.getCustServNumWDash(this.locale);
        const withoutDash = this.getCustServNumWODash(this.locale);
        const num = this.$t('L_restricted_policy_code_number', {
          phoneWODash: withoutDash,
          phoneWDash: withDash,
        });
        return num;
      }
      return '';
    },
    selectedPolicy() {
      if (this.policies && this.policies[this.indexPolicy]) {
        return this.policies[this.indexPolicy];
      }
      return {};
    },
    isPending() {
      return this.selectedPolicy?.pending;
    },
    isRestricted() {
      return this.selectedPolicy?.restricted;
    },
    isAccessNone() {
      return this.selectedPolicy?.data?.access === 'NONE';
    },
    showPayNowMsg() {
      return (this.showPayNowLinkThisCard && !this.showPayInProgress);
    },
    updateTypeList() {
      const selectOptions = [
        {
          value: 0,
          text: this.$t('L_i_want_to_update_my'),
        },
        {
          value: 1,
          text: this.$t('L_beneficiaries'),
        },
      ];
      if (this.selectedPolicy?.billingMode === '01' && this.selectedPolicy?.billingForm === 'B2') {
        selectOptions.push({
          value: 2,
          text: this.$t('L_bank_account'),
        });
        selectOptions.push({
          value: 3,
          text: this.$t('L_draft_date'),
        });
      }
      selectOptions.push({
        value: 4,
        text: this.$t('L_contact_information'),
      });
      selectOptions.push({
        value: 5,
        text: this.$t('L_payment_method'),
      });
      // Add Child - CRC
      // TODO: remove this.selectedPolicy?.billingMode === '01'
      if (this.selectedPolicy?.showAddChildLink) {
        selectOptions.push({
          value: 6,
          text: this.$t('L_add_child'),
        });
      }

      return selectOptions;
    },
    policyData() {
      return this.policies[this.indexPolicy];
    },
    primaryName() {
      if (this.policyData) {
        return this.policyData?.insuredName;
      } return '';
    },
    totalCoverageAmount() {
      return currency(this.policies[this.indexPolicy].totalCoverage, 0);
    },
    formattedPrimaryCoverage() {
      return currency(this.policyData.primaryCoverage, 0);
    },
    formattedSpouseCoverage() {
      return currency(this.policyData.spouseCoverage, 0);
    },
    formattedChildrenCoverage() {
      return currency(this.policyData.childCoverage, 0);
    },
    spouseName() {
      if (this.policyData?.data?.spouseFirstName) {
        return `${this.policyData?.data?.spouseFirstName} ${this.policyData?.data?.spouseLastName}`;
      }
      return '';
    },
    hasChildren() {
      return !!(this.policyData?.data?.children && this.policyData?.data?.children.length > 0);
    },
    childrenNames() {
      return this.policyData?.data?.children;
    },
    policyList() {
      return this.policies.map(p => ({ contractId: p.contractId }));
    },
    hasCRCPackageForCurrentPolicy() {
      if (this.indexPolicy === -1) {
        return false;
      } return !!(
        this.hasPackageGivenPolicyId(this.policies[this.indexPolicy]._contractId) && (this.policies[this.indexPolicy]?.billingForm === 'A1' ||
         this.policies[this.indexPolicy]?.billingForm === 'B2' || this.policies[this.indexPolicy]?.billingForm === 'C2' ||
         this.policies[this.indexPolicy]?.billingForm === 'D2' || this.policies[this.indexPolicy]?.billingForm === 'H1')
      );
    },
    payInProcess() {
      return this.selectedPolicy?.paymentInfo?._payInProcess === '1';
    },
    kubraPaymentInProgress() {
      return this.selectedPolicy?.kubraPaymentStatus !== null;
    },
    showPayInProgress() {
      return this.payInProcess || this.kubraPaymentInProgress;
    },
    formattedPaymentMethod() {
      if (this.policies && this.indexPolicy !== -1) {
        return stringValues.paymentMethod(this.policies[this.indexPolicy]);
      }
      return '';
    },
    formattedDraftDate() {
      return formatDate(this.selectedPolicy?.nextDraftDate, 'll');
    },
    formattedPaymentDueDate() {
      return formatDate(this.selectedPolicy?.nextPaymentDueDate, 'll');
    },
    showPayNowLinkThisCard() {
      let result = this.showPayNowFeatureFlagAndPayNowLinkToggle;
      result = result && !(!!this.selectedPolicy?._kubraPaymentStatus || this.selectedPolicy?._paymentInfo?._payInProcess === '1');
      result = result && this.selectedPolicy?.showPayNowLink;
      return result;
    },
    hasSpouse() {
      return this.selectedPolicy?.spouseCoverage > 0;
    },
    totalPremiumAmount() {
      return currency(`${(+this.selectedPolicy?.modePremium || 0) + (+this.selectedPolicy?.data?.annuityModePrem || 0)}`, 2);
    },
    premiumAmount() {
      return currency(this.selectedPolicy?.modePremium, 2);
    },
    primaryCoverage() {
      return this.selectedPolicy?.primaryCoverage || '';
    },
    spouseCoverage() {
      return this.selectedPolicy?.spouseCoverage || '';
    },
    getPrimeryInsured() {
      return this.selectedPolicy?.primaryInsured?.name || '';
    },
    isH1BillingForm() {
      return this?.selectedPolicy?.billingForm === 'H1';
    },
    payNowLinkDesc() {
      if (this.indexPolicy === -1 && this.policies !== null && this.policies.length > 0) {
        return '';
      }
      let payNowLinkDesc = this.selectedPolicy?.payNowLinkDesc || '';
      const paidToDate = this.selectedPolicy?.paymentInfo?.paidToDate;
      const billForm = this.selectedPolicy?.billingForm;
      const DIRECT_BILL = billForm && billForm.toLowerCase() === 'A1'.toLowerCase();
      const isPaidToDateBeforeToday = moment(paidToDate).isBefore(moment(), 'day');
      const isPaidToDateAfterToday = moment(paidToDate).isAfter(moment(), 'day');
      const isPaidToDateToday = moment(paidToDate).isSame(moment(), 'day');
      const paidToDateFormatted = formatDate(paidToDate, this.dateFormat);
      const paymentAmt = this.selectedPolicy?.paynowInfo?.totalPaymentAmount || '0.00';
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paynowType = 'info';
      if (this.selectedPolicy?.paynowInfo?.inSufficientFunds) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.paynowType = 'warning';
        payNowLinkDesc = this.$t('L_your_automatic_payment_of', { amount: paymentAmt });
      } else if (DIRECT_BILL) {
        if (paidToDate && isPaidToDateBeforeToday) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.paynowType = 'warning';
          payNowLinkDesc = this.$t('L_a_payment_was_due_on', { amount: paymentAmt, date: paidToDateFormatted });
        } else if (paidToDate && isPaidToDateAfterToday) {
          payNowLinkDesc = this.$t('L_a_payment_is_due_on', { amount: paymentAmt, date: paidToDateFormatted });
        } else if (paidToDate && isPaidToDateToday) {
          payNowLinkDesc = this.$t('L_a_payment_is_due_today', { amount: paymentAmt });
        }
        if (this.selectedPolicy.paynowInfo?.hasMultipleOptions) {
          payNowLinkDesc += ` ${this.$t('L_a_payment_option_minimum', { amount: this.selectedPolicy.paynowInfo.minimumPaymentAmount })}`;
        }
      } else {
        payNowLinkDesc = '';
      }
      return payNowLinkDesc;
    },
    getPayNowType() {
      if (this.indexPolicy === -1 && this.policies !== null && this.policies.length > 0) {
        return 'info';
      }
      const billForm = this.selectedPolicy?.billingForm;
      const paidToDate = this.selectedPolicy?.paymentInfo?.paidToDate;
      const DIRECT_BILL = billForm && billForm.toLowerCase() === 'A1'.toLowerCase();
      const isPaidToDateBeforeToday = moment(paidToDate).isBefore(moment(), 'day');
      if (this.selectedPolicy?.paynowInfo?.inSufficientFunds || (DIRECT_BILL && paidToDate && isPaidToDateBeforeToday)) {
        return 'warning';
      }
      return 'info';
    },
    one_of_billing_mode() {
      const billForm = this.selectedPolicy?.billingForm;
      const billMode = this.selectedPolicy?.billingMode || '';
      const paidToDate = this.selectedPolicy?.paymentInfo?.paidToDate || '';
      let modePremium = this.selectedPolicy?.modePremium;
      if (modePremium !== '' || modePremium !== null) {
        modePremium = currency(modePremium, 2);
      }
      const DIRECT_BILL = billForm && billForm.toLowerCase() === 'A1'.toLowerCase();
      const TerminateAfterPaidOffDate = billForm && billForm.toLowerCase() === 'H1'.toLowerCase();
      const policyPremiumDisplayLogicArray = [];
      if (DIRECT_BILL || TerminateAfterPaidOffDate) {
        if (billMode === '01') {
          policyPremiumDisplayLogicArray.push(this.$t('L_monthly_bill'));
        } else if (billMode === '03') {
          policyPremiumDisplayLogicArray.push(this.$t('quarterly_bill'));
        } else if (billMode === '06') {
          policyPremiumDisplayLogicArray.push(this.$t('L_semi_annual_bill'));
        } else if (billMode === '12') {
          policyPremiumDisplayLogicArray.push(this.$t('L_annual_bill'));
        } else {
          policyPremiumDisplayLogicArray.push(this.$t('L_monthly_bill'));
        }
        policyPremiumDisplayLogicArray.push(modePremium);
        if (TerminateAfterPaidOffDate) {
          policyPremiumDisplayLogicArray.push(this.$t('L_paid_through'));
        } else {
          policyPremiumDisplayLogicArray.push(this.$t('L_due_date'));
        }
        policyPremiumDisplayLogicArray.push(moment(paidToDate).format('MMM DD, YYYY'));
      } else if (billForm === 'B2' || billForm === 'b2') {
        // return monthly payment amount
        policyPremiumDisplayLogicArray.push(this.$t('L_monthly_bank_draft'));
        policyPremiumDisplayLogicArray.push(modePremium);
        policyPremiumDisplayLogicArray.push(this.$t('L_draft_date'));
        const draftDate = formatDate(this.selectedPolicy?.nextDraftDate, 'MM/DD/YYYY');
        policyPremiumDisplayLogicArray.push(moment(draftDate).format('MMM DD, YYYY'));
      } else if (billForm === 'C2' || billForm === 'c2') {
        // C2 = Government allotment
        policyPremiumDisplayLogicArray.push(this.$t('L_government_allotment'));
        policyPremiumDisplayLogicArray.push(modePremium);
        policyPremiumDisplayLogicArray.push(this.$t('L_due_date'));
        policyPremiumDisplayLogicArray.push(moment(paidToDate).format('MMM DD, YYYY'));
      } else if (billForm === 'D2' || billForm === 'd2') {
        // D2 = payroll deduction
        policyPremiumDisplayLogicArray.push(this.$t('L_payroll_deduction'));
        policyPremiumDisplayLogicArray.push(modePremium);
        policyPremiumDisplayLogicArray.push(this.$t('L_due_date'));
        policyPremiumDisplayLogicArray.push(moment(paidToDate).format('MMM DD, YYYY'));
      } else {
        policyPremiumDisplayLogicArray.push('');
        policyPremiumDisplayLogicArray.push('');
        policyPremiumDisplayLogicArray.push('');
        policyPremiumDisplayLogicArray.push('');
      }
      return policyPremiumDisplayLogicArray;
    },
  },
  filters: {
    formattedAmount(amount) {
      const cents = amount.toString().split('.')[1];
      if (+cents > 0) {
        return currency(amount, 2);
      }
      return currency(amount, 0);
    },
  },
  methods: {
    ...mapActions('policy', ['setPaymentStep', 'setScrollToPolicyDetails']),
    __t,

    navigateToPolicyDetails() {
      if (this.updateType > 0) {
        let scrollLocation = '';
        if (this.updateType === 1) {
          scrollLocation = 'beneficiary';
        } else if (this.updateType === 2 || this.updateType === 3 || this.updateType === 5) {
          scrollLocation = 'payment';
        } else if (this.updateType === 4) {
          scrollLocation = 'contact_info';
        } else if (this.updateType === 6) {
          scrollLocation = 'add_child';
        }
        this.setScrollToPolicyDetails(scrollLocation);
        this.setPolicyIndex();
        this.$router.push({ name: 'PolicyDetails', params: { contractId: this.indexPolicy } });
      }
    },
    async payNow() {
      await this.setPolicyIndex();
      // Calling payment call and if pay now exists then go through
      await this.$store.dispatch('policy/getPaymentInfo', this.policies[this.indexPolicy].contractId);
      if (!this.showPayInProgress && this.showPayNowLinkThisCard) {
        // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
        try {
          const i = this.$dynatrace.enterAction(`Dashboard_payNow_policy_${this.policies[this.indexPolicy]?.contractId ||
            this.indexPolicy}-${this.policies[this.indexPolicy]?.insuredName}_Selected`);
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_start');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        this.setPaymentStep(1);
        await this.$router.push({ name: 'GuidanceCalculator' });
        this.$router.push({ name: 'PolicyDetails', params: { contractId: this.indexPolicy } });
      } else {
        this.showMessageDialog = true;
      }
    },
    async setPolicyRtPolicyDetails() {
      this.setPolicyIndex();
      await this.$router.push({ name: 'GuidanceCalculator' });
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.indexPolicy } });
    },
    async setPolicyIndex() {
      // TODO do this only on OR link click for "Policy Details, Go click"
      await this.$store.dispatch('policy/changePolicy', { selectedIndex: this.indexPolicy });
      await this.$store.dispatch('policy/getPaymentInfo', this.policies[this.indexPolicy].contractId);
    },
    async navigatePayNow() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction(`Dashboard_payNow_policy_${this.policies[this.indexPolicy]?.contractId}-${this.policies[this.indexPolicy]?.insuredName}_Selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      try {
        const i = this.$dynatrace.enterAction('OneTimePayment_start');
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      this.setPolicyIndex();
      this.setPaymentStep(1);
      await this.$router.push({ name: 'GuidanceCalculator' });
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.indexPolicy } });
    },
  },
  mounted() {
    if (this.$refs.items !== undefined) {
      Ada.popupMenu(this.$refs.policies, this.$refs.items.$el);
    }
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.payment_in_process {
  float: right;
}
.border_bottom_line {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}
.background_color_light_blue {
  background-color: #f5f7fa;
  padding-top: 10px;
  padding-bottom: 10px;
}
.view_details_class {
  font-size: 14px;
  color: #0c6dca;
  padding-left: 27px;
  font-weight: bold;
  cursor: pointer;
  float: left;
}
.border_top_policy_num_header {
  border-top: 1px solid black;
  padding-bottom: 20px;
}

.pointerhand {
  cursor: pointer;
}
.infoBox {
  background-color: #e2f0fe;
  border-top: 4px solid #3399ff;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.selectMaxWidth {
  max-width: 320px;
  width: 100%;
  padding-bottom: 4px !important;
}
.underline_bottom{
  border-bottom: 2px solid #adadad;
}
.warningBox {
  background-color: #fdf3e2;
  border-top: 4px solid #f39c12;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.iconBig {
  font-size: 28px;
}

.go_mdAndUp {
  margin-top: 2px;
  margin-bottom: 24px;
}
.go_less_than_md {
  margin-top: 14px !important;
  margin-bottom: 24px;
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.mobile_go_button_mt {
  margin-top:0px;
  width: 220px;
  max-width: 220px;
  height: 32px !important;
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.blueBackground {
  background-color: #f5f7fa;
}
.shield_image_mdAndUp {
  position: relative;
  top: 25%;
}
.shield_image_below_md {
  position: relative;
  top: 0%;
  margin-bottom: 20px;
}
.pay_now_button {
  width: 180px;
  height: 40px;
}
.payNow_Center {
  text-align: -webkit-center;
}
a#pay_now_link
  text-decoration:underline;
a.heading_two
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  color: #ffffff !important;
  @media $display-breakpoints.sm-only
    font-size: 22px;
  @media $display-breakpoints.xs-only
    font-size: 20px;

.main-title
  margin: 18px 0 0 0;
.my-primerica-header
  line-height 53px

.policy-status-icon
  font-size: 20px;
  font-weight: 500;
  &.active
    color: #0b8d1f;
  &.pending
    color: #ee7026;

.term-life-insurance-card
  .row-info
    margin: 18px 0 0 0;
    .info-title
      font-size: $headings.subheading.size;
      margin-bottom: $spacers.one.y;
      font-weight: $font-weights.medium;
    .info-content
      font-size: $headings.h6.size;
      font-weight: $font-weights.regular;
  .top-right
    .help-text
      font-size: 14px;
  .protected-box
    background-color: #eeeeee;
    padding: 18px;
    margin: 24px 0 0 0;
    h1
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin: 0 0 5px 0;

.btn-policy-details
  width: 100%;
  max-width: 288px;
  margin-right: 0px;
  margin-left: 0px;


.policy-items-dropdown
  position: relative;
  border-radius: 13.5px;
  width: 135px;
  height: 27px;
  background: #ebebeb;
  margin: 0;
  padding: 0px 35px 0 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  letter-spacing: normal;
  color: #000;
  span
    font-weight 600
  .v-icon
    position: absolute;
    right: 10px;
    top: 2px;
    color: #2196f3;
.policy-number-text
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 0 0;

.mh-1
  min-height 1px;
.mh-120
  min-height: 120px;
@media only screen and (max-width: 768px) and (min-width: 600px)
  .right-border
    border-right: 1px solid #d4d4d4;
  .right-choice-flex-box
    margin-top 56px;
  .slider-box
    margin-top: 24px;
@media $display-breakpoints.xs-only
  .container
    padding 0px !important;
  .term-life-insurance-card
    .protected-box
      h1
        margin-top: 16px;
  .saving-debt-section
    .left
      padding 0px;
    .right
      padding 0px;
    .box
      margin 0px;
    .box-btn
      font-size 16px;
  .dashboard-icon
    margin-left 16px;
  .right-choice-flex-box
    margin-top 56px;
  .text-center
    text-align: center;
  .font-size-mobile
    font-size: 16px;

</style>

<template>
  <div>
    <!-- French v-show="language==='fr_CA'" -->
    <div id="ZN_5mZ8sppetCF3bwy" />
    <!-- Spanish  v-show="language==='es_US'"  -->
    <div id="ZN_8dg1vhNLyE2wPFI" />
    <!-- English  v-show="(language==='en_CA' || language === 'en_US')"  -->
    <div id="ZN_2lVXqyYUBIdPzGl" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Ada } from '@primerica/vue-components';
import Config from '@/shared/util/config';


export default {
  name: 'Qualtrics',
  computed: {
    ...mapGetters('customer', ['customerProfile']),
  },
  props: {
    language: {
      type: String,
      required: true,
      default: 'en_US',
    },
  },
  data: () => ({
    englishInitialized: false,
    frenchInitialized: false,
    spanishInitialized: false,
  }),
  watch: {
    async language() {
      await this.init();
      await this.showHideQualtricsBasedOnLanguage();
    },
  },
  methods: {
    async init() {
      if (this.customerProfile.customerId) {
        /* eslint-disable */
        try {
          window.Q_ci = this.customerProfile.customerId;
          window.Q_cenv = Config.get('env');
          window.Q_curl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
          const g = this.g();
        
          // Both US and CANADA gets the English version
          if ((this.language.indexOf('en_CA') > -1 || this.language.indexOf('en_US') > -1) && this.englishInitialized === false) {
            new g(100, 'r', 'QSI_S_ZN_2lVXqyYUBIdPzGl', `https://zn2lvxqyyubidpzgl-primerica.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_2lVXqyYUBIdPzGl&Q_LOC=${encodeURIComponent(window.location.href)}`).go(); // .start();
            this.englishInitialized = true;
          }
          // If CANADA they will get French option
          if (this.language.indexOf('fr_CA') > -1  && this.frenchInitialized === false) { 
            new g(100, 'r', 'QSI_S_ZN_5mZ8sppetCF3bwy', `https://zn5mz8sppetcf3bwy-primerica.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_5mZ8sppetCF3bwy&Q_LOC=${encodeURIComponent(window.location.href)}`).go(); // .start();
            this.frenchInitialized = true;
          } else if (this.language.indexOf('es_US') > -1 && this.spanishInitialized === false) { // US gets Spanish
            new g(100, 'r', 'QSI_S_ZN_8dg1vhNLyE2wPFI', `https://zn8dg1vhnlye2wpfi-primerica.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_8dg1vhNLyE2wPFI&Q_LOC=${encodeURIComponent(window.location.href)}`).go(); // .start();
            this.spanishInitialized = true;
          } 
          this.updateForADA();
          this.cleanDOM();
        } catch (e) {}
        /* eslint-enable */
      }
    },
    g() {
      /* eslint-disable */
      return function(e,h,f,g) {
        this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
        this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
        this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
        this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g+ "&t=" + (new Date()).getTime();document.body&&document.body.appendChild(a)}};
        // this.start=function(){var a=this;window.addEventListener?window.addEventListener("load",function(){a.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){a.go()})}
      }
      /* eslint-enable */
    },

    cleanDOM() {
      const repeatDivs = Array.from(document.querySelectorAll('#arcotuserdataDiv'));
      repeatDivs.forEach((elem, i) => {
        if (i > 0) {
          document.body.removeChild(elem);
        }
      });
    },

    async showHideQualtricsBasedOnLanguage() {
      const frenchSpanishButton = document.getElementById('QSIFeedbackButton-target-container');
      const frenchSpanishBody = document.getElementById('QSIFeedbackButton-btn');
      const englishButtonBody = document.getElementsByClassName('QSISlider SI_bJUfqKlfykOdjGl_SliderContainer');

      if (this.language === 'fr_CA' || this.language === 'es_US') {
        // French or Spanish btn and body
        if (this.frenchInitialized || this.spanishInitialized) {
          if (frenchSpanishButton) {
            frenchSpanishButton.hidden = false;
          }
          if (frenchSpanishBody) {
            frenchSpanishBody.hidden = false;
          }
        }
        // English btn & body
        if (this.englishInitialized) {
          if (englishButtonBody.length > 0) {
            englishButtonBody[0].hidden = true;
          }
        }
      } else { // This is going to be en_US, en_CA and catch all
        // French or Spanish btn and body
        if (this.frenchInitialized || this.spanishInitialized) {
          if (frenchSpanishButton) {
            frenchSpanishButton.hidden = true;
          }
          if (frenchSpanishBody) {
            frenchSpanishBody.hidden = true;
          }
        }
        // English btn & body
        if (this.englishInitialized) {
          if (englishButtonBody.length > 0) {
            englishButtonBody[0].hidden = false;
          }
        }
      }
    },
    updateForADA() {
      const qualtricsIframe = document.querySelector('[data-src*="primerica.az1.qualtrics"]');
      if (!qualtricsIframe) {
        return;
      }
      const qDivs = [].slice.call(document.querySelectorAll('.QSISlider > div'));
      const qImages = [].slice.call(document.querySelectorAll('.QSISlider img'));
      const qSlider = document.querySelector('.QSISlider > div[id*="slider-control-"]');
      const qCloseBtn = document.querySelector('.QSISlider img[src*="svg-close-btn-black-7"]').parentElement;
      Ada.makeButton(qCloseBtn, 'Close feedback form.');
      Ada.hideFromSR(qCloseBtn);
      qualtricsIframe.setAttribute('title', 'Qualtrics Feedback');
      Ada.hideFromSR(qualtricsIframe);
      qDivs.forEach((item) => {
        item.removeAttribute('tabindex');
      });
      qImages.forEach((image) => {
        image.setAttribute('alt', '');
      });
      Ada.makeButton(qSlider, 'Feedback form toggle');
      qSlider.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.target.click();
        } else if ((e.keyCode === 9 && e.shiftKey) && !qCloseBtn.hasAttribute('aria-hidden')) {
          qCloseBtn.focus();
        }
      });
      qSlider.addEventListener('click', () => {
        if (qualtricsIframe.hasAttribute('aria-hidden')) {
          Ada.revealToSR(qualtricsIframe);
          setTimeout(() => {
            qualtricsIframe.focus();
          }, 250);
          Ada.revealToSR(qCloseBtn);
        } else {
          Ada.hideFromSR(qualtricsIframe);
          Ada.hideFromSR(qCloseBtn);
        }
      });
      qCloseBtn.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.target.click();
        } else if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          qSlider.focus();
        }
      });
      qCloseBtn.addEventListener('click', () => {
        qSlider.focus();
        Ada.hideFromSR(qualtricsIframe);
        Ada.hideFromSR(qCloseBtn);
      });
    },
  },
  async mounted() {
    try {
      await this.init();
      await this.showHideQualtricsBasedOnLanguage();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  },
};
</script>

<i18n src="./locales.json"></i18n>

<template>
  <v-card>
    <v-card-text>
      <v-layout justify-center row wrap>
        <v-flex xs12 md9 class="text-xs-center">
          <h2 aria-live="assertive" class="font-weight-regular">
            {{ $t('L_start_new_policy') }}
          </h2>
          <h4 class="mt-3 font-weight-regular">
            {{ $t('L_you_can_lock_in') }}
          </h4>
          <h4 class="mt-3 font-weight-regular">
            {{ custServNum }}
          </h4>
        </v-flex>
        <v-flex xs12 md6>
          <v-layout row wrap>
            <v-flex xs12 mt-4 pt-3>
              <FirstNameInput v-model="firstName" :form-input="$v.firstName" input-id="first_name" :label="$t('L_firstname')" :auto-capitalize="true" />
            </v-flex>
            <v-flex xs12>
              <FirstNameInput
                input-id="middle_name"
                v-model="middleName"
                :form-input="$v.middleName"
                :label="$t('L_middle_name')"
                :validation-messages="{
                  name: 'Middle Name is Invalid.',
                  maxLength: 'Middle Name can be a Maximum of 32 Characters in Length.',
                }"
                :auto-capitalize="true"
              />
            </v-flex>
            <v-flex xs12>
              <LastNameInput input-id="last_name" v-model="lastName" :form-input="$v.lastName" :label="$t('L_last_name')" :auto-capitalize="true" />
            </v-flex>
            <v-flex xs12>
              <DateInput
                :disabled="true"
                id="birth_date"
                v-model="birthDate"
                :form-input="$v.birthDate"
                :label="$t('L_birth_date')"
                :validation-messages="{
                  date: $t('L_invalid_date'),
                  required: $t('L_birth_date_required'),
                }"
              />
            </v-flex>
            <v-flex v-if="isUsPolicy" xs12>
              <SSNInput id="ssn" enable-masking v-model="ssn" :form-input="$v.ssn" :label="$t('L_ssn')" />
            </v-flex>
            <v-flex v-if="isCanadianPolicy" xs12>
              <SINInput
                id="sin"
                enable-masking
                v-model="sin"
                :form-input="$v.sin"
                :label="$t('L_sin')"
                :validation-messages="{
                  ssn: $t('L_invalid_sin'),
                }"
              />
            </v-flex>
            <v-flex xs12>
              <RadioButtonGroup
                v-model="gender"
                :options="genders"
                :form-input="$v.gender"
                :validation-messages="{
                  required: 'Gender is required',
                }"
              />
            </v-flex>
            <v-flex xs12>
              <v-btn block color="primary" @click="next" :disabled="$v.$anyError">
                {{ $t('L_next') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
// TODO: fix dob validation
// TODO: disable next button; !$v.$anyDirty || $v.$anyError
import { mapGetters, mapActions } from 'vuex';
import { requiredIf, required } from 'vuelidate/lib/validators';
import {
  FirstNameInput,
  // FirstNameValidation,
  LastNameInput,
  // LastNameValidation,
  DateInput,
  SSNInput,
  SSNValidation,
  SINInput,
  SINValidation,
  RadioButtonGroup,
} from '@primerica/vue-components';
import { formatDate } from '@primerica/vue-components/src/util/formatters';

export function isSSNRequired() {
  return this.isUsPolicy;
}

export const validateName3 = (value) => {
  if (!value) {
    return true;
  }
  return value.match(/^([A-Za-z][A-Za-z '\-.]+)$/) !== null;
};

export const formatFromEnglish = (date) => {
  const year = date.slice(4);
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  return `${year}-${month}-${day}`;
};

export const formatFromFrench = (date) => {
  const year = date.slice(date.length - 4);
  const month = date.slice(2, 4);
  const day = date.slice(0, 2);
  return `${year}-${month}-${day}`;
};

export default {
  name: 'CRCStepAPersonalInfo',
  components: {
    FirstNameInput,
    LastNameInput,
    DateInput,
    SSNInput,
    SINInput,
    RadioButtonGroup,
  },
  data: () => ({
    birthDate: '',
    gender: '',
  }),
  validations() {
    return {
      gender: {
        required,
      },
      firstName: {
        ...validateName3,
        required,
      },
      middleName: {},
      lastName: {
        ...validateName3,
        required,
      },
      birthDate: {
        required,
      },
      sin: {
        ...SINValidation,
      },
      ssn: {
        required: requiredIf(isSSNRequired),
        ...SSNValidation,
      },
    };
  },
  methods: {
    ...mapActions({
      crcNext: 'childrider/crcNext',
      setNPFirstName: 'childrider/setNPFirstName',
      setNPMiddleName: 'childrider/setNPMiddleName',
      setNPLastName: 'childrider/setNPLastName',
      setNPDOB: 'childrider/setNPDOB',
      setNPGender: 'childrider/setNPGender',
      setNPSSN: 'childrider/setNPSSN',
    }),
    next() {
      this.setNPDOB(this.formattedBirthDate);
      this.setNPGender(this.gender);
      this.crcNext();
    },
  },
  mounted() {
    this.birthDate = formatDate(this.NPDOB, this.dateFormat);
    if (this.NPGender) {
      this.gender = this.NPGender;
    }
  },
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      locale: 'getLocale',
      currentPolicy: 'policy/currentPolicy',
      NPFirstName: 'childrider/NPFirstName',
      NPLastName: 'childrider/NPLastName',
      NPMiddleName: 'childrider/NPMiddleName',
      NPGender: 'childrider/NPGender',
      NPDOB: 'childrider/NPDOB',
      NPSSN: 'childrider/NPSSN',
      getCustServNumWDash: 'customer/getCustServNumWDash',
    }),
    custServNum() {
      if (this.locale?.length > 0) {
        const withDash = this.getCustServNumWDash(this.locale);
        return this.$t('L_use_the_form_below', {
          dashedPhoneNum: withDash,
        });
      }
      return '';
    },
    isFrenchLocale() {
      return this.locale.indexOf('fr') !== -1;
    },
    formattedBirthDate() {
      if (this.isFrenchLocale) {
        return formatFromFrench(this.birthDate);
      }
      return formatFromEnglish(this.birthDate);
    },
    firstName: {
      get() {
        return this.NPFirstName;
      },
      set(value) {
        this.setNPFirstName(value);
      },
    },
    middleName: {
      get() {
        return this.NPMiddleName;
      },
      set(value) {
        this.setNPMiddleName(value);
      },
    },
    lastName: {
      get() {
        return this.NPLastName;
      },
      set(value) {
        this.setNPLastName(value);
      },
    },
    ssn: {
      get() {
        if (!this.isCanadianPolicy) {
          return this.NPSSN;
        }
        return '';
      },
      set(value) {
        if (!this.isCanadianPolicy) {
          this.setNPSSN(value);
        }
      },
    },
    sin: {
      get() {
        if (this.isCanadianPolicy) {
          return this.NPSSN;
        }
        return '';
      },
      set(value) {
        if (this.isCanadianPolicy) {
          this.setNPSSN(value);
        }
      },
    },
    genders() {
      return [
        {
          label: this.$t('L_male'),
          value: 'M',
        },
        {
          label: this.$t('L_female'),
          value: 'F',
        },
      ];
    },
    isUsPolicy() {
      return !!(this.currentPolicy?.isUSPolicy || this.currentPolicy?.isNewYorkPolicy);
    },
    isCanadianPolicy() {
      return !!this.currentPolicy?.isCanadaPolicy;
    },
    isQuebecPolicy() {
      return this.currentPolicy?.isCanadaQuebecPolicy;
    },
  },
};
</script>

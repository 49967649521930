<i18n src="./locales.json"></i18n>
<template>
  <v-card class="pb-4 card-wrapper">
    <Alerts context="childrider" />
    <v-card-title class="justify-center">
      <h3 role="heading" aria-level="2" class="card-title pt-1 font-weight-regular">
        {{ $t('L_other_acknowledgements') }}
      </h3>
    </v-card-title>
    <v-spacer class="pt-2" />
    <v-card-text
      class="text-content frame-parent mb-4"
    >
      <v-card
        class="scroll-y"
      >
        <v-card-text
          id="scroll-target"
          v-scroll:#scroll-target="onScroll"
          column
          align-center
          justify-center
          style="max-height: 400px;"
        >
          <div v-for="(item, index) in acknowledgementHTML"
               :key="index"
          >
            <div v-html="item" />
          </div>
        </v-card-text>
      </v-card>
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          xs12
          sm11
          md9
          mt-4
          class="text-xs-center"
        >
          <a
            @click="openPDF"
          >
            <img
              src="../../assets/picture_as_pdf-24px.svg"
              alt=""
            >
            {{ $t('L_child_rider_exchange_package') }} {{ generationDate }}</a>
          <ChildRiderExchangePackage
            v-if="showModal"
            :show-modal="showModal"
            @close="showModal = false"
          />
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          xs12
          sm11
          md9
          class="text-xs-center"
        >
          <v-checkbox
            color="primary"
            v-model="accepted"
            :label="$t('L_by_checking_the_box_I_agree')"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-layout row wrap justify-center>
        <v-flex xs8 md6>
          <v-btn
            :loading="loading"
            id="i_agree"
            block
            color="primary"
            :disabled="!accepted"
            @click="submit"
          >
            {{ $t('L_i_agree') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import { openInNewWindow } from '@/mixins/openNewWindow';
import Alerts from '@/components/Alerts.vue';
import { DOCUMENT_OBJECT_TYPES } from '@/shared/constants';
import ChildRiderExchangePackage from '../ChildRiderExchangePackage/ChildRiderExchangePackage.vue';

export default {
  name: 'CRCStepE',
  data: () => ({
    offsetTop: 0,
    accepted: false,
    showModal: false,
  }),
  components: {
    ChildRiderExchangePackage,
    Alerts,
  },
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      policyDocs: 'policy/activePolicyDocuments',
      firstName: 'childrider/NPFirstName',
      lastName: 'childrider/NPLastName',
      selectedPackage: 'childrider/NPPackage',
      selectedOptionDetails: 'childrider/NPSelectedOptionDetails',
      acknowledgementHTML: 'childrider/NPAcknowledgementHTML',
      loading: 'childrider/loading',
      locale: 'getLocale',
    }),
    generationDate() {
      return `(${formatDate(this.selectedPackage?.generationDate, this.dateFormat)})`;
    },
  },
  methods: {
    ...mapActions({
      viewPDF: 'childrider/openPDFPackage',
      crcSubmit: 'childrider/crcSubmit',
      setLoading: 'childrider/setLoading',
      pullAgreement: 'childrider/pullAgreement',
    }),

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    async openPDF() {
      try {
        const respUrl = await this.viewPDF(DOCUMENT_OBJECT_TYPES.CRC);
        const isWeb = this.$pc2capacitor.isWeb();
        await this.openInNewWindow({ url: respUrl, target: '_blank', inappbrowser: !isWeb });
      } catch (error) {
        this.$store.commit('addError', { error, context: 'childrider' }, { root: true });
      }
    },
    async submit() {
      let i;
      try {
        this.setLoading(true);
        await this.crcSubmit();
        /*
          * Emit a custom action when a user successfully submits a child rider conversion request
          * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
        */
        i = this.$dynatrace.enterAction('CRC_successful_submission');
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        this.$store.commit('addError', { error, context: 'childrider' }, { root: true });
      } finally {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    locale(newData, oldData) {
      if (newData !== oldData) {
        this.pullAgreement('C');
      }
    },
  },
};
</script>

<i18n src='../../locales.json'></i18n>

<template>
  <div>
    <v-card>
      <!-- TODO: make this alert a separate component -->
      <!-- No more eligible child rider -->
      <v-layout>
        <v-flex
          xs12
        >
          <Alert v-if="isLastChild"
                 type="warning"
                 :dismissible="false"
                 icon="warning"
                 color="warning"
                 :expandable="false"
                 :colored-border="true"
                 class="setTopToBeFlushWIthMainDiv"
          >
            <v-layout
              slot="message"
              :class="$vuetify.breakpoint.xsOnly ? 'row wrap font-size--13' : ''"
            >
              <v-flex xs12 class="font-size--16">
                {{ $t('L_our_records_show_you',{ policyNo:currentPolicy._contractId, expireDate: crcExpireDate }) }}.
              </v-flex>
            </v-layout>
          </Alert>
        </v-flex>
      </v-layout>
      <!-- END - No more eligible child rider -->
      <v-card-text>
        <v-layout>
          <v-flex
            xs12
            class="text-xs-center"
          >
            <v-layout
              justify-center
            >
              <v-flex
                xs12
                md9
                py-4
              >
                <h3
                  aria-live="assertive"
                  class="font-weight-regular"
                >
                  {{ $t('L_your_request_for_individual_life') }}
                </h3>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                sm3
                py-2
              >
                <div class="font-weight-medium">
                  {{ $t('L_primary_insured') }}
                </div>
                <div>{{ getPrimaryInsured }}</div>
              </v-flex>
              <v-flex
                xs12
                sm3
                py-2
              >
                <div class="font-weight-medium">
                  {{ headerTimeLabel }}
                </div>
                <div>{{ getMonthlyBankDraftPremium }}</div>
              </v-flex>
              <v-flex
                xs12
                sm3
                py-2
              >
                <div class="font-weight-medium">
                  {{ $t('L_coverage_amount') }}
                </div>
                <div>{{ getCoverageAmount }}</div>
              </v-flex>
              <v-flex
                xs12
                sm3
                py-2
              >
                <div class="font-weight-medium">
                  {{ $t('L_request_number') }}
                </div>
                <div>{{ getRequestNumber }}</div>
              </v-flex>
            </v-layout>
            <v-layout
              justify-center
            >
              <v-flex
                xs12
                sm8
              >
                <h4
                  class="mt-3 font-weight-regular"
                >
                  {{ $t('L_this_policy_has_been_added_to') }}
                </h4>
                <h4
                  class="font-weight-regular"
                  v-html="$t('L_we_have_also',{email:email})"
                />
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              pa-4
            >
              <v-flex
                xs12
              >
                <v-btn
                  id="back_to_dashboard"
                  class="secondary"
                  color="primary"
                  @click="goBackToDashboard"
                >
                  {{ $t('L_back_to_dashboard') }}
                </v-btn>
                <v-btn
                  id="continue_to_next_child"
                  v-if="hasCRCPackageForCurrentPolicy"
                  color="primary"
                  @click="continueWithNextChild"
                >
                  {{ $t('L_continue_to_next_child') }}
                </v-btn>
                <v-btn
                  id="continue_to_next_child"
                  v-if="isLastChild && !hasCRCPackageForCurrentPolicy"
                  color="primary"
                  @click="continueWithAddAnotherChild"
                >
                  {{ $t('L_add_another_child') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import Alert from '@/components/Alert.vue';

export default {
  name: 'CRCStepF',
  methods: {
    ...mapActions({
      crcCancel: 'childrider/crcCancel',
      crcStart: 'childrider/crcStart',
      pullPackage: 'childrider/pullPackage',
      startAddAChild: 'policy/startAddAChild',
    }),
    goBackToDashboard() {
      this.crcCancel().then(() => {
        this.$router.push({ name: 'Dashboard' });
      });
    },
    continueWithNextChild() {
      this.crcStart().then(() => {
        this.$router.push({ name: 'ChildRiderLayout' });
      });
    },
    continueWithAddAnotherChild() {
      this.startAddAChild().then(() => {
        this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
      });
    },
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
      currentPolicy: 'policy/currentPolicy',
      customerProfile: 'customer/customerProfile',
      hasPackageGivenPolicyId: 'childrider/hasPackageGivenPolicyId',
      crcExpireDate: 'childrider/crcExpireDate',
      isLastChild: 'childrider/lastChild',
      selectedFaceAmount: 'childrider/NPSelectedFaceAmount',
      selectedPremiumAmount: 'childrider/NPSelectedPremiumAmount',
      newPolicy: 'childrider/newPolicy',
      getPrimaryInsured: 'childrider/NPFullName',
      selectedCoverageOption: 'childrider/NPSelectedOption',
      selectedOptionDetails: 'childrider/NPSelectedOptionDetails',
      getCustServNumWDash: 'customer/getCustServNumWDash',
      selectedPolicyIndex: 'policy/selectedPolicyIndex',
    }),
    email() {
      return this.customerProfile.email;
    },
    hasCRCPackageForCurrentPolicy() {
      return this.hasPackageGivenPolicyId(this.currentPolicy._contractId);
    },
    getMonthlyBankDraftPremium() {
      return `$${this.selectedPremiumAmount}`;
    },
    getCoverageAmount() {
      const faceAmount = this.selectedFaceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `$${faceAmount}`;
    },
    getRequestNumber() {
      return this.newPolicy.requestNumber;
    },
    headerTimeLabel() {
      let timeLabel;
      if (this.currentPolicy?.billingForm === 'A1') {
        if (this.currentPolicy?.billingMode === '01') {
          timeLabel = this.$t('L_quarterly_premium_amount');
        } else if (this.currentPolicy?.billingMode === '03') {
          timeLabel = this.$t('L_quarterly_premium_amount');
        } else if (this.currentPolicy?.billingMode === '06') {
          timeLabel = this.$t('L_semi_annual_premium_amount');
        } else if (this.currentPolicy?.billingMode === '12') {
          timeLabel = this.$t('L_annual_premium_amount');
        } else {
          timeLabel = this.$t('L_monthly_premium_amount');
        }
      } else if (this.currentPolicy?.billingForm === 'C2' || this.currentPolicy?.billingForm === 'D2' || this.currentPolicy?.billingForm === 'H1') {
        timeLabel = this.$t('L_quarterly_premium_amount');
      } else {
        timeLabel = this.$t('L_monthly_premium_amount');
      }
      return timeLabel;
    },
  },
  components: { Alert },
};
</script>
<style lang="stylus" scoped>
  .setTopToBeFlushWIthMainDiv
    margin-top: -4px;
</style>

import { render, staticRenderFns } from "./PolicyDetails.vue?vue&type=template&id=60a1864c&scoped=true"
import script from "./PolicyDetails.vue?vue&type=script&lang=js"
export * from "./PolicyDetails.vue?vue&type=script&lang=js"
import style0 from "./PolicyDetails.vue?vue&type=style&index=0&id=60a1864c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a1864c",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fdetails%2FPolicyDetails.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
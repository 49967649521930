<i18n src='../locales.json'></i18n>

<template>
  <v-layout
    row
    wrap
    mt-3
  >
    <v-flex xs12>
      <v-layout row>
        <v-flex
          xs12
          md7
          id="cover_funeral_expenses"
        >
          <div
            class="heading_three"
          >
            {{ $t('L_cover_funeral') }}
          </div>
          <div
            class="heading_four mt-2 font-weight-medium"
          >
            {{ $t('L_how_much_funeral') }}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      xs12
      mb-4
      class="shift-up"
    >
      <v-layout
        row
        wrap
      >
        <LinkedSlider
          v-model="amount"
          :form-input="formInput"
          :max="20000"
          :step="200"
          :tooltip-text="$t('L_average_cost_burial')"
          @input="$emit('input', $event)"
          label-id="cover_funeral_expenses"
        />
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { Slider } from '@primerica/vue-components';
import CurrencyDisplay from '../../CurrencyDisplay.vue';
import LinkedSlider from '../../LinkedSlider.vue';

export default {
  name: 'CoverFuneralExpenses',
  data: () => ({
    sliderAmount: 8000,
    amount: 8000,
    manualInput: false,
  }),
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    formInput: {
      type: Object,
      required: true,
    },
  },
  components: {
    LinkedSlider,
    Slider,
    CurrencyDisplay,
  },
  watch: {
    value(value) {
      this.amount = value;
    },
  },
  mounted() {
    this.amount = this.sliderAmount = this.value;
  },
};
</script>

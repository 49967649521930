<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <v-layout class="justify-center">
      <v-flex xs11>
        <v-card>
          <v-card-title class="justify-space-around text-xs-center">
            <h2 class="heading_two">
              {{ $t('L_enter_your_bank_account_information') }}
            </h2>
          </v-card-title>
          <v-card-text class="background-light-grey">
            <v-layout column>
              <v-flex xs12 sm7>
                <!-- Starts top left half -->
                <v-layout row wrap>
                  <v-flex xs12 sm7>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <span class="font-weight-medium">{{
                          $t('L_payment_type')
                        }}</span>
                      </v-flex>
                      <v-flex xs12 mt-2>
                        <span class="font-weight-medium grey-ada-text">
                          {{ $t(formattedPaymentType) }}
                        </span>
                      </v-flex>
                      <!-- <v-flex xs12>
                        <span class="font-weight-medium" id="AchStepOne">&nbsp;</span>
                      </v-flex> -->
                      <!-- <v-flex xs12 md9 mt-2 pr-8 mr-8>
                        <div class="pr-2 mr-2">
                          <v-text-field
                            v-model="accountHolder"
                            id="account_holder_name"
                            :label="$t('L_account_holder_name')"
                            :error-messages="accountHolderErrorMessages"
                            @change="validateAccountHolder"
                            @keyup="validateAccountHolder"
                            @blur="validateAccountHolder"
                            :maxlength="75"
                            :disabled="disableAccountHolderNameEdit"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12 mb-4>
                        <span class="font-size--14 font-weight-medium">
                          {{ $t('L_account_holder_must_be_same_as_owner') }}
                        </span>
                      </v-flex> -->
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm5>
                    <!-- Starts top right half -->
                    <RadioButtonGroup
                      v-model="accountType"
                      :options="accountOptions"
                      :form-input="$v.accountType"
                    />
                    <span class="font-size--14 font-weight-normal">
                      {{ $t('L_be_sure_to_select') }}
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-flex xs12 sm8>
                      <BankRoutingNumber
                        :max-lengthinput="isCanadaPolicy ? 8 : 9"
                        :min-length-input="isCanadaPolicy ? 8 : 9"
                        bank-routingid="onetp_routing_number_ca"
                        v-model="routingNumber"
                        :form-input="$v.routingNumber"
                        @blur="checkRouting"
                        :label="$t('L_routing_number')"
                      />
                    </v-flex>
                    <v-flex xs12 sm9>
                      <BankNameInput
                        id="onetp_bank_name"
                        :class="
                          bankName.length > 0 ? 'valid-bank' : 'invalid-bank'
                        "
                        v-model="bankName"
                        :form-input="$v.bankName"
                        :label="$t('L_bank_name')"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-flex xs12 sm8>
                      <BankAccountNumber
                        :max-length-input="isCanadaPolicy ? 12 : 17"
                        :min-length-input="isCanadaPolicy ? 3 : 2"
                        bank-accountid="onetp_bank_acct_number"
                        v-model="bankAcctNumber"
                        :form-input="$v.bankAcctNumber"
                        :label="$t('L_account_number')"
                      />
                    </v-flex>
                    <v-flex xs12 sm9>
                      <VerifyBankAccountNumber
                        :max-length-input="isCanadaPolicy ? 12 : 17"
                        bank-accountid="onetp_verify_bank_acct_number"
                        v-model="verifyBankAcctNumber"
                        :form-input="$v.verifyBankAcctNumber"
                        :label="$t('L_verify_account_number')"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs12 mt-3 class="text-xs-center">
                {{
                  $t('L_upon_succesful_processing', { paid: futurePaidToDate })
                }}
              </v-flex>
              <v-flex class="text-sm-right text-xs-center mt-4">
                <v-layout row wrap class="justify-end">
                  <v-flex
                    xs12
                    sm2
                    :class="$vuetify.breakpoint.smAndUp ? 'mr-4' : ''"
                  >
                    <v-btn block class="mb-3" color="secondary" @click="cancel">
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-btn
                      block
                      class="white--text mb-3"
                      :disabled="badForm"
                      @click="goToStepTwo"
                      color="primary"
                    >
                      {{ $t('L_continue') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import {
  RadioButtonGroup,
  BankNameInput,
  BankAccountNumber,
  VerifyBankAccountNumber,
  BankRoutingNumber,
} from '@primerica/vue-components';
import Config from '@/shared/util/config';
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';
import stringValues from '@/shared/util/stringValues';
import { filters } from '@/mixins/filters';
import moment from 'moment';
import messages from '../../../../../../../../../../../shared/validation/messages';

export const notSameDigits = (value) => {
  const regexp = /(\d)\1{7}/;
  return !regexp.test(value);
};

export const letterSpaceLetter = (value) => {
  const regexp = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
  return regexp.test(value);
};

export default {
  name: 'AchStepOne',
  mixins: [filters],
  components: {
    RadioButtonGroup,
    BankNameInput,
    BankAccountNumber,
    VerifyBankAccountNumber,
    BankRoutingNumber,
  },
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
    minimumAmountSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    bank: '',
    accountHolder: '', // this.policyData?.owner?.name
    accountType: 0,
    bankAcctNumber: '',
    verifyBankAcctNumber: '',
    routingNumber: '',
    accountHolderErrorMessages: [],
  }),
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    ...mapGetters('policy', {
      bankNameResponse: 'bankNameResponse',
      paymentData: 'paymentData',
    }),
    disableAccountHolderNameEdit() {
      return Config.get('payments.disableAccountHolderNameEdit');
    },
    bankName() {
      const name = this?.bankNameResponse
        ? this.bankNameResponse[0]?.name
        : null;
      return name || this.bank;
    },
    accountOptions() {
      return [
        {
          label: this.$t('L_savings_account'),
          value: this.bankAccountType === 3 ? 0 : 1,
        },
        {
          label: this.$t(this.checkingAccount),
          value: this.bankAccountType === 3 ? 1 : 0,
        },
      ];
    },
    badForm() {
      return this.$v.anyError || this.$v.$invalid;
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    checkingAccount() {
      return this.isCanadaPolicy ? 'L_chequing_account' : 'L_checking_account';
    },
    bankAccountType() {
      return this.policyData?.paymentInfo?.accountType;
    },
    formattedPaymentType() {
      return this.policyData?.billingMode && this.policyData?.billingForm
        ? stringValues.paymentMethod(this.policyData)
        : '';
    },
    futurePaidToDate() {
      const paidToDate = moment(this.policyData?.paymentInfo?.paidToDate);
      if (this.minimumAmountSelected) {
        const futurePaidToDate = paidToDate
          .add(1, 'months')
          .format('YYYY-MM-DD');
        return formatDate(futurePaidToDate, this.dateFormat);
      }
      const futurePaidToDate = paidToDate
        .add(this.policyData?.billingMode, 'months')
        .format('YYYY-MM-DD');
      return formatDate(futurePaidToDate, this.dateFormat);
    },
    accountDiff() {
      const currentAccount = [
        this.policyData?.paymentInfo?.bank?.name.trim(),
        this.policyData?.paymentInfo?.routingNumber,
        this.policyData?.paymentInfo?.accountNumber,
      ].toString();
      const paymentData = [
        this.bankName.trim(),
        this.routingNumber,
        this.hideNums(this.bankAcctNumber),
      ].toString();
      return currentAccount !== paymentData;
    },
    hadNSF() {
      return this.policyData?.payNowLinkDesc?.indexOf('unable to process') >= 0;
    },
    errorMessages() {
      const errors = [];
      const validationMessages = Object.assign(
        {},
        messages.accountHolder,
        this.validationMessages,
      );
      if (typeof this.formInput === 'undefined' || !this.formInput.$dirty) {
        return errors;
      }
      if (this.formInput.$error) {
        // If there is an error on the field
        Object.keys(validationMessages).forEach((type) => {
          if (
            this.formInput[type] === false &&
            validationMessages &&
            Object.prototype.hasOwnProperty.call(validationMessages, type)
          ) {
            errors.push(validationMessages[type]);
          }
        });
        if (errors.length === 0) {
          // If no messages have been added for this error
          errors.push(messages.generic.invalid);
        }
      }
      return errors.map(msg =>
        (typeof this.$t !== 'undefined'
          ? this.$t(msg, { length: this.minLengthInput })
          : msg));
    },
  },
  methods: {
    ...mapMutations({
      setPreLoadBank: 'policy/setPreLoadBank',
    }),
    validateAccountHolder() {
      let error1 = '';
      const tempAccountHolder = this.accountHolder;
      const regex = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
      this.accountHolderErrorMessages = [];
      if (tempAccountHolder.trim().length === 0) {
        error1 = this.$t('L_account_holder_name_is_required');
      } else if (!regex.test(tempAccountHolder)) {
        error1 = this.$t('L_account_holder_name_is_invalid');
      }
      if (error1.length > 0) {
        this.accountHolderErrorMessages.push(error1);
      }
    },
    checkRouting() {
      if (this.$v.routingNumber.$invalid) {
        this.clearBank();
        return;
      }
      const country = this.isCanadaPolicy ? 'CA' : 'US';
      const payload = {
        policyNo: this.policyData?.contractId,
        routingNumber: this.routingNumber,
        country,
      };
      this.getBankInfo(payload);
    },
    getBankInfo(payload) {
      this.$store.dispatch('policy/getBankInfo', payload).then((response) => {
        if (!response) {
          this.clearBank();
        }
      });
    },
    goToStepTwo() {
      const paymentData = {
        amount: this.policyData?.paymentInfo?._pACPremium,
        // Edit allowed as of MYP-2480
        accountHolder: this.accountHolder, // this.policyData?.owner?.name,
        accountNumber: this.bankAcctNumber,
        accountType:
          this.accountType === 0 ? this.$t(this.checkingAccount) : 'Savings',
        accountCode: this.accountType,
        bankName: this.bankName,
        paymentType: this.$t(this.formattedPaymentType),
        routingNumber: this.routingNumber,
        differentAccount: this.accountDiff,
        hadNSF: this.hadNSF,
      };
      this.$store.commit('policy/setPaymentData', paymentData);
      this.$emit('goToStepTwo');
      this.setPreLoadBank(this.accountDiff);
    },
    clearBank() {
      this.$store.commit('policy/clearBank');
    },
    cancel() {
      this.clearBank();
      this.$emit('cancel');
    },
    hideNums(val) {
      return filters.filters.hideNums(val);
    },
  },
  async mounted() {
    this.accountHolder = this.policyData?.owner?.name;
    try {
      const i = this.$dynatrace.enterAction('OneTimePayment_ACH_BankInformation_PageDisplayed');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
  validations() {
    return {
      accountType: {
        required,
      },
      // accountHolder: {
      //   required,
      //   letterSpaceLetter,
      // },
      bankName: {
        required,
      },
      bankAcctNumber: {
        required,
        notSameDigits,
        minLength: minLength(this.isCanadaPolicy ? 3 : 2),
        maxLength: maxLength(this.isCanadaPolicy ? 12 : 17),
      },
      routingNumber: {
        required,
        notSameDigits,
        minLength: minLength(this.isCanadaPolicy ? 8 : 9),
      },
      verifyBankAcctNumber: {
        required,
        notSameDigits,
        sameAs: sameAs('bankAcctNumber'),
      },
    };
  },
};
</script>

<style lang="stylus">
.grey-ada-text {
  color: #737171;
}

.valid-bank.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}

.valid-bank.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}
</style>

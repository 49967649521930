<i18n src="./locales.json"></i18n>
<template>
  <v-card>
    <v-card-title>
      <span role="heading" aria-level="2" class="pl-2 pr-2 card-title font-size--24">
        {{ $t('L_confirm_your_changes') }}
      </span>
    </v-card-title>
    <v-divider pb-2 />
    <v-layout row wrap class="paddingLRDesktop">
      <v-flex xs12 mt-4 font-weight-bold font-size--16>
        {{ $t('L_please_review_and_confirm_that_this_information_is_correct') }}
      </v-flex>
      <v-flex xs12 mt-4 font-weight-regular font-size--16>
        {{ $t('L_the_beneficiaries_listed_below_will_be_used_to_update_this_policy_and_will_replace') }}
      </v-flex>
      <v-flex xs12 mt-4 font-weight-regular font-size--16>
        <span v-html="$t('L_if_You_need_to_make_changes_select_the_back_button')" />
      </v-flex>
    </v-layout>
    <!-- PRIMARY SECTION-->
    <v-layout row wrap class="paddingLRDesktop blueBoxInsured">
      <v-flex xs12 mt-2 mb-2 font-weight-regular font-size--24>
        {{ $t('L_policy_insured') }} <span class="font-weight-bold"> {{ primaryInsured }}</span>
      </v-flex>
    </v-layout>
    <!-- PRIMARY - Primary Benef-->
    <v-layout row wrap class="paddingLRDesktop lineSeparation">
      <v-flex xs8 font-weight-bold pt-2 pb-2 font-size--16 text-xs-left>
        {{ $t('L_primary_beneficiary') }}
      </v-flex>
      <v-flex xs4 font-weight-bold pt-2 pb-2 font-size--16 text-xs-center>
        {{ $t('L_allocation_percentage') }}
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      v-for="(beneficiary, index) in beneficiaryPrimary"
      :key="beneficiary.beneKeyId + 'primaryBeneficiary' + index"
      :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
      class="layoutHeightVertCenterData paddingLRDesktop lineSeparation"
    >
      <v-flex xs8 font-size--14 pt-2 pb-2 text-xs-left class="textOverflow">
        <span v-if="beneficiary.irrevocable">
          <v-icon color="#4e4949c7">lock</v-icon>
        </span>
        {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
      </v-flex>
      <v-flex xs4 font-size--14 pt-2 pb-2 text-xs-center>
        {{ beneficiary.beneficiaryPercent }} %
      </v-flex>
    </v-layout>
    <!-- PRIMARY - Contingent Benef-->
    <v-layout row wrap class="paddingLRDesktop lineSeparation">
      <v-flex xs8 font-weight-bold pt-2 pb-2 font-size--16 text-xs-left>
        {{ $t('L_contingent_beneficiary') }}
      </v-flex>
      <v-flex xs4 font-weight-bold pt-2 pb-2 font-size--16 text-xs-center>
        {{ $t('L_allocation_percentage') }}
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      v-for="(beneficiary, index) in beneficiaryPrimaryContingent"
      :key="beneficiary.beneKeyId + 'beneficiaryPrimaryContingent' + index"
      :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
      class="layoutHeightVertCenterData paddingLRDesktop lineSeparation"
    >
      <v-flex xs8 font-size--14 pt-2 pb-2 text-xs-left class="textOverflow">
        <span v-if="beneficiary.irrevocable">
          <v-icon color="#4e4949c7">lock</v-icon>
        </span>
        {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
      </v-flex>
      <v-flex xs4 font-size--14 pt-2 pb-2 text-xs-center>
        {{ beneficiary.beneficiaryPercent }} %
      </v-flex>
    </v-layout>
    <!-- SPOUSE SECTION-->
    <span v-if="showSpouseSection">
      <v-layout row wrap class="paddingLRDesktop blueBoxInsured">
        <v-flex xs12 mt-2 mb-2 font-weight-regular font-size--24>
          {{ $t('L_policy_spouse_insured') }} <span class="font-weight-bold"> {{ spouseInsured }}</span>
        </v-flex>
      </v-layout>
      <!-- Spouse - Primary Benef-->
      <v-layout row wrap class="paddingLRDesktop lineSeparation">
        <v-flex xs8 font-weight-bold pt-2 pb-2 font-size--16 text-xs-left>
          {{ $t('L_primary_beneficiary') }}
        </v-flex>
        <v-flex xs4 font-weight-bold pt-2 pb-2 font-size--16 text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiarySpouse"
        :key="beneficiary.beneKeyId + 'primaryBeneficiary' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData paddingLRDesktop lineSeparation"
      >
        <v-flex xs8 font-size--14 pt-2 pb-2 text-xs-left class="textOverflow">
          <span v-if="beneficiary.irrevocable">
            <v-icon color="#4e4949c7">lock</v-icon>
          </span>
          {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
        </v-flex>
        <v-flex xs4 font-size--14 pt-2 pb-2 text-xs-center> {{ beneficiary.beneficiaryPercent }} % </v-flex>
      </v-layout>
      <!-- Spouse - Contingent Benef-->
      <v-layout row wrap class="paddingLRDesktop lineSeparation">
        <v-flex xs8 font-weight-bold pt-2 pb-2 font-size--16 text-xs-left>
          {{ $t('L_contingent_beneficiary') }}
        </v-flex>
        <v-flex xs4 font-weight-bold pt-2 pb-2 font-size--16 text-xs-center>
          {{ $t('L_allocation_percentage') }}
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap
        v-for="(beneficiary, index) in beneficiarySpouseContingent"
        :key="beneficiary.beneKeyId + 'beneficiaryPrimaryContingent' + index"
        :class="index % 2 === 0 ? 'bkgrnd-color-blue-white' : 'bkgrnd-color-white'"
        class="layoutHeightVertCenterData paddingLRDesktop lineSeparation"
      >
        <v-flex xs8 font-size--14 pt-2 pb-2 text-xs-left class="textOverflow">
          <span v-if="beneficiary.irrevocable">
            <v-icon color="#4e4949c7">lock</v-icon>
          </span>
          {{ getFullNameForDisplayWithLocale(beneficiary.fullName) }}
        </v-flex>
        <v-flex xs4 font-size--14 pt-2 pb-2 text-xs-center> {{ beneficiary.beneficiaryPercent }} % </v-flex>
      </v-layout>
    </span>
    <v-divider pt-2 pb-2 class="solidBlack paddingLRDesktop" />
    <StickyContainer>
      <span slot="stickycontent">
        <!-- Button section-->
        <v-layout row wrap class="paddingLRDesktop justify-center">
          <v-flex xs12 mt-4 mb-4 text-xs-center>
            <v-btn id="ConfirmBeneficiary_Back" class="pl-4 pr-4 buttonFormat" color="secondary" @click="goBackToMyBeneficiary">
              <h3 class="heading_three font-weight-bold" :class="locale === 'fr_CA' ? 'font-size--14' : 'font-size--18'">
                {{ $t('L_back') }}
              </h3>
            </v-btn>
            <v-btn id="ConfirmBeneficiary_Confirm_and_submit" class="pl-4 pr-4 buttonFormat" color="primary" @click="confirmAndSubmit" :disabled="!isSubmitButtonReady">
              <h3 class="heading_three font-weight-bold" :class="locale === 'fr_CA' ? 'font-size--14' : 'font-size--18'">
                {{ $t('L_confirm_and_submit') }}
              </h3>
            </v-btn>
          </v-flex>
        </v-layout>
      </span>
    </StickyContainer>
    <!-- Disclaimer Section-->
    <v-layout row wrap ml-4 mr-4>
      <v-flex xs12 text-xs-left mt-4 font-size--14>
        <span>
          <v-icon color="#4e4949c7">lock</v-icon>
        </span>
        <span v-html="$t('L_note_beneficiaries_with_this_icon_represent_an')" />
      </v-flex>
      <v-flex xs12 text-xs-left mt-4 mb-4 font-size--14>
        <span>
          <i18n path="L_the_above_beneficiaries_will_be_recorded_as" tag="p" class="font-weight-regular">
            <span>{{ customerServicePhoneNum }}</span>
          </i18n>
        </span>
      </v-flex>
    </v-layout>
    <v-dialog v-model="displaySuccessMessage" max-width="700" :persistent="true" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card>
        <v-layout row wrap>
          <v-flex class="greenbar">
            &nbsp;
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 text-xs-right mt-2 mr-2 mb-4 font-size--18>
            <v-icon @click="closeDialogAndResetBeneficiaryStep" color="#000000">
              close
            </v-icon>
          </v-flex>
        </v-layout>
        <v-layout row wrap ml-4 mr-4>
          <v-flex xs12 font-size--18 font-weight-bold mb-4>
            <v-icon color="#0b8d1f" class="pr-2">
              check_circle
            </v-icon>{{ $t('L_Beneficiary_changes_completed') }}
          </v-flex>
          <v-flex xs12 font-size--16 mb-4 text-xs-center>
            {{ $t('L_your_beneficiary_changes_were_successfully_applied_to_your_life_policy') }}
          </v-flex>
          <v-flex xs12 text-xs-center mb-4>
            <v-btn class="pl-4 pr-4 " :class="$vuetify.breakpoint.xsOnly ? 'buttonFormat_mobile' : 'buttonFormat'" color="primary" @click="closeDialogAndResetBeneficiaryStep">
              <h3 class="heading_three font-weight-bold font-size--18">
                {{ $t('L_OK') }}
              </h3>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import StickyContainer from '../../../../../../../components/StickyContainer/StickyContainer.vue';

export const contactInfo = [
  { number: 8002574725, phone: '(800) 257-4725', email: 'plic@primerica.com' },
  { number: 8002574725, phone: '(800) 257-4725', email: 'nblic@primerica.com' },
  { number: 8003877976, phone: '(800) 387-7976', email: 'plicc_cn@primerica.com' },
];
export default {
  name: 'ConfirmBeneficiary',
  components: {
    StickyContainer,
  },
  data: () => ({
    displaySuccessMessage: false,
    isSubmitButtonReady: true,
  }),
  computed: {
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('policy', ['currentPolicy', 'policies', 'beneficiaryPrimary', 'beneficiaryPrimaryContingent', 'beneficiarySpouse', 'beneficiarySpouseContingent']),
    customerServicePhoneNum() {
      if (this.locale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.locale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.locale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
    showSpouseSection() {
      if (this.currentPolicy?.data?.spouseFirstName) {
        return `${this.currentPolicy?.data?.spouseFirstName} ${this.currentPolicy?.data?.spouseLastName}`;
      }
      return '';
    },
    primaryInsured() {
      return `${this.currentPolicy?.primaryInsured?.firstName} ${this.currentPolicy?.primaryInsured?.lastName}` || '';
    },
    spouseInsured() {
      if (this.currentPolicy?.data?.spouseLastName) {
        return `${this.currentPolicy?.data?.spouseFirstName} ${this.currentPolicy?.data?.spouseLastName}`;
      }
      return this.$t('L_policy_beneficiaries_spouse');
    },
  },
  methods: {
    ...mapActions('policy', ['getPolicyOverview', 'setBeneficiaryStep', 'setBeneficiaries']),
    ...mapMutations(['setRootLoading']),
    closeDialogAndResetBeneficiaryStep() {
      this.displaySuccessMessage = false;
      this.setBeneficiaryStep(-1);
    },
    getFullNameForDisplayWithLocale(fullName) {
      let retFullName = fullName;
      if (this.locale === 'fr_CA' || this.locale === 'es_US') {
        if (fullName === 'Estate') {
          retFullName = this.$t('L_estate');
        } else if (fullName === 'All Children Equally') {
          retFullName = this.$t('L_all_children_equally');
        } else {
          return retFullName;
        }
      }
      return retFullName;
    },
    async confirmAndSubmit() {
      this.isSubmitButtonReady = false;
      try {
        const i = this.$dynatrace.enterAction('confirmBeneficiaries_submit');
        this.$dynatrace.leaveAction(i);
        const tempDynatraceString = `PC2_ConfirmBenf_Submit_Policynum-${this.currentPolicy?.contractId}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const response = await this.setBeneficiaries();
        if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
          this.displaySuccessMessage = true;
        }
        const j = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(j);
      } catch (error) {
        /*  */
        this.isSubmitButtonReady = true;
      }
      this.isSubmitButtonReady = true;
    },
    goBackToMyBeneficiary() {
      this.setBeneficiaryStep(1);
    },
  },
};
</script>

<style scoped>
.blueBoxInsured {
  background-color: #d2dee9;
  padding-left: 24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}
.lineSeparation {
  border-bottom: 1px solid #cccccc;
  margin-left: 20px;
  margin-right: 20px;
}
#ra_external {
  width: 100%;
}
#mainIframe {
  overflow-y: scroll;
}
.paddingLRDesktop {
  padding-left: 20px;
  padding-right: 20px;
}
.dialog_header_label {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
}
.dialog_header_label_mobile {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.dialog_close_icon {
  font-size: 40px !important;
}
.dialog_close_icon_mobile {
  font-size: 25px !important;
  margin-top: -5px;
}
.dialog_body_text {
  font-size: 18px;
  font-weight: regular;
  padding-bottom: 80px;
}
.dialog_body_text_mobile {
  font-size: 13px;
  font-weight: regular;
  padding-bottom: 40px;
}
.dialog_button {
  margin-top: 50px;
  font-size: 20px;
  font-weight: bold;
}
.dialog_button_mobile {
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
}
.buttonFormat {
  width: 300px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
}
.buttonFormat_mobile {
  width: 180px;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
}
.solidBlack {
  border: 2px solid black;
  margin-top: 40px;
}
.greenbar {
  border-top: 8px solid #0b8d1f;
}
</style>

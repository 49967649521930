import { render, staticRenderFns } from "./NavigationDrawer.vue?vue&type=template&id=64e6445e&scoped=true"
import script from "./NavigationDrawer.vue?vue&type=script&lang=js"
export * from "./NavigationDrawer.vue?vue&type=script&lang=js"
import style0 from "./NavigationDrawer.vue?vue&type=style&index=0&id=64e6445e&prod&lang=stylus&scoped=true"
import style1 from "./NavigationDrawer.vue?vue&type=style&index=1&id=64e6445e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e6445e",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FNavigationDrawer%2FNavigationDrawer.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
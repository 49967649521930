<i18n src="../../../locales.json"></i18n>
<template>
  <div>
    <Alert
      :show-alert="showSuccess"
      :dismissable="true"
      :auto-dismiss="true"
      :auto-dismiss-timeout="5000"
      :message="$t('L_successfully_changed_your_payment_option', { email: customerProfile.email })"
      alert-icon="check"
      type="success"
      :new-alert-type="true"
    />
    <v-divider />
    <v-layout class="text-xs-center" row wrap>
      <v-flex xs12 class="my-4">
        <v-icon class="done" size="53">
          check_circle
        </v-icon>
      </v-flex>
      <v-flex xs12>
        <v-card-title
          class="py-0
                justify-center"
        >
          <span role="heading" aria-level="3" class="title alert-heading font-weight-regular mb-0 text-xs-center px-4">
            {{ $t('L_payment_updated_successfully') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-layout text-xs-center row wrap class="justify-center">
            <v-flex xs12 mt-3>
              {{ $t('L_payment_bill_emailed') }}
            </v-flex>

            <v-flex xs12 px-1>
              <i18n path="L_payment_make_changes_to_address" tag="span" class="font-weight-regular">
                <a href="javascript:void(0)" @click="$emit('open-contact-details')" class="text-decoration-underline">{{ $t('L_contact_details') }}</a>
              </i18n>
            </v-flex>

            <v-flex xs12 mt-3>
              <v-btn id="edit-payment_btn_done" class="pl-4 pr-4" color="primary" @click="closePanel">
                <h3
                  class="heading_three
                               font-weight-medium
                               font-size-md"
                >
                  {{ $t('L_payment_done') }}
                </h3>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { Alert } from '@primerica/vue-components';

export default {
  name: 'StepThree',
  components: {
    Alert,
  },
  data: () => ({
    showSuccess: true,
  }),
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['currentPolicy']),
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    async closePanel() {
      // This removes the payment Alerts
      this.removeAlerts('payment');
      this.$store.commit('policy/setPanelValue', -1);
      this.$emit('done');
      await this.$store.dispatch('policy/getPolicyOverview', this.currentPolicy?.contractId);
    },
  },
};
</script>
<style scoped>
.done {
  border-radius: 50%;
  color: white;
  background-color: #063562;
}
</style>

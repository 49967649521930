<i18n src='./locales.json'></i18n>
<template>
  <v-layout
    justify-center
    row
    wrap
  >
    <v-flex
      xs12
    >
      <Alert
        :show-alert="true"
        :dismissable="false"
        :message="$t('L_payment_change_alert_warning')"
        alert-icon="warning"
        type="error"
        :new-alert-type="true"
      />
    </v-flex>
    <v-flex
      md7
      sm10
      xs12
      px-3
    >
      <v-card-title
        class="pb-0
                justify-center"
      >
        <span
          role="heading"
          aria-level="3"
          class="title alert-heading font-weight-regular mb-0 text-xs-center px-4"
        >
          {{ $t('L_payment_change_alert_heading') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-layout
          text-xs-center
          row
          wrap
          class="justify-center"
        >
          <v-flex
            xs8
            mt-3
          >
            {{ $t('L_payment_change_alert_desc') }}
          </v-flex>
          <v-flex
            xs12
            mt-3
          >
            <v-btn
              id="alert_change_payment_btn_cancel"
              class="px-4"
              color="secondary"
              @click="cancel"
            >
              <h3 class="heading_three
                                 font-weight-medium
                                 font-size-md"
              >
                {{ $t('L_cancel') }}
              </h3>
            </v-btn>
            <v-btn
              id="alert_change_payment_btn_continue"
              class="px-4"
              color="primary"
              @click="goToStepOne"
            >
              <h3
                class="heading_three
                               font-weight-medium
                               font-size-md"
              >
                {{ $t('L_payment_change_alert_change') }}
              </h3>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-flex>
  </v-layout>
</template>
<script>
import { Alert } from '@primerica/vue-components';

export default {
  name: 'AlertChangePayment',
  components: {
    Alert,
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    goToStepOne() {
      this.$emit('disablePaymentDetailsLink');
      this.$emit('goToStepOne');
    },
  },
};
</script>
<style scoped>
.alert-heading {
  line-height: 1.5 !important;
}
</style>

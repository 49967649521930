<i18n src='./locales.json'></i18n>

<template>
  <v-flex mt-2 xs12 class="representative">
    <v-card
      flat
      :class="hide ? 'elevation-0' : 'agent_card'"
      :color="hide ? 'transparent' : '#ffffff'"
    >
      <v-card-title>
        <v-flex xs12 sm12 md6 lg6
                :class="{'text-xs-center': hide}"
        >
          <span
            role="heading"
            aria-level="2"
            :class="hide ? 'title mb-0 pb-0 font-weight-medium' : 'card-title'"
          >
            {{ title }}
          </span>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 v-if="agents.length > 1 && !hide">
          <v-select
            filled
            :items="agents"
            solo
            single-line
            item-text="account"
            item-value="index"
            v-model="currentAgent"
            color="black"
            hide-details
          />
        </v-flex>
      </v-card-title>

      <v-divider dark v-if="!hide" />

      <v-card-text
        :class="hide ? 'text-xs-center pt-0' : 'pb-4'"
      >
        <template v-if="hide">
          <p>{{ $t('L_call_the_home_office') }}</p>
          <p>
            <v-icon
              size="24"
              class="mr-2"
              color="#ffffff"
            >
              phone
            </v-icon>
            <a
              :href="`tel:+1${getCustServNumWODash(locale)}`"
              class="font-weight-medium font-size--20 white--text"
            >{{ (getCustServNumWDash(locale)) }}</a>
          </p>
        </template>
        <v-layout
          row
          wrap
          align-start
          class="wrap-at-xs"
          :class="{'text-xs-center': $vuetify.breakpoint.xsOnly}"
          v-else
        >
          <v-flex d-flex xs12 sm7 md6 lg5 class="wrap-at-xs">
            <v-layout row wrap>
              <v-flex shrink pt-3
                      :class="{
                        'd-block width-100': $vuetify.breakpoint.xsOnly
                      }"
              >
                <img
                  class="rep-image mr-4"
                  :src="displayPic"
                  alt="rep image"
                >
              </v-flex>
              <v-flex
                mt-3
                class="wrap-at-xs"
              >
                <div
                  class="title mb-1 font-weight-medium"
                >
                  {{ agent.name }}
                </div>
                <div
                  v-if="agent.isRvp"
                  class="mb-1"
                >
                  {{ agent.titleDescription }}
                </div>
                <div v-if="agent.email">
                  <img
                    height="11"
                    width="18"
                    src="@/assets/email-icon.png"
                    class="mr-2"
                    alt="email me"
                  >
                  <a
                    v-ada-modal
                    id="contact_your_rep_trigger"
                    href="javascript:void(0)"
                    class="font-weight-medium contact-rep-trigger"
                    @click="contactMe = !contactMe"
                    aria-label="contact my rep"
                    role="button"
                  >{{ $t('L_rep_email_me') }}</a>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm5 md4 mt-3 class="wrap-at-xs" v-if="this.office">
            <template
              v-if="!agent.isRvp && rvp"
            >
              <div class="font-weight-medium mb-2 pb-1">
                {{ $t('L_rep_working_out_of') }}:
              </div>
              <div class="font-weight-medium">
                {{ rvp.name }}
              </div>
            </template>
            <div
              v-if="agent.isRvp"
              class="font-weight-medium mb-1"
            >
              {{ $t('L_rep_office_address') }}
            </div>
            <div
              v-if="!agent.isRvp && hasRvp"
            >
              {{ rvp.titleDescription }}
            </div>
            <div>
              {{ office.address1 }}
              <span
                class="no-wrap"
                v-if="office.address2"
              >
                {{ office.address2 }}
              </span>
            </div>
            <div>
              {{ office.city }},
              {{ office.state }}
              {{ office.zip }}
              (<a
                class="map-link"
                :href="navigateTo"
                target="_blank"
                :aria-label="linkAddr+' '+$t('L_opens_new_window')"
              >{{ $t('L_rep_map') }}</a>)
            </div>
          </v-flex>
          <v-flex xs12 sm4 md3 mt-3 pt-1
                  :class="{
                    'shift-right': $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly
                  }"
          >
            <v-layout
              class="mb-1"
            >
              {{ $t('L_rep_contact_info') }}
            </v-layout>
            <v-layout
              v-if="agent.useCellPhone && agent.cell"
              class="mb-1 font-size--16"
            >
              <v-flex aria-label="phone">
                {{ $t('L_rep_mobile_long') }}: <a :href="`tel:+1${agent.cell}`">{{ agent.cell | phone }}</a>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="office.phone"
              class="mb-1 font-size--16"
            >
              <v-flex aria-label="phone">
                {{ $t('L_rep_office_long') }}: <a :href="`tel:+1${office.phone}`">{{ office.phone | phone }}</a>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="office.fax"
              class="font-size--16"
            >
              <v-flex aria-label="fax">
                {{ $t('L_rep_fax_long') }}: <a :href="`tel:+1${office.fax}`">{{ office.fax | phone }}</a>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <ContactRep
        v-if="agent"
        v-model="contactMe"
        :agents="[agent]"
        @close="contactMe = false"
        @send-message="send($event)"
        close-selector="contact_your_rep_close"
        modal-class="contact_your_rep_modal"
        :sender="sender"
      />
    </v-card>
  </v-flex>
</template>

<script>
import bff from '@/shared/api/bff';
import { mapGetters } from 'vuex';
import { openMap } from '@/mixins/openMap';
import { ContactRep, sendRepEmail } from '@primerica/vue-components';

const repFallbackImage = require('../../src/assets/rep-thumb.svg');

export default {
  name: 'Representative',
  mixins: [openMap],
  props: {
    agents: {
      type: Array,
      required: true,
    },
    restricted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ContactRep,
  },
  data: () => ({
    contactMe: false,
    agent: Object,
    currentAgent: '',
  }),
  computed: {
    ...mapGetters('customer', {
      getCustServNumWDash: 'getCustServNumWDash',
      getCustServNumWODash: 'getCustServNumWODash',
      customerProfile: 'customerProfile',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters(['getAgent']),
    hasRvp() {
      return this.rvp && this.rvp.name;
    },
    rvp() {
      return (this.agent?.rvpId) ? this.agent?.rvp : {};
    },
    title() {
      let header = '';
      if (this.hide) {
        header = this.$t('L_questions_about_policy');
      } else {
        header = (this.agents?.length === 1) ? this.$t('L_your_rep') : this.$t('L_your_rep_account');
      }
      return header;
    },
    displayPic() {
      return this.agent?.displayPic ? this.agent?.displayPic : repFallbackImage;
    },
    office() {
      return (this.agent?.office) ? this.agent.office : {};
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    hide() {
      return this.restricted || !this.agent || this.agent?.isHomeOfficeAgent;
    },
    repFallbackImage() {
      // eslint-disable-next-line
      return require('@/assets/rep-thumb.svg');
    },
  },
  filters: {
    phone: number => number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
  },
  mounted() {
    this.setAgent();
  },
  methods: {
    send(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
    setAgent() {
      if (this.agents?.length > 0) {
        this.agent = this.agents[0];
        this.currentAgent = this.agent?.index;
      }
      this.setAddressToNavigate(this.office);
    },
  },
  watch: {
    currentAgent(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.agent = this.agents.find(a => a.index === newVal);
      }
    },
    agents(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setAgent();
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.representative
  .agent_card
    color: #333333;
  .rep-image
    width: 80px;
  @media (max-width: 599px)
    .wrap-at-xs
      min-width: 100% !important;
  .width-100
    width: 100% !important;
  .shift-right
    margin-left: 114px;
  .bgc-white
    background: #333;
  .close-dialog
    pull: right;
    font-size: 19px;
  a
    color: #0c6dca !important;
    font-weight: normal;
    &.map-link
      padding-bottom: 3px

</style>


import Config from '@/shared/util/config';
import { AuthConnect, OktaProvider } from '@ionic-enterprise/auth';
import { CapacitorFacade } from '../../capacitorPlugin';
import { initializeSessionVault, setVaultErrorHandler, clearSession, getSession, setSession } from '../utils/sessionManager';
import { revokeToken } from './oktaApi';

let initializing;
const _isNative = CapacitorFacade.isNativePlatform();
const _provider = new OktaProvider();
const {
  clientId, discoveryUrl, scope,
} = Config.get('auth.providers.okta');
const _oktaOptions = {
  clientId, // '0oa8dltzqrUL1wdgg1d7',
  discoveryUrl, // 'https://mydevprimerica.oktapreview.com/oauth2/aus80lkmogPKt0gt51d7/.well-known/openid-configuration',
  logoutUrl: _isNative ? 'myprimerica://com.primerica.myprimerica/app/postLogoutPorch' : window.location.origin + window.location.pathname,
  redirectUri: _isNative ? 'myprimerica://com.primerica.myprimerica/app/' : `${window.location.origin}${Config.get('auth.redirectUriBase')}/`, // + window.location.pathname,
  scope, // 'openid profile email offline_access client-portal:write',
  audience: '',
};

const _performAuthConnectInit = async () => {
  // ? see https://ionic.io/docs/auth-connect
  // ? see https://ionic.io/docs/auth-connect/interfaces/AuthConnectConfig
  await AuthConnect.setup({
    platform: _isNative ? 'capacitor' : 'web',
    ios: {
      webView: 'private',
      safariWebViewOptions: {
        dismissButtonStyle: 'cancel',
      },
    },
    android: {
      isIncognito: true,
    },
    web: {
      uiMode: 'current',
      authFlow: 'PKCE',
    },
  });
};

const _initializeAuthConnect = async () => {
  if (!initializing) {
    initializing = new Promise((resolve, reject) => {
      _performAuthConnectInit()
        .then(() => {
          // TODO: DT tag/log
          resolve();
        })
        .catch((e) => {
          // TODO: catch and handle error maybe use DT
          reject(e);
        });
    });
  }
  return initializing;
};

/* saves auth result in session manager // ! will clear session if auth result is falsy */
const _saveAuthResult = async (_authResult) => {
  if (_authResult) {
    await setSession(_authResult);
  } else {
    await clearSession();
  }
};

/* tries to refresh token and on failure will set auth result to null */
const _refreshAuth = async (_authResult) => {
  let newAuthResult = null;
  if (await AuthConnect.isRefreshTokenAvailable(_authResult)) {
    try {
      newAuthResult = await AuthConnect.refreshSession(_provider, _authResult);
    } catch (err) {
      // TODO: log in DT
      // newAuthResult is null
      // _saveAuthResult gets null and will clear the session
    }
    _saveAuthResult(newAuthResult);
  } else {
    await clearSession();
  }
  return newAuthResult;
};

/* obtain the auth result from session manager AND use refresh token if access token is expired */
const _getAuthResult = async () => {
  let authResult = await getSession();
  if (authResult && (await AuthConnect.isAccessTokenExpired(authResult))) {
    authResult = await _refreshAuth(authResult);
  }
  return authResult;
};

/* initialize the sessionVault and Auth Connect */
const initialize = async () => {
  if (_isNative) await initializeSessionVault();
  await _initializeAuthConnect();
};

/* login and save auth result */
export const login = async () => {
  await _initializeAuthConnect();
  const _authResult = await AuthConnect.login(_provider, _oktaOptions); // TODO: catch and handle error
  await _saveAuthResult(_authResult);
  return _authResult;
};

/* logout and set auth result to null */
export const logout = async () => {
  await _initializeAuthConnect();
  let _authResult = await getSession().catch((err) => {
    // eslint-disable-next-line
    console.error(`[hybridAuthClient.js > logout > getSession] error: ${JSON.stringify(err, null, 2)}`);
  });
  if (!_authResult) {
    _authResult = await AuthConnect.buildAuthResult(_provider, _oktaOptions, {}).catch((err) => {
      // eslint-disable-next-line
      console.error(`[hybridAuthClient.js > logout > AuthConnect.buildAuthResult] error: ${JSON.stringify(err, null, 2)}`);
    });
  }
  const { refreshToken } = _authResult;
  await revokeToken(refreshToken);
  await clearSession().catch((err) => {
    // eslint-disable-next-line
    console.error(`[hybridAuthClient.js > logout > clearSession] error: ${JSON.stringify(err, null, 2)}`);
  });
  await AuthConnect.logout(_provider, _authResult).catch((err) => {
    // eslint-disable-next-line
    console.error(`[hybridAuthClient.js > logout > AuthConnect.logout] error: ${JSON.stringify(err, null, 2)}`);
  });
};

/* clear local session by clearing the vault */
// TODO: add unit test
export const clearLocalAuthSession = async () => {
  await clearSession().catch((err) => {
    // eslint-disable-next-line
    console.error(`[hybridAuthClient.js > logout > clearSession] error: ${JSON.stringify(err, null, 2)}`);
  });
};

export const isAuthenticated = async () => {
  await _initializeAuthConnect();
  return !!(await _getAuthResult()); // getAuthResult handles refreshing expired access token
};

export const getAccessToken = async () => {
  await _initializeAuthConnect();
  const _authResult = await _getAuthResult(); // getAuthResult handles refreshing expired access token
  return _authResult?.accessToken;
};

// TODO: add unit test
export const getRefreshToken = async () => {
  await _initializeAuthConnect();
  const _authResult = await _getAuthResult(); // getAuthResult handles refreshing expired access token
  return _authResult?.refreshToken;
};

// TODO: add unit test
export const getDecodedIdToken = async () => {
  await _initializeAuthConnect();
  const _authResult = await _getAuthResult();
  if (_authResult) {
    const decodedIdToken = await AuthConnect.decodeToken('id', _authResult);
    return decodedIdToken;
  }
};

// TODO: add unit test
export const getDecodedAccessToken = async () => {
  await _initializeAuthConnect();
  const _authResult = await _getAuthResult();
  if (_authResult) {
    const decodedAccessToken = await AuthConnect.decodeToken('access', _authResult);
    return decodedAccessToken;
  }
};

export const handleLoginCallback = async (p) => {
  await _initializeAuthConnect();
  const _authResult = await AuthConnect.handleLoginCallback(p, _oktaOptions);
  _saveAuthResult(_authResult);
  return _authResult;
};

export const hybridAuthClient = {
  initialize: _initializeAuthConnect,
  login,
  logout,
  clearLocalAuthSession,
  isAuthenticated,
  getAccessToken,
  getRefreshToken,
  getDecodedIdToken,
  getDecodedAccessToken,
  handleLoginCallback,
  setVaultErrorHandler: (eh) => {
    try {
      // set vault errorHandler
      if (_isNative) {
        setVaultErrorHandler(eh);
      }
    } catch (error) {
      // TODO: handle error or log in DT
    }
  },
};

export default {
  hybridAuthClient,
  initialize,
};

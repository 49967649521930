<i18n src="./locales.json"></i18n>
<template>
  <v-card xs12>
    <MessageDialog
      :show-dialog="showMessageDialog"
      :has-button-two="true"
      type="warning"
      :title="$t('L_cancel_changes')"
      :message="$t('L_by_cancelling_no_change')"
      :button-two-label="$t('L_yes_cancel')"
      :button-one-label="$t('L_no_continue')"
      :is-persistent="true"
      @buttonTwoAction="closeThisPage"
      @buttonOneAction="showMessageDialog = false"
    />
    <v-card-title>
      <v-flex>
        <span role="heading" aria-level="2" class="pr-2 card-title font-size--24">
          {{ $t('L_Beneficiary_Details') }}
        </span>
      </v-flex>
    </v-card-title>
    <v-divider pb-2 />
    <v-layout row wrap>
      <v-flex xs12 class="mt-4" :class="$vuetify.breakpoint.xsOnly ? 'margin-left-20' : 'margin-left-40'">
        <span font-size--16>
          <i18n path="L_Enter_information_for_NAMEs_primaryORcontingent_beneficiary" tag="span" class="font-weight-regular">
            <span>{{ getNameOnPolicy }}</span>
            <span class="font-weight-medium">
              {{ getPrimaryOrContingent }}
            </span>
          </i18n>
        </span>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <!-- <div> beneType - {{ beneType }} </div>
        <div> allocationPercentage - {{ allocationPercentage }} </div>
        <div> this.beneficiaryType - {{ beneficiaryType }} </div>
        <div> this.address2Data    - {{ address2Data }} </div>
        <div> this.stateData       - {{ stateData }} </div>
        <div> this.cityData        - {{ cityData }} </div>
        <div> this.address1Data    - {{ address1Data }} </div>
        <div> this.zipData         - {{ zipData }} </div>
        <div> this.relationshipToInsured - {{ relationshipToInsured }} </div>
        <div> this.trusteeFirstName   - {{ trusteeFirstName }} </div>
        <div> this.middleName         - {{ middleName }} </div>
        <div> this.firstName          - {{ firstName }} </div>
        <div> this.trusteeLastName    - {{ trusteeLastName }} </div>
        <div> this.irrevocable        - {{ irrevocable }} </div>
        <div> this.ssn                - {{ ssn }} </div>
        <div> this.lastName           - {{ lastName }} </div>
        <div> this.fullName           - {{ fullName }} </div>
        <div> this.cloneBeneficiary - {{ cloneBeneficiary }} </div>
        <div> irrevocable - {{ irrevocable }} </div>
        <div> revocable - {{ revocable }} </div>
        <div> $v - {{ $v }} </div>
        <div> beneType - {{ beneType }} </div>
        <div> beneficiaryPrimary - {{ beneficiaryPrimary }}</div>
        <div> beneficiaryPrimaryContingent - {{ beneficiaryPrimaryContingent }}</div>
        <div> beneficiarySpouse - {{ beneficiarySpouse }}</div>
        <div> beneficiarySpouseContingent - {{ beneficiarySpouseContingent }}</div>
        <div> $v.usStateData.$model - {{ $v.usStateData.$model }}</div>
        <div> $v.caTerritoryData.$model - {{ $v.caTerritoryData.$model }}</div>
        <div> stateData - {{ stateData }} </div>-->
      </v-flex>
    </v-layout>
    <div class="max-width-card" :class="$vuetify.breakpoint.xsOnly ? 'margin-left-20' : 'margin-left-40'">
      <v-layout row wrap>
        <v-flex class="input-label-header margin-bottom-40 ">
          <sup class="redText">*</sup> {{ $t('L_Required_Fields') }}
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 text-xs-left class="input-label-header">
          <sup class="redText">*</sup> {{ $t('L_Beneficiary_Type') }}
        </v-flex>
        <v-flex xs12>
          <v-select :items="beneficiaryTypeList" single-line :placeholder="$t('L_Beneficiary_Type')" v-model="beneficiaryType" :label="$t('L_Beneficiary_Type')" />
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="beneficiaryType !== ''">
        <v-flex xs12 text-xs-left class="input-label-header" v-if="beneficiaryType === 'Y' || beneficiaryType === 'X'">
          <sup class="redText moveRedStartBeforeLabel">*</sup>
        </v-flex>
        <v-flex xs12 v-if="beneficiaryType === 'Y' || beneficiaryType === 'X'">
          <AllocationPercentage v-model="allocationPercentage" :form-input="$v.allocationPercentage" :label="$t('L_allocation_percentage')" />
        </v-flex>
        <v-flex xs12 v-if="beneficiaryType === 'C' || beneficiaryType === 'E'">
          <span>{{ $t('L_allocation_percentage') }} </span>
        </v-flex>
        <v-flex xs12 mb-4 pb-4 v-if="beneficiaryType === 'C' || beneficiaryType === 'E'">
          {{ allocationPercentage }}
        </v-flex>
        <!-- <v-flex mb-4 font-size--12 v-else-if="(beneficiaryType ==='X' || beneficiaryType === 'Y') && $vuetify.breakpoint.xsOnly">
          {{ $t('L_allocation_percentage_tooltip') }}
        </v-flex> -->
        <v-flex xs12 v-if="showName">
          <sup class="redText moveRedStartBeforeLabel">*</sup>
          <FirstNameInput
            v-model="firstName"
            :form-input="$v.firstName"
            input-id="first_name"
            :auto-capitalize="true"
            :maxlen="50"
            :validation-messages="{
              dupfName: $t('L_name_has_already_been_used'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showName">
          <FirstNameInput
            input-id="middle_name"
            v-model="middleName"
            :form-input="$v.middleName"
            :label="$t('L_Middle_Name')"
            :auto-capitalize="true"
            :maxlen="50"
            :validation-messages="{
              name: $t('L_middle_name_is_invalid'),
              dupmName: $t('L_name_has_already_been_used'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showName">
          <sup class="redText moveRedStartBeforeLabel">*</sup>
          <LastNameInput
            input-id="last_name"
            v-model="lastName"
            :label="$t('L_Last_Name')"
            :form-input="$v.lastName"
            :auto-capitalize="true"
            :maxlen="50"
            :validation-messages="{
              duplName: $t('L_name_has_already_been_used'),
            }"
          />
        </v-flex>

        <v-flex xs12 v-if="showFullName" class="font-size--12 font-weight-regular">
          <sup class="redText">*</sup> <span>{{ $t('L_full_name') }}</span>
        </v-flex>
        <v-flex xs12 v-if="showFullName">
          <v-text-field
            v-model="fullName"
            id="full_name"
            :placeholder="$t('L_full_name')"
            :error-messages="errorMessagesFullName"
            @blur="onBlurFullName"
            @change="onBlurFullName"
            @keyup="onBlurFullName"
            maxlength="75"
          >
            <template slot="append">
              <v-icon class="right-icon" v-show="fullNameDirty && errorMessagesFullName === ''" color="success">
                check_circle
              </v-icon>
              <v-icon class="right-icon" v-show="errorMessagesFullName && errorMessagesFullName.length > 0" color="error">
                error
              </v-icon>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 v-if="!isCanadaPolicy && showSSNorSIN">
          <SSNInput id="ssn" enable-masking v-model="ssn" :form-input="$v.ssn" :label="$t('L_Social_Security_Number')" />
        </v-flex>
        <v-flex xs12 text-xs-left class="input-label-header" v-if="showRelationshipToInsured">
          <sup class="redText">*</sup> <span>{{ $t('L_Relationship_to_Insured') }}</span>
        </v-flex>
        <v-flex xs12 v-if="showRelationshipToInsured">
          <v-select :items="relationshipToInsuredList" single-line :placeholder="$t('L_Relationship_to_Insured')" v-model="relationshipToInsured" />
        </v-flex>

        <v-flex xs12 v-if="showRevocableCheckbox" class="mt-0 font-size--12">
          <div class="font-weight-bold">
            {{ $t('L_if_you_are_a_resident_of_quebec') }}
          </div>
          <v-checkbox v-model="revocable" color="primary" :label="$t('L_revocable')" />
        </v-flex>
        <v-flex xs12 v-if="showBeneficiaryUnderAgeOf18AndTrustee" class="mt-4 font-size--12">
          <div class="font-size--12 font-weight-bold">
            <sup class="redText">*</sup> <span class="input-label-header">{{ $t('L_Beneficiary_under_age_of_18') }}</span>
          </div>
          <RadioButtonGroup
            v-model="beneficiaryUnder18"
            :form-input="$v.beneficiaryUnder18"
            :options="beneficiaryUnder18YesAndNo"
            :horizontal="false"
            :validation-messages="{
              required: $t('L_Relationship_to_Insured_is_required'),
            }"
          />
        </v-flex>
        <v-flex xs12 class="font-size--12 font-weight-bold" v-if="showBeneficiaryUnderAgeOf18AndTrustee && beneficiaryUnder18 === 'Y'">
          <sup class="redText moveRedStartBeforeLabel">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showBeneficiaryUnderAgeOf18AndTrustee && beneficiaryUnder18 === 'Y'">
          <v-text-field
            v-model="trusteeFirstName"
            id="trusteeFirstName"
            :error-messages="errorMessagesTrusteeFirstName"
            @blur="onBlurTrusteeFirstName"
            @change="onBlurTrusteeFirstName"
            @keyup="onBlurTrusteeFirstName"
            :label="$t('L_trustee_first_name')"
            maxlength="50"
          >
            <template slot="append">
              <v-icon class="right-icon" v-show="trusteeFirstNameDirty && errorMessagesTrusteeFirstName === ''" color="success">
                check_circle
              </v-icon>
              <v-icon class="right-icon" v-show="errorMessagesTrusteeFirstName && errorMessagesTrusteeFirstName.length > 0" color="error">
                error
              </v-icon>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 class="input-label-header" v-if="showBeneficiaryUnderAgeOf18AndTrustee && beneficiaryUnder18 === 'Y'">
          <sup class="redText moveRedStartBeforeLabel">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showBeneficiaryUnderAgeOf18AndTrustee && beneficiaryUnder18 === 'Y'">
          <v-text-field
            v-model="trusteeLastName"
            id="trusteeLastName"
            :error-messages="errorMessagesTrusteeLastName"
            @blur="onBlurTrusteeLastName"
            @change="onBlurTrusteeLastName"
            @keyup="onBlurTrusteeLastName"
            :label="$t('L_trustee_last_name')"
            maxlength="50"
          >
            <template slot="append">
              <v-icon class="right-icon" v-show="trusteeLastNameDirty && errorMessagesTrusteeLastName === ''" color="success">
                check_circle
              </v-icon>
              <v-icon class="right-icon" v-show="errorMessagesTrusteeLastName && errorMessagesTrusteeLastName.length > 0" color="error">
                error
              </v-icon>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex xs12 v-if="showDOBAndPhoneNumber">
          <DateInput
            id="new_userid_dob"
            id-prop="new_userid_dob"
            v-model="dateOfBirth"
            :quebec="isQuebec"
            :form-input="$v.dateOfBirth"
            :label="$t('L_date_of_birth')"
            :validation-messages="{
              date: $t('L_invalid_date'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showDOBAndPhoneNumber">
          <PhoneNumberInput id="new_userid_telephone" id-prop="new_userid_telephone" v-model="telephone" :form-input="$v.telephone" :label="$t('L_telephone')" />
        </v-flex>
        <v-flex xs12 v-if="showEmailAddress">
          <EmailInput id="new_userid_email" id-prop="new_userid_email" v-model="emailAddress" :form-input="$v.emailAddress" :label="$t('L_email_address')" />
        </v-flex>
        <v-flex xs12 v-if="showAddress">
          <sup class="redText moveRedStartBeforeLabel" v-if="isNyPolicy && beneficiaryType === 'Y'">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showAddress">
          <AddressInput
            id-prop="update_contact_details_address_one"
            v-model="address1Data"
            :form-input="$v.address1Data"
            :label="$t('L_Address_Line_1')"
            :auto-capitalize="true"
            :validation-messages="{
              addressLine1Required: $t('L_address_line_1_required'),
              addressLine1Validation: $t('L_address_line_1_is_invalid'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showAddress">
          <AddressInput
            id-prop="update_contact_details_address_two"
            v-model="address2Data"
            :form-input="$v.address2Data"
            :label="$t('L_Address_Line_2')"
            :auto-capitalize="true"
            :validation-messages="{
              addressLine2Validation: $t('L_address_line_2_is_invalid'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showAddress">
          <sup class="redText moveRedStartBeforeLabel" v-if="isNyPolicy && beneficiaryType === 'Y'">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showAddress">
          <CityInput
            id-prop="update_contact_details_city"
            v-model="cityData"
            :form-input="$v.cityData"
            :label="$t('L_City')"
            :validation-messages="{
              cityRequired: $t('L_city_is_required'),
              cityValidation: $t('L_city_is_invalid'),
              cityMax: $t('L_generic_invalid'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showAddress && !isCanadaPolicy">
          <sup class="redText moveRedStartBeforeLabel" v-if="isNyPolicy && beneficiaryType === 'Y'">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showAddress && !isCanadaPolicy">
          <StateInput
            id-prop="update_contact_details_state"
            v-model="stateData"
            :form-input="$v.usStateData"
            :label="$t('L_State')"
            :validation-messages="{
              stateRequired: $t('L_state_is_required'),
            }"
          />
          <div class="tinyPixel">
            $v.usStateData.$model - {{ $v.usStateData.$model }} $v.caTerritoryData.$model - {{ $v.caTerritoryData.$model }} stateData - {{ stateData }} $v - {{ $v }}
          </div>
        </v-flex>
        <v-flex xs12 v-if="showAddress && isCanadaPolicy">
          <TerritoryInput id-prop="update_contact_details_province" v-model="stateData" :form-input="$v.caTerritoryData" :label="$t('L_policy_province')" />
        </v-flex>
        <v-flex xs12 v-if="showAddress && !isCanadaPolicy">
          <sup class="redText moveRedStartBeforeLabel" v-if="isNyPolicy && beneficiaryType === 'Y'">*</sup>
        </v-flex>
        <v-flex xs12 v-if="showAddress && !isCanadaPolicy">
          <ZipCodeInput
            id-prop="update_contact_details_zip_code"
            v-model="zipData"
            :form-input="$v.zipData"
            :label="$t('L_Zip_Code')"
            :validation-messages="{
              zipRequired: $t('L_zipcode_is_required'),
            }"
          />
        </v-flex>
        <v-flex xs12 v-if="showAddress && isCanadaPolicy">
          <PostalCodeInput id-prop="update_contact_details_zip_code" v-model="zipData" :territory="stateData" :form-input="$v.zipData" :label="$t('L_Postal_Code')" />
        </v-flex>
      </v-layout>
    </div>
    <v-layout row wrap class="gray-background">
      <v-flex v-if="isCanadaPolicy" xs12 class="font-size--16 pl-4 pr-4 pt-4 pb-4">
        {{ $t('L_canada_important_disclosure') }}
      </v-flex>
      <v-flex v-else xs12 class="font-size--16 pl-4 pr-4 pt-4 pb-4">
        {{ $t('L_us_and_ny_important_disclosure') }}
      </v-flex>
    </v-layout>
    <StickyContainer>
      <span slot="stickycontent">
        <v-layout row wrap justify-center>
          <v-flex xs12 mt-4 mb-4 pb-4 text-xs-center>
            <v-btn id="editbeneficiary_cancel" class="pl-4 pr-4 buttonFormat" color="secondary" @click="showMessageDialog = true">
              <h3 class="heading_three font-weight-bold font-size--18">
                {{ $t('L_cancel') }}
              </h3>
            </v-btn>
            <v-btn id="editbeneficiary_submit" class="pl-4 pr-4 buttonFormat" color="primary" @click="saveProcess" :disabled="disablebutton">
              <h3 class="heading_three font-weight-bold font-size--18">
                {{ $t('L_Save') }}
              </h3>
            </v-btn>
          </v-flex>
        </v-layout>
      </span>
    </StickyContainer>
  </v-card>
</template>

<script>
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { validateName } from '@/shared/validation';
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import {
  AllocationPercentage,
  AllocationPercentageValidation,
  FirstNameInput,
  FirstNameValidation,
  LastNameInput,
  LastNameValidation,
  SSNInput,
  SSNValidation,
  EmailInput,
  EmailValidation,
  AddressInput,
  AddressValidation,
  CityInput,
  CityValidation,
  StateInput,
  StateValidation,
  TerritoryInput,
  TerritoryValidation,
  ValidForTerritory,
  ZipCodeInput,
  ZipCodeValidation,
  PostalCodeInput,
  PostalCodeValidation,
  RadioButtonGroup,
  DateInput,
  PhoneNumberInput,
  DateValidation,
  PhoneNumberValidation,
} from '@primerica/vue-components';
import MessageDialog from '@/components/MessageDialog.vue';
import StickyContainer from '../../../../../../../components/StickyContainer/StickyContainer.vue';

export function isSSNRequired() {
  return this.isUsPolicy;
}
export const addressLine1Validation = () => (value) => {
  const regex = /^[a-zA-Z0-9\-.# ']+ ?[a-zA-Z0-9\-.# ']+$/;
  const match = value && value.match(regex);
  if (value === '') {
    return true;
  }
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!match === false) {
    return false;
  }
  return true;
};

export const addressLine1Required = isNYPolicy => (value) => {
  if (value === '' && isNYPolicy) {
    return false;
  }
  return true;
};

export const cityRequired = isNYPolicy => (value) => {
  if (value === '' && isNYPolicy) {
    return false;
  }
  return true;
};

export const stateRequired = isNYPolicy => (value) => {
  if (value === '' && isNYPolicy) {
    return false;
  }
  return true;
};

export const zipRequired = isNYPolicy => (value) => {
  if (value === '' && isNYPolicy) {
    return false;
  }
  return true;
};

export const cityValidation = () => (value) => {
  const regex = /^[a-zA-Z\-.']+ ?[a-zA-Z\-. ']+$/;
  const match = value && value.match(regex);
  if (value === '') {
    return true;
  }
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!match === false) {
    return false;
  }
  return true;
};

export default {
  name: 'EditBeneficiaryDetails',
  components: {
    AllocationPercentage,
    AllocationPercentageValidation,
    FirstNameInput,
    FirstNameValidation,
    LastNameInput,
    LastNameValidation,
    SSNInput,
    SSNValidation,
    EmailInput,
    EmailValidation,
    AddressInput,
    AddressValidation,
    CityInput,
    CityValidation,
    StateInput,
    StateValidation,
    TerritoryInput,
    TerritoryValidation,
    ValidForTerritory,
    ZipCodeInput,
    ZipCodeValidation,
    PostalCodeInput,
    PostalCodeValidation,
    RadioButtonGroup,
    DateValidation,
    PhoneNumberInput,
    DateInput,
    MessageDialog,
    StickyContainer,
    /*
    Alert,
    AddressInput,
    PostalCodeInput,
    CityInput,
    StateInput,
    TerritoryInput,
    ZipCodeInput,
    EditContactInfo, */
  },
  data: () => ({
    hasFinished: false,
    fullNameDirty: false,
    trusteeFirstNameDirty: false,
    trusteeLastNameDirty: false,
    beneficiaryType: '',
    allocationPercentage: '',
    firstName: '',
    middleName: '',
    lastName: '',
    fullName: '',
    ssn: '',
    relationshipToInsured: '',
    beneficiaryUnder18: '',
    trusteeFirstName: '',
    trusteeLastName: '',
    emailAddress: '',
    address1Data: '',
    address2Data: '',
    cityData: '',
    stateData: '',
    zipData: '',
    telephone: '',
    dateOfBirth: '',
    dateSigned: '',
    updateUserId: '',
    irrevocable: false,
    revocable: true,
    errorMessagesFullName: '',
    errorMessagesTrusteeFirstName: '',
    errorMessagesTrusteeLastName: '',
    cloneBeneficiary: null,
    isDupFirstName: false,
    isDupMiddleName: false,
    isDupLastName: false,
    showRevocableCheckbox: false,
    showMessageDialog: false,
  }),
  props: {
    beneObj: {
      type: Object,
      required: false,
      default: () => {},
    },
    beneType: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({ locale: 'getLocale' }),
    ...mapGetters('policy', {
      beneficiaryStep: 'beneficiaryStep',
      beneficiaries: 'beneficiaries',
      currentPolicy: 'currentPolicy',
      beneficiaryPrimary: 'beneficiaryPrimary',
      beneficiaryPrimaryContingent: 'beneficiaryPrimaryContingent',
      beneficiarySpouse: 'beneficiarySpouse',
      beneficiarySpouseContingent: 'beneficiarySpouseContingent',
    }),
    isQuebec() {
      return this.locale.indexOf('fr') > -1;
    },
    beneficiaryUnder18YesAndNo() {
      return [
        {
          name: this.$t('L_YES'),
          label: this.$t('L_YES'),
          value: 'Y',
        },
        {
          name: this.$t('L_NO'),
          label: this.$t('L_NO'),
          value: 'N',
        },
      ];
    },
    isUsPolicy() {
      return this?.policyData?.isUSPolicy;
    },
    isNyPolicy() {
      return this.currentPolicy?.isNewYorkPolicy;
    },
    isCanadaPolicy() {
      return !!this.currentPolicy?.isCanadaPolicy;
    },
    isQuebecPolicy() {
      return this.currentPolicy?.isCanadaQuebecBeneficiaryPolicy;
    },
    selectHint() {
      return 'TODO hint';
    },
    primaryInsured() {
      return `${this.currentPolicy?.primaryInsured?.firstName} ${this.currentPolicy?.primaryInsured?.lastName}` || '';
    },
    spouseInsured() {
      if (this.currentPolicy?.data?.spouseLastName) {
        return `${this.currentPolicy?.data?.spouseFirstName} ${this.currentPolicy?.data?.spouseLastName}`;
      }
      return this.$t('L_policy_beneficiaries_spouse');
    },
    showDOBAndPhoneNumber() {
      let res = false;
      if (this.beneficiaryType === 'Y' && !this.isCanadaPolicy) {
        res = true;
      }
      return res;
    },
    showName() {
      let res = false;
      if (this.beneficiaryType === 'Y') {
        res = true;
      }
      return res;
    },
    showFullName() {
      let res = false;
      if (this.beneficiaryType === 'X') {
        res = true;
      }
      return res;
    },
    showSSNorSIN() {
      let res = false;
      if (this.beneficiaryType === 'Y') {
        res = true;
      }
      return res;
    },
    showRelationshipToInsured() {
      let res = false;
      if (this.beneficiaryType === 'Y') {
        res = true;
      }
      return res;
    },
    showEmailAddress() {
      let res = false;
      if (this.beneficiaryType === 'Y' || this.beneficiaryType === 'X') {
        res = true;
      }
      return res;
    },
    showBeneficiaryUnderAgeOf18AndTrustee() {
      let res = false;
      if (this.beneficiaryType === 'Y' || this.beneficiaryType === 'C') {
        if (this.isCanadaPolicy) {
          res = true;
        }
      }
      return res;
    },
    showAddress() {
      let res = false;
      if (this.beneficiaryType === 'Y' || this.beneficiaryType === 'X') {
        res = true;
      }
      return res;
    },
    getNameOnPolicy() {
      if (this.beneObj?.beneKeyId && this.beneficiaryStep === 3) {
        // For edit we pass the entire beneficiary object as beneObj which contains the id as beneKeyId from which we can pull the object we need and
        // check the beneficiaryType and pull the Primary | Spouse accordingly
        if (this.beneObj.beneficiaryType === 'P' || this.beneObj.beneficiaryType === 'C') {
          return this.primaryInsured;
        }
        // const obj = this.beneficiaries.filter(bene => bene.beneKeyId === this.beneId);
        // if (obj && (obj[0].beneficiaryType === 'P' || obj[0].beneficiaryType === 'C')) {
        //   return this.primaryInsured;
        // }
        return this.spouseInsured;
      }
      // if it's add we pass the beneType so we can use that to pull primary | spouse
      if (this.beneficiaryStep === 2 && (this.beneType === 'P' || this.beneType === 'C')) {
        return this.primaryInsured;
      }
      return this.spouseInsured;
    },
    getPrimaryOrContingent() {
      if (this.beneObj?.beneKeyId !== '' && this.beneficiaryStep === 3) {
        // For edit we pass the entire beneficiary obj in beneObj
        // check the beneficiaryType and pull the Primary if 'P' or 'S'
        if (this.beneObj.beneficiaryType === 'P' || this.beneObj.beneficiaryType === 'S') {
          return this.$t('L_primary');
        }
        // const obj = this.beneficiaries.filter(bene => bene.beneKeyId === this.beneId);
        // if (obj && (obj[0].beneficiaryType === 'P' || obj[0].beneficiaryType === 'S')) {
        //   return this.$t('L_primary');
        // }
        return this.$t('L_contingent');
      }
      // if it's add we pass the beneType so we can use that to pull Primary if 'P' or 'S'
      if (this.beneficiaryStep === 2 && (this.beneType === 'P' || this.beneType === 'S')) {
        return this.$t('L_primary');
      }
      return this.$t('L_contingent');
    },
    beneficiaryTypeList() {
      const benTypeOptions = [];
      // Individual
      benTypeOptions.push({
        value: 'Y',
        text: this.$t('L_individual'),
      });
      // All Children Equally
      const benType = this.beneficiaryStep === 2 ? this.beneType : this.beneObj.beneficiaryType;
      let fullName = 'All Children Equally';
      if (benType === 'P' || benType === 'C') {
        if (
          this.beneficiaryPrimary.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) +
            this.beneficiaryPrimaryContingent.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) !==
          ''
        ) {
          // alreadyExists = true;
        } else {
          benTypeOptions.push({
            value: 'C',
            text: this.$t('L_all_children_equally'),
          });
        }
      } else if (benType === 'S' || benType === 'H') {
        if (
          this.beneficiarySpouse.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) +
            this.beneficiarySpouseContingent.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) !==
          ''
        ) {
          // alreadyExists = true;
        } else {
          benTypeOptions.push({
            value: 'C',
            text: this.$t('L_all_children_equally'),
          });
        }
      } else {
        benTypeOptions.push({
          value: 'C',
          text: this.$t('L_all_children_equally'),
        });
      }
      // Group, Organizations or Entity
      benTypeOptions.push({
        value: 'X',
        text: this.$t('L_group_organizations_or_entity'),
      });
      // Estate
      fullName = 'Estate';
      if (benType === 'P' || benType === 'C') {
        if (
          this.beneficiaryPrimary.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) +
            this.beneficiaryPrimaryContingent.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) !==
          ''
        ) {
          // alreadyExists = true;
        } else {
          benTypeOptions.push({
            value: 'E',
            text: this.$t('L_estate'),
          });
        }
      } else if (benType === 'S' || benType === 'H') {
        if (
          this.beneficiarySpouse.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) +
            this.beneficiarySpouseContingent.filter(benObj => benObj.fullName === fullName && benObj.beneKeyId !== this.cloneBeneficiary?.beneKeyId) !==
          ''
        ) {
          // alreadyExists = true;
        } else {
          benTypeOptions.push({
            value: 'E',
            text: this.$t('L_estate'),
          });
        }
      } else {
        benTypeOptions.push({
          value: 'E',
          text: this.$t('L_estate'),
        });
      }
      return benTypeOptions;
    },
    relationshipToInsuredList() {
      let retObj = [];
      if (this.beneficiaryType === 'C') {
        retObj.push({
          value: '',
          text: '',
        });
      } else if (this.beneficiaryType === 'E') {
        retObj.push({
          value: '',
          text: '',
        });
      } else if (this.beneficiaryType === 'X') {
        retObj.push({
          value: '',
          text: '',
        });
      } else if (this.beneficiaryType === 'Y') {
        let isSpouseAlreadyUsedInPrimaryOrContingent = false;
        if (this.beneType === 'P' || this.beneType === 'C' || this.cloneBeneficiary?.beneficiaryType === 'P' || this.cloneBeneficiary?.beneficiaryType === 'C') {
          if (
            this.beneficiaryPrimary.filter(obj => obj.beneficiaryEntityType === 'Y' && obj.relationship === 'Spouse').length +
              this.beneficiaryPrimaryContingent.filter(obj => obj.beneficiaryEntityType === 'Y' && obj.relationship === 'Spouse').length >
            0
          ) {
            isSpouseAlreadyUsedInPrimaryOrContingent = true;
          }
        }
        if (this.beneType === 'S' || this.beneType === 'H' || this.cloneBeneficiary?.beneficiaryType === 'S' || this.cloneBeneficiary?.beneficiaryType === 'H') {
          if (
            this.beneficiarySpouse.filter(obj => obj.beneficiaryEntityType === 'Y' && obj.relationship === 'Spouse').length +
              this.beneficiarySpouseContingent.filter(obj => obj.beneficiaryEntityType === 'Y' && obj.relationship === 'Spouse').length >
            0
          ) {
            isSpouseAlreadyUsedInPrimaryOrContingent = true;
          }
        }
        let isCurrentUserRelationshipSpouse = false;
        if (this.cloneBeneficiary && this.cloneBeneficiary.relationship === 'Spouse') {
          isCurrentUserRelationshipSpouse = true;
        }
        if (isSpouseAlreadyUsedInPrimaryOrContingent && !isCurrentUserRelationshipSpouse) {
          retObj = [
            {
              value: 'Child', // TODO value
              text: this.$t('L_child'),
            },
            {
              value: 'Sibling', // TODO value
              text: this.$t('L_sibling'),
            },
            {
              value: 'Parent', // TODO value
              text: this.$t('L_parent'),
            },
            {
              value: 'Relative', // TODO value
              text: this.$t('L_relative'),
            },
            {
              value: 'Friend', // TODO value
              text: this.$t('L_friend'),
            },
            {
              value: 'Other', // TODO value
              text: this.$t('L_other'),
            },
          ];
          if (this.isCanadaPolicy) {
            retObj.push({
              value: 'Common Law Spouse', // TODO value
              text: this.$t('L_common_law_spouse'),
            });
          }
        } else {
          retObj = [
            {
              value: 'Spouse', // TODO value
              text: this.$t('L_spouse'),
            },
            {
              value: 'Child', // TODO value
              text: this.$t('L_child'),
            },
            {
              value: 'Sibling', // TODO value
              text: this.$t('L_sibling'),
            },
            {
              value: 'Parent', // TODO value
              text: this.$t('L_parent'),
            },
            {
              value: 'Relative', // TODO value
              text: this.$t('L_relative'),
            },
            {
              value: 'Friend', // TODO value
              text: this.$t('L_friend'),
            },
            {
              value: 'Other', // TODO value
              text: this.$t('L_other'),
            },
          ];
          if (this.isCanadaPolicy) {
            retObj.push({
              value: 'Common Law Spouse', // TODO value
              text: this.$t('L_common_law_spouse'),
            });
          }
        }
      }
      return retObj;
    },
    disablebutton() {
      let disableButton = false;
      if (this.beneficiaryType === 'C') {
        if (this.isCanadaPolicy) {
          if (this.beneficiaryUnder18 === 'Y') {
            if (this.trusteeFirstName.length === 0 || this.errorMessagesTrusteeFirstName !== '') {
              disableButton = true;
            }
            if (this.trusteeLastName.length === 0 || this.errorMessagesTrusteeLastName !== '') {
              disableButton = true;
            }
          } else if (this.beneficiaryUnder18 === '') {
            disableButton = true;
          }
        } else {
          disableButton = false;
        }
      } else if (this.beneficiaryType === 'E') {
        disableButton = false;
      } else if (this.beneficiaryType === 'X') {
        if (this.allocationPercentage <= 0 || this.allocationPercentage > 100) {
          disableButton = true;
        }
        if (this.fullName.length === 0 || this.errorMessagesFullName.length > 0) {
          disableButton = true;
        }
      } else if (this.beneficiaryType === 'Y') {
        if (this.allocationPercentage <= 0 || this.allocationPercentage > 100) {
          disableButton = true;
        }
        if (this.$v.firstName.$invalid || this.$v.lastName.$invalid || this.relationshipToInsured === '') {
          disableButton = true;
        }
        if (this.isNyPolicy) {
          if (this.$v.address1Data.$invalid || this.$v.address2Data.$invalid || this.$v.cityData.$invalid || this.stateData === '' || this.$v.zipData.$invalid) {
            disableButton = true;
          }
        }
        if (this.isCanadaPolicy) {
          if (this.beneficiaryUnder18 === 'Y') {
            if (this.trusteeFirstName.length === 0 || this.errorMessagesTrusteeFirstName !== '') {
              disableButton = true;
            }
            if (this.trusteeLastName.length === 0 || this.errorMessagesTrusteeLastName !== '') {
              disableButton = true;
            }
          } else if (this.beneficiaryUnder18 === '') {
            disableButton = true;
          }
        }
      } else {
        disableButton = true;
      }
      /* Checking for elements that are displayed but not required to see if they have errors */
      if (this.$v.middleName.$dirty && this.$v.middleName.$invalid) {
        disableButton = true;
      }
      if (this.$v.ssn.$dirty && this.$v.ssn.$invalid) {
        disableButton = true;
      }
      if (this.$v.emailAddress.$dirty && this.$v.emailAddress.$invalid) {
        disableButton = true;
      }
      if (this.$v.address1Data.$dirty && this.$v.address1Data.$invalid) {
        disableButton = true;
      }
      if (this.$v.address2Data.$dirty && this.$v.address2Data.$invalid) {
        disableButton = true;
      }
      if (this.$v.cityData.$dirty && this.$v.cityData.$invalid) {
        disableButton = true;
      }
      if (this.$v.usStateData.$dirty && this.$v.usStateData.$invalid) {
        disableButton = true;
      }
      if (this.$v.caTerritoryData.$dirty && this.$v.caTerritoryData.$invalid) {
        disableButton = true;
      }
      if (this.$v.zipData.$dirty && this.$v.zipData.$invalid) {
        disableButton = true;
      }
      if (this.$v.dateOfBirth.$dirty && this.$v.dateOfBirth.$invalid) {
        disableButton = true;
      }
      if (this.$v.telephone.$dirty && this.$v.telephone.$invalid) {
        disableButton = true;
      }
      return disableButton;
    },
  },
  methods: {
    ...mapMutations('policy', {
      setAddressUpdateSuccess: 'setAddressUpdateSuccess',
      setBeneficiaryStep: 'setBeneficiaryStep',
      setBeneficiaryPrimary: 'setBeneficiaryPrimary',
      setBeneficiaryPrimaryContingent: 'setBeneficiaryPrimaryContingent',
      setBeneficiarySpouse: 'setBeneficiarySpouse',
      setBeneficiarySpouseContingent: 'setBeneficiarySpouseContingent',
      setBeneficiaryDataDirty: 'setBeneficiaryDataDirty',
    }),
    isBenObjSameAsCurrBenTypeDropdown(val) {
      let isBenObjSameAsCurrBenType = false;
      if (this.beneficiaryType.toUpperCase() === 'C' && val.toUpperCase() === 'C') {
        isBenObjSameAsCurrBenType = true;
      } else if (this.beneficiaryType.toUpperCase() === 'E' && val.toUpperCase() === 'E') {
        isBenObjSameAsCurrBenType = true;
      } else if (this.beneficiaryType.toUpperCase() === 'X' && (val.toUpperCase() === 'X' || val.toUpperCase() === 'O')) {
        isBenObjSameAsCurrBenType = true;
      } else if (this.beneficiaryType.toUpperCase() === 'Y' && (val.toUpperCase() === 'I' || val.toUpperCase() === 'P' || val.toUpperCase() === 'S' || val.toUpperCase() === 'Y')) {
        isBenObjSameAsCurrBenType = true;
      }
      return isBenObjSameAsCurrBenType;
    },
    onBlurFullName() {
      this.fullNameDirty = true;
      if (this.fullNameAlreadyExists()) {
        this.errorMessagesFullName = this.$t('L_name_has_already_been_used');
      } else if (this.fullName.match(/^([A-Za-z][A-Za-z \-.']+)$/)) {
        this.errorMessagesFullName = '';
      } else if (this.fullName === '') {
        this.errorMessagesFullName = this.$t('L_Full_name_is_required');
      } else {
        this.errorMessagesFullName = this.$t('L_Full_name_is_invalid');
      }
    },
    revFullNameAlreadyExists() {
      return !this.fullNameAlreadyExists();
    },
    cityMaxReached() {
      return this.cityData.length <= 25;
    },
    relationshipComparisonSame(val) {
      let isBenObjSameAsCurrBenType = false;
      if (this.relationshipToInsured.toUpperCase() === val.toUpperCase()) {
        isBenObjSameAsCurrBenType = true;
      }
      return isBenObjSameAsCurrBenType;
    },
    fullNameAlreadyExists() {
      const alreadyExists = false;
      // let alreadyExists = false;
      /*
      let fullName;
      const fName = this.firstName.trim() === '' ? '' : `${this.firstName.trim()} `;
      const mName = this.middleName.trim() === '' ? '' : `${this.middleName.trim()} `;
      const lName = this.lastName.trim() === '' ? '' : this.lastName.trim();

      if (this.beneficiaryType === 'Y') {
        fullName = (fName + mName + lName).trim().substring(0, 75);
      } else if (this.beneficiaryType === 'X') {
        fullName = this.fullName.trim();
      } else if (this.beneficiaryType === 'C') {
        fullName = 'All Children Equally';
      } else if (this.beneficiaryType === 'E') {
        fullName = 'Estate';
      }
      // Comparing the fullName to existing filters.
      // Rule 1. Allow same fullname for different this.beneficiaryType ('Y', 'X', 'C', 'E')
      // Rule 2. Allow same fullname for this.beneficiary Type === 'Y' (Individual) if relationshipToInsured is different.
      // this.cloneBeneficiary.beneficiaryType ('P','C','S','H')
      if (this.beneType !== '' || this.cloneBeneficiary?.beneficiaryType) {
        // This is for Primary and Contingent of Main Person
        if ((this.beneficiaryStep === 2 && (this.beneType === 'P' || this.beneType === 'C')) ||
           (this.beneficiaryStep === 3 && (this.cloneBeneficiary?.beneficiaryType === 'P' || this.cloneBeneficiary?.beneficiaryType === 'C'))) {
          if (
            this.beneficiaryPrimary.filter(benObj =>
              (
                // Rule 1 For entity type 'C','E','X' can't have same fullname. C = (C), E = (E), X = (O, X), Y = (I, P, S, Y) These comparisons
                // are being done in isBenObjSameAsCurrBenTypeDropdown
                // Check store's beneficiaryPrimary array contains an obj where entityType matches and is of Type X (Entity)
                // Rule 2. if this.beneficiary Type === 'Y' (Individual) && same relationshipToInsured && same fullName
                ((this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.relationshipComparisonSame(benObj?.relationship) &&
                    this.beneficiaryType === 'Y'
                ) ||
                  (this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.beneficiaryType === 'X'
                  )
                ) && (benObj?.fullName.toLowerCase() === fullName.toLowerCase() && benObj?.beneKeyId !== this.cloneBeneficiary?.beneKeyId)
              ))
            +
            this.beneficiaryPrimaryContingent.filter(benObj =>
              (
              // Rule 1 For entity type 'C','E','X' can't have same fullname. C = (C), E = (E), X = (O, X), Y = (I, P, S, Y) These comparisons
              // are being done in isBenObjSameAsCurrBenTypeDropdown
              // Rule 2. if this.beneficiary Type === 'Y' (Individual) && same relationshipToInsured && same fullName
                ((this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.relationshipComparisonSame(benObj?.relationship) &&
                    this.beneficiaryType === 'Y'
                ) ||
                  (this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.beneficiaryType === 'X'
                  )
                ) && (benObj?.fullName.toLowerCase() === fullName.toLowerCase() && benObj?.beneKeyId !== this.cloneBeneficiary?.beneKeyId)
              ))
          ) {
            alreadyExists = true;
          }
          // If the relationship selected is other and fullname matches primaryInsured
          if (this.relationshipToInsured === 'Other' && fullName.toLowerCase() === this.primaryInsured.toLowerCase()) {
            alreadyExists = true;
          }
        } else if ((this.beneficiaryStep === 2 && (this.beneType === 'S' || this.beneType === 'H')) ||
                   (this.beneficiaryStep === 3 && (this.cloneBeneficiary?.beneficiaryType === 'S' || this.cloneBeneficiary?.beneficiaryType === 'H'))) {
          if (
            this.beneficiarySpouse.filter(benObj =>
              (
                // Rule 1 For entity type 'C','E','X' can't have same fullname. C = (C), E = (E), X = (O, X), Y = (I, P, S, Y) These comparisons
                // are being done in isBenObjSameAsCurrBenTypeDropdown
                // Check store's beneficiaryPrimary array contains an obj where entityType matches and is of Type X (Entity)
                // Rule 2. if this.beneficiary Type === 'Y' (Individual) && same relationshipToInsured && same fullName
                ((this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.relationshipComparisonSame(benObj?.relationship) &&
                    this.beneficiaryType === 'Y'
                ) ||
                  (this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.beneficiaryType === 'X'
                  )
                ) && (benObj?.fullName.toLowerCase() === fullName.toLowerCase() && benObj?.beneKeyId !== this.cloneBeneficiary?.beneKeyId)
              ))
            +
            this.beneficiarySpouseContingent.filter(benObj =>
              (
              // Rule 1 For entity type 'C','E','X' can't have same fullname. C = (C), E = (E), X = (O, X), Y = (I, P, S, Y) These comparisons
              // are being done in isBenObjSameAsCurrBenTypeDropdown
              // Rule 2. if this.beneficiary Type === 'Y' (Individual) && same relationshipToInsured && same fullName
                ((this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.relationshipComparisonSame(benObj?.relationship) &&
                    this.beneficiaryType === 'Y'
                ) ||
                  (this.isBenObjSameAsCurrBenTypeDropdown(benObj?.beneficiaryEntityType) &&
                    this.beneficiaryType === 'X'
                  )
                ) && (benObj?.fullName.toLowerCase() === fullName.toLowerCase() && benObj?.beneKeyId !== this.cloneBeneficiary?.beneKeyId)
              ))
          ) {
            alreadyExists = true;
          }
          // If the fullName matches to that of the Spouse and the relationshipToInsured is Other
          if (this.relationshipToInsured === 'Other' && fullName.toLowerCase() === this.spouseInsured?.toLowerCase()) {
            alreadyExists = true;
          }
        }
      }
      */
      return alreadyExists;
    },
    onBlurTrusteeFirstName() {
      this.trusteeFirstNameDirty = true;
      if (this.trusteeFirstName.match(/^([A-Za-z][A-Za-z \-.']+)$/)) {
        this.errorMessagesTrusteeFirstName = '';
      } else if (this.trusteeFirstName === '') {
        this.errorMessagesTrusteeFirstName = this.$t('L_Trustee_First_Name_is_required');
      } else {
        this.errorMessagesTrusteeFirstName = this.$t('L_Trustee_First_Name_is_invalid');
      }
    },
    onBlurTrusteeLastName() {
      this.trusteeLastNameDirty = true;
      if (this.trusteeLastName.match(/^([A-Za-z][A-Za-z \-.']+)$/)) {
        this.errorMessagesTrusteeLastName = '';
      } else if (this.trusteeLastName === '') {
        this.errorMessagesTrusteeLastName = this.$t('L_Trustee_Last_Name_is_required');
      } else {
        this.errorMessagesTrusteeLastName = this.$t('L_Trustee_Last_Name_is_invalid');
      }
    },
    closeThisPage() {
      try {
        this.$emit('cancel');
        const beneficiaryType = this.beneficiaryStep === 3 ? this.cloneBeneficiary.beneficiaryType : this.beneType;
        const tempDynatraceString = `PC2_BenfDetails_Cancel_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${beneficiaryType}_PesonType-${this.beneficiaryType}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      this.setBeneficiaryStep(1);
    },
    async saveProcess() {
      let temp;
      if (this.beneficiaryUnder18 !== 'Y') {
        this.trusteeFirstName = '';
        this.trusteeLastName = '';
      }

      if (this.dateOfBirth) {
        const dob = this.dateOfBirth;
        if (this.isQuebec) {
          const tDate = moment(dob, 'DDMMYYYY');
          this.dateOfBirth = moment(tDate).format('YYYY-MM-DD');
        } else {
          const tDate = moment(dob, 'MMDDYYYY');
          this.dateOfBirth = moment(tDate).format('YYYY-MM-DD');
        }
      }

      const currData = {
        beneficiaryPercent: Number(this.allocationPercentage),
        address: {
          addressLine1: this.address1Data,
          state: this.stateData,
          addressLine2: this.address2Data,
          city: this.cityData,
          country: this.isCanadaPolicy ? 'CA' : 'US',
          zipCode: this.zipData,
        },
        origin: 'A', // Are we using A
        relationship: this.relationshipToInsured,
        emailAddress: this.emailAddress,
        dateOfBirth: this.dateOfBirth,
        telephone: this.telephone,
        trusteeFirstName: this.trusteeFirstName,
        applicationSource: 'PC2',
        middleName: this.middleName,
        firstName: this.firstName,
        // Setting to a negative number if not existing else reuse same number.
        beneKeyId:
          this.cloneBeneficiary?.beneKeyId > 0
            ? this.cloneBeneficiary.beneKeyId
            : -1 * (this.beneficiaryPrimary.length + this.beneficiaryPrimaryContingent.length + this.beneficiarySpouse.length + this.beneficiarySpouseContingent.length),
        // TODO need to add P, C, S, H  this is not the this.beneficiaryType which has C, E, X, Y
        beneficiaryType: this.beneficiaryStep === 2 ? this.beneType : this.cloneBeneficiary.beneficiaryType,
        trusteeLastName: this.trusteeLastName,
        irrevocable: this.irrevocable,
        ssn: this.ssn, // TODO when we fix masking issue with this.ssn,
        lastName: this.lastName,
        fullName: '',
        // TODO for now we think this is the right place to put C, E, X, Y
        beneficiaryEntityType: this.beneficiaryType, // What is this? is this the PERSON_TYPE in LIFE_BENEFICIARY
      };
      // FYI we have this element named after the label however this is not the 'Primary', 'Contingent' for Main or Spouse
      // It is instead the selection of the kind of beneficary template they want to use such as Estate, Entity, Individual or All Children Equally
      if (this.beneficiaryType === 'C') {
        // All Children
        currData.fullName = 'All Children Equally';
      } else if (this.beneficiaryType === 'E') {
        // Estate
        currData.fullName = 'Estate';
      } else if (this.beneficiaryType === 'X') {
        // Entity
        currData.fullName = this.fullName;
      } else if (this.beneficiaryType === 'Y') {
        // Individual
        const fName = this.firstName.trim() === '' ? '' : `${this.firstName.trim()} `;
        const mName = this.middleName.trim() === '' ? '' : `${this.middleName.trim()} `;
        const lName = this.lastName.trim() === '' ? '' : this.lastName.trim();
        currData.fullName = (fName + mName + lName).trim().substring(0, 75);
      }
      // if beneficiaryStep 2 Add mode this.beneType
      if (this.beneficiaryStep === 2) {
        if (this.beneType === 'P') {
          temp = this.beneficiaryPrimary;
          temp.push(currData);
          this.setBeneficiaryPrimary(temp);
        } else if (this.beneType === 'C') {
          temp = this.beneficiaryPrimaryContingent;
          temp.push(currData);
          this.setBeneficiaryPrimaryContingent(temp);
        } else if (this.beneType === 'S') {
          temp = this.beneficiarySpouse;
          temp.push(currData);
          this.setBeneficiarySpouse(temp);
        } else if (this.beneType === 'H') {
          temp = this.beneficiarySpouseContingent;
          temp.push(currData);
          this.setBeneficiarySpouseContingent(temp);
        }
      } else if (this.beneficiaryStep === 3) {
        // if beneficiaryStep 3 Edit mode this.beneObj
        // irrevocable we use existing data for edit, for new one it will be false
        currData.dateSigned = this.cloneBeneficiary.dateSigned;
        currData.updateUserId = this.cloneBeneficiary.updateUserId;
        if (this.cloneBeneficiary.beneficiaryType === 'P') {
          temp = this.beneficiaryPrimary.filter(ben => ben.beneKeyId !== this.cloneBeneficiary.beneKeyId);
          temp.push(currData);
          this.setBeneficiaryPrimary(temp);
        } else if (this.cloneBeneficiary.beneficiaryType === 'C') {
          temp = this.beneficiaryPrimaryContingent.filter(ben => ben.beneKeyId !== this.cloneBeneficiary.beneKeyId);
          temp.push(currData);
          this.setBeneficiaryPrimaryContingent(temp);
        } else if (this.cloneBeneficiary.beneficiaryType === 'S') {
          temp = this.beneficiarySpouse.filter(ben => ben.beneKeyId !== this.cloneBeneficiary.beneKeyId);
          temp.push(currData);
          this.setBeneficiarySpouse(temp);
        } else if (this.cloneBeneficiary.beneficiaryType === 'H') {
          temp = this.beneficiarySpouseContingent.filter(ben => ben.beneKeyId !== this.cloneBeneficiary.beneKeyId);
          temp.push(currData);
          this.setBeneficiarySpouseContingent(temp);
        }
      }
      this.hasFinished = true;
      try {
        const beneficiaryType = this.beneficiaryStep === 3 ? this.cloneBeneficiary.beneficiaryType : this.beneType;
        const tempDynatraceString = `PC2_BenfDetails_Save_Policynum-${this.currentPolicy?.contractId}_BeneficiaryType-${beneficiaryType}_PesonType-${this.beneficiaryType}`;
        // const tempStr = 'PC2_UpdateBenf_Edit_Policynum-' + <policy>_BeneficiaryType-[P,C,S,H]_Name-<Full Name - first 30 characters, replace spaces with underlines>
        const i = this.$dynatrace.enterAction(tempDynatraceString);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      this.resetForm();
      // await saveCall
      this.setBeneficiaryDataDirty(true);
      this.setBeneficiaryStep(1);
    },
    resetForm() {
      // Not resetting data if it's edit
      if (this.beneficiaryStep !== 3 && !this.hasFinished) {
        this.fullNameDirty = false;
        this.trusteeFirstNameDirty = false;
        this.trusteeLastNameDirty = false;
        this.allocationPercentage = '';
        this.firstName = '';
        this.middleName = '';
        this.lastName = '';
        this.fullName = '';
        this.ssn = '';
        this.relationshipToInsured = '';
        this.beneficiaryUnder18 = '';
        this.trusteeFirstName = '';
        this.trusteeLastName = '';
        this.emailAddress = '';
        this.dateOfBirth = '';
        this.telephone = '';
        this.address1Data = '';
        this.address2Data = '';
        this.cityData = '';
        this.stateData = '';
        this.zipData = '';
        this.errorMessagesFullName = '';
        this.errorMessagesTrusteeFirstName = '';
        this.errorMessagesTrusteeLastName = '';
      }
    },
  },
  watch: {
    stateData(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (!this.isCanadaPolicy) {
          this.$v.usStateData.$model = this.stateData;
        } else {
          this.$v.caTerritoryData.$model = this.stateData;
        }
      }
    },
    revocable(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.irrevocable = !newVal;
      }
    },
    firstName(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (this.fullNameAlreadyExists()) {
          this.isDupFirstName = true;
        }
      }
    },
    middleName(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (this.fullNameAlreadyExists()) {
          this.isDupMiddleName = true;
        }
      }
    },
    lastName(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        if (this.fullNameAlreadyExists()) {
          this.isDupLastName = true;
        }
      }
    },
    relationshipToInsured(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        // QuebecPolicy with relationship of Spouse and Individual selected for BeneficiaryType
        if (this.isQuebecPolicy && newVal === 'Spouse' && this.beneficiaryType === 'Y') {
          // set irrevocable to true
          // set flag to show the checkbox having verbiage for Irrevocable Beneficiary under the relationship
          this.showRevocableCheckbox = true;
          // if (this.$v.$anyDirty) {
          this.irrevocable = true;
          this.revocable = false;
          // }
        } else {
          this.showRevocableCheckbox = false;
        }
      }
    },
    // fullName(newVal, oldVal) {
    //   if (newVal !== oldVal && newVal) {
    //     if (this.fullNameAlreadyExists()) {
    //       this.errorMessagesFullName = this.$t('L_name_has_already_been_used');
    //     } else {
    //       this.errorMessagesFullName = '';
    //     }
    //   }
    // },
    beneficiaryType(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.resetForm();
        this.$v.$reset();
        this.relationshipToInsured = '';
        this.showRevocableCheckbox = false;
        if (newVal === 'E') {
          // Estate
          this.allocationPercentage = '100';
          this.fullName = 'Estate';
          this.relationshipToInsured = 'Estate';
        } else if (newVal === 'C') {
          // All Children Equally
          this.allocationPercentage = '100';
          this.fullName = 'All Children Equal';
        } else if (newVal === 'X' && this.beneficiaryStep === 2) {
          // Entity
          this.allocationPercentage = '';
          this.fullName = '';
        } else if (newVal === 'Y' && this.beneficiaryStep === 2) {
          // Individual
          this.allocationPercentage = '';
          this.fullName = '';
          if (this.relationshipToInsured === 'Spouse' && this.isQuebecPolicy) {
            this.irrevocable = true;
            this.revocable = false;
            this.showRevocableCheckbox = true;
          } else {
            this.showRevocableCheckbox = false;
          }
        }
        this.$nextTick(() => {
          if (oldVal !== '' && this.stateData !== '') {
            this.stateData = '';
            this.$nextTick(() => {
              if (!this.isCanadaPolicy) {
                this.$v.usStateData.$model = '';
              } else {
                this.$v.caTerritoryData.$model = '';
              }
            });
            this.$nextTick(() => {
              if (!this.isCanadaPolicy) {
                this.$v.usStateData.reset();
              } else {
                this.$v.caTerritoryData.reset();
              }
            });
          }
        });
      }
    },
    locale(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.dateOfBirth) {
          const isOldLocaleFrench = oldVal.indexOf('fr') > -1;
          const isNewLocaleFrench = newVal.indexOf('fr') > -1;
          if (isOldLocaleFrench && !isNewLocaleFrench) {
            const tDate = moment(this.dateOfBirth, 'DDMMYYYY');
            this.dateOfBirth = moment(tDate).format('MMDDYYYY');
          } else if (!isOldLocaleFrench && isNewLocaleFrench) {
            const tDate = moment(this.dateOfBirth, 'MMDDYYYY');
            this.dateOfBirth = moment(tDate).format('DDMMYYYY');
          }
        }
      }
    },
  },
  mounted() {
    this.beneficiaryType = '';
    this.resetForm();

    // This is for edit we want to set the beneType i.e. 'P', 'C', 'S', 'H'
    if (this.beneficiaryStep === 2) {
      // TODO what needs to go here for add since this is add
    } else if (this.beneficiaryStep === 3) {
      this.cloneBeneficiary = { ...this.beneObj };
      this.beneficiaryType = this.cloneBeneficiary.beneficiaryEntityType;
      // TODO beneficiaryEntityType
      if (
        this.cloneBeneficiary.beneficiaryEntityType === 'I' ||
        this.cloneBeneficiary.beneficiaryEntityType === 'P' ||
        this.cloneBeneficiary.beneficiaryEntityType === 'S' ||
        this.cloneBeneficiary.beneficiaryEntityType === 'Y'
      ) {
        this.beneficiaryType = 'Y';
      }
      if (this.cloneBeneficiary.beneficiaryEntityType === 'X' || this.cloneBeneficiary.beneficiaryEntityType === 'O') {
        this.beneficiaryType = 'X';
      }
      if (this.cloneBeneficiary.beneficiaryEntityType === 'C') {
        this.beneficiaryType = 'C';
      }
      if (this.cloneBeneficiary.beneficiaryEntityType === 'E') {
        this.beneficiaryType = 'E';
      }
    }
    this.$nextTick(() => {
      if (this.beneficiaryStep === 3) {
        this.allocationPercentage = this.cloneBeneficiary.beneficiaryPercent.toString();
        this.address1Data = this.cloneBeneficiary.address.addressLine1;
        this.stateData = this.cloneBeneficiary.address.state;
        this.address2Data = this.cloneBeneficiary.address.addressLine2;
        this.cityData = this.cloneBeneficiary.address.city;
        this.zipData = this.cloneBeneficiary.address.zipCode;
        // TODO get the proper mapping here need to call a function
        this.relationshipToInsured = this.cloneBeneficiary.relationship;
        this.trusteeFirstName = this.cloneBeneficiary.trusteeFirstName;
        this.middleName = this.cloneBeneficiary.middleName;
        this.firstName = this.cloneBeneficiary.firstName;
        this.trusteeLastName = this.cloneBeneficiary.trusteeLastName;
        this.irrevocable = this.cloneBeneficiary.irrevocable;
        this.revocable = !this.cloneBeneficiary.irrevocable;
        if (this.isQuebecPolicy && this.relationshipToInsured === 'Spouse' && this.beneficiaryType === 'Y') {
          this.showRevocableCheckbox = true;
        }
        this.ssn = this.cloneBeneficiary.ssn || '';
        this.lastName = this.cloneBeneficiary.lastName;
        this.fullName = this.cloneBeneficiary.fullName;
        this.emailAddress = this.cloneBeneficiary.emailAddress;
        if (this.showDOBAndPhoneNumber) {
          this.telephone = this.cloneBeneficiary.telephone;
          if (this.cloneBeneficiary.dateOfBirth) {
            const dob = this.cloneBeneficiary.dateOfBirth;
            const tDate = moment(dob, 'YYYY-MM-DD');
            if (this.isQuebec) {
              this.dateOfBirth = moment(tDate).format('DDMMYYYY');
            } else {
              this.dateOfBirth = moment(tDate).format('MMDDYYYY');
            }
          }
        } else {
          this.telephone = '';
          this.dateOfBirth = '';
        }
        this.dateSigned = this.cloneBeneficiary.dateSigned;
        this.updateUserId = this.cloneBeneficiary.updateUserId;

        if (this.cloneBeneficiary.trusteeFirstName + this.cloneBeneficiary.trusteeLastName !== '') {
          this.beneficiaryUnder18 = 'Y';
        } else if (this.isCanadaPolicy && (this.beneficiaryType === 'Y' || this.beneficiaryType === 'C')) {
          this.beneficiaryUnder18 = 'N';
        } else {
          this.beneficiaryUnder18 = '';
        }
      }
      this.$nextTick(() => {
        this.irrevocable = this.cloneBeneficiary.irrevocable;
        this.revocable = !this.cloneBeneficiary.irrevocable;
      });
    });

    // http://confluence.primerica.com/display/PD/Add+Beneficiary+Details
    // Beneficiary Type to Relationship Mapping - Backend Saving to the table

    // Ada.addKeyClick(this.$refs.contact);
    // Only after the "Submit" from My Beneficiary do we actually save the data.
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      throw e;
    }
  },
  validations() {
    return {
      allocationPercentage: {
        ...AllocationPercentageValidation,
        required,
      },
      firstName: {
        required,
        ...FirstNameValidation,
        maxLength: maxLength(50),
        dupfName: this.revFullNameAlreadyExists,
      },
      middleName: {
        maxLength: maxLength(50),
        minLength: minLength(1),
        dupmName: this.revFullNameAlreadyExists,
        name: validateName,
      },
      lastName: {
        required,
        ...LastNameValidation,
        maxLength: maxLength(50),
        duplName: this.revFullNameAlreadyExists,
      },
      fullName: {
        required,
      },
      beneficiaryUnder18: {
        required,
      },
      ssn: SSNValidation,
      emailAddress: {
        ...EmailValidation,
      },
      dateOfBirth: {
        date: DateValidation.date(false, this.isQuebec),
      },
      telephone: {
        ...PhoneNumberValidation,
      },
      address1Data: {
        ...AddressValidation,
        addressLine1Required: addressLine1Required(this.isNyPolicy && this.beneficiaryType === 'Y'),
        addressLine1Validation: addressLine1Validation(),
      },
      address2Data: {
        ...AddressValidation,
        addressLine2Validation: addressLine1Validation(),
      },
      cityData: {
        ...CityValidation,
        cityRequired: cityRequired(this.isNyPolicy && this.beneficiaryType === 'Y'),
        cityValidation: cityValidation(),
        cityMax: this.cityMaxReached,
      },
      usStateData: {
        ...StateValidation,
        stateRequired: stateRequired(this.isNyPolicy && this.beneficiaryType === 'Y'),
      },
      caTerritoryData: TerritoryValidation,
      zipData:
        this?.currentPolicy?.isCanadaPolicy || this.isCanadaPolicy
          ? {
            ...PostalCodeValidation,
            validForTerritory: ValidForTerritory(this.stateData, this.zipData),
          }
          : {
            ...ZipCodeValidation,
            zipRequired: zipRequired(this.isNyPolicy && this.beneficiaryType === 'Y'),
          },
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main'
.moveRedStartBeforeLabel {
  margin-bottom: -15px;
  display: block;
  margin-left: -8px;
  padding-top: 15px;
}
.moveRedStarLeftOnly {
  margin-left: -5px;
}
.moveRedStarAfterFullname{
  position: relative;
  margin-top: -75px;
  margin-left: 80px;
  display: block;
  margin-bottom: 50px;
}
.gray-background{
  background-color: #f5f5f5;
}
.max-width-card {
  max-width: 360px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-left-40 {
  margin-left: 40px;
}
.input-label-header {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.redText {
  color: red;
}
.paddingLRMobile {
 padding-left: 10px;
 padding-right:10px;
}
.paddingLRDesktop {
  padding-left: 20px
  padding-right: 20px
}
.move-right
  float: right;
.bkgrnd-color-white
  background-color: #ffffff;
.bkgrnd-color-blue-white
  background-color: #f2f2f2;
.blue-bkgrnd
  background-color: #c9e5f9;
.buttonFormat
  width: 300px;
  height: 44px;
  font-size: 18px;
  font-weight: 600;
.sm-pl-5
  @media $display-breakpoints.sm-and-up
    padding-left: 40px;

@media $display-breakpoints.md-and-up
  .pad-left-md
    padding-left: 12px
  .pad-right-md
    padding-right: 12px

.blue-border {
  border: solid 2px #2196f3;
  cursor pointer;
}

.select-address {
  color: #2196f3 !important;
}

/deep/ label.v-label--active {
  font-weight: 500;
  color: #333;
}

/deep/ div.v-input {
  padding-bottom: 12px;
}

h3.heading_three.font-weight-medium {
  font-weight: 500 !important;
}
.font-size-md {
  font-size: 16px !important;
}
.inlineText {
  display: inline-text;
}
button.short {
  height: 40px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px #063562 solid;
  width: auto !important;
}
.tinyPixel {
  color: transparent;
  font-size: 0.001px;
  height: 0.001px;
}
</style>

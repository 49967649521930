<i18n src="../locales.json"></i18n>

<template>
  <v-flex xs12 sm6 md6 class="mt8 pa8">
    <CardTemplate id="update_userid_card">
      <img slot="icon" class="account-img" src="../assets/update-username.svg" alt="" role="presentation">
      <span slot="title" v-html="$t('L_update_username')" />
      <template slot="content">
        <span v-if="customerProfile">{{ customerProfile.email }}</span>
      </template>
      <a v-ada-modal slot="action" href="javascript:void(0)" role="button" @click="showUpdateUserIdDialog = true" id="update_userid_trigger" :aria-label="$t('L_update_user_id')">{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="showUserIDPasswordPage? $t('L_update_user_id'): $t('L_update_user_id_verification')"
      :show="showUpdateUserIdDialog"
      @save="nextPage"
      @close="closeDialog"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton"
      :show-send-grid="getSendGridShowStatus"
      save-changes-btn-id="save_user_id"
      close-btn-id="close_user_id"
      close-btn-class="update_userid_close"
      modal-class="update_userid_modal"
      submit-btn-text="L_next"
    >
      <v-layout v-if="showUserIDPasswordPage" row wrap slot="content">
        <form @submit.prevent>
          <v-flex xs12 class="mb-5 text-xs-center">
            <div>{{ $t('L_update_user_id_subheading') }}</div>
          </v-flex>
          <v-flex xs12 class="mb-5 text-xs-center">
            <span class="font-weight-medium font-size--14">{{ $t('L_note_you_will_be_signed_out') }}</span>
          </v-flex>
          <v-flex xs12>
            <label class="mb-3" style="display: block;">{{ $t('L_current_user_id') }}</label>
            <label v-if="customerProfile" class="mb-5" style="display: block; color: #767575;">{{ customerProfile.email }}</label>
          </v-flex>
          <v-flex xs12>
            <EmailInput
              id="new_userid_email"
              id-prop="new_userid_email"
              v-model="emailAddress"
              :form-input="$v.emailAddress"
              :label="$t('L_new_user_id')"
              :validation-messages="{
                notCurrentEmail: $t('L_no_current_user_id'),
              }"
            />
          </v-flex>
          <v-flex xs12>
            <ConfirmEmailInput
              id="confirm-new_userid_email"
              v-model="confirmEmailAddress"
              :form-input="$v.confirmEmailAddress"
              :label="$t('L_confirm_new_user_id')"
              :validation-messages="{
                notMatchingEmail: $t('L_confirm_email_match'),
              }"
            />
          </v-flex>
          <v-flex xs12>
            <PasswordInput
              v-model="currentPassword"
              :form-input="$v.currentPassword"
              :label="$t('L_enter_current_password')"
              id-prop="enter_current_password"
              enable-show-hide
              valid-icon="check_circle"
              enable-masking
            />
          </v-flex>
        </form>
      </v-layout>
      <v-layout v-else row wrap slot="content">
        <div v-if="showSendGridPage" class="text-xs-center">
          <v-flex xs12>
            <img class="sendgridImageWidth" alt="SendGrid phone icon" src="../assets/authphone.png">
          </v-flex>
          <v-flex xs12 mb-4 class="protect_your_account">
            <span>{{ $t('L_protect_your_account_with_two_factor_authentication') }}</span>
          </v-flex>
          <v-flex xs12 mb-4 class="a_verification_code">
            <span>{{ $t('L_a_verification_code_will_be_sent_to_your_email_below') }}</span>
          </v-flex>
          <v-flex xs12 mb-4 class="email_blue">
            <v-icon
              class="right-icon"
              color="#277dd0"
            >
              email
            </v-icon>
            {{ $t('L_email_client_email', { customerEmail: emailAddress }) }}
          </v-flex>
        </div>
        <div v-else>
          <form @submit.prevent class="text-xs-center">
            <v-flex xs12 class="mb-4">
              <h3 class="heading_three font-weight-bold font-size--15">
                {{ $t('L_authenticate_your_email_address') }}
              </h3>
            </v-flex>
            <v-flex xs12 class="mt-4 mb-4 font-weight-regular font-size--14 text-xs-left">
              {{ $t('L_enter_the_verification_code_we_sent_to_your_email_address', { emailaddress: emailAddress }) }}
            </v-flex>
            <v-layout row wrap>
              <v-flex class="mb-4 mt-2 xs12">
                <v-text-field
                  v-model="otpCode"
                  id="opt-code"
                  :label="$t('L_enter_code')"
                  required
                  clear-icon="cancel"
                  mask="######"
                  type="tel"
                  style="padding-bottom: 5px"
                  :error-messages="otpErrorMessages"
                  @input="checkOTPErrors"
                  @focus="checkOTPErrors"
                />
                <v-layout wrap class="font-size--12 text-xs-left mt-3">
                  <v-flex xs12>
                    {{ $t('L_didnt_receive_one') }}
                    <a href="javascript:void(0)" @click="requestUserChallenge">{{ $t('L_resend_code') }}</a>
                  </v-flex>
                  <v-flex xs12>
                    {{ $t('L_please_wait_25_seconds') }}
                  </v-flex>
                  <v-flex xs12 mb-4>
                    {{ $t('L_check_your_spam_folder_too') }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </form>
        </div>
      </v-layout>
    </MyAccountDialog>
  </v-flex>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { required, requiredIf, minLength } from 'vuelidate/lib/validators';
import { EmailInput, EmailValidation, ConfirmEmailInput, ConfirmEmailValidation, PasswordInput } from '@primerica/vue-components';
// import { CapacitorFacade } from '../../../../../plugins/capacitorPlugin';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';
import { enableSaveButton } from '../updatePassword/UpdatePassword.vue';

export function showDialog() {
  this.showUpdateUserIdDialog = true;
}

export const notCurrentEmailValidation = (value, vm) => value !== vm.customerProfile.email?.trim();
export const notSameAsEmail = (value, vm) => value.toLowerCase() === vm.emailAddress.toLowerCase();

export default {
  name: 'UpdateUserID',
  components: {
    CardTemplate,
    MyAccountDialog,
    EmailInput,
    EmailValidation,
    ConfirmEmailInput,
    ConfirmEmailValidation,
    PasswordInput,
  },
  data: () => ({
    emailAddress: '',
    confirmEmailAddress: '',
    currentPassword: '',
    otpCode: '',
    otpErrorMessages: [],
    count: 0,
    otpCodeExpired: false,
    mfaToken: '',
    showUpdateUserIdDialog: false,
    closeIsActive: false,
    challengeRequested: false,
    showUserIDPasswordPage: true,
    showSendGridPage: false,
    currentPage: 'userIdPage',
  }),
  validations() {
    return {
      emailAddress: {
        ...EmailValidation,
        required,
        notCurrentEmail: notCurrentEmailValidation,
      },
      confirmEmailAddress: {
        ...ConfirmEmailValidation,
        notMatchingEmail: notSameAsEmail,
      },
      currentPassword: {
        required,
      },
      otpCode: {
        required: requiredIf(vm => vm.challengeRequested),
        minLength: minLength(6),
      },
    };
  },
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({
      locale: 'getLocale',
      isMobileApp: 'isMobileApp',
    }),
    enableSaveButton() {
      return this.$v.$anyDirty && !this.$v.$invalid && this.count < 3 && !this.otpCodeExpired;
    },
    getSendGridShowStatus() {
      return this.showUserIDPasswordPage === false && this.showSendGridPage === true;
    },
  },
  watch: {
    enableSaveButton,
  },
  methods: {
    ...mapMutations({ removeAlerts: 'removeAlerts' }),
    ...mapActions({
      requestUserIdChallenge: 'home/requestUserIdChallenge',
    }),
    clearForm() {
      this.emailAddress = '';
      this.confirmEmailAddress = '';
      this.currentPassword = '';
      this.otpCode = '';
      this.$v.$reset();
    },
    nextPage() {
      if (this.currentPage === 'userIdPage') {
        this.currentPage = 'sendGrid';
        this.showUserIDPasswordPage = false;
        this.showSendGridPage = true;
      } else if (this.currentPage === 'sendGrid') {
        this.currentPage = 'verificationPage';
        this.showUserIDPasswordPage = false;
        this.showSendGridPage = false;
        this.requestUserChallenge();
      } else if (this.currentPage === 'verificationPage') {
        this.updateUserID();
      }
    },
    handleUpdateUserId() {
      return this.challengeRequested ? this.updateUserID() : this.requestUserChallenge();
    },
    requestUserChallenge() {
      this.count = 0;
      this.otpErrorMessages = [];
      this.otpCode = '';
      this.removeAlerts();
      this.requestUserIdChallenge({
        loginId: this.emailAddress,
        password: this.currentPassword,
        locale: this.locale,
        token: this.mfaToken,
      }).then(this.setToken);
    },
    setToken(mfaToken) {
      this.challengeRequested = true;
      this.mfaToken = mfaToken;
    },
    updateUserID() {
      const updateUserID = {
        loginId: this.emailAddress,
        password: this.currentPassword,
        otpCode: this.otpCode,
        token: this.mfaToken,
        locale: this.locale,
      };
      this.removeAlerts();
      this.$store
        .dispatch('home/updateUserID', updateUserID)
        .then((response) => {
          /*
           * Emit custom actions when user successfully saves changes to: UserID
           * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
           */
          const i = this.$dynatrace.enterAction('account_settings_changes_saved_user_id');
          this.$dynatrace.leaveAction(i);
          return this.logUserOut(response);
        })
        .catch(this.handleOTPCodeErrors);
    },
    checkOTPErrors() {
      const validationError = [];
      if (!this.$v.otpCode.required) {
        validationError.push(this.$t('L_otp_required_field'));
      }
      if (!this.$v.otpCode.minLength) {
        validationError.push(this.$t('L_otp_code_min_length'));
      }
      this.otpErrorMessages = validationError;
    },
    handleOTPCodeErrors({ response }) {
      let error1 = '';
      if (response?.data?.appStatusCode === 'dcp-3003') {
        this.count += 1;
        switch (this.count) {
          case 1:
            error1 = this.$t('L_otp_code_first_attempt'); // messages.otpCode.firstAttempt;
            break;
          case 2:
            error1 = this.$t('L_otp_code_second_attempt'); // messages.otpCode.secondAttempt;
            break;
          case 3:
            error1 = this.$t('L_otp_code_third_attempt'); // messages.otpCode.thirdAttempt;
            break;
          default:
            error1 = this.$t('L_otp_generic_invalid'); // messages.otpCode.otpGenericInvalid;
        }
        this.otpErrorMessages.push(error1);
      }
      if (response?.data?.appStatusCode === 'dcp-3001') {
        this.otpErrorMessages.push(this.$t('L_otp_code_exceeded')); // messages.otpCode.otpCodeExceeded
      }
      if (response?.data?.appStatusCode === 'dcp-3002') {
        this.otpCodeExpired = true;
        this.otpErrorMessages.push(this.$t('L_otp_code_expired')); // messages.otpCode.expired
      }
    },
    async logUserOut(response) {
      if (!response) return;
      this.closeIsActive = false;
      await this.$store.dispatch('resetStore');
      if (this.isMobileApp) {
        await this.$auth.logout(); // ! clear browser session and revoke tokens
        await this.$router.push({ name: 'PostLogoutPorch' });
      } else {
        await this.$auth.logout(); // ! clear browser session and revoke tokens
      }
    },
    resetProcess() {
      this.count = 0;
      this.otpCodeExpired = false;
      this.challengeRequested = false;
      this.currentPage = 'userIdPage';
      this.showUserIDPasswordPage = true;
      this.showSendGridPage = false;
      this.clearForm();
      this.removeAlerts();
    },
    closeDialog() {
      this.resetProcess();
      this.showUpdateUserIdDialog = false;
    },
  },
  mounted() {
    const clickTarget = this.$el.querySelector('.clickable');
    if (clickTarget) {
      clickTarget.addEventListener('click', showDialog.bind(this));
    }
  },
};
</script>

<style lang="stylus" scoped>
.sendgridImageWidth {
  max-width: 450px;
  width: 90%;
}
.update_user_id {
  font-weight: 600;
  font-size: 18px;
}
.protect_your_account {
  font-weight: 600;
  font-size: 16px;
}
.a_verification_code {
  font-weight: 400;
  font-size: 14px;
}
.email_blue {
  font-weight: bold;
  font-size: 12px;
  color: #277dd0;
}

</style>

<i18n src='./locales.json'></i18n>

<template>
  <div
    id="clickbox"
  >
    <v-bottom-nav
      v-if="mobile"
      zclass="hidden-lg-and-up"
      class="setBottomNavHeight"
      style="z-index:11;"
      :active.sync="bottomNav"
      :value="true"
      fixed
      color="white"
    >
      <v-btn
        color="#02579E"
        flat
        value="Home"
        @click="homeActionProp();"
        class="pl-0 pr-0 move-icon-up"
      >
        <div :class="bottomNav === 'Home'? 'selectedBottomIcon' : 'notSelectedBottomIcon'">
          <div><v-icon>home</v-icon></div>
          <div class="font-size--14">
            {{ $t('L_home') }}
          </div>
        </div>
      </v-btn>

      <v-btn
        color="#02579E"
        flat
        value="Life Insurance"
        @click="lifeActionProp();"
        class="pl-0 pr-0 move-icon-up"
      >
        <div :class="bottomNav === 'Life Insurance'? 'selectedBottomIcon' : 'notSelectedBottomIcon'">
          <div><v-icon>shield</v-icon></div>
          <div class="font-size--14">
            {{ $t('L_life') }}
          </div>
        </div>
      </v-btn>
      <v-btn
        color="#02579E"
        flat
        value="Investments"
        @click="investmentsActionProp();"
        class="pl-0 pr-0 move-icon-up"
      >
        <div :class="bottomNav === 'Investments'? 'selectedBottomIcon' : 'notSelectedBottomIcon'">
          <div><v-icon>leaderboard</v-icon></div>
          <div class="font-size--14">
            {{ $t('L_invest') }}
          </div>
        </div>
      </v-btn>
      <v-btn
        color="#02579E"
        flat
        value="Debt Solutions"
        @click.prevent="debtActionProp();"
        class="pl-0 pr-0 move-icon-up"
      >
        <div :class="bottomNav === 'Debt Solutions'? 'selectedBottomIcon' : 'notSelectedBottomIcon'">
          <div><v-icon>credit_card_off</v-icon></div>
          <div class="font-size--14">
            {{ $t('L_debt_text') }}
          </div>
        </div>
      </v-btn>
      <v-btn
        id="anchorMoreMenu"
        color="#02579E"
        flat
        value="more"
        @click="submenu = !submenu"
        class="pl-0 pr-0 move-icon-up"
      >
        <div :class="bottomNav === 'more'? 'selectedBottomIcon' : 'notSelectedBottomIcon'">
          <div><v-icon>more_horiz</v-icon></div>
          <div class="font-size--14">
            {{ $t('L_more') }}
          </div>
        </div>
      </v-btn>


      <!-- <v-flex
        class="pt-0"
        :class="$vuetify.breakpoint.lgAndUp?'shrink':''"
      >
        <v-layout
          row
          align-center
          justify-end
          :class="$vuetify.breakpoint.lgAndUp?'pt-0':'pt-0'"
        > -->

      <!-- </v-layout>
      </v-flex> -->
    </v-bottom-nav>
    <v-menu
      v-if="mobile"
      style="z-index:12;"
      transition="slide-y-transition"
      :close-on-content-click="false"
      class="ml-2"
      v-model="submenu"
      attach="#anchorMoreMenu"
    >
      <v-list
        ref="items"
      >
        <v-list-tile
          v-if="showRepLink && !hideAllButLocale"
          @click.prevent="repActionProp();toggleDrawerProp();submenu=false;"
          :class="[selectedItem==='My Rep'?'selectedMenuItemClass':'nonSelectedMenuItemClass']"
        >
          <v-list-tile-title class="font-size--16">
            {{ $t('L_my_rep') }}
          </v-list-tile-title>
        </v-list-tile>

        <v-list-tile
          v-if="showDocLink && !hideAllButLocale"
          @click.prevent="documentsActionProp();toggleDrawerProp();submenu=false;"
          :class="[selectedItem==='Documents'?'selectedMenuItemClass':'nonSelectedMenuItemClass']"
        >
          <v-list-tile-title class="font-size--16">
            {{ $t('L_my_document') }}
          </v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          v-if="!hideAllButLocale && helpEnabled"
          @click.prevent="helpActionProp();toggleDrawerProp();submenu=false;"
          :class="{'not-live' : !helpActiveProp }"
        >
          <v-list-tile-title class="font-size--16 helpClass">
            {{ $t('L_help') }}
          </v-list-tile-title>
        </v-list-tile>
        <v-list-group
          :class="languageActiveProp === true ? '' : 'hide'"
        >
          <template v-slot:activator>
            <v-list-tile class="selectedLangClass">
              <v-icon
                color="#02579e"
              >
                language
              </v-icon>
              <v-list-tile-title class="ml-2 font-size--16">
                {{ $t(`L_${radioLang}`) }}
              </v-list-tile-title>
            </v-list-tile>
          </template>

          <v-list-tile
            :class="radioLang === language? 'selectedLangOptionClass': 'notSelectedLangOptionClass'"
            v-for="(language, index) in languages"
            :key="index"
            @click="updateLocale(language);submenu=false;"
          >
            <div
              class="leftSpaceBeforeLangOption font-size--16"
            >
              {{ $t(`L_${language}`) }}
            </div>
          </v-list-tile>
        </v-list-group>

        <v-list-tile
          v-if="!hideAllButLocale"
          :class="[selectedItem==='Account Settings'?'selectedMenuItemClass':'nonSelectedMenuItemClass']"
        >
          <v-list-tile-title
            class="font-size--16"
            @click.prevent="accountActionProp();toggleDrawerProp();submenu=false;"
          >
            {{ $t('L_account_setting') }}
          </v-list-tile-title>
        </v-list-tile>

        <v-list-tile
          @click="logoutProp();submenu=false;"
          class="signOutClass"
        >
          <v-list-tile-title class="font-size--16 signOutInnerClass">
            {{ $t('L_sign_out') }}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!mobile"
      v-model="dataDrawerProp"
      fixed
      floating
      temporary
      app
      right
      class="hidden-lg-and-up mobile-navigation-menu"
      :class="$vuetify.breakpoint.width<480?'mobile-navigation-menuPhone':'mobile-navigation-menuTablet'"
      id="navigationDrawerId"
    >
      <v-list
        class="pa-1"
        role="list"
      >
        <v-list-tile role="listitem" @click.prevent="homeActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
              >
                {{ $t('L_home') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile role="listitem" @click.prevent="lifeActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
              >
                {{ $t('L_life_insurance') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile role="listitem" @click.prevent="investmentsActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
                :class="{'not-live': !investmentsActiveProp}"
                :aria-label="$t('L_investment')"
              >
                {{ $t('L_investment') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile role="listitem" @click.prevent="debtActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
                :class="{'not-live' : !debtActiveProp }"
                :aria-label="$t('L_debt')"
              >
                {{ $t('L_debt') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="showRepLink"
          role="listitem"
          @click.prevent="repActionProp();clickToCloseTheDrawer();"
        >
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
              >
                {{ $t('L_my_rep') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-if="showDocLink"
          role="listitem"
          @click.prevent="documentsActionProp();clickToCloseTheDrawer();"
        >
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
                :aria-label="$t('L_my_document')"
              >
                {{ $t('L_my_document') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile v-if="helpEnabled" role="listitem" @click.prevent="helpActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                id="mobilenav_help"
                href="javascript:void(0)"
                :class="{'not-live' : !helpActiveProp }"
                :aria-label="$t('L_help')"
              >
                {{ $t('L_help') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile role="listitem" @click.prevent="accountActionProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0)"
              >
                {{ $t('L_account_setting') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          role="listitem"
          :class="languageActiveProp === true ? '' : 'hide'"
        >
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <locale-toggle
                :current-locale="currentLocale"
                :languages="languages"
                :display="languageActiveProp"
                :display-icon="true"
                @update-locale="updateLocale"
                submenu-location=""
              />
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile role="listitem" @click="logoutProp();clickToCloseTheDrawer();">
          <v-list-tile-content class="mb-1">
            <v-list-tile-title>
              <a
                href="javascript:void(0);"
              >
                {{ $t('L_sign_out') }}
              </a>
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

import Config from '@/shared/util/config';
import LocaleToggle from '../LocaleToggle/LocaleToggle.vue';

export default {
  name: 'NavigationDrawer',
  components: {
    LocaleToggle,
  },
  data: () => ({
    radioLang: '',
    drawer: false,
    bottomNav: 'Home',
    submenu: false,
    dataDrawerProp: false,
  }),
  props: {
    drawerProp: {
      type: Boolean,
      required: true,
      default: false,
    },
    toggleDrawerProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    selectedItem: {
      type: String,
      required: true,
      default: '',
    },
    focusMenuCloseProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    homeActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    investmentsActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    lifeActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    accountActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    repActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    documentsActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    helpActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    showRepLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDocLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    helpActiveProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    investmentsActiveProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    debtActionProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    debtActiveProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    logoutProp: {
      type: Function,
      required: true,
      default: () => undefined,
    },
    languageActiveProp: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLocale: {
      type: String,
      required: true,
      default: () => 'en_US',
    },
    languages: {
      type: Array,
      required: true,
      default: () => [],
    },
    hideAllButLocale: {
      type: Boolean,
      default: false,
      required: false,
    },
    mobile: {
      type: Boolean,
      required: true,
      default: false,
    },
    clickToCloseTheDrawer: {
      type: Function,
      required: true,
      default: () => undefined,
    },
  },
  watch: {
    currentLocale(value) {
      this.radioLang = value;
    },
    selectedItem(value) {
      if (value === 'Home' || value === 'Life Insurance' || value === 'Investments' || value === 'Debt Solutions') {
        this.bottomNav = value;
      } else {
        this.bottomNav = 'more';
      }
    },
  },
  computed: {
    isWideEnoughForFullMenu() {
      return this.$vuetify.breakpoint.width > 1284;
    },
    helpEnabled() {
      return Config.get('help.enabled');
    },
  },
  mounted() {
    this.radioLang = this.currentLocale;
    this.dataDrawerProp = this.drawerProp;
  },
  // components: {
  //   LocaleToggle,
  // },
  methods: {
    onClickOutside2() {
      if (this.drawerProp === true) {
        this.clickToCloseTheDrawer();
      }
    },
    updateLocale(language) {
      this.$emit('reupdate-locale', language);
      this.clickToCloseTheDrawer();
    },
    // closeToggleDrawerProp() {
    //   this.$emit('drawer-prop-false');
    //   // this.toggleDrawerProp(false);
    // },
  },
};
</script>

<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main';
.selectedBottomIcon {
  padding-top: 10px !important;
  background-color: #E2F0FE !important;
  background: #E2F0FE !important;
  color: #02579E !important;
  border: 2px solid #E2F0FE !important;
  height: 88px !important;
  width: 100% !important;
}
.notSelectedBottomIcon {
  padding-top: 10px !important;
  background-color: #F5F7FA !important;
  background: #F5F7FA !important;
  color: #333333 !important;
  height: 88px !important;
  width: 100% !important;
}
.move-icon-up {
  height: 88px !important;
  display: inline-flex !important;
  align-items: flex-start !important;
  padding: 0px !important;
  font-size: 12px !important;
}
.setBottomNavHeight {
  height: 88px !important;
}
.cssOneHidden {
  margin-top: 336px;
}
.cssTwoHidden {
  margin-top: 288px;
}
.v-navigation-drawer--close {
  display: none !important;
}
.v-menu__content.left
  margin-left: -164px !important;
.mobile-navigation-menu
  z-index 110;
  margin-top: 77px !important;
  /deep/ .v-list__tile
    height: 100% !important;
    padding: 0 8px;
  .v-list__tile__title
    margin 5px 2px 5px 2px !important;
    white-space: initial;
    /* text-overflow: initial; */
    overflow: visible;
    height: unset;
    line-height: unset;
    min-height: 20px;
  .v-list__tile__content
    font-size 16px !important;
    font-weight normal !important;
  .close-drawer-btn
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 100;
.mobile-navigation-menuPhone
  z-index 110;
  width: 180px;
  max-width: 180px;
.mobile-navigation-menuTablet
  z-index 110;
  width: 240px;
  max-width: 240px;
.w100
  width 100%;
.v-list__tile__content
  a
    color rgba(0,0,0, 0.87) !important;
    font-weight normal !important;

.v-list__tile__title > a.not-live
  color: #656565 !important;
  cursor: text;
</style>
<style scoped>
#header .v-menu__content {
  zright: auto !important;
  zleft: auto !important;
  zbottom: 40px !important;
  position: absolute !important;
  margin-top: -332px !important;
}
.hide {
  display: none;
}
.navigationDrawerCSS {
  z-index: 12;
  margin-top: 80px !important;
  width: 100% !important;
  max-width: 325px !important;
  margin-bottom: 100px;
}
.close-drawer-btn {
  float: right;
}
.selectedLangClass, .selectedMenuItemClass {
  background-color: #f5f7fa;
  color: #000000;
}
.selectedLangOptionClass {
  background-color: #f5f7fa;
}
.selectedLangOptionClass a div, .selectedMenuItemClass a div, .selectedMenuItemClass div div {
  font-weight: bold;
  color: #02579e !important;
}
.notSelectedLangOptionClass, .nonSelectedMenuItemClass {
  background-color: #FFFFFF;
}
.notSelectedLangOptionClass a div, .nonSelectedMenuItemClass a div, .helpClass, .signOutInnerClass {
  font-weight: normal;
  color: #000000 !important;
}
.leftSpaceBeforeLangOption {
  margin-left: 32px;
}

.nonSelectedMenuItemClass:hover {
  background-color: #f5f7fa;
}
.signOutClass {
  background-color: #FFFFFF;
  color:#000000;
}
.blackLabel {
  color: #000000;
}
</style>

<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider v-if="!showDescription" />
    <Alerts context="payment" />
    <Alert
      v-if="showDescription"
      :dismissable="false"
      :message="
        $t(draftMessage, { amount: draftAmount, date: formattedPaidToDate })
      "
      alert-icon="info"
      type="info"
      :new-alert-type="true"
    />
    <v-layout class="justify-center">
      <v-flex xs11 :class="showDatePicker?'':'addPT20'">
        <v-card>
          <v-card-title class="justify-space-around text-xs-center">
            <h2 class="heading_two">
              {{ $t('L_enter_your_bank_account_information') }}
            </h2>
          </v-card-title>
          <v-card-text class="background-light-grey">
            <v-layout column>
              <v-flex xs12 sm7 :class="showDatePicker?'':'addPT31'">
                <!-- Starts top left half -->
                <v-layout row wrap :class="showDatePicker?'':'addPB50'">
                  <v-flex xs12 sm6>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <span class="font-weight-medium">{{
                          $t('L_payment_type')
                        }}</span>
                      </v-flex>
                      <v-flex xs12 mt-2>
                        <span class="font-weight-medium grey-ada-text">
                          {{ $t('L_monthly_bank_draft') }}
                        </span>
                      </v-flex>
                      <!-- <v-flex xs12>
                        <span class="font-weight-medium" id="UpdateBankOne">&nbsp;</span>
                      </v-flex> -->
                      <!-- <v-flex xs12 md-6 mt-2 pr-2>
                        <div class="mr-2 pr-2">
                          <v-text-field
                            v-model="accountHolder"
                            id="account_holder_name"
                            :label="$t('L_account_holder_name')"
                            :error-messages="accountHolderErrorMessages"
                            @change="validateAccountHolder"
                            @keyup="validateAccountHolder"
                            @blur="validateAccountHolder"
                            :maxlength="75"
                            :disabled="disableAccountHolderNameEdit"
                          />
                        </div>
                      </v-flex>
                      <v-flex xs12 mb-4>
                        <span class="font-size--14 font-weight-medium">
                          {{ $t('L_account_holder_must_be_same_as_owner') }}
                        </span>
                      </v-flex> -->
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <RadioButtonGroup
                      :class="{ 'radio-up': $vuetify.breakpoint.smAndUp }"
                      v-model="accountType"
                      :options="accountOptions"
                      :form-input="$v.accountType"
                    />
                    <span
                      class="font-size--12 font-weight-normal"
                    >
                      {{ $t('L_be_sure_to_select') }}
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  :class="{ 'content-relative': $vuetify.breakpoint.smAndUp }"
                  style="padding-bottom:30px;"
                >
                  <v-flex
                    xs12
                    sm6
                  >
                    <v-flex xs12 sm8>
                      <BankRoutingNumber
                        :max-lengthinput="isCanadaPolicy ? 8 : 9"
                        :min-length-input="isCanadaPolicy ? 8 : 9"
                        bank-routingid="onetp_routing_number_ca"
                        v-model="routingNumber"
                        :form-input="$v.routingNumber"
                        @blur="checkRouting"
                        :label="$t('L_routing_number')"
                      />
                    </v-flex>
                    <v-flex xs12 sm9>
                      <BankNameInput
                        id="onetp_bank_name"
                        :class="
                          bankName.length > 0 ? 'valid-bank' : 'invalid-bank'
                        "
                        v-model="bankName"
                        :form-input="$v.bankName"
                        :label="$t('L_bank_name')"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6
                  >
                    <!-- <v-flex
                      xs12
                      class="font-size--12 font-weight-normal be-sure-text-up"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      {{ $t('L_be_sure_to_select') }}
                    </v-flex> -->
                    <v-flex xs12 sm8>
                      <BankAccountNumber
                        bank-accountid="onetp_bank_acct_number"
                        v-model="bankAccountNumber"
                        :max-length-input="isCanadaPolicy ? 12 : 17"
                        :min-length-input="6"
                        :form-input="$v.bankAccountNumber"
                        :label="$t('L_account_number')"
                      />
                    </v-flex>
                    <v-flex xs12 sm9 zclass="verify-acc-no-up">
                      <VerifyBankAccountNumber
                        bank-accountid="onetp_verify_bank_acct_number"
                        v-model="verifyBankAcctNumber"
                        :max-length-input="isCanadaPolicy ? 12 : 17"
                        :min-length-input="6"
                        :form-input="$v.verifyBankAcctNumber"
                        :label="$t('L_verify_account_number')"
                      />
                    </v-flex>
                    <v-flex xs12 sm12 v-if="showDatePicker">
                      <DatePicker
                        v-model="datepicker"
                        :form-input="$v.datepicker"
                        @updateDate="changeDate"
                        @formatInput="formatInput"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions :class="showDatePicker?'':'addPB50'">
            <v-layout row wrap>
              <v-flex class="text-sm-right text-xs-center mt-4">
                <v-layout row wrap class="justify-end">
                  <v-flex
                    xs12
                    sm2
                    :class="$vuetify.breakpoint.smAndUp ? 'mr-4' : ''"
                  >
                    <v-btn block class="mb-3" color="secondary" @click="cancel">
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-btn
                      block
                      class="white--text mb-3"
                      :disabled="badForm"
                      @click="goToStepTwo"
                      color="primary"
                    >
                      {{ $t('L_continue') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import {
  Alert,
  RadioButtonGroup,
  BankNameInput,
  BankAccountNumber,
  VerifyBankAccountNumber,
  BankRoutingNumber,
  DatePicker,
} from '@primerica/vue-components';

import Config from '@/shared/util/config';
import Alerts from '@/components/Alerts.vue';
import {
  formatDate,
  currency,
} from '@primerica/vue-components/src/util/formatters';
import moment from 'moment';
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';
import messages from '../../../../../../../../../../../shared/validation/messages';

export const exceeded = val => val > 0 && val < 29;

export const notRouting = (value, vm) => value !== vm.routingNumber;

export const notSameDigits = (value) => {
  const regexp = /(\d)\1{7}/;
  return !regexp.test(value);
};

export const routingNumberNotFound = (value, vm) => {
  if (vm.isCanadaPolicy && vm.routingNumber.length === 8 && vm.bankName === '' && vm.processCompleted) {
    return false;
  } else if (!vm.isCanadaPolicy && vm.routingNumber.length === 9 && vm.bankName === '' && vm.processCompleted) {
    return false;
  } return true;
};

export const letterSpaceLetter = (value) => {
  const regexp = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
  return regexp.test(value);
};

export default {
  name: 'UpdateBankOne',
  components: {
    RadioButtonGroup,
    BankNameInput,
    BankAccountNumber,
    VerifyBankAccountNumber,
    BankRoutingNumber,
    DatePicker,
    Alert,
    Alerts,
  },
  data: () => ({
    bank: '',
    accountType: 0,
    accountHolder: '',
    bankAccountNumber: '',
    verifyBankAcctNumber: '',
    routingNumber: '',
    datepicker: '01',
    showDescription: false,
    accountHolderErrorMessages: [],
    processCompleted: false,
  }),
  props: {
    showDatePicker: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    ...mapGetters('policy', {
      bankNameResponse: 'bankNameResponse',
      paymentData: 'paymentData',
      policyData: 'currentPolicy',
    }),
    ...mapState('policy', {
      preLoadBank: 'preLoadBank',
    }),
    disableAccountHolderNameEdit() {
      return Config.get('payments.disableAccountHolderNameEdit');
    },
    bankName() {
      const name = this?.bankNameResponse
        ? this.bankNameResponse[0]?.name
        : null;
      return name || this.bank;
    },
    accountOptions() {
      return [
        {
          label: this.$t('L_savings_account'),
          value: this.bankAccountType === 3 ? 0 : 1,
        },
        {
          label: this.$t(this.checkingAccount),
          value: this.bankAccountType === 3 ? 1 : 0,
        },
      ];
    },
    badForm() {
      return this.$v.anyError || this.$v.$invalid;
    },
    clientName() {
      return this.policyData?.owner?.name;
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    checkingAccount() {
      return this.isCanadaPolicy ? 'L_chequing_account' : 'L_checking_account';
    },
    bankAccountType() {
      return this.policyData?.paymentInfo?.accountType;
    },
    formattedPaidToDate() {
      return formatDate(
        this.policyData?.paymentInfo?.paidToDate,
        this.dateFormat,
      );
    },
    draftAmount() {
      return currency(+this.policyData.paynowInfo.totalPaymentAmount, 2);
    },
    draftMessage() {
      const paidToDate = moment(this.policyData?.paymentInfo?.paidToDate).format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');
      if (paidToDate < currentDate) {
        return 'L_there_will_be_a_draft_generate_past_draft_date';
      }
      return 'L_there_will_be_a_draft_generate';
    },
    errorMessages() {
      const errors = [];
      const validationMessages = Object.assign(
        {},
        messages.accountHolder,
        this.validationMessages,
      );
      if (typeof this.formInput === 'undefined' || !this.formInput.$dirty) {
        return errors;
      }
      if (this.formInput.$error) {
        // If there is an error on the field
        Object.keys(validationMessages).forEach((type) => {
          if (
            this.formInput[type] === false &&
            validationMessages &&
            Object.prototype.hasOwnProperty.call(validationMessages, type)
          ) {
            errors.push(validationMessages[type]);
          }
        });
        if (errors.length === 0) {
          // If no messages have been added for this error
          errors.push(messages.generic.invalid);
        }
      }
      return errors.map(msg =>
        (typeof this.$t !== 'undefined'
          ? this.$t(msg, { length: this.minLengthInput })
          : msg));
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    validateAccountHolder() {
      let error1 = '';
      const tempAccountHolder = this.accountHolder;
      const regex = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
      this.accountHolderErrorMessages = [];
      if (tempAccountHolder.trim().length === 0) {
        error1 = this.$t('L_account_holder_name_is_required');
      } else if (!regex.test(tempAccountHolder)) {
        error1 = this.$t('L_account_holder_name_is_invalid');
      }
      if (error1.length > 0) {
        this.accountHolderErrorMessages.push(error1);
      }
    },
    async checkRouting() {
      if ((this.isCanadaPolicy && this.routingNumber.length === 8) || (!this.isCanadaPolicy && this.routingNumber.length === 9)) {
        this.bank = '';
        const country = this.isCanadaPolicy ? 'CA' : 'US';
        const payload = {
          policyNo: this.policyData?.contractId,
          routingNumber: this.routingNumber,
          country,
        };
        await this.getBankInfo(payload);

        if (this.$v.routingNumber.$invalid) {
          this.clearBank();
        }
      }
    },
    async getBankInfo(payload) {
      this.processCompleted = false;
      await this.$store.dispatch('policy/getBankInfo', payload).then((response) => {
        if (!response) {
          this.clearBank();
        } this.processCompleted = true;
      });
    },
    async goToStepTwo() {
      await this.checkRouting();
      if (this.bankName !== '') {
        const bankInfo = {
          policyNumber: this.policyData?.contractId,
          reqMode: '01',
          draftDay: this.datepicker,
          nextDraftDate: this.formattedPaidToDate,
          nextCycleDate: this.policyData?.paymentInfo?.nextCycleDate,
          // MYP-2480 Change to allow users to type the name of account holder
          accountName: this.accountHolder, // this.policyData?.owner?.name,
          routingNumber: this.routingNumber,
          accountNumber: this.bankAccountNumber,
          accountType: this.accountType,
          draftAmount: this.policyData?.paymentInfo?.monthlyPremium,
          bankName: this.bankName,
          isCanada: this.policyData?.isCanadaPolicy,
          accountTypeString:
            this.accountType === 0 ? this.$t(this.checkingAccount) : 'Savings',
        };
        this.$emit('goToStepTwo', bankInfo);
      }
    },
    clearBank() {
      this.$store.commit('policy/clearBank');
    },
    cancel() {
      try {
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t('L_monthly_bank_draft')?.replaceAll(' ', '-')}_CancelChange_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.clearBank();
      this.$emit('cancel');
    },
    formatInput() {
      if (this.datepicker.length === 1) {
        this.datepicker = `0${this.datepicker}`;
      }
    },
    changeDate(val) {
      this.datepicker = val;
      const data = {
        paidToDate: this.policyData?.paymentInfo?.paidToDate,
        selectedMonthlyPaymentDay: val,
      };
      this.$store
        .dispatch('policy/getDraftDescription', data)
        .then((response) => {
          if (response) {
            this.showDescription = response.showDescription;
          }
        });
    },
  },
  validations() {
    return {
      accountType: {
        required,
      },
      bankName: {
        required,
      },
      // accountHolder: {
      //   required,
      //   letterSpaceLetter,
      // },
      routingNumber: {
        required,
        notSameDigits,
        minLength: minLength(this.isCanadaPolicy ? 8 : 9),
        routingNumberNotFound,
      },
      bankAccountNumber: {
        required,
        notSameDigits,
        notRouting,
        minLength: minLength(6),
        maxLength: maxLength(this.isCanadaPolicy ? 12 : 17),
      },
      verifyBankAcctNumber: {
        required,
        notSameDigits,
        notRouting,
        sameAs: sameAs('bankAccountNumber'),
      },
      datepicker: {
        required,
        exceeded,
      },
    };
  },
  async mounted() {
    this.accountHolder = this.policyData?.owner?.name;
    if (this.preLoadBank) {
      this.bank = this.paymentData?.bankName;
      this.bankAccountNumber = this.paymentData?.accountNumber;
      this.verifyBankAcctNumber = this.paymentData?.accountNumber;
      this.routingNumber = this.paymentData?.routingNumber;
      this.accountType = this.bankAccountType === '3' ? 1 : 0;
      this.datepicker = this.policyData?._paymentInfo?._autoDraftPaymentDate;
    }
    // this.removeAlerts('payment');
  },
};
</script>

<style lang="stylus">
.addPT20 {
  padding-top:20px !important;
}
.addPT31 {
  padding-top:31px !important;
}
.addPB50 {
  padding-bottom: 50px !important;
}
.grey-ada-text {
  color: #737171;
}

.valid-bank.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
}

.valid-bank.v-text-field > .v-input__control > .v-input__slot:after {
  display: none !important;
}

.content-relative {
  position: relative;
}

.content-shift-up {
  position: absolute;
  top: -100px;
  right: 0;
  width: -webkit-fill-available;
}

.radio-up {
  margin-top: -20px;
}

.verify-acc-no-up {
  margin-top: -25px;
}

.be-sure-text-up {
  margin-top: -14px;
}

.routing-space {
  margin-top: 6px;
}
</style>

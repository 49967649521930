import moment from 'moment';
import { canadaRegex, quebecRegex, usRegex } from '@primerica/vue-components/src/components/Inputs/Date/DateValidation';

export const inputFormat = (canada, quebec) => {
  switch (true) {
    case canada && !quebec:
      return 'YYYYMMDD';
    case quebec:
      return 'DDMMYYYY';
    default:
      break;
  }
  return 'MMDDYYYY';
};
export const validDate = (value) => {
  if (value.length !== 8) {
    return false;
  }
  return !!(value.match(usRegex) || value.match(canadaRegex) || value.match(quebecRegex));
};

export const dateLessThan15DayOldOrInFuture = (canada, quebec) => (value) => {
  if (!value || !validDate(value)) {
    return true;
  }

  const max = moment().subtract(15, 'days');
  const tdate = moment(value, inputFormat(canada, quebec));
  // This is being added per D-03305 where if date is in future instead of showing
  // Child must at least 15 days old, we will show invalid message instead
  return tdate <= max;
};

export const minDate = (canada, quebec) => (value) => {
  if (!value || !validDate(value)) {
    return true;
  }
  const min = moment().subtract(24.5, 'years');
  const tdate = moment(value, inputFormat(canada, quebec));
  return tdate > min;
};

export const minDateNonNextGen = (canada, quebec) => (value) => {
  if (!value || !validDate(value)) {
    return true;
  }
  const min = moment().subtract(24.5, 'years');
  const tdate = moment(value, inputFormat(canada, quebec));
  return tdate > min;
};

export const minDateNextGen = (canada, quebec) => (value) => {
  if (!value || !validDate(value)) {
    return true;
  }
  const min = moment().subtract(17.5, 'years');
  const tdate = moment(value, inputFormat(canada, quebec));
  return tdate > min;
};

export const maxDate = (canada, quebec) => (value) => {
  if (!value || !validDate(value)) {
    return true;
  }
  const tdate = moment(value, inputFormat(canada, quebec));
  const max = moment().subtract(15, 'days');
  const now = moment();
  // This is being added per D-03305 where if date is in future instead of showing
  // Child must at least 15 days old, we will show invalid message instead
  if (tdate > now) {
    return true;
  }

  return tdate < max;
};


export const dateBetween18And100 = (canada, quebec) => (value) => {
  // return false shows error on front end
  // Checking for null, undefined or empty value if so return false
  if (!value) {
    return false;
  }
  let regex = usRegex;
  if (canada) {
    regex = canadaRegex;
  }
  if (quebec) {
    regex = quebecRegex;
  }
  const match = value && value.match(regex);
  // if value doesn't match the regex match will be null so !!(match) will be false
  if (!!(match) === false) {
    return false;
  }
  const tdate = moment(value, inputFormat(canada, quebec));
  const min = moment().subtract(18, 'years');
  const max = moment().subtract(100, 'years').subtract(1, 'days');
  // date entered is before 100 years or after 18 years i.e less than 18 years return false
  if (tdate < max || tdate >= min) {
    return false;
  } return true;
};

export const bornOnOrAfterIssue = (issueDate, canada, quebec) => (value) => {
  if (!issueDate || !value || !validDate(value)) {
    return true;
  }
  const date = moment(value, inputFormat(canada, quebec));
  const issued = moment(issueDate, 'MM/DD/YYYY');
  return date >= issued;
};


export const dateFormat = value => moment(value, 'MMDDYYYY').format('YYYY-MM-DD');


export const formatFromUS = (date) => {
  const year = date.slice(4);
  const month = date.slice(0, 2);
  const day = date.slice(2, 4);
  return `${year}-${month}-${day}`;
};

export const formatFromCA = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return `${year}-${month}-${day}`;
};

export const formatFromQuebec = (date) => {
  const year = date.slice(4);
  const month = date.slice(2, 4);
  const day = date.slice(0, 2);
  return `${year}-${month}-${day}`;
};

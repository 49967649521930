<template>
  <div
    @click="clicked"
    :class="[{'clickable': isClickable }, item.class]"
    v-html="item.markup || itemText"
  />
</template>

<script>
export default {
  props: {
    item: {
      default: null,
      type: Object,
    },
  },
  computed: {
    isClickable() {
      return this.item && this.item.clickable;
    },
    itemText() {
      if (this.item && this.item.text) {
        return this.item.text;
      }
      return this.$t('L_na');
    },
  },
  methods: {
    clicked() {
      if (this.item.clickable) {
        this.$emit('click');
      }
      if (this.item.linkable) {
        this.$emit('link');
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>

<i18n src='./locales.json'></i18n>

<template>
  <v-flex
    class="footer-offset"
    xs12
    card
  >
    <v-flex
      card-wrapper
    >
      <v-flex
        xs12
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            lg8
            white
            class="bottom-left"
          >
            <v-layout
              row
              class="guidance-panel"
            >
              <v-flex xs12>
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    class="header"
                  >
                    <v-flex
                      xs6
                      sm6
                    >
                      <h3
                        v-html="$t('L_life_guidance_calculator')"
                        role="presentation"
                        class="heading_three text-xs-center pt-4 mt-3 ml-3 pl-1 font-weight-bold text-uppercase text--white"
                      />
                    </v-flex>
                  </v-flex>
                  <v-layout
                    class="rule-of-thumb"
                    row
                    pa-3
                    align-center
                  >
                    <v-flex>
                      <img
                        class="mr-3"
                        src="./assets/md-rule.svg"
                        alt="rule"
                        style="float: left;"
                      >
                    </v-flex>
                    <v-flex>
                      <span
                        role="heading"
                        aria-level="2"
                        class="font-size--16 font-weight-medium rule-heading"
                      >{{ $t('L_rule_of_thumb') }}</span>
                      <span
                        class="font-size--14 rule-subheading"
                      >
                        <br>
                        {{ $t('L_rule_subheading') }}
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-flex
                    class="guidance-text"
                    pl-4
                    ml-2
                    pt-4
                    mt-2
                    mb-4
                    pb-2
                  >
                    <div
                      class="heading_three"
                    >
                      {{ $t('L_when_you_die') }}
                    </div>
                    <div
                      class="heading_three mt-4"
                    >
                      {{ $t('L_use_guidance') }}
                    </div>
                    <div
                      class="heading_three mt-4 mb-2 font-weight-medium"
                    >
                      {{ $t('L_adjust_amounts') }}
                    </div>
                  </v-flex>
                </v-layout>
                <v-expansion-panel
                  v-model="panel"
                  role="presentation"
                  expand
                >
                  <v-expansion-panel-content
                    role="presentation"
                    tabindex="-1"
                  >
                    <h2
                      id="immediate_cash_need_container"
                      slot="header"
                      class="heading_two"
                    >
                      <img
                        class="heading-icon"
                        src="./assets/immediate.svg"
                        alt=""
                        role="presentation"
                      >
                      <span
                        ref="cash"
                        role="button"
                        tabindex="0"
                        :aria-expanded="`${panel[0]}`"
                        id="immediate_cash_need_header"
                      >
                        {{ $t('L_immediate_cash_needs') }}
                      </span>
                    </h2>
                    <v-icon slot="actions">
                      {{ $vuetify.icons.expand }}
                    </v-icon>
                    <ImmediateCashNeeds
                      v-if="!loading"
                      :dime-data="immediateCashNeed"
                      @updated="onCashUpdate"
                      @next="nextPanel"
                      @include-spouse="onSpouseIncluded"
                    />
                  </v-expansion-panel-content>
                  <v-expansion-panel-content
                    role="presentation"
                    tabindex="-1"
                  >
                    <h2
                      id="income_replacement_container"
                      slot="header"
                      class="heading_two"
                    >
                      <img
                        class="heading-icon"
                        src="./assets/income.svg"
                        alt=""
                        role="presentation"
                      >
                      <span
                        ref="income"
                        role="button"
                        tabindex="0"
                        :aria-expanded="`${panel[1]}`"
                        id="income_replacement_header"
                      >
                        {{ $t('L_income_replacement') }}
                      </span>
                    </h2>
                    <v-icon slot="actions">
                      {{ $vuetify.icons.expand }}
                    </v-icon>
                    <OngoingExpenses
                      v-if="!loading"
                      :dime-data="incomeReplacement"
                      id="income_replacement_panel"
                      v-model="panels.incomeReplacement"
                      @updated="onIncomeUpdate"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            md4
            class="bottom-right"
            grey
            lighten-3
          >
            <Sidebar
              :current-coverage="currentCoverage"
            />
            <a
              class="scroll-to-top text-xs-center hidden-md-and-down"
              :class="{'show':showScrollButton}"
              role="button"
              tabindex="0"
              @keypress.enter="$event.target.click()"
              @click="scrollToTop()"
            >
              <v-icon>keyboard_arrow_up</v-icon><br>
              <h6
                role="presentation"
                class="heading_six font-weight-medium"
              >{{ $t('L_back_to_top') }}</h6>
            </a>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import { Achievement, Slider, Ada } from '@primerica/vue-components';
import bff from '@/shared/api/bff';
import CurrencyDisplay from './components/CurrencyDisplay.vue';
import ImmediateCashNeeds from './components/ImmediateCashNeeds/ImmediateCashNeeds.vue';
import OngoingExpenses from './components/Ongoing Expenses/OngoingExpenses.vue';
import GuidancePill from './components/GuidancePill.vue';
import OtherCoverage from './components/OtherCoverage/OtherCoverage.vue';
import Sidebar from './components/Sidebar/Sidebar.vue';

export const formatNumber = number => Intl.NumberFormat('en-US', {
  style: 'decimal',
}).format(number);

export default {
  name: 'GuidanceCalculator',
  components: {
    Sidebar,
    OtherCoverage,
    GuidancePill,
    OngoingExpenses,
    ImmediateCashNeeds,
    CurrencyDisplay,
    Slider,
    Achievement,
  },
  data: () => ({
    amount: 0,
    loading: false,
    panel: [true, true],
    incomeReplacement: {
      you: {
        need: 0,
        years: 15,
      },
      spouse: {
        need: 0,
        years: 15,
      },
    },
    spouseOngoingExpenses: 0,
    immediateCashNeed: {
      you: {
        mortgage: 0,
        debt: 0,
        education: 0,
        funeral: 8000,
      },
      spouse: {
        mortgage: 0,
        debt: 0,
        education: 0,
        funeral: 8000,
      },
    },
    vertical: true,
    panels: {
      immediateCashNeeds: true,
      incomeReplacement: false,
    },
    debounce: undefined,
    yourCalculationDebounce: undefined,
    spouseCalculationDebounce: undefined,
    spouseCalculationIncluded: false,
    scrollListener: undefined,
    showScrollButton: false,
  }),
  validations: {
    amount: {},
  },
  computed: {
    ...mapGetters('dime', {
      dimeData: 'dimeData',
      includeSpouse: 'includeSpouse',
    }),
    ...mapGetters('policy', {
      activePrimaryCoverage: 'activePrimaryCoverage',
      activeSpouseCoverage: 'activeSpouseCoverage',
    }),
    currentCoverage() {
      return {
        you: this.activePrimaryCoverage || 0,
        spouse: this.activeSpouseCoverage || 0,
      };
    },
  },
  methods: {
    initialize(data) {
      this.immediateCashNeed.you.mortgage = data?.primary?.mortgage || 0;
      this.immediateCashNeed.you.debt = data?.primary?.debt || 0;
      this.immediateCashNeed.you.education = data?.primary?.education || 0;
      this.immediateCashNeed.you.funeral = data?.primary?.funeral || 0;
      this.incomeReplacement.you.need = data?.primary?.incomeGoal || 0;
      this.incomeReplacement.you.years = data?.primary?.yearsOfNeed || 0;
      this.incomeReplacement.spouse.need = data?.spouse?.incomeGoal || 0;
      this.incomeReplacement.spouse.years = data?.spouse?.yearsOfNeed || 0;
      this.spouseCalculationIncluded = data?.primary?.spouseIncluded === 'Y';
      if (this.spouseCalculationIncluded) {
        this.immediateCashNeed.spouse.mortgage = data?.spouse?.mortgage || 0;
        this.immediateCashNeed.spouse.debt = data?.spouse?.debt || 0;
        this.immediateCashNeed.spouse.education = data?.spouse?.education || 0;
        this.immediateCashNeed.spouse.funeral = data?.spouse?.funeral || 0;
        this.incomeReplacement.spouse.need = data?.spouse?.incomeGoal || 0;
        this.incomeReplacement.spouse.years = data?.spouse?.yearsOfNeed || 0;
        this.calculateSpouseDime();
      }
      this.calculateYourDime();
      this.loading = false;
    },
    onSpouseIncluded(spouseIncluded) {
      if (spouseIncluded && !this.spouseCalculationIncluded) {
        this.immediateCashNeed.spouse = Object.assign({}, this.immediateCashNeed.you);
        this.immediateCashNeed = Object.assign({}, this.immediateCashNeed);
        this.spouseCalculationIncluded = true;
        this.calculateSpouseDime();
      }
      this.$store.dispatch('dime/setIncludeSpouse', !!spouseIncluded);
      this.saveDimeData();
    },
    scrollToIncomeReplacement() {
      const pos = this.$vuetify.breakpoint.xsOnly ? 950 : 1200;
      this.$vuetify.goTo(pos, {
        easing: 'easeInOutCubic',
        duration: 300,
      });
    },
    nextPanel() {
      window.BrowserAgentExtension.logTextMetric({
        key: 'Click',
        value: 'Guidance.Calculator.Next',
      });
      this.panel = [false, true];
      window.setTimeout(this.scrollToIncomeReplacement, 300);
    },
    onCashUpdate(event) {
      this.immediateCashNeed = event;
      this.saveDimeData();
      this.calculateYourDime();
      this.calculateSpouseDime();
    },
    onIncomeUpdate(event) {
      this.incomeReplacement = event;
      this.saveDimeData();
      this.calculateYourDime();
      this.calculateSpouseDime();
    },
    saveCallback(data) {
      return () => {
        bff.saveDimeData(data);
      };
    },
    calculateYouCallback() {
      this.$store.dispatch('dime/calculateYou', {
        incomeNeed: this.incomeReplacement.you.need,
        yearsOfNeed: this.incomeReplacement.you.years,
        mortgage: this.immediateCashNeed.you.mortgage,
        debt: this.immediateCashNeed.you.debt,
        education: this.immediateCashNeed.you.education,
        funeral: this.immediateCashNeed.you.funeral,
        spouseIncluded: this.includeSpouse,
      });
    },
    calculateSpouseCallback() {
      this.$store.dispatch('dime/calculateSpouse', {
        incomeNeed: this.incomeReplacement.spouse.need,
        yearsOfNeed: this.incomeReplacement.spouse.years,
        mortgage: this.immediateCashNeed.spouse.mortgage,
        debt: this.immediateCashNeed.spouse.debt,
        education: this.immediateCashNeed.spouse.education,
        funeral: this.immediateCashNeed.spouse.funeral,
        spouseIncluded: (this.spouseCalculationIncluded) ? true : this.includeSpouse,
      });
    },
    calculateYourDime() {
      if (typeof (this.yourCalculationDebounce) !== 'undefined') {
        window.clearTimeout(this.yourCalculationDebounce);
      }
      this.yourCalculationDebounce = window.setTimeout(this.calculateYouCallback, 1000);
    },
    calculateSpouseDime() {
      if (typeof (this.spouseCalculationDebounce) !== 'undefined') {
        window.clearTimeout(this.spouseCalculationDebounce);
      }
      this.spouseCalculationDebounce = window.setTimeout(this.calculateSpouseCallback, 1000);
    },
    saveDimeData() {
      if (typeof (this.debounce) !== 'undefined') {
        window.clearTimeout(this.debounce);
      }
      const dimeData = {
        primary: {
          currentCoverage: this.currentCoverage.you,
          ...this.immediateCashNeed.you,
          ...this.incomeReplacement.you,
          spouseIncluded: this.includeSpouse,
        },
        spouse: {
          currentCoverage: this.currentCoverage.spouse,
          ...this.immediateCashNeed.spouse,
          ...this.incomeReplacement.spouse,
          spouseIncluded: this.includeSpouse,
        },
      };
      this.debounce = window.setTimeout(this.saveCallback(dimeData), 2000);
    },
    onScroll() {
      const scrollPosition = document.body.scrollTop;
      const windowHeight = document.body.clientHeight;
      const documentHeight = document.body.offsetHeight;
      this.showScrollButton = (documentHeight - scrollPosition) < (windowHeight + 50);
    },
    scrollToTop() {
      this.$vuetify.goTo(0, { duration: 300, easing: 'easeInOutCubic' });
    },
  },
  mounted() {
    this.scrollListener = window.addEventListener('scroll', this.onScroll);
    this.loading = true;
    this.$store.dispatch('dime/getData').then(this.initialize);
    if (!document.querySelector('#app > #footer_wrapper')) {
      const tabletFooter = document.getElementById('tablet_footer');
      const mobileFooter = document.getElementById('mobile_footer');
      const appWrapper = document.getElementById('app');
      const footerWrapper = document.createElement('div');
      footerWrapper.id = 'footer_wrapper';
      footerWrapper.classList.add('gray', 'lighten-3', 'bottom-right', 'hidden-lg-and-up');
      footerWrapper.style.backgroundColor = 'rgb(238,238,238)';
      footerWrapper.style.position = 'fixed';
      footerWrapper.style.bottom = '0';
      footerWrapper.style.right = '0';
      footerWrapper.style.left = '0';
      footerWrapper.append(tabletFooter);
      footerWrapper.append(mobileFooter);
      appWrapper.append(footerWrapper);
    }
    Ada.addKeyClick(this.$refs.cash);
    Ada.addKeyClick(this.$refs.income);
  },
  destroyed() {
    const footer = document.querySelector('#app > #footer_wrapper');
    if (footer) {
      footer.remove();
    }
    window.removeEventListener('scroll', this.scrollListener);
  },
};
</script>

<style lang="stylus" scoped>
$superSmall = "only screen and (max-width: 399px)"
$mdAndDown = "only screen and (max-width: 1023px)"
$lgAndUp = "only screen and (min-width: 1024px)"
$smAndDown = "only screen and (max-width: 768px)"
$xsOnly = "only screen and (max-width: 500px)"
.card
  border-radius: 0 0 3px 3px;

.left-panel
  border-radius: 0 0 0 3px;

.bottom
  border-radius: 0
  &-right
    border-bottom-right-radius: 3px;
    @media $smAndDown
      border-bottom-right-radius: 3px;
      left: 3px;
  &-left
    border-bottom-left-radius: 3px;
    @media $smAndDown
      width: 100% !important
      border-radius: 0

@media only screen and (max-width: 1023px) and (min-width: 768px)
  .bottom-right
      display none;

.footer-offset
  @media $mdAndDown
    margin-bottom:110px;
  @media $xsOnly
    margin-bottom: 55px;

.text--white {
  color: white;
  font-size: 26px !important;
  line-height: 1.4em;
  @media $xsOnly {
    font-size: 20px !important;
    padding-top: 0 !important;
  }
}

.header {
  height: 144px;
  background-image: url(assets/family2.png);
  background-position: center;
  background-size: cover;
  position: relative;
  @media $xsOnly {
    height: 89px !important;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.rule-of-thumb {
  position: relative;
  background-color: #f3f3f3;
}

.scroll-to-top
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 76px;
  width: 76px;
  opacity: 0;
  transition: opacity 300ms;
  &.show
    opacity: 1;
  background-color: #02579e;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.3) !important;
  h6.heading_six
    margin-top: -6px;
    color: #fff;
  &>i
    line-height: 0.6em;
    color: #fff !important;
    font-size: 4em !important;

/deep/ input
  &#mortgage_display,
  &#years_display
    text-align center !important

/deep/ .slider-tooltip
  margin-top: -42px

/deep/ .v-btn--small
  text-transform: none
  margin: 0 0 0 8px

/deep/ img.heading-icon
  margin: 0 16px -10px 0
  @media $superSmall
    height: 1.8em;
    margin-right: 10px;

/deep/ .v-text-field.text-xs-center
  input {
    text-align: center !important
  }

.v-expansion-panel
  box-shadow: none
/deep/ .v-expansion-panel__header
  border-top: 1px solid #cccccc;
  padding-top 32px
  padding-bottom 32px
  .v-expansion-panel__header__icon
    i
      font-size: 2em !important;
  @media $superSmall
    padding: 16px;
    h2.heading_two
      font-size: 18px;

/deep/ .expansion-panel-header
  @media $superSmall
    padding: 16px !important;
    h2.heading_two
      font-size: 18px !important;

.v-expansion-panel__container
  border-top: none !important;

@media $superSmall
  .rule-of-thumb,
  .guidance-text {
    h3.heading_three {
      font-size: 18px !important;
    }
    padding-left: 16px !important;
    padding-right: 22px !important
  }

</style>

<i18n src='./locales.json'></i18n>

<template>
  <v-layout row wrap>
    <v-flex xs12 md7 class="content">
      <div class="font-size--20">
        {{ $t('L_by_following_your_debt_stacking_plan') }}
        <v-tooltip max-width="450px" html="true" open-on-click :open-on-hover="false" right close-delay="2000">
          <template v-slot:activator="{ on }">
            <v-icon color="blue darken-2" dark small v-on="on" class="moveiconup">
              help
            </v-icon>
          </template>
          <span class="toolTipBox">
            <p v-html="$t('L_primerica_debt_stacking_methodology')" />
          </span>
        </v-tooltip>
      </div>
      <v-layout
        row
        wrap
        class="header text-xs-center font-size--12"
      >
        <v-flex xs4>
          {{ $t('L_debt_free_age') }}
        </v-flex>
        <v-flex xs4>
          {{ $t('L_years_sooner') }}
        </v-flex>
        <v-flex xs4>
          {{ $t('L_interest_avoided') }}
        </v-flex>
      </v-layout>

      <v-layout
        row
        wrap
        class="data text-xs-center font-size--30"
      >
        <v-flex xs4>
          {{ debtFreeAgeWStacking }}
        </v-flex>
        <v-flex xs4>
          {{ yearsSooner }}
        </v-flex>
        <v-flex xs4>
          {{ interestAvoided }}
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 md5 class="learn-more">
      <v-btn
        block
        outline
        color="white"
        @click="goToViewPlan"
        :aria-label="$t('L_view_plan')"
        class="setBlueBackground"
        style="background-color: #063562 !important;"
      >
        {{ $t('L_view_plan') }}
      </v-btn>
      <v-flex xs12 class="importantDisclosure cursorPointer" text-xs-center @click="openImportantDisclosures">
        {{ $t('L_important_assumption_and_disclosures') }}
      </v-flex>
    </v-flex>

    <v-spacer class="mb-2" />
  </v-layout>
</template>
<script>
import { mapGetters } from 'vuex';
import Config from '@/shared/util/config';
import { openInNewWindow } from '@/mixins/openNewWindow';

export default {
  name: 'DebtSolutionsV03Top',
  data() {
    return {
      document: {
        title: '',
        content: '',
        loading: false,
      },
    };
  },
  mixins: [openInNewWindow],
  computed: {
    ...mapGetters({
      planWOStacking: 'debtsolutions/planWOStacking',
      planWStacking: 'debtsolutions/planWStacking',
    }),
    debtFreeAgeWStacking() {
      return this.planWStacking?.planTotals?.primaryDebtPayoffAge || '';
    },
    WOStackingMonth() {
      return (12 * this.planWOStacking?.planTotals?.debtPayoffYears) + this.planWOStacking?.planTotals?.debtPayoffMonths;
    },
    WStackingMonth() {
      return (12 * this.planWStacking?.planTotals?.debtPayoffYears) + this.planWStacking?.planTotals?.debtPayoffMonths;
    },
    yearsSooner() {
      const yearsSoonerVal = Math.ceil((this.WOStackingMonth - this.WStackingMonth) / 12);
      return (Number.isNaN(yearsSoonerVal)) ? '' : yearsSoonerVal;
    },
    interestAvoided() {
      const diff = this.planWOStacking?.planTotals?.projectedInterestPaid - this.planWStacking?.planTotals?.projectedInterestPaid;
      return (!Number.isNaN(diff))
        ? this.$n(diff, 'currencyNoCents', 'en-US')
        : '';
    },
  },
  methods: {
    goToViewPlan() {
      if (this.$route.path !== '/debtsolutions/myplan') {
        this.$router.push({ name: 'MyPlan' });
      }
    },
    async openImportantDisclosures() {
      await this.openInNewWindow({ url: `${Config.get('content_stack')}/blt676ecb292039a611/MISC-006.pdf`, target: '_blank' });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .v-btn
    border: 2px solid #063562;
    background-color: #063562;
    padding-right:15px !important;
  .v-btn__content
    color: #063562;
  .textBlue
    color: #063562;
  .learn-more
    padding: 0 15px;
  .v-btn
    background-color: #063562 !important
    color: white;
    border-color: white;
  .font-weight-medium
    font-weight: 500;
  .font-size--30
    font-size: 30px;
  .toolTipBox
    height: 320px;
    width: 432px;
    position: relative;
  .smallWidthTooltip
    max-width: 300px;
  .largeWidthTooltip
    max-width: 452px;
  .moveiconup
    position: absolute;
    margin-top: -3px;
  .importantDisclosure
    color: #02579e;
    font-size: 16px;
    font-weight: 600;
  .helpXSBox
    max-width:330px !important;
  .helpLargerBox
    max-width:432px !important;
  .cursorPointer
    cursor: pointer;
  .setBlueBackground
    background-color: #063562 !important;
    background: #063562 !important;
    color: white !important;
</style>

import { render, staticRenderFns } from "./VerifyPhoneWrapper.vue?vue&type=template&id=7833c1f4&scoped=true"
import script from "./VerifyPhoneWrapper.vue?vue&type=script&lang=js"
export * from "./VerifyPhoneWrapper.vue?vue&type=script&lang=js"
import style0 from "./VerifyPhoneWrapper.vue?vue&type=style&index=0&id=7833c1f4&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7833c1f4",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FVerifyPhoneWrapper%2FVerifyPhoneWrapper.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
<template>
  <v-flex xs12 mt-8>
    <TermLifeTitle />
    <v-card>
      <LoadingIndicator :loading="loading" />
      <v-layout row wrap>
        <TerminatedPolicy v-if="noLifeOrTerminatedPolicy===1" />
        <PrePurchaseLifeCard v-else-if="noLifeOrTerminatedPolicy===2" />
      </v-layout>
    </v-card>
    <div v-if="!loading && !(noLifeOrTerminatedPolicy===1) && !(noLifeOrTerminatedPolicy===2)">
      <div v-for="(item, index) in policies"
           :key="index + 'termLife'"
      >
        <TermLifeCard :index-policy="index" />
      </div>
      <div>
        <!-- Show the Are you Properly Protected? Section -->
        <TermLifeCard :index-policy="-1" />
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import TermLifeCard from './components/TermLifeCard/TermLifeCard.vue';
import PrePurchaseLifeCard from './components/PrePurchaseLifeCard/PrePurchaseLifeCard.vue';
import TermLifeTitle from './components/TermLifeTitle/TermLifeTitle.vue';
import RestrictedPolicy from './components/RestrictedPolicy/RestrictedPolicy.vue';
import TerminatedPolicy from './components/TerminatedPolicy/TerminatedPolicy.vue';

export default {
  name: 'TermLife',
  components: {
    TermLifeCard,
    RestrictedPolicy,
    TermLifeTitle,
    PrePurchaseLifeCard,
    LoadingIndicator,
    TerminatedPolicy,
  },
  computed: {
    ...mapGetters(['getAgent']),
    ...mapGetters({
      policies: 'policy/policies',
      isRestricted: 'policy/isRestricted',
      loading: 'policy/policyInfoLoading',
      hasTerminatedPolicies: 'policy/hasTerminatedPolicies',
      policyData: 'policy/currentPolicy',
    }),
    noLifePolicy() {
      return !!(this.policies.length === 0);
    },
    noLifeOrTerminatedPolicy() {
      if (this.noLifePolicy) {
        if (this.hasTerminatedPolicies === true) {
          return 1;
        } return 2;
      } return -1;
    },

  },
  methods: {
    ...mapActions('policy', ['getPolicyOverview']),
  },
  // created() {
  //   this.getPolicyOverview();
  // },
};
</script>


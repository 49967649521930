import axios from 'axios';
import { Session } from '@/shared/util/session';
import { v4 as uuid } from '../shared/util/uuid';
import { getAuthClient } from './auth';
import { DynatracePlugin } from './dynatracePlugin';
import store from '../store/index';

let reqIndex = 0;

const addHeader = (config, header, value) => {
  config.headers[header] = value;
};

const setCorrelationId = () => {
  if (!Session.get('X-Correlation-ID')) {
    Session.set('X-Correlation-ID', uuid());
  }
};

const dynatraceResponseInterceptor = (config) => {
  try {
    // try and catch sensitive data
    const stringifiedData = JSON.stringify(config?.data) || '';
    const hasSensitiveData = stringifiedData.toLowerCase().includes('persontaxid') || stringifiedData.toLowerCase().includes('ssn') || stringifiedData.toLowerCase().includes('sin');
    if (hasSensitiveData) {
      const i = DynatracePlugin.getInstance().enterAction(`PC2_WARNING: possible sensitive data on: ${config?.config?.url}`);
      DynatracePlugin.getInstance().leaveAction(i);
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return config;
};
const dynatraceResponseErrorInterceptor = (err) => {
  try {
    DynatracePlugin.getInstance().reportCustomError('PC2_XHR_ERR', `${err?.response?.status} -- ${err?.config?.method} -- ${err?.config.url} : ${err?.message}`, null, false);
    // eslint-disable-next-line no-empty
  } catch (error) {}
  throw err;
};

const sessionManagerRequestInterceptor = async (config) => {
  // TODO: have a better list of urls to bypass and a better way to track the list
  if (config.url?.includes('/pub/auditlog') || config.url?.includes('/revoke')) return config;
  const authClient = getAuthClient();
  const accessToken = await authClient.getAccessToken();
  if (accessToken && config.headers) {
    addHeader(config, 'authorization', `Bearer ${accessToken}`);
  }
  return config;
};

const sessionManagerRequestErrorInterceptor = err => Promise.reject(err);

const sessionManagerResponseInterceptor = response => response;

const sessionManagerResponseErrorInterceptor = async (err) => {
  if (err?.response?.status === 401) {
    const authClient = getAuthClient();
    const authenticated = await !authClient.isAuthenticated();
    if (await !authenticated) {
      await authClient.logout();
      return Promise.reject(err);
    }
    return axios.request(err.config); // retry
  }
  return Promise.reject(err);
};

const metadataRequestInterceptor = (config) => {
  Session.resetExpiration(); // ? for session timeout dialog

  addHeader(config, 'x-correlation-id', `${Session.get('X-Correlation-ID')}:${reqIndex++}`);
  addHeader(config, 'deviceDNA', store.state.deviceDNA);
  addHeader(config, 'deviceid', Session.get('deviceId', true));
  /*
  session and device related info
  x-pri-device='iPhone 14'
  x-pri-os='17.3'
  x-pri-platform='Android'
  x-pri-app='0.2.50'
  */
  const { appVersion, deviceInfo } = store.getters.userSessionMeta;
  addHeader(config, 'x-pri-device', deviceInfo.deviceModel);
  addHeader(config, 'x-pri-os', deviceInfo.deviceOsVersion);
  addHeader(config, 'x-pri-platform', deviceInfo.devicePlatform);
  addHeader(config, 'x-pri-app', appVersion);

  return config;
};

const generatePlugin = _axios => ({
  install() {
    setCorrelationId();
    // ? use session manager to handle passing tokens
    _axios.interceptors.request.use(sessionManagerRequestInterceptor, sessionManagerRequestErrorInterceptor);
    // ? use session manager to handle 401 requests by refreshing tokens
    _axios.interceptors.response.use(sessionManagerResponseInterceptor, sessionManagerResponseErrorInterceptor);
    // ? use dynatrrase to tag response and errors
    _axios.interceptors.response.use(dynatraceResponseInterceptor, dynatraceResponseErrorInterceptor);
    // ? setup some monitoring metadata and misc tasks
    _axios.interceptors.request.use(metadataRequestInterceptor);
  },
});

export default generatePlugin;

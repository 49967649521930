import Config from '@/shared/util/config';
import axios from 'axios';

const getAlerts = async () => {
  const response = await axios.get(`${Config.get('endpoints.bff')}/alerts`);
  return response;
};

const dismissAlert = async (alertId) => {
  const response = await axios.put(`${Config.get('endpoints.bff')}/alerts/${alertId}/dismiss`);
  return response;
};

const completeAlert = async (alertId, payload) => {
  const response = await axios.put(`${Config.get('endpoints.bff')}/alerts/${alertId}/complete`, payload);
  // TODO: remove mock
  /* const response = {
    status: 200,
    data: {
      alertId: `${alertId}`,
      status: 'ok',
    },
  }; */
  return response;
};

const getDocuments = async (policyIds) => {
  const query = policyIds ? `?policyIds=${policyIds}` : '';
  const response = await axios.get(`${Config.get('endpoints.bff')}/documents${query}`);
  return response;
};

const getAppInfo = async () => {
  const response = await axios.get(`${Config.get('endpoints.bff')}/pub/info`);
  return response;
};

const postAuditLog = async (auditlog) => {
  /*
  TODO: remove below and add n confluence and add link here
  SAMPLE auditlog
  {
    "sessionId": "444", // optional
    "actionId": "MobileApp_Device_FailureAction", // optional
    "details": "Failed to login2", // Additional message stored - optional
    "username": "mypriteam+som@gmail.com", // optional - Username field value need to be passed from UI. Not able to get from token
    "message": "AndroidSystemPasscodeUnavailable", // Error type - optional
    "deviceId": "dev123456245", // optional
    "metadata": { // optional
        "deviceName": "iPhone 14",
        "osVersion": "17.3",
        "platform": "Android"
    }
  }
   */
  const response = await axios.post(`${Config.get('endpoints.bff')}/pub/auditlog`, auditlog);
  return response.data;
};

export default {
  async bootstrap() {
    const response = await axios.get(`${Config.get('endpoints.bff')}/bootstrap`);
    return response.data;
  },

  async getInvestments(country, features) {
    const query = !features ? `?country=${country}` : `?country=${country}&features=${features}`;
    const response = await axios.get(`${Config.get('endpoints.bff')}/investments${query}`);
    return response.data;
  },

  async getCommunicationsPreferences(policies) {
    const url = `${Config.get('endpoints.bff')}/communications/preferences?policyNumbers=${policies.join(',')}`;
    const response = await axios.get(url);
    return response.data;
  },

  async updatePaperless(selections, locale, setEcommDisplayed = false) {
    const settings = Object.keys(selections).map(key => ({
      policyId: key,
      communicationChannel: selections[key],
    }));
    const options = {};
    if (setEcommDisplayed) {
      options.headers = { setEcommDisplayed: 'true', preferredLang: locale };
    } else {
      options.headers = { preferredLang: locale };
    }
    return axios.patch(`${Config.get('endpoints.bff')}/communications/preferences`, settings, options);
  },

  async snoozeEcomm() {
    return axios.patch(`${Config.get('endpoints.bff')}/communications/snooze?type=ecomm`);
  },
  async snoozeVerifyPhone() {
    return axios.patch(`${Config.get('endpoints.bff')}/communications/snooze?type=phone`);
  },
  async getInvestmentStatementMetadata() {
    const response = await axios.get(`${Config.get('endpoints.bff')}/investments/statements`);
    return response.data;
  },

  async getInvestmentCalculator(scenario) {
    const response = await axios.post(`${Config.get('endpoints.bff')}/investments/calculator`, scenario);
    return response.data;
  },

  async getSamlToken(scenario) {
    const response = await axios.post(`${Config.get('endpoints.bff')}/investments/samltoken`, scenario);
    return response.data;
  },

  async getPolicyOverview(policyIds) {
    const query = policyIds ? `?policyIds=${policyIds}` : '';
    return axios.get(`${Config.get('endpoints.bff')}/policies${query}`);
  },

  async getDimeData() {
    return axios.get(`${Config.get('endpoints.bff')}/dime`);
  },

  async dimeCalc(dimeInput) {
    const res = await axios.put(`${Config.get('endpoints.bff')}/dime/calculation`, dimeInput);
    return res.data;
  },

  async saveDimeData(data) {
    return axios.put(`${Config.get('endpoints.bff')}/dime`, data);
  },

  async getDebt() {
    return axios.get(`${Config.get('endpoints.bff')}/debt/scenarios`);
  },

  async postDebtAndCalculate(scenario) {
    return axios.post(`${Config.get('endpoints.bff')}/debt/postdebt`, scenario);
  },

  async updateContactInfo(info) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/contactInfo`, info);
  },

  updateMobileNumber(mobileInfo) {
    return axios.put(`${Config.get('endpoints.bff')}/customers/phone`, mobileInfo);
  },

  async verifyContactInfo(info) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/contactInfo/verify`, info);
  },

  async sendRepEmail(email) {
    return axios.post(`${Config.get('endpoints.bff')}/contact/agent`, email);
  },

  async addChildToRider(childRider) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/child/add`, childRider);
  },

  async getPaymentInfo(number) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/payment`, {
      headers: {
        policyNumber: number,
      },
    });
  },

  async getBankData(data) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/payment/info`, {
      headers: {
        policyNo: data.policyNo,
        routingNumber: data.routingNumber,
        country: data.country,
      },
    });
  },

  async submitAchPayment(data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/payment/submit`, data);
  },

  async updateBillingMode(data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/payment/billingmode/update`, data);
  },

  async getDraftDescription(data) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/draft/description`, {
      headers: {
        paidtodate: data.paidToDate,
        selectedday: data.selectedMonthlyPaymentDay,
      },
    });
  },

  async submitEcomms(data) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/ecomm/update`, data);
  },

  async pullPackages() {
    return axios.get(`${Config.get('endpoints.bff')}/crc/packages/summary`);
  },

  async pullPackage(policyId, packageId) {
    return axios.get(`${Config.get('endpoints.bff')}/crc/package/info`, {
      headers: {
        packageId,
        policyId,
      },
    });
  },

  async updatePackage(data) {
    return axios.put(`${Config.get('endpoints.bff')}/crc/package/submit`, data);
  },

  async getAgreement(type = 'U', locale, policyNumber) {
    return axios.get(`${Config.get('endpoints.bff')}/customers/agreements/get?policyNumber=${policyNumber}&locale=${locale}&type=${type}`);
  },

  async updateLanguagePreference(preferredLang) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/preferredlang`, { preferredLang });
  },

  async paymentSelection(data) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/paymentselection`, data);
  },

  async updatePassword(request) {
    return axios.put(`${Config.get('endpoints.bff')}/customers/password_change`, request);
  },

  async changeUserVerification(request) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/id/change-verification`, request);
  },

  async createChallenge(challengeSpec) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/challenges`, challengeSpec);
  },

  async requestUserIdChallenge(request) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/challenges`, request);
  },

  async updateUserID(request) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/id`, request);
  },

  async getAgents(ids) {
    const response = await axios.get(`${Config.get('endpoints.bff')}/agents/${ids.join(',')}`);
    return response.data;
  },

  async getTrustedDevices() {
    return axios.get(`${Config.get('endpoints.bff')}/devices/trusted-devices`);
  },

  async deleteTrustedDevices() {
    return axios.delete(`${Config.get('endpoints.bff')}/devices/trusted-devices`);
  },

  async ackPolicy(data) {
    return axios.patch(`${Config.get('endpoints.bff')}/policies/acknowledge`, data);
  },

  async getRightAnswersHelpUrl() {
    const rightanswerslink = Config.get('endpoints.rightanswerslink');
    const x = axios.get(rightanswerslink);
    await this.timeout(2500);
    return x;
  },

  async timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  async getBeneficiaries(policyNumber) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/${policyNumber}/beneficiaries`);
  },

  async setBeneficiaries(policyNumber, data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/${policyNumber}/beneficiaries`, { beneficiaries: data });
  },

  async setMonthlyWithDrawalDay(policyNumber, data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/${policyNumber}/payment/draftday`, { draftDay: data });
  },

  async updateTermsOfUse(agreementId) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/agreements/${agreementId}/version`);
  },
  getAlerts,
  dismissAlert,
  completeAlert,
  getDocuments,
  getAppInfo,
  postAuditLog,
};

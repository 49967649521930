import { render, staticRenderFns } from "./SidebarDesktop.vue?vue&type=template&id=074f3fc6"
import script from "./SidebarDesktop.vue?vue&type=script&lang=js"
export * from "./SidebarDesktop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../../../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fpolicy%2Fguidance%2Fcomponents%2FSidebar%2Fcomponents%2FSidebarDesktop%2FSidebarDesktop.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
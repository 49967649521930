<i18n src='./locales.json'></i18n>
<template>
  <v-content class="pt-0">
    <MessageDialog
      :show-dialog="dialog"
      :has-button-two="true"
      type="warning"
      :title="$t('L_cancel_withdrawal_day_change')"
      :message="$t('L_do_you_want_to_cancel_the_changes_to_withdrawal_day')"
      :button-two-label="$t('L_yes')"
      :button-one-label="$t('L_no')"
      :is-persistent="true"
      @buttonTwoAction="closeAndGoToPolicyDetails"
      @buttonOneAction="dialog=false"
    />
    <!-- Section Guide
    One Time Payment -    1 Payment Amount  2 Payment Method  3 Welcome Back
    ACH              -    1 Payment Amount  2 Payment Method  3 Review Payment 4 Confirmation
    -->
    <!-- This is for screen larger than xsOnly Horizontal Mode -->
    <v-stepper v-model="step" v-show="!$vuetify.breakpoint.smAndDown">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1" color="#3399ff" class="stepperTextSize">
          {{ $t('L_select_day') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2" color="#3399ff" class="stepperTextSize">
          {{ $t('L_review') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 3" step="3" color="#3399ff" class="stepperTextSize">
          {{ $t('L_confirmation') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <WDStepOne
            @closeAndGoToPolicyDetails="closeAndGoToPolicyDetails"
            @goToWDStepTwo="goToWDStepTwo"
            @cancelDialog="dialog=true"
            @selectDateIssueNextPayDateEarly="selectDateIssueNextPayDateEarly"
            @setDateForNextPayment="setDateForNextPayment"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <WDStepTwo
            :day-selected="daySelected"
            :date-issue-next-pay-date-early="dateIssueNextPayDateEarly"
            :date-for-next-payment="dateForNextPayment"
            @cancelDialog="dialog=true"
            @goToWDStepThree="goToWDStepThree"
            @goBackToWDStepOne="goBackToWDStepOne"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <div>
            <WDStepThree
              :day-selected="daySelected"
              :date-issue-next-pay-date-early="dateIssueNextPayDateEarly"
              :date-for-next-payment="dateForNextPayment"
              @cancelDialog="dialog=true"
            />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- This is for xsOnly Vertical Mode-->
    <v-stepper
      v-model="step"
      vertical
      v-show="$vuetify.breakpoint.smAndDown"
    >
      <v-stepper-step
        :complete="step > 1"
        step="1"
        color="#3399ff"
        class="stepperTextSize"
      >
        {{ $t('L_select_day') }}
      </v-stepper-step>

      <v-stepper-content step="1">
        <WDStepOne
          @closeAndGoToPolicyDetails="closeAndGoToPolicyDetails"
          @cancelDialog="dialog=true"
          @goToWDStepTwo="goToWDStepTwo"
          @selectDateIssueNextPayDateEarly="selectDateIssueNextPayDateEarly"
          @setDateForNextPayment="setDateForNextPayment"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2" color="#3399ff" class="stepperTextSize">
        {{ $t('L_review') }}
      </v-stepper-step>

      <v-stepper-content step="2">
        <WDStepTwo
          :day-selected="daySelected"
          :date-issue-next-pay-date-early="dateIssueNextPayDateEarly"
          :date-for-next-payment="dateForNextPayment"
          @cancelDialog="dialog=true"
          @goToWDStepThree="goToWDStepThree"
          @goBackToWDStepOne="goBackToWDStepOne"
        />
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3" color="#3399ff" class="stepperTextSize">
        {{ $t('L_confirmation') }}
      </v-stepper-step>

      <v-stepper-content step="3">
        <WDStepThree
          :day-selected="daySelected"
          :date-issue-next-pay-date-early="dateIssueNextPayDateEarly"
          :date-for-next-payment="dateForNextPayment"
          @cancelDialog="dialog=true"
        />
      </v-stepper-content>
    </v-stepper>
  </v-content>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';
import { currency } from '@primerica/vue-components/src/util/formatters';
import MessageDialog from '../../../components/MessageDialog.vue';
import WDStepOne from './components/WDStepOne.vue';
import WDStepTwo from './components/WDStepTwo.vue';
import WDStepThree from './components/WDStepThree.vue';

export default {
  name: 'WithDrawalDateLayout',
  components: {
    WDStepOne,
    WDStepTwo,
    WDStepThree,
    MessageDialog,
  },
  props: {
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    // alertContext: {
    //   type: String,
    //   required: false,
    //   default: 'go_paperless',
    // },
    // policynumber: {
    //   type: String,
    //   required: false,
    //   default: '',
    // },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
    persistent: true,
    dialog: false,
    step: 1,
    daySelected: '1',
    dateIssueNextPayDateEarly: -1,
    dateForNextPayment: '',
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'currentPolicy',
      'selectedPolicyIndex',
      'paymentData',
    ]),
    ...mapGetters({
      locale: 'getLocale',
    }),
    getTotalDue() {
      return currency(+this.currentPolicy?.paynowInfo?.totalPaymentAmount, 2);
    },
    getMinimumDue() {
      return currency(+this.currentPolicy?.paynowInfo?.minimumPaymentAmount, 2);
    },
  },
  methods: {
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'setPaymentStep', 'setWithDrawalDateStep']),
    async closeAndGoToPolicyDetails() {
      this.setWithDrawalDateStep(-1);
      this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
    },
    goToWDStepTwo(WithDrawalSelectedDay) {
      this.daySelected = WithDrawalSelectedDay;
      this.step = 2;
      this.setWithDrawalDateStep(2);
    },
    goToWDStepThree() {
      this.step = 3;
      this.setWithDrawalDateStep(3);
    },
    goBackToWDStepOne() {
      this.step = 1;
      this.setWithDrawalDateStep(1);
    },
    selectDateIssueNextPayDateEarly(val) {
      this.dateIssueNextPayDateEarly = val;
    },
    setDateForNextPayment(val) {
      this.dateForNextPayment = val;
    },
  },
};
</script>

<style lang="stylus" scoped>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
    padding-left: 5px;
  }
}
.stepperTextSize {
  zfont-size: 12px;
}
.v-stepper__header {
  overflow: auto;
  display: flex !important;
  flex-wrap:no-wrap;
  justify-content: left;
}
</style>

<i18n src='./locales.json'></i18n>

<template>
  <v-layout id="message" row wrap :class="boxstyle" align-center justify-center>
    <v-flex xs12>
      <!-- Displaying Message With Link Text -->
      <v-layout v-if="linkLabel" row wrap align-center justify-center pa-2>
        <v-flex xs12 sm8 md8 lg8>
          <v-layout>
            <v-flex xs2 sm1 md1 lg1 align-self-center>
              <v-icon :color="type" large>
                {{ icon }}
              </v-icon>
            </v-flex>
            <v-flex xs10 sm11 md11 lg11 align-self-center>
              <span v-html="message" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4>
          <a
            @click="click"
            v-html="linkLabel"
          />
        </v-flex>
      </v-layout>

      <!-- Displaying Message With Button -->
      <v-layout v-if="buttonLabel" row wrap align-center justify-center pa-2>
        <v-flex xs12 sm9 md9 lg9>
          <v-layout>
            <v-flex xs2 sm1 md1 lg1 align-self-center>
              <v-icon :color="type" large>
                {{ icon }}
              </v-icon>
            </v-flex>
            <v-flex xs10 sm11 md11 lg11 pr-2 align-self-center>
              <span v-html="message" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs10 sm3 md3 lg3>
          <v-btn
            block
            class="white--text"
            @click="click"
            :color="buttonColor"
          >
            {{ buttonLabel }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Message',
  props: {
    type: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    linkLabel: {
      type: String,
      required: false,
      default: '',
    },
    linkColor: {
      type: String,
      required: false,
      default: '#39ac4b',
    },
    buttonLabel: {
      type: String,
      required: false,
      default: '',
    },
    buttonColor: {
      type: String,
      required: false,
      default: '#39ac4b',
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
  computed: {
    boxstyle() {
      if (this.type === 'error') {
        return 'error-box';
      } else if (this.type === 'warning') {
        return 'warning-box';
      } else if (this.type === 'success') {
        return 'success-box';
      } else if (this.type === 'info') {
        return 'info-box';
      }
      return '';
    },
    icon() {
      if (this.type === 'success') {
        return 'check_circle';
      }
      return this.type;
    },
  },
};
</script>

<style lang="stylus">
@require '~vuetify/src/stylus/settings/_variables'

.error-box {
  background-color: #fdecec !important;
  border-top: 4px solid #dd3333;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
}

.info-box {
  background-color: #e2f0fe !important;
  border-top: 4px solid #3399ff;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
}

.success-box {
  background-color: #d8f8dd !important;
  border-top: 4px solid #0b8d1f;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
}

.warning-box {
  background-color : #fdf3e2 !important;
  border-top: 4px solid #f39c12;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
  a {
    font-size: 16px;
  }
}

</style>


import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';
import home from '../modules/home/store';
import policy from '../modules/policy/store';
import dime from '../modules/policy/guidance/store';
import customer from './customer';
import childrider from '../modules/childrider/store';
import debtsolutions from '../modules/debtsolutions/store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    home,
    policy,
    dime,
    customer,
    childrider,
    debtsolutions,
  },
  state,
  getters,
  actions,
  mutations,
  strict: debug,
});

export default store;

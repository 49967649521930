import { render, staticRenderFns } from "./TerminatedPolicy.vue?vue&type=template&id=8e85ab80&scoped=true"
import script from "./TerminatedPolicy.vue?vue&type=script&lang=js"
export * from "./TerminatedPolicy.vue?vue&type=script&lang=js"
import style0 from "./TerminatedPolicy.vue?vue&type=style&index=0&id=8e85ab80&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e85ab80",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fmodules%2Fhome%2Fdashboard%2Fcomponents%2FTermLife%2Fcomponents%2FTerminatedPolicy%2FTerminatedPolicy.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports
import Config from '@/shared/util/config';
import NotFoundPage from '@primerica/vue-components/src/components/404/NotFoundPage.vue';
import store from '../store/index';
import home from '../modules/home/router';
import policy from '../modules/policy/router';
import alertAndNotification from '../modules/AlertAndNotification/router';
import documents from '../modules/documents/router';
import childrider from '../modules/childrider/router';
import investments from '../modules/investments/router';
import debtsolutions from '../modules/debtsolutions/router';
import error from '../modules/error/router';
import MainLayout from '../layouts/MainLayout.vue';
import login from '../modules/login/router';

const routes = [
  {
    path: '',
    redirect: (to) => {
      if ((to.query.code && to.query.state) || to.query.error || to.query.errorCode) {
        return { name: 'PostLoginLobby' };
      } else if (to.query.registrationId) {
        window.location = `${Config.get('auth.registrationUrl')}/?sessionId=0&sessionData=0&platform=0#/registration/personalized/${to.query.registrationId}`;
      }
      return { name: 'PreLoginLobby' };
    },
  },
  error,
  login,
  home,
  policy,
  alertAndNotification,
  documents,
  debtsolutions,
  investments,
  childrider,
  {
    path: '*',
    component: MainLayout,
    children: [
      {
        name: 'PageNotFound',
        path: '',
        component: NotFoundPage,
        meta: {
          pageTitle: 'Main.NotFound',
        },
      },
    ],
  },
];

const BootstrapGuard = async (to, from, next) => {
  if (!store.getters.initialized) {
    await store.dispatch('bootstrap');
  }
  // Catching a user refresh, this originally was applied to TermLife however
  // we can't see the route from vue page so we don't know when to call getPolicyOverview
  // this solves the issue of multiple calls being made to getPolicyOverview as well as
  // the issue of user refreshing on Debt or Investment pages and coming over to life page directly
  // from those pages
  // if ((from.fullPath === '/' && from.name === null) || from.name === 'LoginCallback') {
  // await store.dispatch('policy/getPolicyOverview');
  // }
  if (store.getters.initialized) {
    if (
      from.path === '/home/dashboard' ||
      (store.getters['policy/beneficiaryStep'] === -1 &&
        (store.getters['policy/withDrawalDateStep'] === -1 || store.getters['policy/withDrawalDateStep'] === 3) &&
        (store.getters['policy/paymentStep'] <= 0 || store.getters['policy/paymentStep'] === 3))
    ) {
      // Fetch policy/payment every time user goes to home unless they just came to the site
      // if (to.path === '/home/dashboard' && from.name === 'PolicyDetails') {
      //   await store.dispatch('policy/getPolicyOverview');
      // }
      if (store.getters['policy/paymentStep'] === -2) {
        store.dispatch('policy/setPaymentStep', -1);
      }
      // Check that the beneficiaryStep === -1 to go to next else set warning
      // paymentStep === -1 means not started.  paymentStep === 3 means user is at the Done button screen in Kubra Screen
      next();
    } else {
      store.commit('updateShowNavigationConfirmation', true);
      store.commit('updateNavigationPathOnUserConfirmation', to);
      if (store.getters['policy/beneficiaryStep'] !== -1 || store.getters['policy/withDrawalDateStep'] !== -1 || store.getters['policy/paymentStep'] >= 0) {
        store.commit('updateNavigationDialogBody', 'L_any_changes_that_you_may_have_made_will_not_be_saved');
      }
    }
  } else if (Config.get('showBootstrapErrors')) {
    next('/error');
    // TODO: report in DT
  } else {
    // TODO: report n DT
    store.dispatch('goToLogin');
  }
};

const NON_BOOTSTRAP_ROUTES = ['/error', '/login', '/app'];
const NO_AUTH_REQUIRED_ROUTES = ['Error', 'LoginShell', 'LoginCallback', 'PageNotFound', 'AppUpdate', 'UnsupportedBrowser', 'MaintenanceMode', 'PostLogoutPorch'];
routes.forEach((r) => {
  const doesRouteNeedBootstrap = !NON_BOOTSTRAP_ROUTES.includes(r.path);
  const doesRoureNeedAuthentication = !NO_AUTH_REQUIRED_ROUTES.includes(r.name);
  if (doesRouteNeedBootstrap) {
    r.beforeEnter = BootstrapGuard;
  }
  if (doesRoureNeedAuthentication) {
    const meta = { ...r.meta, requiresAuth: true };
    r.meta = meta;
  }
});

export default routes;

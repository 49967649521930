<i18n src="../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <v-layout
      class="justify-center"
    >
      <v-flex
        xs12
      >
        <Alerts text="payment" />
        <v-card class="card-ach-wrapper">
          <v-card-title
            class="justify-space-around text-xs-center"
          >
            <h2 class="heading_two">
              {{ $t('L_information_update_complete') }}
            </h2>
          </v-card-title>
          <v-card-text
            class="background-light-grey pl-4"
          >
            <v-layout
              class="mb-4"
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ $t('L_monthly_bank_draft') }}</span>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              row
              wrap
              mb-4
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_payment_amount') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.draftAmount | currency }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_type') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountTypeString }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Two -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_holder_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountName }}</span>
                </div>
              </v-flex> -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_account_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.accountNumber | hideNums }}</span>
                </div>
              </v-flex>
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_routing_number') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.routingNumber | hideNums }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Three -->
            <v-layout
              row
              wrap
              mb-4
            >
              <!-- Second part in step one -->
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_bank_name') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.bankName }}</span>
                </div>
              </v-flex>
            </v-layout>
            <!-- Row Four -->
            <v-layout
              v-if="!!dateFormatted"
              row
              wrap
              mb-2
              pt-2
            >
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_next_payment_draft_date') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ dateFormatted }}</span>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <div class="mb-2">
                  <span class="font-weight-medium">{{ $t('L_future_payment_day_heading') }}</span>
                </div>
                <div>
                  <span class="font-weight-regular grey-ada-text">{{ bankInfo.draftDay | draftDay }} {{ $t('L_future_payment_day_content') }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                mt-3
              >
                <v-flex
                  class="text-sm-right text-xs-center"
                >
                  <v-layout
                    row
                    wrap
                    class="text-xs-center justify-center"
                  >
                    <v-flex
                      xs5
                      sm2
                    >
                      <v-btn
                        id="bank_info_updated"
                        block
                        class="mb-3"
                        color="primary"
                        @click="done"
                      >
                        {{ $t('L_done') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Alerts from '@/components/Alerts.vue';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import { filters } from '@/mixins/filters';

export default {
  name: 'UpdateBankThree',
  mixins: [filters],
  components: {
    Alerts,
  },
  props: {
    bankInfo: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      policy: 'policy/currentPolicy',
    }),
    dateFormatted() {
      return formatDate(this.policy?.paymentInfo?.paidToDate, this.dateFormat);
    },
  },
  methods: {
    ...mapMutations({
      removeAlerts: 'removeAlerts',
    }),
    done() {
      this.$emit('done');
      this.$store.commit('policy/clearBank');
      // TODO
      // clear out the alerts here
      this.removeAlerts('payment');
    },
  },
  filters: {
    currency(value) {
      return `$${value}`;
    },
    draftDay(value) {
      let num;
      const val = value.charAt(0) === '0' ? value.slice(1) : value;
      switch (val) {
        case '1':
        case '21':
          num = 'st';
          break;
        case '2':
        case '22':
          num = 'nd';
          break;
        case '3':
        case '23':
          num = 'rd';
          break;
        default:
          num = 'th';
      }
      return `${val}${num}`;
    },
  },
};
</script>

<style scoped lang="stylus">
  .grey-ada-text
    color: #737171
  .disclaimer-wrapper
    overflow-y: scroll;
    max-height: 263px;
    min-height: 250px;
    border-radius: 2px;
    border: solid 1px #979797;
    padding: 20px;
  .card-ach-wrapper
    position: relative;
  .fixed-top-sm-message-banner
    position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    z-index: 1;
</style>

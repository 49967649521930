<i18n src='../locales.json'></i18n>

<template>
  <div>
    <v-layout
      align-center
      :justify-center="$vuetify.breakpoint.smAndDown"
      class="mb-2 pb-1"
    >
      <span
        class="font-size--14 font-weight-medium"
      >{{ $t('L_my_office') }}</span>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex
        v-if="rep.office"
        xs12
      >
        <a
          :href="navigateTo"
          target="_blank"
          :aria-label="$t('L_opens_new_window')+' '+linkAddr"
        >
          <v-layout
            :justify-start="$vuetify.breakpoint.mdAndUp"
            :justify-center="$vuetify.breakpoint.smAndDown"
            align-center
          >
            <img
              src="./assets/map-marker.svg"
              height="28"
              class="pr-3"
              role="presentation"
              alt=""
            >
            <span
              class="font-size--14 font-weight-regular"
              style="color: #333;"
            >
              {{ rep.office.address1 }}
              <br
                v-if="rep.office.address2"
                class="hidden-sm-and-down"
              >
              {{ rep.office.address2 }}
              <br class="hidden-sm-and-down">
              {{ rep.office.city }}, {{ rep.office.state }} {{ rep.office.zip }}
            </span>
          </v-layout>
        </a>
      </v-flex>
      <v-flex
        xs12
        v-if="!rep.isRvp"
      >
        <v-layout
          justify-start
          align-center
        >
          <span
            class="font-size--14 mt-3"
            v-html="$t('L_rep_working_out_of', { rvp: rvpInfo } )"
          />
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { openMap } from '@/mixins/openMap';
import { titleCase } from '@primerica/vue-components/src/util/formatters';

export default {
  name: 'Address',
  mixins: [openMap],
  props: {
    rep: {
      type: Object,
      required: true,
      default: () => undefined,
    },
  },
  computed: {
    rvpInfo() {
      if (this.rep?.rvp) {
        return titleCase(`${this.rep?.rvp?.titleDescription} ${this.rep?.rvp?.name}`);
      }
      return '';
    },
  },
  mounted() {
    this.setAddressToNavigate(this.rep.office);
  },
};
</script>

<i18n src="../locales.json"></i18n>
<template>
  <v-content class="pt-0">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 md6>
            <v-layout row wrap>
              <v-flex xs12 class="font-size--16 mb-4">
                <div class="font-size--16 font-weight-bold mb-2">
                  {{ $t('L_your_current_withdrawal_date') }}
                </div>
                <div class="font-size--24 font-weight-regular">
                  {{ getCurrentWithDrawalDate }}
                </div>
              </v-flex>
              <v-flex xs12 mt-2>
                <div class="font-size--16 font-weight-bold mb-2">
                  {{ $t('L_select_a_new_withdrawal_day') }}
                </div>
                <div class="font-size--24 font-weight-regular">
                  <span>
                    <v-select
                      :items="getDateOptions"
                      v-model="selectedOption"
                      solo
                      single-line
                      prepend-icon="date_range"
                      class="selectMaxWidth"
                      :placeholder="$t('L_Withdrawal_date')"
                    />
                  </span>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap align-center justify-center pa-2 :class="(selectDateIssueNextPayDateEarly === 1 || selectDateIssueNextPayDateEarly === 3) ? 'warning-box': 'info-box'"
              v-if="(selectDateIssueNextPayDateEarly !== -1)"
    >
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12 v-if="(selectDateIssueNextPayDateEarly === 1 || selectDateIssueNextPayDateEarly === 3)">
            <span class="pr-2 mb-4">
              <v-icon :color="'#f39c12'" large class="font-size--28">
                warning
              </v-icon>
            </span>
            <span class="font-size--14" v-if="selectDateIssueNextPayDateEarly === 3">{{ $t('L_because_your_current_draft_day_is_within_the_next') }} </span>
            <span class="font-size--14" v-if="selectDateIssueNextPayDateEarly === 1">{{ $t('L_because_you_have_chosen_a_date_in_the_past') }} </span>
          </v-flex>
          <v-flex xs12 v-if="selectDateIssueNextPayDateEarly === 1 || selectDateIssueNextPayDateEarly === 2 || selectDateIssueNextPayDateEarly === 3">
            <div class="font-size--14 mt-4 mb-4">
              {{ $t('L_going_forward_your_next_payment_will_be_withdrawn_on_the', { dayth: getSelectedDate }) }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <div class="text-xs-center justify-center mt-4 pt-4">
      <v-layout row wrap>
        <v-flex xs0 sm0 md4>
          &nbsp;
        </v-flex>
        <v-flex xs12 sm12 md4 pr-4>
          <v-btn
            block
            color="secondary"
            @click="cancelWithDrawalDate"
            class="maxwidthButton"
          >
            {{ $t('L_cancel') }}
          </v-btn>
        </v-flex>
        <v-flex xs12 sm12 md4 pr-4>
          <v-btn
            block
            class="white--text mb-3 maxwidthButton"
            @click="goToNextStep"
            :disabled="selectedOption === -1"
            color="primary"
          >
            {{ $t('L_continue') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import moment from 'moment';
import Message from '../../../../components/Message.vue';

export default {
  name: 'WDStepOne',
  components: { Message },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
    selectedOption: -1,
    persistent: true,
    hideMinimum: false,
  }),
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', [
      'policies',
      'currentPolicy',
      'routeNameToNavigateBack',
      'selectedPolicyIndex',
    ]),
    ...mapGetters({
      locale: 'getLocale',
      dateFormat: 'getDateFormat',
    }),
    selectDateIssueNextPayDateEarly() {
      let issueWithDate = -1;
      if (this.selectedOption !== -1) {
        try {
          const i = this.$dynatrace.enterAction(`SelectWithdrawalDay_${this.selectedOption}_Selected`);
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
      }
      // Current Day and Current Month (remember index 0 is Jan)
      const currentMonthDay = moment().date();
      const currentMonth = moment().month();
      // New Draft Day selected by User
      const newDraftDay = parseInt(this.selectedOption, 10);
      // Current Draft Day (aka Existing Draft Day)
      const currentDraftDate = formatDate(this.currentPolicy?.nextDraftDate, 'MM/DD/YYYY');
      const currentDraftDateDay = moment(currentDraftDate).date();
      // const currentDraftDateMonth = moment(currentDraftDate).month();
      // Payment Schedule
      const nextPaymentDueDate = formatDate(this.currentPolicy?.nextPaymentDueDate, 'MM/DD/YYYY');
      // const nextPaymentDueDay = moment(nextPaymentDueDate).date();
      const nextPaymentDueMonth = moment(nextPaymentDueDate).month(); // 0 is Jan, 11 is Dec
      // MYP-8254 and MYP-8268 for logic
      // Have not selected date
      if ((newDraftDay < 0)) {
        issueWithDate = -1;
      } else if (newDraftDay < currentMonthDay && nextPaymentDueMonth > currentMonth) {
        // Payment has been made for current month
        issueWithDate = 2;
        // Going forward your payments will be withdrawn
      } else if (newDraftDay < currentMonthDay && nextPaymentDueMonth <= currentMonth) {
        // Payment has not been made for current month
        issueWithDate = 1;
        // Because you have chosen a date in the past && Going forward your payments will be withdrawn
      } else if (newDraftDay > currentDraftDateDay && newDraftDay - 3 > currentMonthDay) {
        // new draft after current draft date.  Current Draft date more than 3 days from current date
        issueWithDate = 2;
        // Going forward your payments will be withdrawn
      } else if (newDraftDay > currentDraftDateDay && newDraftDay - 3 < currentMonthDay) {
        // new draft after current draft date.  Current Draft date less than or equal to 3 days from current date
        issueWithDate = 3;
        // Because your current draft day is within the next 3 days, && Going forward your payments will be withdrawn
      } else if (newDraftDay < currentDraftDateDay && newDraftDay - 3 > currentMonthDay) {
        // new draft before current draft date.  Current Draft date more than 3 days from current date
        issueWithDate = 2;
        // Going forward your payments will be withdrawn
      } else if (newDraftDay < currentDraftDateDay && newDraftDay - 3 < currentMonthDay) {
        // new draft before current draft date. Current Draft date less than or equal to 3 days from current date
        issueWithDate = 3;
        // Because your current draft day is within the next 3 days, && Going forward your payments will be withdrawn
      } else {
        issueWithDate = 2; // Going forward your payments will be withdrawn
      }
      // if (issueWithDate > 0 && currentDraftDateMonth > currentMonth) {
      //   issueWithDate = 2;
      // }
      return issueWithDate;
    },
    getDateForNextPayment() {
      let tDate = moment().set('date', parseInt(this.selectedOption, 10)).format('MM/DD/YYYY');
      const now = moment().format('MM/DD/YYYY');
      if (tDate <= now) {
        tDate = moment().set('date', parseInt(this.selectedOption, 10)).add(1, 'months').format('MM/DD/YYYY');
      }
      return tDate;
    },
    getCurrentWithDrawalDate() {
      // return formatDate(this.currentPolicy?.nextPaymentDueDate, 'll');
      // TODO check if this is right place to see withdrawal date
      return formatDate(this.currentPolicy?.nextDraftDate, this.dateFormat);
    },
    getDateOptions() {
      const dateOptions = [];
      // TODO
      const currentDate = formatDate(this.currentPolicy?.nextDraftDate, 'MM/DD/YYYY');
      let day = 1;
      if (currentDate.length > 6) {
        // For MM/DD/YYYY format
        day = parseInt(currentDate.substr(3, 2), 10);
        // If we have DD/MM/YYYY use substr(0,2)

        for (let i = 1; i < 29; i++) {
          if (i !== day) {
            if (i === 1) {
              dateOptions.push({ value: i.toString(), text: `${i}st` });
            } else if (i === 2) {
              dateOptions.push({ value: i.toString(), text: `${i}nd` });
            } else if (i === 3) {
              dateOptions.push({ value: i.toString(), text: `${i}rd` });
            } else if (i === 21) {
              dateOptions.push({ value: i.toString(), text: `${i}st` });
            } else if (i === 22) {
              dateOptions.push({ value: i.toString(), text: `${i}nd` });
            } else if (i === 23) {
              dateOptions.push({ value: i.toString(), text: `${i}rd` });
            } else {
              dateOptions.push({ value: i.toString(), text: `${i}th` });
            }
          }
        }
      }
      return dateOptions;
    },
    getSelectedDate() {
      let x = '';
      x = this.selectedOption;
      if (this.selectedOption === '1' || this.selectedOption === '21') {
        x += 'st ';
      } else if (this.selectedOption === '2' || this.selectedOption === '22') {
        x += 'nd ';
      } else if (this.selectedOption === '3' || this.selectedOption === '23') {
        x += 'rd ';
      } else {
        x += 'th ';
      }
      return x;
    },
  },
  methods: {
    ...mapActions({
      paymentSelection: 'policy/paymentSelection',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument', 'setWithDrawalDateStep']),
    cancelWithDrawalDate() {
      this.$emit('cancelDialog');
      try {
        const i = this.$dynatrace.enterAction('SelectWithdrawalDay_Cancel_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    },
    async goToNextStep() {
      try {
        const i = this.$dynatrace.enterAction('SelectWithdrawalDay_Continue_Selected');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      try {
        const i = this.$dynatrace.enterAction('ReviewWithdrawalDay_PageDisplayed');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.$emit('goToWDStepTwo', this.selectedOption);
      this.$emit('selectDateIssueNextPayDateEarly', this.selectDateIssueNextPayDateEarly);
      this.$emit('setDateForNextPayment', this.getDateForNextPayment);
      // Setting data back to default;
      this.selectedOption = -1;
    },

  },
};
</script>

<style lang="stylus" scoped>
.selectMaxWidth {
  max-width: 320px;
  width: 100%;
  padding-bottom: 4px !important;
}
.bg-light-grey {
  background-color: #f5f5f5;
}
.cardNotSelected {
  max-width: 295px;
  border: 2px solid #cccccc;
  background-color: #ffffff;
  height: 85px;
}

.info-box {
  background-color: #e2f0fe !important;;
  border-top: 4px solid #3399ff;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
  a {
    font-size: 16px;
  }
}

.warning-box {
  background-color : #fdf3e2 !important;
  border-top: 4px solid #f39c12;
  margin-left: 0px !important;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  padding: 2px 10px 4px 10px;
  a {
    font-size: 16px;
  }
}
.cardSelected {
  max-width: 295px;
  border: 2px solid #6cd07b;
  background-color: #d8f8dd;
  height: 85px;
}

.cardTitle {
  font-size: 16px;
  color: #333333;
}
.cardAmount {
  padding-top: 0px;
  padding-left: 16px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.cardFooter {
  padding-top:8px;
  font-size: 12px;
  color: #333333;
}
.vertCenterCircleHoriRight {
  margin: auto;
  text-align: right;
  padding-right: 10px;
}
.firstTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.secondTextContent {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.thirdTextContent {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.maxwidthButton {
  max-width: 300px;
}
.moveRight {
  text-align: right;
}
.clickable {
  cursor: pointer;
}
</style>

<i18n src="../../../../locales.json"></i18n>
<template>
  <div>
    <v-divider />
    <Alerts context="payment" />
    <v-layout class="justify-center">
      <v-flex xs11>
        <v-card>
          <v-card-title class="justify-space-around text-xs-center">
            <h3 class="heading_three">
              {{ $t('L_confirm_new_payment_details') }}
            </h3>
          </v-card-title>
          <DirectPaymentPlaceHolder>
            <template slot="paymentType">
              {{ $t(formattedPaymentType) }}
            </template>
            <template slot="paymentAmountLabel">
              {{ $t('L_payment_amount') }}
            </template>
            <template slot="paymentAmount">
              {{ insurancePaymentAmount | currency }}
            </template>
            <template slot="nextPaymentDateLabel">
              {{ $t('L_next_payment_due') }}
            </template>
            <template slot="nextPaymentDate">
              {{ formattedPaidToDate }}
            </template>
            <template slot="billingInformationLabel">
              {{ $t('L_billing_information') }}
            </template>
            <template slot="description">
              <i18n path="L_edit_this_info" tag="span" class="font-weight-regular">
                <a href="javascript:void(0)" @click="$emit('open-contact-details')" class="text-decoration-underline">{{ $t('L_contact_details') }}</a>
              </i18n>
            </template>
            <template slot="mailingAddressLabel">
              {{ $t('L_mailing_address') }}
            </template>
            <template slot="address">
              <v-flex xs12>
                {{ policyData._owner._address.address1 }}.
              </v-flex>
              <v-flex xs12 v-if="policyData._owner._address.address2">
                {{ policyData._owner._address.address2 }}
              </v-flex>
              <v-flex xs12>
                {{ policyData._owner._address._city }}, {{ policyData._owner._address._state }} {{ policyData._owner._address._zip }}
              </v-flex>
            </template>
          </DirectPaymentPlaceHolder>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex class="text-sm-right text-xs-center mt-4">
                <v-layout row wrap class="justify-end">
                  <v-flex xs12 sm2 :class="$vuetify.breakpoint.smAndUp ? 'mr-4' : ''">
                    <v-btn block class="mb-3" color="secondary" @click="cancel">
                      {{ $t('L_cancel') }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-btn block class="white--text mb-3" @click="updateBillingMode" color="primary">
                      {{ $t('L_i_authorize') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import stringValues from '@/shared/util/stringValues';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import Alerts from '@/components/Alerts.vue';
import DirectPaymentPlaceHolder from '@/components/DirectPayment/DirectPaymentPlaceHolder.vue';

export default {
  name: 'ToDirect',
  components: {
    Alerts,
    DirectPaymentPlaceHolder,
  },
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
    newBillingMode: {
      type: String,
      required: true,
    },
  },
  filters: {
    currency(value) {
      return `$${value}`;
    },
  },
  computed: {
    ...mapGetters({
      dateFormat: 'getDateFormat',
      locale: 'getLocale',
    }),
    ...mapGetters('customer', ['customerProfile']),
    insurancePaymentAmount() {
      if (this.newBillingMode === '03') {
        return this.policyData?.paymentInfo?.quarterlyPremium;
      } else if (this.newBillingMode === '06') {
        return this.policyData?.paymentInfo?.semiAnnualPremium;
      }
      return this.policyData?.paymentInfo?.annualPremium;
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    formattedPaymentType() {
      return this.newBillingMode ? stringValues.paymentMethod({ billingMode: this.newBillingMode, billingForm: 'A1' }) : '';
    },
    formattedPaidToDate() {
      return formatDate(this.policyData?.paymentInfo?.paidToDate, this.dateFormat);
    },
    policyNumber() {
      return this?.policyData?.contractId;
    },
    nextCycleDate() {
      return this.policyData?.paymentInfo?.nextCycleDate;
    },
    accountName() {
      return this?.policyData?.paymentInfo?.accountName;
    },
    routingNumber() {
      const number = this?.policyData?.paymentInfo.routingNumber;
      if (number) {
        return number.replace('-', '');
      }
      return number;
    },
    accountNumber() {
      return this?.policyData?.paymentInfo?.accountNumber;
    },
    accountType() {
      return this?.policyData?.paymentInfo?.accountType;
    },
    draftDay() {
      return this?.policyData?.paymentInfo?._autoDraftPaymentDate;
    },
  },
  methods: {
    ...mapMutations({
      setPaymentInfoShowPaymentAmountDateBillingMethod: 'policy/setPaymentInfoShowPaymentAmountDateBillingMethod',
    }),
    updateBillingMode() {
      try {
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t(this.formattedPaymentType)?.replaceAll(' ', '-')}_ConfirmChange_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      const paymentInfo = {
        nextCycleDate: this.nextCycleDate,
        draftAmount: this.insurancePaymentAmount,
        reqMode: this.newBillingMode,
        draftDay: this.draftDay,
        emailAddress: this.customerProfile.email,
        language: this.locale,
      };
      this.$store
        .dispatch('policy/updateBillingMode', {
          paymentInfo,
          policyNumber: this.policyNumber,
        })
        .then(async (response) => {
          if (response) {
            try {
              const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t(this.formattedPaymentType)?.replaceAll(' ', '-')}_ChangesSubmitted_Success`);
              this.$dynatrace.leaveAction(i);
            } catch (error) {
              /*  */
            }
            this.showSuccessBanner();
            this.$emit('goToStepThree');
            const resStatus = await this.refreshPolicies();
            if (resStatus === 200) {
              // await this.refreshPaymentData();
            }
          } else {
            try {
              const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t(this.formattedPaymentType)?.replaceAll(' ', '-')}_ChangesSubmitted_Failure_updateBillingModeError`);
              this.$dynatrace.leaveAction(i);
            } catch (error) {
              /*  */
            }
          }
        })
        .catch((err) => {
          try {
            const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t(this.formattedPaymentType)?.replaceAll(' ', '-')}_ChangesSubmitted_Failure_${err}`);
            this.$dynatrace.leaveAction(i);
          } catch (error) {
            /*  */
          }
        });
      this.setPaymentInfoShowPaymentAmountDateBillingMethod(true);
    },
    async refreshPolicies() {
      return this.$store.dispatch('policy/getPolicies');
    },
    async refreshPaymentData() {
      await this.$store.dispatch('policy/getPolicyOverview', this.policyNumber);
    },
    showSuccessBanner() {
      /*
       * Emit a custom action when a user successfully submits an update update payment option request
       * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
       */
      const i = this.$dynatrace.enterAction('payment_option_changes_saved');
      this.$dynatrace.leaveAction(i);
      this.$store.commit('addAlert', {
        type: 'success',
        details: { message: this.$t('L_successfully_changed_your_payment_option', { email: this.customerProfile.email }) },
        context: 'payment',
        autoDismiss: false,
      });
    },
    cancel() {
      try {
        const i = this.$dynatrace.enterAction(`UpdateBillingMethod_${this.$t(this.formattedPaymentType)?.replaceAll(' ', '-')}_CancelChange_selected`);
        this.$dynatrace.leaveAction(i);
      } catch (error) {
        /*  */
      }
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped lang="stylus">
.theme--light.v-sheet
  color: #777777
</style>

<i18n src="./locales.json"></i18n>
<template>
  <v-flex class="rep" xs12 v-if="hasReps">
    <v-layout class="align-center" row wrap>
      <v-flex class="picture" xs4 md2>
        <div class="icon">
          <img :src="agentImageUrl" class="agentImage" :alt="altAgentImageUrl"
               v-img-fallback="repFallbackImage"
          >
        </div>
      </v-flex>
      <v-flex class="heading_three" xs8 md6>
        {{ $t('L_your_representative', { agent: agentName }) }}
      </v-flex>
      <v-flex class="button-wrapper" v-if="hasReps"
              :class="{'pt-3': $vuetify.breakpoint.xsOnly}" xs12 md4
      >
        <v-btn
          block
          outline
          color="white"
          @click="contactRep = true"
          class="contactYourRepButtonWidth"
          :aria-label="$t('L_contact_contact_your_rep')"
        >
          {{ $t('L_contact_contact_your_rep') }}
        </v-btn>
      </v-flex>
      <ContactRep
        v-model="contactRep"
        :agents="agents"
        @close="contactRep = false"
        @send-message="sendRepEmail($event)"
        v-if="hasReps"
        :sender="sender"
      />
    </v-layout>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ContactRep, sendRepEmail } from '@primerica/vue-components';

export default {
  name: 'ContactRepFooter',
  components: {
    ContactRep,
  },
  data() {
    return {
      contactRep: false,
    };
  },
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters('policy', [
      'policiesSortedByDate',
    ]),
    ...mapGetters('customer', [
      'investmentsSortedByDate',
      'customerProfile',
    ]),
    ...mapGetters(['getAgent', 'agents']),
    hasReps() {
      return this.agents?.length > 0;
    },
    agentName() {
      return this.agent?.name ? this.agent.name : '';
    },
    sender() {
      return {
        email: this.customerProfile?.email || '',
        phone: this.customerProfile?.mobilePhone || '',
        firstName: this.customerProfile.firstName,
        lastName: this.customerProfile.lastName,
      };
    },
    agent() {
      return (this.agents?.length > 0) ? this.agents[0] : {};
    },
    agentImageUrl() {
      return this.agent?.displayPic;
    },
    altAgentImageUrl() {
      return `Rep pic ${this.agent?.displayPic}`;
    },
    repFallbackImage() {
      // eslint-disable-next-line
      return require('@/assets/rep-thumb.svg');
    },
  },
  methods: {
    ...mapActions('customer', ['contactRepresentative']),
    sendRepEmail(emailPayload) {
      sendRepEmail.bind(this)(this.contactRepresentative, emailPayload);
    },
  },
};
</script>

<style scoped lang="stylus">
.contactYourRepButtonWidth
  width: 264px;
  max-width: 265px;
  min-width: 264px;
.picture
  .agentImage
  .icon
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border-radius: 50%;
.rep
    padding: 15px 0;
    background: #eee;
    .button-wrapper .v-btn
      width: 85%
      margin: 0 auto;
      background-color: #063562 !important;
</style>

<i18n src='../../../../locales.json'></i18n>
<template>
  <!-- Desktop Starts -->
  <v-layout
    row
    wrap
    class="hidden-sm-and-down"
  >
    <v-flex
      xs12
    >
      <v-flex pt-3>
        <Achievement
          :progress="getProgressProp > 100 ? 100 : getProgressProp"
          :size="13"
        />
      </v-flex>
    </v-flex>
    <v-flex
      v-if="includeSpouseProp"
      xs12
      mt-4
    >
      <v-tabs
        id="you_spouse_tabs"
        grow
        v-model="selectedTab"
        color="white"
        slider-color="transparent"
      >
        <v-tab
          color="black"
          @click="updateSelectedTabTop(0)"
        >
          {{ $t('L_you') }}
        </v-tab>
        <v-tab
          @click="updateSelectedTabTop(1)"
        >
          {{ $t('L_spouse') }}
        </v-tab>
      </v-tabs>
    </v-flex>
    <v-flex
      pt-4
      pl-4
      pr-4
      pb-2
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          xs12
        >
          <GuidancePill
            :header="selectedTab === 0 ? $t('L_pill_you_have') : $t('L_pill_spouse_has')"
            :amount="``"
            :footer="$t('L_pill_in_protection')"
          >
            <span>
              <span style="font-size: 1.4em">{{ formattedCurrentCoverageProp }}*</span>
            </span>
          </GuidancePill>
        </v-flex>
        <v-flex
          xs12
          mt-4
        >
          <GuidancePill
            :header="selectedTab === 0 ? $t('L_pill_you_need') : $t('L_pill_spouse_needs')"
            :amount="formattedNeedProp"
            :footer="$t('L_pill_in_life_insurance')"
          />
        </v-flex>
        <v-flex
          xs12
          mt-4
        >
          <h4
            class="heading_four text-xs-center font-size--14"
            role="presentation"
          >
            <a
              v-ada-modal
              id="how_did_we_calculate_trigger"
              tabindex="0"
              role="button"
              href="javascript:void(0)"
              class="font-weight-regular"
              @click="dialog = true"
            >{{ $t('L_how_did_we_calculate') }}</a>
          </h4>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      grey
      lighten-2
      pl-4
      pt-2
      pr-4
      pb-2
    >
      <v-layout
        v-if="additionalNeedProp > 0"
        row
        wrap
        class="mb-3"
      >
        <v-flex
          xs12
          class="text-xs-center"
        >
          <v-layout justify-center>
            <img
              src="../../../../assets/md-recommend.svg"
              :alt="$t('L_recommended_additional_coverage')"
            >
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          mt-3
        >
          <GuidancePill
            :header="$t('L_we_recommend_adding')"
            :amount="formattedAdditionalProp"
            :footer="$t('L_in_life_coverage')"
          />
        </v-flex>
        <v-flex
          xs12
          pt-4
          class="text-xs-center"
        >
          <h3
            v-html="$t('L_increase_coverage_today')"
            class="heading_three font-weight-medium"
          />
        </v-flex>
      </v-layout>
      <v-layout
        justify-center
      >
        <v-btn
          v-if="displayContactRep"
          v-ada-modal
          id="contact_your_rep_guidance_trigger"
          block
          color="primary"
          class="contact-rep-trigger"
          @click="$emit('contact-rep', true)"
        >
          {{ $t('L_contact_your_rep') }}
        </v-btn>
      </v-layout>
    </v-flex>
    <!-- Desktop Dialog -->
    <v-dialog
      v-model="dialog"
      max-width="400"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card class="how_did_we_calculate_modal">
        <v-card-text
          class="font-weight-regular how_did_we_calculate_content"
          v-html="$t('L_how_did_we_calculate_text')"
        />

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="lighten-2 how_did_we_calculate_close"
            color="primary"
            @click="dialog = false"
          >
            Got It
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { Achievement, Ada } from '@primerica/vue-components';
import GuidancePill from '../../../GuidancePill.vue';

export default {
  name: 'SidebarDesktop',
  components: {
    GuidancePill,
    Achievement,
  },
  props: {
    includeSpouseProp: {
      type: Boolean,
      required: true,
      default: false,
    },
    getProgressProp: {
      type: Number,
      required: true,
      default: null,
    },
    formattedCurrentCoverageProp: {
      type: String,
      required: true,
      default: '',
    },
    formattedNeedProp: {
      type: String,
      required: true,
      default: '',
    },
    additionalNeedProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    formattedAdditionalProp: {
      type: [Number, String],
      required: true,
      default: null,
    },
    displayContactRep: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    updateSelectedTabTop(value) {
      this.$emit('updateSelectedTab', value);
    },
  },
  data: () => ({
    padding: 0,
    selectedTab: 0,
    dialog: false,
    contactMe: false,
  }),
  mounted() {
    setTimeout(() => {
      const tabs = document.getElementById('you_spouse_tabs');
      Ada.makeTabs(tabs);
    }, 1000);
  },
  watch: {
    includeSpouseProp(newData) {
      if (newData === false) {
        this.updateSelectedTabTop(0);
        this.selectedTab = 0;
      }
    },
  },
};
</script>


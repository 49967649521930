import bff from '@/shared/api/bff';
import Config from '@/shared/util/config';
import { getAuthClient } from '@/plugins/auth/index';
import { CapacitorFacade } from '@/plugins/capacitorPlugin';
import {} from './getters';
import policyState from '../modules/policy/store/state';
import { version } from '../../package.json';

export const bootstrap = async ({ commit }) => {
  commit('setRootLoading', true);
  try {
    const response = await bff.bootstrap();
    for (let i = 0; i < response.tasks.length; i++) {
      const obj = response.tasks[i];
      obj.shownFlag = false;
    }
    commit('setTasks', response.tasks);
    commit('setInitialized', true);
    commit('changeLocale', response.customerProfile.preferredLang);

    // Merging the DB features and Json config features
    const features = Config.get('features') || [];
    const { featureFlags } = response;
    featureFlags.forEach((element) => {
      features.forEach((el, idx) => {
        if (element.feature === el.feature) {
          features.splice(idx, 1);
        }
      });
    });
    const featureList = featureFlags.concat(features);
    commit('setFeatures', featureList);
    commit('customer/updateCustomerProfile', response.customerProfile, {
      root: true,
    });
  } catch (e) {
    const details = {
      key: 'L_failed_to_bootstrap',
      correlationId: e.correlationId,
      traceId: e.traceId,
      detail: `${e.message} ${e.detail ? e.detail : ''}`,
    };
    commit('addAlert', { type: 'error', details, context: 'app' }, { root: true });
  }
};

export const changeLocale = async (state, locale) => {
  try {
    const response = await bff.updateLanguagePreference(locale);
    if (response.status === 200) {
      state.commit('changeLocale', locale);
    }
  } catch (error) {
    state.commit('addAlert', { type: 'error', details: error, context: 'MyAccount' }, { root: true });
    throw error;
  }
};

export const changeLocalePreLogin = async (state, locale) => {
  state.commit('changeLocale', locale);
};

export const snoozeEcomm = async (state) => {
  const p = bff.snoozeEcomm().catch((error) => {
    state.commit('addAlert', { type: 'error', details: error, context: 'Ecomm' }, { root: true });
    throw error;
  });
  return p;
};

export const snoozeVerifyPhone = async ({ getters, commit }) => {
  await bff.snoozeVerifyPhone();
  window.sessionStorage.setItem('hideVerifyPhone', 'true');
  // window.sessionStorage.setItem('paperlessDismissed', 'true');
  const tasksObj = JSON.parse(JSON.stringify(getters.tasks));
  const idx = tasksObj.findIndex(a => a.name === 'VERIFY_PHONE' && a.shownFlag === false);
  if (idx !== -1) {
    tasksObj[idx].shownFlag = true;
  }
  commit('setTasks', tasksObj);
};

export const ackPolicy = async ({ getters, commit }, data) => {
  try {
    const resp = await bff.ackPolicy(data);
    if (resp.data) {
      const tasksObj = JSON.parse(JSON.stringify(getters.tasks));
      const idx = tasksObj.findIndex(a => a.name === 'POLICY_ACK' && a.policyNumbers[0] === data.policyNum && a.shownFlag === false);
      if (idx !== -1) {
        tasksObj[idx].shownFlag = true;
      }
      commit('setTasks', tasksObj);
    } else {
      commit('addError', { error: { key: 'L_generic_error_message' }, context: 'policy_ack' }, { root: true });
    }
  } catch (e) {
    commit('addError', { error: e, context: 'policy_ack' }, { root: true });
  }
};

export const goToLogin = async () => {
  const authClient = getAuthClient();
  await authClient.logout(); // ? just to clear the session
  await authClient.login();
};

export const addNotification = async ({ commit }, value) => {
  commit('addNotification', value);
};

export const removeNotification = async ({ commit }, index) => {
  commit('removeNotification', index);
};

export const removeNotifications = async ({ commit }, context) => {
  commit('removeNotifications', context);
};

export const updateNotification = async ({ commit }, notifobj) => {
  commit('updateNotification', notifobj);
};

export const updateSpanishBanner = async ({ commit }, showBanner) => {
  commit('updateSpanishBanner', showBanner);
};

export const getAlerts = async ({ commit }) => {
  try {
    const response = await bff.getAlerts();
    if (response.status === 200) {
      if (response.data) {
        commit('updateAlertsAndNotifications', response.data);
      } else {
        commit('addAlert', { type: 'error', details: response, context: 'AlertsAndNotifications' }, { root: true });
      }
    }
  } catch (error) {
    commit('addAlert', { type: 'error', details: error, context: 'AlertsAndNotifications' }, { root: true });
    throw error;
  }
};

export const dismissAlert = async ({ commit }, alertId) => {
  try {
    const response = await bff.dismissAlert(alertId);
    if (response.status !== 200) {
      return false;
    }
    return true;
  } catch (error) {
    commit('addAlert', { type: 'error', details: error, context: 'AlertsAndNotifications' }, { root: true });
    return false;
  }
};

export const completeAlert = async ({ commit }, { alertId, payload }) => {
  try {
    const response = await bff.completeAlert(alertId, payload);
    if (response.status !== 200) {
      throw new Error();
    }
    return true;
  } catch (error) {
    commit('addAlert', { type: 'error', details: error, context: 'AlertsAndNotifications' }, { root: true });
    throw error;
  }
};

export const updateTermsOfUse = async ({ commit }, agreementId) => {
  try {
    const response = await bff.updateTermsOfUse(agreementId);
    if (response.status !== 200) {
      return false;
    }
    return true;
  } catch (error) {
    commit('addAlert', { type: 'error', details: error, context: 'AlertsAndNotifications' }, { root: true });
    return false;
  }
};

export const getDocuments = async ({ commit }) => {
  const policyIds = policyState.policies.filter(p => p.active || p.pending).map(d => d.data.policyNum);
  const p = bff.getDocuments(policyIds).then((response) => {
    if (response?.status === 200) {
      commit('updateDocuments', response?.data);
    }
  });
  return p;
};

export const getAppInfo = async ({ commit }) => {
  const resp = await bff.getAppInfo().then((response) => {
    if (response?.status === 200) {
      commit('setMaintenanceMode', response?.data?.isMaintenanceMode);
      commit('setRecommendedAppVersion', response?.data?.appVersion);
      return Promise.resolve(response?.data?.isMaintenanceMode);
    }
    return false;
  }).catch((error) => {
    commit('addAlert', { type: 'error', details: error, context: 'MaintenanceMode' }, { root: true });
    return false;
  });
  return resp;
};

// TODO: add unit test

export const postAuditLog = async ({ rootGetters, getters }, auditLog) => {
  const customerProfile = rootGetters['customer/customerProfile']; // TODO: find a better source
  const { sessionId, appVersion, deviceInfo } = getters.userSessionMeta;
  let username;
  const authClient = getAuthClient();
  try {
    await authClient?.getDecodedAccessToken()
      .then((_accessToken) => {
        username = _accessToken?.sub ?? customerProfile?.email;
      });
  } catch (error) {}
  const l = {
    username,
    sessionId,
    deviceId: deviceInfo?.deviceId,
    metadata: {
      deviceName: deviceInfo?.deviceModel,
      osVersion: deviceInfo?.deviceOsVersion,
      platform: deviceInfo?.devicePlatform,
      appVersion,
    },
  };
  const resp = await bff.postAuditLog({ ...l, ...auditLog }).catch(() => { /* log errors, do nothin */ });
  return resp;
};

// TODO: add unit test

export const buildUserSessionMetaInfo = async ({ commit }) => {
  /*
  x-pri-device='iPhone 14'
  x-pri-os='17.3'
  x-pri-platform='Android'
  x-pri-app='0.2.50'
  */
  let sessionId = '';
  let deviceInfo = '';
  let deviceId = '';
  try {
    // TODO: consider using `Promise.allSettled`
    await Promise.all([
      CapacitorFacade.getDeviceInfo(),
      CapacitorFacade.getDeviceId(),
    ])
      .then(([_deviceInfo, _deviceId]) => {
        deviceInfo = _deviceInfo;
        deviceId = _deviceId?.identifier;
      });
  } catch (error) { }
  try {
    sessionId = Array.from(window.crypto.getRandomValues(new Uint32Array(3))).join('');
  } catch (error) { }
  const i = {
    sessionId,
    appVersion: version,
    deviceInfo: {
      deviceId,
      deviceModel: deviceInfo?.model,
      deviceOsVersion: deviceInfo?.osVersion,
      devicePlatform: deviceInfo?.platform,
    },
  };
  commit('setUserSessionMeta', i);
};

export const resetStore = ({ commit }) => {
  commit('resetState');
  commit('home/resetState', null, { root: true });
  commit('policy/resetState', null, { root: true });
  commit('dime/resetState', null, { root: true });
  commit('customer/resetState', null, { root: true });
  commit('childrider/resetState', null, { root: true });
  commit('debtsolutions/resetState', null, { root: true });
};

<i18n src="../../locales.json"></i18n>
<template>
  <v-flex mt-2 xs12>
    <EditContactInfo
      v-if="showEditContactInfo"
      :email="email"
      :phone="phoneNumber"
      :address1="address"
      :address2="address2"
      :city="city"
      :state="state"
      :zip="zip"
      :is-canada="currentPolicy.isCanadaPolicy"
      :editing-category="editingCategory"
      @closeeditcontact="closeEditContact()"
    />
    <v-card>
      <v-card-title>
        <v-flex>
          <span role="heading" aria-level="2" class="card-title">
            {{ $t('L_policy_policy_owner') }}
          </span>
        </v-flex>
      </v-card-title>

      <v-divider />
      <div
        slot="header"
        ref="contact"
        tabindex="0"
        role="button"
        @click="panelOpen = !panelOpen"
        :aria-expanded="`${panelOpen}`"
        id="policy_view_contact_details_container"
        @click.stop="togglePanel(contactDetailsPanel)"
      >
        <div id="policy_view_contact_details">
          {{ $t('L_policy_view_contact_details') }}
        </div>
      </div>
      <v-card id="policy_owner_card" color="contact-details grey lighten-5 pt-4" :class="$vuetify.breakpoint.xsOnly ? '' : 'pl-4 pr-4'">
        <!-- Start of new Window -->
        <v-layout row wrap pb-3 v-if="allowEdit && !(editContactInfo || editing)" justify-center>
          <v-flex xs12 sm12 md4 pl-4 pr-4>
            <v-layout row wrap justify-left>
              <v-flex xs12 font-size--16 font-weight-bold pb-2>
                {{ $t('L_Name') }}
              </v-flex>
              <v-flex xs12 font-size--16 pb-3>
                {{ policyOwner.name }}
              </v-flex>
              <v-flex xs12 font-size--14>
                <i18n path="L_for_information_on_changing_the_policy_owner_name" tag="p" class="font-weight-regular">
                  <a @click="clickHerePolicyOwner" class="font-weight-bold text-decoration-underline">
                    {{ $t('L_click_here') }}
                  </a>
                </i18n>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md8 pl-4 pr-3>
            <v-layout row wrap justify-left>
              <v-flex xs12 font-size--16 font-weight-bold pb-2>
                {{ $t('L_contact_info') }}
              </v-flex>

              <v-flex xs10 font-size--16 pb-3>
                <span class="inlineText mr-2" pr-2 align-left>
                  <v-icon color="#333333">
                    email
                  </v-icon>
                </span>
                <span class="inlineText" align-left pl-2 v-if="email !== ''">{{ email }}</span>
                <span class="inlineText" align-left pl-2 v-if="email === ''">{{ $t('L_information_not_on_file') }}</span>
              </v-flex>
              <v-flex xs2 font-size--16 pb-3 v-if="allowEdit && !isPending && !(editContactInfo || editing)">
                <a
                  @click="
                    editingCategory = 'email';
                    showEditContactInfo = true;
                  "
                >
                  <span v-if="email !== ''">{{ $t('L_edit') }}</span>
                  <span v-if="email === ''">{{ $t('L_add') }}</span>
                </a>
              </v-flex>

              <v-flex xs10 font-size--16 pb-3>
                <span class="inlineText mr-2" pr-2 align-left>
                  <v-icon color="#333333">
                    phone
                  </v-icon>
                </span>
                <span class="inlineText" align-left pl-2 v-if="phoneNumber !== ''">{{ phoneNumber | phoneFormat }}</span>
                <span class="inlineText" align-left pl-2 v-if="phoneNumber === ''">{{ $t('L_information_not_on_file') }}</span>
              </v-flex>
              <v-flex xs2 font-size--16 pb-3 v-if="allowEdit && !isPending && !(editContactInfo || editing)">
                <a
                  @click="
                    editingCategory = 'phone';
                    showEditContactInfo = true;
                  "
                >
                  <span v-if="phoneNumber !== ''">{{ $t('L_edit') }}</span>
                  <span v-if="phoneNumber === ''">{{ $t('L_add') }}</span>
                </a>
              </v-flex>

              <v-flex xs10 font-size--16 pb-3>
                <span class="inlineText mr-2" pr-2 align-left>
                  <v-icon color="#333333">
                    home
                  </v-icon>
                </span>
                <span class="inlineText" align-left pl-2 v-if="address !== ''">{{ address }},</span>
                <div class="pl_address_line_two" v-if="address2">
                  {{ address2 }},
                </div>
                <div class="pl_address_line_two">
                  {{ city }}, {{ state }} {{ zip }}
                </div>
                <div class="inlineText" align-left pl-2 v-if="address === ''">
                  {{ $t('L_information_not_on_file') }}
                </div>
              </v-flex>
              <v-flex xs2 font-size--16 pb-3 v-if="allowEdit && !isPending && !(editContactInfo || editing)">
                <a
                  @click="
                    editingCategory = 'address';
                    showEditContactInfo = true;
                  "
                >
                  <span v-if="address !== ''">{{ $t('L_edit') }}</span>
                  <span v-if="address === ''">{{ $t('L_add') }}</span>
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- End of new Window -->
      </v-card>
    </v-card>
  </v-flex>
</template>

<script>
// import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';
import { openInNewWindow } from '@/mixins/openNewWindow';
import { Ada } from '@primerica/vue-components'; // Config
// import bff from '@/shared/api/bff';
import EditContactInfo from './EditContactInfo.vue';

export default {
  name: 'PolicyOwner',
  data: () => ({
    editing: false,
    phoneNumber: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    panelOpen: false,
    showEditContactInfo: false,
    editingCategory: '',
  }),
  props: {
    allowEdit: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  filters: {
    phoneFormat(value) {
      if (value.length === 10) {
        return `(${value.substr(0, 3)}) ${value.substr(3, 3)}- ${value.substr(6, 4)}`;
      }
      return '';
    },
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', {
      isPending: 'isPending',
      addressVerification: 'addressVerification',
      addressUpdateSuccess: 'addressUpdateSuccess',
      policyOwner: 'policyOwner',
      currentPolicy: 'currentPolicy',
      contactDetailsPanel: 'contactDetailsPanel',
      editContactInfo: 'editContactInfo',
      selectedPolicyIndex: 'selectedPolicyIndex',
    }),
    canEdit() {
      return (this.editContactInfo || this.editing) && this.allowEdit;
    },
    emailToSend() {
      return this.email.length > 0 ? this.email : ' ';
    },
    getSelectedPolicyIndex() {
      return this.selectedPolicyIndex;
    },
    getEmail() {
      return this.currentPolicy?.data?.clientEmailaddress || this.policyOwner.email || this.customerProfile.email;
    },
    getPhoneNumber() {
      return this.currentPolicy?.data?.phoneNumber || this.policyOwner.address.phone || this.customerProfile.mobilePhone;
    },
    getAddress() {
      return this.currentPolicy?.data?.ownerAddress.address1 || this.policyOwner.address.address1 || '';
    },
    getAddress2() {
      return this.currentPolicy?.data?.ownerAddress.address2 || this.policyOwner.address.address2 || '';
    },
    getCity() {
      return this.currentPolicy?.data?.ownerAddress.city || this.policyOwner.address.city || '';
    },
    getState() {
      return this.currentPolicy?.data?.ownerAddress.state || this.policyOwner.address.state || '';
    },
    getZip() {
      return this.currentPolicy?.data?.ownerAddress.zip || this.policyOwner.address.zip || '';
    },
  },
  mixins: [openInNewWindow],
  methods: {
    ...mapMutations('policy', {
      setAddressUpdateSuccess: 'setAddressUpdateSuccess',
      setAddressVerification: 'setAddressVerification',
      setContactInfoEdit: 'setContactInfoEdit',
    }),
    async clickHerePolicyOwner() {
      let embedUrl = '';
      if (this.locale === 'fr_CA') {
        embedUrl = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt6b695b401207c14e/RightAnswers_Change_Policy_Owner_and_Insured_Name_Help_Article_FRENCH.pdf';
      } else {
        embedUrl = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt11e789f80eb6858d/RightAnswers_Change_Policy_Owner_and_Insured_Name_Help_Article.pdf';
      }
      await this.openInNewWindow({ url: embedUrl, target: 'RADashboardWinName' });
    },

    togglePanel(currentPanelValue) {
      if (currentPanelValue === 0) {
        this.$store.commit('policy/setContactDetailsPanel', -1);
      } else {
        this.$store.commit('policy/setContactDetailsPanel', 0);
      }
    },
    // TODO: Review & update if necessary after policy-ds is converted to mule service

    reset() {
      this.email = this.policyOwner.email || '';
      this.phoneNumber = this.policyOwner.address.phone || '';
      this.address = this.policyOwner.address.address1 || '';
      this.address2 = this.policyOwner.address.address2 || '';
      this.city = this.policyOwner.address.city || '';
      this.state = this.policyOwner.address.state || '';
      this.zip = this.policyOwner.address.zip || '';
      this.editing = false;
      this.setContactInfoEdit(false);
      this.$v.$reset();
    },
    async closeEditContact() {
      this.showEditContactInfo = false;
      this.reset();
    },
  },
  mounted() {
    this.reset();
    Ada.addKeyClick(this.$refs.contact);
  },
  watch: {
    addressUpdateSuccess(newData, oldData) {
      /*
       * Emit a custom action when a user successfully submits an update mobile number request
       * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
       */
      const i = this.$dynatrace.enterAction('policy_contact_details_changes_saved');
      this.$dynatrace.leaveAction(i);
      if (!oldData && newData) {
        this.editing = false;
      }
    },
    getSelectedPolicyIndex(newIndex, oldIndex) {
      if (newIndex !== oldIndex) {
        this.reset();
      }
    },
  },
  components: {
    /* EmailInput,
    Alert,
    PhoneNumberInput,
    AddressInput,
    PostalCodeInput,
    CityInput,
    StateInput,
    TerritoryInput,
    ZipCodeInput, */
    EditContactInfo,
  },
};
</script>

<style lang="stylus" scoped>
@import '~vuetify/src/stylus/main'
.pl_address_line_two {
  padding-left: 32px;
}
.sm-pl-5
  @media $display-breakpoints.sm-and-up
    padding-left: 40px;

@media $display-breakpoints.md-and-up
  .pad-left-md
    padding-left: 12px
  .pad-right-md
    padding-right: 12px

.blue-border {
  border: solid 2px #2196f3;
  cursor pointer;
}

.select-address {
  color: #2196f3 !important;
}

/deep/ label.v-label--active {
  font-weight: 500;
  color: #333;
}

/deep/ div.v-input {
  padding-bottom: 12px;
}

h3.heading_three.font-weight-medium {
  font-weight: 500 !important;
}
.font-size-md {
  font-size: 16px !important;
}
.inlineText {
  display: inline-text;
}
button.short {
  height: 40px !important;
  text-transform: none !important;
  box-shadow: none !important;
  border: 1px #063562 solid;
  width: auto !important;
}
</style>

<i18n src="./locales.json"></i18n>
<template>
  <v-flex class="terminated-policy moveUp" xs12>
    <Alert
      type="error"
      :details="{ message: $t('L_Your_life_coverage_is_no_longer_active') }"
      :dismissible="false"
      :expandable="false"
    />
    <v-flex xs12 pl-4 pr-4 mt-4 mb-4>
      {{ getErrorMessage }}
    </v-flex>
    <v-divider v-if="!this.terminatedPolicyAgent.agentId === false" />
    <Representative :agents="getAgents" v-if="!this.terminatedPolicyAgent.length && !this.terminatedPolicyAgent.agentId === false" />
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from '@/components/Alert.vue';
import Representative from '@/components/Representative.vue';

export default {
  name: 'TerminatedPolicy',
  components: {
    Alert,
    Representative,
  },
  data: () => ({
    status: false,
    showPayInProgressTooltip: false,
  }),
  computed: {
    ...mapGetters('policy', {
      loading: 'policyInfoLoading',
      terminatedPolicyAgent: 'terminatedPolicyAgent',
    }),
    getAgents() {
      return [this.terminatedPolicyAgent];
    },
    getErrorMessage() {
      return !this.terminatedPolicyAgent.length && !this.terminatedPolicyAgent.agentId === false ? this.$t('L_terminated_policy_with_RepInfo') : this.$t('L_terminated_policy_No_RepInfo');
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~vuetify/src/stylus/settings/_variables'
.moveUp
  margin-top: -5px;
.thinLineBottom
  border-bottom: 1px solid gray;
</style>

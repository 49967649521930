import { Session } from '@/shared/util/session';
import { getDefaultState } from './state';

export const setBinaryInvestmentStatement = (state, binaryInvestmentStatement) => {
  state.binaryInvestmentStatement = binaryInvestmentStatement;
};

export const setError = ({ commit, state }, error) => {
  state.error = error;
  commit('setLoading', false);
};

export const setLoading = (state, componentLoading) => {
  if (!componentLoading) {
    Object.keys(state.loading).forEach((key) => {
      state.loading[key] = false;
    });
  } else {
    const component = Object.keys(componentLoading)[0];
    state.loading[component] = componentLoading[component];
  }
};

export const setInvestments = (state, investments) => {
  if (investments.groups) {
    state.investments.groups = investments.groups;
  }
  if (investments.calculator) {
    state.investments.calculator = investments.calculator;
  }
  if (investments.summary) {
    state.investments.summary = investments.summary;
  }
  state.investments.isCompliant = investments.isCompliant;
};

export const setInvestmentCalculator = (state, calculator) => {
  state.investments.calculator = calculator;
};

export const setInvestmentStatements = (state, statements) => {
  state.investments.statements = statements;
};

export const setSamlToken = (state, token) => {
  state.investments.samlToken = token;
};

export const updateCustomerProfile = (state, profile) => {
  state.customerProfile = Object.assign(state.customerProfile, profile);
  if (state.customerProfile.customerId) {
    Session.set('Q_ci', state.customerProfile.customerId);
    if (typeof (window?.BrowserAgentExtension?.setCustomerId) === 'function') {
      window.BrowserAgentExtension.setCustomerId({
        customerId: state.customerProfile.customerId,
      });
    }
  }
};

export const resetState = (state) => {
  Object.assign(state, getDefaultState());
};

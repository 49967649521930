<i18n src="./locales.json"></i18n>
<template>
  <v-layout row wrap class="whitebackground">
    <v-flex xs12 v-if="twoOrMoreDebtsAndMinOneUnpaidDebt">
      <!-- Debt Info -->
      <v-flex xs12>
        <DebtInfo @show-debt-video="turnDebtVideoOn" />
      </v-flex>
      <!-- Debt Stacking Plan -->
      <v-flex xs12>
        <DebtStackingPlan />
      </v-flex>
    </v-flex>
    <v-flex xs12 v-else>
      <v-layout row wrap text-xs-center>
        <v-flex xs12 pl-3 pr-3 pt-3 pb-3 :class="$vuetify.breakpoint.xs ? 'enterYourCurrentMob' : 'enterYourCurrent'">
          {{ $t('L_enter_your_current') }}
        </v-flex>
        <v-flex xs12 mb-5>
          <v-btn
            role="link"
            color="primary"
            @click="goToMyDebt"
            class="valignMiddle createYourPlanBtn"
          >
            {{ $t('L_create_your_plan') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <!-- Contact Rep Footer -->
      <v-flex xs12>
        <ContactRepFooter />
      </v-flex>

      <div v-if="showDebtVideo" class="modal">
        <v-layout row wrap :class="$vuetify.breakpoint.xsOnly?'alertModalMobile':'alertModal'">
          <div xs12 class="verbiagePara">
            <span class="debtStackingLabel">{{ $t('L_Debt_Stacking') }}</span>
            <span class="moveXToRight" @click="closeDebtVideo">X</span>
          </div>
          <div :class="$vuetify.breakpoint.xsOnly?'videoMobile':'video'">
            <iframe
              :class="$vuetify.breakpoint.xsOnly?'iframeVideoMobile':'iframeVideo'"
              :src="$t('L_video_url')"
              title="video"
              frameborder="0"
              allowfullscreen
              allow="accelerometer; encrypted-media; gyroscope"
            />
          </div>
          <v-flex xs12 class="videoCloseBtn" @click="closeDebtVideo">
            {{ $t('L_close') }}
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/components/Alert.vue';
import ContactRepFooter from '@/components/ContactRep/ContactRepFooter.vue';
import DebtInfo from './components/DebtInfo.vue';
import DebtStackingPlan from './components/DebtStackingPlan.vue';

export default {
  name: 'MyPlan',
  components: {
    DebtInfo,
    DebtStackingPlan,
    ContactRepFooter,
    Alert,
  },
  data: () => ({
    iSliderIndex: 0,
    contactYourRep: false,
    policy: {},
    showDebtVideo: false,
  }),
  computed: {
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters({
      step: 'debtsolutions/step',
      planStatus: 'debtsolutions/planStatus',
      hasNoPlan: 'debtsolutions/hasNoPlan',
      hasActivePlan: 'debtsolutions/hasActivePlan',
      hasCompletedPlan: 'debtsolutions/hasCompletedPlan',
      twoOrMoreDebtsAndMinOneUnpaidDebt: 'debtsolutions/twoOrMoreDebtsAndMinOneUnpaidDebt',
      unpaidDebts: 'debtsolutions/unpaidDebts',
    }),
  },
  methods: {
    ...mapActions('debtsolutions', {
      debtInit: 'debtInit',
      setStepToStart: 'setStepToStart',
    }),
    goToMyDebt() {
      if (this.$route.path !== '/debtsolutions/mydebt') {
        this.$router.push({ name: 'TermLifeBenefits' }).then(() => {
          this.$router.push({ name: 'MyDebt' });
        });
      }
    },
    turnDebtVideoOn() {
      this.showDebtVideo = true;
    },
    closeDebtVideo() {
      this.showDebtVideo = false;
    },
  },
};
</script>

<style scoped lang="stylus">
.whitebackground
  background-color: white;
.backgroundGray
  background-color: #eeeeee;
.valignMiddle
  zvertical-align: middle;
  margin: auto !important;
.agentImage
  height: 80px;
  vertical-align: middle;
.your_representative_can_help_css
  color: #4a4a4a;
  font-size: 24px;
  line-height: 1.5;
.enterYourCurrent
  font-size: 20px;
  color: #333333;
  line-height: 1.5;
.enterYourCurrentMob
  font-size: 16px;
  color: #333333;
  line-height: 1.5;
.modal
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

.alertModal
  position: fixed;
  height: 500px;
  width: 760px;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
.video
  width: 650px;
  height: 392px;
  margin: auto;
  margin-top: 0px;
.iframeVideo
  width: 100%;
  max-width: 770px;
  height: 390px;
.alertModalMobile
  position: fixed;
  width: 320px;
  height: 235px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
.videoMobile
  width: 300px;
  height: 170px;
  margin:auto;
  margin-top: 0px;
iframeVideoMobile
  width: 100%;
  height: 180px;
  max-width: 320px;
.verbiagePara
  color: #02579e;
  font-size: 24px;
  padding-left: 20px;
  width: 100%;
  height: 40px;
.infoTitle
  float: right;
  position: relative;
.modal-content iframe
  margin: 0 auto;
  display: block;
.videoCloseBtn
  text-align: right;
  padding-right: 30px;
  font-size: 18px;
  color: #02579e;
  font-weight: 600;
.debtStackingLabel
  text-align: left;
  float: left;
  font-weight: 600;
.moveXToRight
  text-align: right;
  float: right;
  font-weight: 600;
  padding-right: 25px;
.modal
  position: fixed; /* Stay in place */
  z-index: 11; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
.createYourPlanBtn
  width: 268px;
</style>

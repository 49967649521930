<i18n src='./locales.json'></i18n>

<template>
  <div class="background">
    <SessionDialog :external-page="false" />
    <!-- openVerifyPhone && canDisplayVerifyPhoneWrapper" -->
    <!-- :open-modal="openVerifyPhone && canDisplayVerifyPhoneWrapper" -->
    <!-- @close-verify-phone-wrapper="closeVerifyPhoneWrapper" -->
    <!-- These are three modals Terms, PolicyAck, Paperless (Ecomm) -->
    <GoPaperlessAction @success="handleGoPaperlessActionSuccess" @error="handleGoPaperlessActionError" @snooze="handleGoPaperlessActionSnooze" />
    <!--VerifyPhone Modal -->
    <VerifyPhoneWrapper
      @show-successful-verify="handleCallsGetAlerts"
      :allow-edit="true"
      @close-verify-phone-dialog="closeVerifyPhoneDialog"
      ref="verifyaccdash"
    />
    <NavigationConfirmation v-if="getShowNavigationConfirmation" />
    <SuccessVerifyPhone v-if="showSuccessfulVerify" @close-success-verify-phone="closeSuccessVerifyPhone" />
    <Header
      :investments-active="hasInvestments"
      :investments-action="investmentsAction"
      :term-life-active="hasTermLife"
      :term-life-action="termLifeAction"
      :home-action="goToMyFinancialHome"
      :rep-action="repAction"
      :help-active="helpFlag"
      :help-action="helpAction"
      :languages="languages"
      :language-active="languageFlag"
      :current-locale="currentLocale"
      :user-initial="getUserInitial"
      :selected-item="getSelectedItem"
      @reupdate-locale="updateLocale"
      :show-rep-link="showRepLink"
      :debt-sol-active="hasActiveDebt"
      :debt-sol-action="debtAction"
      :alert-and-notification-action="alertAndNotificationAction"
      :documents-action="documentsAction"
      :notificationwarn="notificationwarn"
      :hide-all-but-locale="openTermsOfUse || openPolicyAck"
      :mobile="isMobileApp"
    />

    <LeftSidebar
      v-if="!openTermsOfUse && !openPolicyAck"
      :term-life-active="hasTermLife"
      :term-life-action="termLifeAction"
      :investments-active="hasInvestments"
      :investments-action="investmentsAction"
      :agent="agent"
      :redirect-to-dashboard="goToMyFinancialHome"
      @contact-rep="sendEmail"
      :customer="customerProfile"
      :debt-sol-active="hasActiveDebt"
      :debt-sol-action="debtAction"
    />

    <v-content
      :style="style"
      id="main_content"
      tabindex="-1"
      :class="$vuetify.breakpoint.mdAndDown ?'margin_for_main_content':''"
    >
      <Notifications
        @call-verify-phone-wrapper="showVerifyPhone"
        @view-notification="showNotificationDetail"
        v-if="isDashboard && this.alertsAndNotifications.length > 0 && !openTermsOfUse && !openPolicyAck" context="noticeboard"
      />
      <NotificationDetail
        v-if="showNotificationDetailDialog"
        :show-notification-detail="showNotificationDetailDialog"
        :notification-id="notificationDetailId"
        @accept-action-clicked="hideNotificationDetail"
        @reject-action-clicked="hideNotificationDetail"
        @dismiss-action-clicked="hideNotificationDetail"
      />
      <Alerts context="app" />
      <SpanishBanner v-if="spanishBanner" />
      <!-- TODO: remove if unused -->
      <TermsOfUseModal
        v-if="openTermsOfUse" context="noticeboard"
      />
      <!-- TODO: remove if unused -->
      <PolicyAcknowledgementAction
        v-if="openPolicyAck"
        :show="openPolicyAck"
        :policynumber="getPolicyAckNotShown"
        @mark-as-shown="setShownFlag"
      />
      <v-container
        v-if="!isDashboard"
        pt-0
        pb-0
      >
        <v-layout
          v-if="goBackText != ''"
        >
          <v-flex
            xs12
            mt-4
          >
            <a
              @click="goBack"
              class="return-home-link font-size--16 font-weight-regular"
              href="javascript:void(0)"
            >
              <v-icon>chevron_left</v-icon>{{ goBackText }}
            </a>
          </v-flex>
        </v-layout>
      </v-container>

      <router-view v-if=" !openTermsOfUse && !openPolicyAck" />
    </v-content>
    <Footer :is-mobile="isMobileApp"
            :show-domestic-violence-card="showDomesticViolenceCard"
            :current-locale="currentLocale"
            v-if=" !openTermsOfUse && !openPolicyAck"
    />
    <Qualtrics :language="currentLocale" zv-if=" !openTermsOfUse && !openPolicyAck" />
  </div>
</template>

<script>

import GoPaperlessAction from '@/components/GoPaperlessAction/GoPaperlessAction.vue';
import PolicyAcknowledgementAction from '@/components/PolicyAcknowledgementAction/PolicyAcknowledgementAction.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LeftSidebar, FullScreenLoading, sendRepEmail } from '@primerica/vue-components';
import Header from '@/components/Header/Header.vue';
import Config from '@/shared/util/config';
import Alerts from '@/components/Alerts.vue';
import Qualtrics from '@/components/Qualtrics.vue';
import { COUNTRIES } from '@/shared/constants';
import bff from '@/shared/api/bff';
import { openInNewWindow } from '@/mixins/openNewWindow';
import SessionDialog from '@/components/SessionDialog/SessionDialog.vue';
import TermsOfUseModal from '../components/TermsOfUseModal/TermsOfUseModal.vue';
import VerifyPhoneWrapper from '../components/VerifyPhoneWrapper/VerifyPhoneWrapper.vue';
import Notifications from '../components/MyNotifications/Notifications.vue';
import NotificationDetail from '../components/MyNotifications/NotificationDetail.vue';
import SpanishBanner from '../components/SpanishBanner.vue';
import SuccessVerifyPhone from '../modules/home/myaccount/components/successVerifyPhone/successVerifyPhone.vue';
import NavigationConfirmation from '../components/NavigationConfirmation/NavigationConfirmation.vue';
import Footer from '../components/Footer/Footer.vue';

export default {
  components: {
    PolicyAcknowledgementAction,
    GoPaperlessAction,
    Header,
    Footer,
    LeftSidebar,
    SessionDialog,
    FullScreenLoading,
    TermsOfUseModal,
    VerifyPhoneWrapper,
    Alerts,
    Qualtrics,
    Notifications,
    NotificationDetail,
    SpanishBanner,
    SuccessVerifyPhone,
    NavigationConfirmation,
  },
  mixins: [openInNewWindow],
  data: () => ({
    languages: [],
    languagesPrefs: Config.get('languagePreferences'),
    helpRef: Config.get('help.authenticated'),
    showSuccessfulVerify: false,
    showNotificationDetailDialog: false,
    notificationDetailId: null,
    // showVerifyPhoneWrapper: false,
  }),
  computed: {
    ...mapGetters({
      alertsAndNotifications: 'alertsAndNotifications',
      getShowNavigationConfirmation: 'getShowNavigationConfirmation',
    }),
    ...mapGetters({
      tasks: 'tasks',
      currentLocale: 'getLocale',
      containsFeature: 'containsFeature',
      spanishBanner: 'spanishBanner',
      isShowGoPaperlessWrapper: 'isShowGoPaperlessWrapper',
      isIos: 'isIos',
      isAndroid: 'isAndroid',
    }),
    ...mapGetters('customer', [
      'investments',
      'customerProfile',
      'investmentsSortedByDate',
    ]),
    ...mapGetters(['getAgent', 'notifications', 'agents']),
    ...mapGetters('policy', {
      policies: 'policies',
      policyData: 'currentPolicy',
      beneficiaryStep: 'beneficiaryStep',
      selectedPolicyIndex: 'selectedPolicyIndex',
      policiesSortedByDate: 'policiesSortedByDate',
      paymentStep: 'paymentStep',
    }),
    ...mapGetters('debtsolutions', {
      unpaidDebts: 'unpaidDebts',
      paidOffDebts: 'paidOffDebts',
    }),
    isMobileApp() {
      return this.isIos || this.isAndroid;
    },
    notificationwarn() {
      return this.alertsAndNotifications?.length > 0;
    },
    helpFlag() {
      return this.containsFeature('HELP');
    },
    showRepLink() {
      return this.agents?.length > 0;
    },
    languageFlag() {
      if (this.containsFeature('LANGUAGE-TOGGLE')) {
        if (this.languages?.length > 1) {
          return true;
        }
      }
      return false;
    },

    getUserInitial() {
      let initial = '';
      if (this.customerProfile?.firstName && this.customerProfile.firstName.length > 0) {
        initial += this.customerProfile.firstName[0].toUpperCase();
      }
      if (this.customerProfile?.lastName && this.customerProfile.lastName.length > 0) {
        initial += this.customerProfile.lastName[0].toUpperCase();
      }
      return initial;
    },
    getSelectedItem() {
      if (this.$route) {
        switch (this.$route.name) {
          case 'Dashboard':
            return 'Home';
          case 'PolicyDetails':
          case 'GuidanceCalculator':
          case 'TermLifeBenefits':
            return 'Life Insurance';
          case 'Investments':
            return 'Investments';
          case 'LearnMoreDebt':
          case 'MyDebt':
          case 'MyPlan':
            return 'Debt Solutions';
          case 'Representative':
            return 'My Rep';
          case 'Documents':
          case 'DocumentListMobile':
          case 'NoticesAndConfirmations':
          case 'PolicyDocuments':
          case 'InvestmentStatements':
            return 'Documents';
          case 'MyAccount':
            return 'Account Settings';
          case 'AlertAndNotifications':
            return 'Notifications';
          default:
            return '';
        }
      }
      return '';
    },
    goBackText() {
      if (!this.isDashboard) {
        if (this.isDocumentListRoute) {
          return this.$t('L_back');
        }
        return ''; // this.$t('L_return_to_financial_home');
      }
      return '';
    },
    isDashboard() {
      return this?.$route?.name === 'Dashboard';
    },
    isDocumentListRoute() {
      return this?.$route?.name === 'DocumentListMobile';
    },
    hasInvestments() {
      return this.investments.summary.totalValue > 0;
    },
    hasTermLife() {
      return this.policies.length > 0;
    },
    hasActivePolicies() {
      return this.policies.filter(policy => policy?.statusText === 'ACTIVE').length > 0;
    },
    // TODO: remove if unused
    openTermsOfUse() {
      let foundTermsOfUse = false;
      if (this.tasks.find(a => a.name === 'TERMS_OF_USE')) {
        foundTermsOfUse = true;
      }
      return foundTermsOfUse;
    },
    // TODO: remove if unused
    openPolicyAck() {
      let foundPOLICYACK = false;
      if (!this.openTermsOfUse && this.tasks.find(a => a.name === 'POLICY_ACK' && a.shownFlag === false)) {
        foundPOLICYACK = true;
      }
      return (
        foundPOLICYACK
        // && this.filteredPolicies?.length > 0
      );
    },
    getPolicyAckNotShown() {
      const idx = this.tasks.findIndex(a => a.name === 'POLICY_ACK' && a.shownFlag === false);
      if (idx !== -1) {
        return this.tasks[idx].policyNumbers[0];
      } return '';
    },
    openVerifyPhone() {
      let foundVerifyPhone = false;
      if (this.tasks.find(a => a.name === 'VERIFY_PHONE' && a.shownFlag === false)) {
        foundVerifyPhone = true;
      }

      const result = (
        foundVerifyPhone &&
        this.$store.getters.containsFeature('CUST-VERIFYPHONE'));
      return result;
    },
    style() {
      const style = {
        paddingTop: 0,
        paddingBottom: '96px',
      };
      if (this.openTermsOfUse || this.openPolicyAck) {
        style.marginLeft = '0px';
        style.paddingTop = '64px';
        style.marginTop = '50px';
        style.height = '100%';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        style.marginLeft = '238px';
        style.paddingTop = '64px';
      } return style;
    },
    // Returning if isCanadaPolicy for the currentPolicy selected on Life
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    showDomesticViolenceCard() {
      return this.customerProfile?.country !== COUNTRIES.CANADA;
    },
    notRestrictedOrCA() {
      const filteredPolicies = this.policies?.filter(policy => !policy?.isCanadaPolicy && !policy?.restricted && policy?.contractId !== 0);
      return filteredPolicies.map(policy => policy?.contractId);
    },
    filteredPolicies() {
      return this?.policies?.filter(policy => this.notRestrictedOrCA?.includes(policy?.contractId));
    },
    agent() {
      return (this.agents?.length === 1) ? this.agents[0] : {};
    },
    hasActiveDebt() {
      return this?.unpaidDebts?.length > 0;
    },
    hasCompletedDebt() {
      return this?.unpaidDebts?.length === 0 && this?.paidOffDebts?.length > 0;
    },
    isDebtSolutionLinkActive() {
      // B-31053 Home Page | Left Pane, Mobile Menu and Header Navigation Logic
      // https://www7.v1host.com/Primerica/story.mvc/Summary?oidToken=Story%3A269334&RoomContext=TeamRoom%3A245632&concept=TeamRoom
      // The logic updates will allow the links for these sections to always be active / enabled
      return true;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    ...mapActions(['getDocuments', 'getAlerts', 'dismissAlert', 'completeAlert']),
    ...mapActions(['changeLocale', 'addNotification', 'removeNotification', 'removeNotifications']),
    ...mapMutations(['setRootLoading', 'updateSpanishBanner', 'setVerifyPhonePageToLand', 'setShowVerifyPhoneWrapper']),
    handleCallsGetAlerts() {
      // this.showVerifyPhoneWrapper = false;
      this.setShowVerifyPhoneWrapper(false);
      this.getAlerts();
      this.showSuccessfulVerify = true;
    },
    closeVerifyPhoneDialog() {
      this.setShowVerifyPhoneWrapper(false);
      this.getAlerts();
      this.showSuccessfulVerify = false;
    },
    closeSuccessVerifyPhone() {
      this.showSuccessfulVerify = false;
    },
    showNotificationDetail(alertId) {
      this.showNotificationDetailDialog = true;
      this.notificationDetailId = alertId;
    },
    async hideNotificationDetail() {
      this.showNotificationDetailDialog = false;
      this.notificationDetailId = -1;
      await this.getAlerts();
    },
    showVerifyPhone() {
      // this.$refs.verifyaccdash.gotoPageOne();
      this.setVerifyPhonePageToLand('first');
      this.setShowVerifyPhoneWrapper(true);
    },
    handleGoPaperlessActionSuccess() {
      this.getAlerts();
    },
    handleGoPaperlessActionError() {
      this.getAlerts();
    },
    handleGoPaperlessActionSnooze() {
      this.getAlerts();
    },
    investmentsAction() {
      if (this.hasInvestments) {
        this.$router.push({ path: '/investments' });
      } else {
        this.$router.push({ path: '/investments/learn-more' });
      }
    },
    termLifeAction() {
      if (this.policies.length > 0) {
        this.$router.push({ name: 'PolicyDetails', params: { contractId: this.selectedPolicyIndex } });
      } else {
        this.$router.push({ path: '/benefits' });
      }
    },
    debtAction() {
      if (this.hasActiveDebt) {
        this.$router.push({ name: 'MyDebt' });
      } else {
        this.$router.push({ name: 'LearnMoreDebt' });
      }
    },
    repAction() {
      // This is to handle beneficiary page not being shown then go to My Rep
      // if (this.beneficiaryStep === -1 && this.paymentStep === -1) {
      this.$router.push({ name: 'Representative' });
      // }
    },
    async helpAction() {
      if (this.helpFlag) {
        const helpUrl = await bff.getRightAnswersHelpUrl();
        const encodedURL = encodeURI(helpUrl?.data?.URL);
        await this.openInNewWindow({ url: encodedURL, target: 'RADashboardWinName' });
      }
    },
    alertAndNotificationAction() {
      this.$router.push({ name: 'AlertAndNotifications' });
    },
    documentsAction() {
      try {
        const i = this.$dynatrace.enterAction('link_documents_header');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      if (this.isMobile) {
        this.$router.push({ name: 'Documents' });
      } else {
        this.$router.push({ name: 'NoticesAndConfirmations' }); // default
      }
    },
    goBack() {
      if (!this.isDashboard) {
        if (this.isDocumentListRoute && this.isMobile) {
          this.$router.push({ name: 'Documents' });
        } else {
          this.goToMyFinancialHome();
        }
      }
    },
    goToMyFinancialHome() {
      this.$router.push({ name: 'Dashboard' });
    },
    sendEmail(payload) {
      sendRepEmail.bind(this)(bff.sendRepEmail, payload);
    },
    updateLocale(language) {
      this.changeLocale(language);
      if (language?.indexOf('es') > -1) {
        this.updateSpanishBanner(true);
      } else {
        this.updateSpanishBanner(false);
      }
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
    setShownFlag(policyNum) {
      // To find a specific object in an array of objects
      const idx = this.tasks.findIndex(a => a.name === 'POLICY_ACK' && a.policyNumbers[0] === policyNum);
      if (idx !== -1) {
        this.tasks[idx].shownFlag = true;
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('policy/getPolicyOverview');
      this.getAlerts();
      this.setRootLoading(false);
      this.validateLanguagePreference();
      if (this.currentLocale?.indexOf('es') > -1) {
        this.updateSpanishBanner(true);
      }
      window.setTimeout(async () => { await this.getDocuments(); }, 0);
      await this.$store.dispatch('childrider/crcInit');
    } catch (e) {}
  },
};
</script>

<style lang="stylus" scoped>
  .background {
    min-height: 100%;
    background-image: linear-gradient(to top, #607c97, #063562);
  }
  .return-home-link
    color: #fff !important;
    .v-icon
      color: #fff
      font-size: 2em
      line-height: .6em
      margin-left: -8px
  #alerts {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 1;
  }
  .margin_for_main_content {
    margin-top: 85px;
  }
</style>

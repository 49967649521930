<i18n src="./locales.json"></i18n>

<template>
  <v-content
    style="padding: 0 !important"
    aria-label="Login landing page"
    class="fullHeight noTopMargin fullWidth_whiteBackground"
  >
    <v-container fluid class="fullWidth_whiteBackground mx-auto mt-4 mt-md-6 mb-4 pb-4 zfullHeight">
      <Alerts context="vault" />
      <v-layout row wrap>
        <v-flex xs12 text-xs-right>
          <locale-toggle
            :current-locale="currentLocale"
            :languages="languages"
            :display="languageFlag"
            :display-icon="true"
            @update-locale="updateLocale"
            submenu-location=""
          />
        </v-flex>
      </v-layout>
      <div v-if="isLoading" class="centerProgress">
        <v-progress-circular indeterminate :size="size" :width="width" :color="color" />
      </div>
      <div v-else>
        <div v-if="isProcessingUserEvent" class="centerProgress">
          <v-progress-circular indeterminate :size="size" :width="width" :color="color" />
        </div>
        <v-layout row wrap text-xs-center pt-4 mt-4 mb-4>
          <v-flex xs12>
            <div>
              <img
                src="@/assets/primerica-logo.svg"
                height="70"
                alt="Primerica Beta"
              >
            </div>
          </v-flex>
          <v-flex xs12>
            <div id="unsupported_browser_title" class="display-2 mt-4 mb-4 ml-4 mr-4 text-md-display-3 font-weight-regular text-center mt-sm-12">
              <span class="title">
                <span v-if="isTouchId">{{ $t('L_sign_in_fingerprint_id') }}</span>
                <span v-else-if="isFaceId">{{ $t('L_sign_in_with_face_id') }}</span>
              </span>
            </div>
            <v-layout row wrap text-xs-center>
              <v-flex xs12 md4 pb-4>
                <div class="title font-weight-regular text-xs-center">
                  <v-img v-if="isTouchId"
                         contain
                         height="150"
                         width="150"
                         alt="Primerica"
                         class="mx-auto mb-2"
                         src="../assets/touchId.png"
                  />
                  <v-img v-else-if="isFaceId"
                         contain
                         height="150"
                         width="150"
                         alt="Primerica"
                         class="mx-auto mb-2"
                         src="../assets/faceId.png"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 justify-center mt-4 mb-4>
            <v-btn large block color="primary" @click="biometricsLogin" class="title btnWidth subheading font-weight-medium">
              {{ isTouchId ? $t( 'L_use_fingerprint_id') : $t( 'L_use_face_id') }}
            </v-btn>
          </v-flex>
          <v-flex xs12 justify-center mb-4>
            <a href="javascript:void(0)" class="subheading font-weight-medium" @click="goToOktaSignIn" :aria:label="$t('L_ill_use_my_password')">{{ $t('L_ill_use_my_password') }}</a>
          </v-flex>
        </v-layout>
        <div class="BottomStuck">
          <Footer
            :is-mobile="'false'"
            :show-domestic-violence-card="showDomesticViolenceCard"
            :current-locale="currentLocale"
          />
        </div>
      </div>
    </v-container>
  </v-content>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Alerts from '@/components/Alerts.vue';
import { COUNTRIES } from '@/shared/constants';
import Config from '@/shared/util/config';
import LocaleToggle from '@/components/LocaleToggle/LocaleToggle.vue';
import { hasFaceId, hasTouchId, canUseBiometrics, hasStrongBiometricsStrengthLevel, canUnlock, unlockVaultWithBiometrics } from '@/plugins/auth/utils/sessionManager';
import Footer from '../../../components/Footer/Footer.vue';

export default {
  name: 'LoginLandingPage',
  components: {
    LocaleToggle,
    Footer,
    Alerts,
  },
  props: {
    color: {
      type: String,
      default: '#063562',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 70,
    },
    width: {
      type: Number,
      default: 7,
    },
  },
  data: () => ({
    isLoading: true,
    isProcessingUserEvent: false,
    isFaceId: false,
    isTouchId: false,
    canUseFaceId: false,
    canUseTouchId: false,
    biometricsStrengthStrong: false,
    canUseBiometricsAndNoPreferenceYet: false,
    languagesPrefs: Config.get('languagePreferences'),
    languages: [],
    useBiometrics: false,
  }),
  computed: {
    ...mapGetters('customer', [
      'customerProfile',
    ]),
    ...mapGetters({
      currentLocale: 'getLocale',
      containsFeature: 'containsFeature',
      isIos: 'isIos',
      isAndroid: 'isAndroid',
    }),
    languageFlag() {
      if (this.containsFeature('LANGUAGE-TOGGLE')) {
        if (this.languages?.length > 1) {
          return true;
        }
      }
      return false;
    },
    isMobileApp() {
      return this.isIos || this.isAndroid;
    },
    showDomesticViolenceCard() {
      return this.customerProfile?.country !== COUNTRIES.CANADA;
    },
  },
  methods: {
    ...mapActions(['changeLocale', 'goToLogin']),
    async biometricsLogin() {
      if (!this.isProcessingUserEvent) {
        this.isProcessingUserEvent = true;
        window.setTimeout(() => {
          this.isProcessingUserEvent = false;
        }, 3000);
        await unlockVaultWithBiometrics()
          .then(() => {
            this.$router.push({ name: 'Dashboard' });
          })
          .catch(async (err) => {
            try {
              this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[LoginLandingPage] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
              await this.$store.dispatch('postAuditLog', {
                actionId: 'MobileApp_Device_FailureAction', // optional
                details: `[${Date.now()}] ERROR CODE ${err?.code}`, // Additional message stored - optional
                message: err?.message, // Error type - optional
              });
            } catch (error) { /*  */ }
          });
        this.isProcessingUserEvent = false;
      }
    },
    updateLocale(language) {
      this.changeLocale(language);
    },
    validateLanguagePreference() {
      if (!this.languagesPrefs || this.languagesPrefs.length === 0) {
        this.languagePrefs = ['en_US', 'en_CA'];
      }

      if (this.customerProfile.country === COUNTRIES.CANADA) {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_CA') > 0);
        if (this.currentLocale?.indexOf('_CA') === -1) {
          this.changeLocale('en_CA');
        }
      } else {
        this.languages = this.languagesPrefs?.filter(lang => lang.indexOf('_US') > 0);
        if (this.currentLocale?.indexOf('_US') === -1) {
          this.changeLocale('en_US');
        }
      }
    },
    async goToOktaSignIn() {
      if (!this.isProcessingUserEvent) {
        this.isProcessingUserEvent = true;
        window.setTimeout(() => {
          this.isProcessingUserEvent = false;
        }, 3000);
        await this.goToLogin().catch((err) => {
          try {
            this.$dynatrace.reportCustomError('PC2_MOBILE_ERR', `[LoginLandingPage - Login failed] ${err?.code}: ${err?.message || JSON.stringify(err)}`);
          } catch (error) { /*  */ }
        });
        this.$router.push({ name: 'Dashboard' });
        this.isProcessingUserEvent = false;
      }
    },
  },
  async mounted() {
    this.validateLanguagePreference();

    // Check to show for biometrics
    const isNativePlatform = this.$pc2capacitor.isNativePlatform();
    if (isNativePlatform) {
      await Promise.all([hasFaceId(), hasTouchId(), canUseBiometrics(), hasStrongBiometricsStrengthLevel(), canUnlock()])
        .then(([_hasFaceId, _hasTouchId, _canUseBiometrics, _hasStrongBiometricsStrengthLevel, _canUnlock]) => {
          this.isFaceId = _hasFaceId;
          this.isTouchId = _hasTouchId;
          this.canUseBiometricsAndNoPreferenceYet = _canUnlock && _canUseBiometrics && _hasStrongBiometricsStrengthLevel;
          window.setTimeout(() => {
            this.isLoading = false;
          }, 2800);
        });
    }
  },
};
</script>

<style lang="stylus" scoped>
.backgroundWhite {
  background-color: #FFFFFF !important;
}
.btnWidth {
  max-width: 300px !important;
  width: 100%;
  margin: auto;
}
#main_content {
  height: 100% !important;
}
.fullHeight {
  height: 100vh !important;
}
.noTopMargin {
  margin-top: -15px !important;
}
.BottomStuck {
  width: 100%;
  position: fixed;
  bottom: 0px !important;
  background-color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: -20px !important;
  margin-right: -20px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  z-index: 1;
  white-space: normal;
  @media only screen and (min-width: 600px) {
    z-index: 2;
  }
}
.pointer-mouse {
  cursor: pointer;
}
.plpr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.greyLink {
  color: gray !important;
}
.link {
  font-weight: 600;
  color: #02579e;
  cursor: pointer;
}
.blueBackground {
  background-color: #E2F0FE !important;
  color: #063562 !important;
}
.fullWidth_whiteBackground {
  background-color: #FFFFFF !important;
  max-width: 100% !important;
  @media only screen and (min-width: 760px) {
    zmax-width: 75% !important;
  }
}
#unsupported_browser_title {
  @media only screen and (max-width: 760px) {
    font-size: 32px !important;
  }
}
#supportedBrowsers p {
  color: #333 !important;
}
#supportedBrowsers a.text-decoration-none {
  text-decoration: none !important;
}
a.underline-link {
  text-decoration:underline !important;
}
.pad-bottom {
  padding-bottom: 100px;
}
.centerProgress {
  left: calc(50% + -38px);
  top: 50vh;
  position: absolute;
}
</style>

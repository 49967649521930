<i18n src="../locales.json"></i18n>
<template>
  <v-content class="pt-0">
    <Alerts context="payment" />
    <!-- Disclaimer for Credit Card / Debit Card Kubra before launching a new tab-->
    <v-dialog v-model="dialog" max-width="880" :persistent="persistent" class="ml-4">
      <v-card class="orangeTop">
        <v-card-title class="text-xs-center font-size--20 font-weight-bold mt-4">
          <span class="centerText mt-4 pt-4">{{ $t('L_You_are_about_to_leave_MyPrimerica') }}</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <span class="font-size--16 font-weight-bold">{{ $t('L_You_will_need_the_following_information_to_make_your_payment') }}</span>
            </v-flex>
            <v-flex xs12 mt-4>
              <v-layout row wrap font-weight-regular>
                <v-flex xs12 sm2 mb-4>
                  <span class="font-size--12" v-if="isCanadaPolicy">{{ $t('L_Postal_Code') }}</span>
                  <span class="font-size--12" v-else>{{ $t('L_Zip_Code') }}</span>
                  <div xs2 class="font-size--18 font-weight-bold">
                    {{ zipCode }}
                  </div>
                </v-flex>
                <v-flex xs12 sm10 mb-4>
                  <span class="font-size--12">{{ $t('L_Policy_Number') }}</span>
                  <div xs10 class="font-size--18 font-weight-bold" @click="copyPolicyToClipboard()">
                    {{ contractId }}
                    <span class="clickable font-size--16 font-weight-bold blue_label">
                      <v-icon color="#02579e">content_copy</v-icon> {{ $t('L_copy') }}
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 mt-4 mb-4>
              <span class="font-size--16 font-weight-bold mt-4">{{ $t('L_Important_information_regarding_your_account') }}</span>
            </v-flex>
            <v-flex xs12 font-size--12>
              <ul>
                <li class="spaceBelow10">
                  {{ $t('L_Payments_will_show_on_your_statement_as_Primerica_EZ') }}
                </li>
                <li class="spaceBelow10">
                  {{ $t('L_all_payments_made_through_this_website_are_processed') }}
                </li>
                <li class="spaceBelow10">
                  {{ $t('L_Payments_made_before_4PM_ET_typically_will_be_posted_the_following_business_day') }}
                </li>
                <li class="spaceBelow10">
                  {{ $t('L_Payments_made_after_4PM_ET_typically_will_be_posted_no_later_than_2_business_days_after_payment') }}
                </li>
                <li class="spaceBelow10">
                  {{ $t('L_Payments_made_on_or_after_the_premium_due_date_will_not_prevent_the_Life_Insurance_policy_from_lapsing') }}
                </li>
                <li class="spaceBelow10">
                  {{ $t('L_By_Clicking_Continue_below_you_consent_to_Primerica_sharing_your_account_information') }}
                </li>
              </ul>
            </v-flex>
            <v-flex xs12 mt-4 mb-4>
              <div class="font-size--14 font-weight-bold">
                {{ $t('L_Third_Party_Website_Disclaimer') }}
              </div>
              <div class="font-size--12 font-weight-regular">
                {{ $t('L_By_clicking_I_Agree_you_will_enter_a_website_operated_by_an_entity_not_affiliated_with_Primerica') }}
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-layout row wrap class="justify-end">
            <v-flex xs0 sm0 md4>
              &nbsp;
            </v-flex>
            <v-flex xs12 sm12 md4 mb-4 pr-4>
              <v-btn
                block
                color="secondary"
                @click="dialog = false"
                class="maxwidthButton"
              >
                {{ $t('L_cancel') }}
              </v-btn>
            </v-flex>
            <v-flex xs12 sm12 md4 mb-4 pr-4>
              <v-btn
                block
                color="primary"
                class="white--text mb-3 maxwidthButton"
                @click="goToNextStep()"
              >
                {{ $t('L_I_agree') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Check image showing routing and account number-->
    <v-dialog v-model="showcheck" max-width="880" :persistent="persistent">
      <v-card class="orangeTop">
        <v-card-title class="mb-4">
          <v-layout row wrap>
            <v-flex xs12 class="text-xs-right" @click="showcheck = false">
              <v-icon>close</v-icon>
            </v-flex>
            <v-flex xs12 class="headline text-xs-center fontWeight600 font-size--24">
              {{ $t('L_how_to_find_the_routing_and_account_number') }}
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-layout row wrap ml-4 mr-4>
          <!-- <v-flex class="checkCSS">
          </v-flex> -->
          <img src="./check-mobile.svg" alt="check" :class="$vuetify.breakpoint.xsOnly?'mobileCheckImg': 'checkImg'">
        </v-layout>
        <div :class="$vuetify.breakpoint.xsOnly?'mobileCheckImg':'checkImg'">
          <v-layout row wrap>
            <v-flex xs6 class="text-xs-center font-weight-bold font-size--24">
              |
            </v-flex>
            <v-flex xs6 class="text-xs-center font-weight-bold font-size--24 pl-2">
              |
            </v-flex>

            <v-flex xs6 :class="$vuetify.breakpoint.xsOnly?'text-xs-left':'text-xs-center'">
              {{ $t('L_routing_number') }}
            </v-flex>
            <v-flex xs6 class="pl-2" :class="$vuetify.breakpoint.xsOnly?'text-xs-right':'text-xs-center'">
              {{ $t('L_account_number') }}
            </v-flex>
          </v-layout>
        </div>
        <v-layout row wrap mt-4 mb-4>
          <v-flex xs12>
            <v-btn
              block
              class="white--text mb-3 maxwidthButton2 centerButton"
              @click="showcheck=false"
              color="primary"
            >
              {{ $t('L_ok') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout row wrap mb-4>
      <v-flex xs12 v-if="selectedAmountOverLimit">
        <!-- Amount 2500 or higher -->
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
          color="info"
          :value="true"
        >
          <v-layout row wrap>
            <v-flex xs11 sm11 md11 lg11>
              <div class="text-color">
                {{ $t('L_credit_and_debit_card_payment_option_is_not_available') }}
              </div>
            </v-flex>
          </v-layout>
        </v-alert>
      </v-flex>
      <v-flex xs12 mb-4 font-weight-bold>
        <div class="firstLabel">
          {{ $t('L_please_select_your_payment_method') }}
        </div>
      </v-flex>
      <v-flex xs12 sm12 md4 mb-4 :class="{ sm4: !isNyPolicy, sm6: isNyPolicy }" class="clickable">
        <div class="text-xs-left" :class="paymentMethod === 0 ? 'green-border-box' : 'gray-border-box'" @click="setPaymentMethod(0)">
          <v-icon :color="paymentMethod === 0 ? '#39ac4b' : '#02579e'" class="ml-2 mr-2">
            account_balance
          </v-icon>
          <span class="font-weight-bold">{{ $t('L_checking') }}</span>
          <v-icon color="#39ac4b" v-if="paymentMethod == 0" class="greenCheckRight">
            check_circle
          </v-icon>
        </div>
      </v-flex>
      <v-flex xs12 sm12 md4 mb-4 :class="{ sm4: !isNyPolicy, sm6: isNyPolicy }" class="clickable">
        <div class="text-xs-left" :class="paymentMethod === 1 ? 'green-border-box' : 'gray-border-box'" @click="setPaymentMethod(1)">
          <v-icon :color="paymentMethod === 1 ? '#39ac4b' : '#02579e'" class="ml-2 mr-2">
            savings
          </v-icon>
          <span class="font-weight-bold">{{ $t('L_savings') }}</span>
          <v-icon color="#39ac4b" v-if="paymentMethod == 1" class="greenCheckRight">
            check_circle
          </v-icon>
        </div>
      </v-flex>
      <v-flex xs12 sm12 md4 mb-4 :class="{ sm4: !isNyPolicy, sm6: isNyPolicy }" v-if="!isNyPolicy" class="clickable">
        <div class="text-xs-left" :class="{ 'green-border-box': paymentMethod === 2, 'gray-border-box': paymentMethod !== 2, 'gray-box': selectedAmountOverLimit }" @click="setPaymentMethod(2)">
          <v-icon :color="paymentMethod === 2 ? '#39ac4b' : '#02579e'" class="ml-2 mr-2">
            credit_card
          </v-icon>
          <span class="font-weight-bold">{{ $t('L_Credit_or_Debit_Card') }}</span>
          <v-icon color="#39ac4b" v-if="paymentMethod == 2" class="greenCheckRight">
            check_circle
          </v-icon>
        </div>
      </v-flex>
    </v-layout>
    <!-- Checking and Savings -->
    <v-layout row wrap v-if="paymentMethod === 0 || paymentMethod === 1">
      <v-flex xs12>
        <!-- Please enter your bank account info-->
        <div class="ml10">
          <v-layout row wrap class="mt-4 mb-4">
            <v-flex xs12 class="font-size--16 font-weight-bold text-xs-left">
              {{ $t('L_please_enter_your_bank_account_information') }}
            </v-flex>
          </v-layout>
          <!-- Routing Account Bank Name-->
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <v-flex xs12 sm12 md8>
                <BankRoutingNumber
                  :max-lengthinput="isCanadaPolicy ? 8 : 9"
                  :min-length-input="isCanadaPolicy ? 8 : 9"
                  bank-routingid="onetp_routing_number_ca"
                  v-model="routingNumber"
                  :form-input="$v.routingNumber"
                  @blur="checkRouting"
                  :label="$t('L_routing_number')"
                />
              </v-flex>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-flex xs12 sm12 md8>
                <!-- <BankNameInput id="onetp_bank_name" :class="bankName.length > 0 ? 'valid-bank' : 'invalid-bank'" v-model="bankName" :form-input="$v.bankName" :label="$t('L_bank_name')" /> -->
                <div class="labelBankName">
                  {{ $t('L_bank_name') }}
                </div>
                <div class="valueBankName">
                  {{ bankName }}
                </div>
              </v-flex>
            </v-flex>

            <v-flex xs12 sm12 md6>
              <v-flex xs12 sm12 md8>
                <BankAccountNumber
                  :max-length-input="isCanadaPolicy ? 12 : 17"
                  :min-length-input="6"
                  bank-accountid="onetp_bank_acct_number"
                  v-model="bankAcctNumber"
                  :form-input="$v.bankAcctNumber"
                  :label="$t('L_account_number')"
                />
              </v-flex>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-flex xs12 sm12 md8>
                <VerifyBankAccountNumber
                  :max-length-input="isCanadaPolicy ? 12 : 17"
                  bank-accountid="onetp_verify_bank_acct_number"
                  v-model="verifyBankAcctNumber"
                  :form-input="$v.verifyBankAcctNumber"
                  :label="$t('L_verify_account_number')"
                />
              </v-flex>
            </v-flex>
          </v-layout>
          <!-- ? Where to find Bank Routing and Account Number -->
          <v-layout row wrap text-xs-left>
            <v-flex xs12 @click="showCheckImage">
              <span>
                <v-icon mr-2 color="#0c6dca">help</v-icon>
              </span>
              <span class="font-weight-bold font-size--16 blueText mouseCursor">
                {{ $t('L_Where_to_find_Bank_Routing_and_Account_Number') }}
              </span>
            </v-flex>
            <v-flex xs12 class="mt-4 font-size--16">
              <div>
                {{ $t('L_By_continuing_with_this_payment_method_you_agree_that_you_are_the_owner') }}
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>


    <!-- Credit and Debit -->
    <!-- Blue Box-->
    <v-layout row wrap class="blueBox" v-if="paymentMethod === 2">
      <v-flex xs12>
        <v-icon color="#3399ff" class="mr-2">
          info
        </v-icon>
        <span class="font-size--14 font-weight-bold">{{ $t('L_make_a_payment_using_a_credit_or_debit_card') }}</span>
      </v-flex>
      <v-flex xs12>
        <span class="font-size--14 font-weight-regular">{{ $t('L_to_complete_this_payment_you_will_be_directed_to_our_vendor_site') }}</span>
      </v-flex>
    </v-layout>

    <!-- Button -->
    <v-layout row wrap class="justify-end mt-4 pt-4">
      <v-flex xs0 sm0 md4>
        &nbsp;
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          color="secondary"
          @click="cancel"
          class="maxwidthButton"
        >
          {{ $t('L_cancel') }}
        </v-btn>
      </v-flex>
      <v-flex xs12 sm12 md4 pr-4>
        <v-btn
          block
          class="white--text mb-3 maxwidthButton"
          :disabled="badForm"
          @click="goToStepThree"
          color="primary"
        >
          {{ $t('L_continue') }}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import { RadioButtonGroup, BankNameInput, BankAccountNumber, VerifyBankAccountNumber, BankRoutingNumber, Alert } from '@primerica/vue-components';
import Config from '@/shared/util/config';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import stringValues from '@/shared/util/stringValues';
import { filters } from '@/mixins/filters';
import { openInNewWindow } from '@/mixins/openNewWindow';
import Alerts from '@/components/Alerts.vue';
import Message from '@/components/Message.vue';
import moment from 'moment';
import messages from '../../../../../shared/validation/messages';

export const notSameDigits = (value) => {
  const regexp = /(\d)\1{7}/;
  return !regexp.test(value);
};

export const letterSpaceLetter = (value) => {
  const regexp = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
  return regexp.test(value);
};

export default {
  name: 'PayStepTwo',
  components: {
    RadioButtonGroup,
    BankNameInput,
    BankAccountNumber,
    VerifyBankAccountNumber,
    BankRoutingNumber,
    Alerts,
    Alert,
    Message,
  },
  mixins: [openInNewWindow],
  props: {
    selectedAmount: {
      type: String,
      required: true,
      default: '0',
    },
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    // alertContext: {
    //   type: String,
    //   required: false,
    //   default: 'go_paperless',
    // },
    // policynumber: {
    //   type: String,
    //   required: false,
    //   default: '',
    // },
    policyData: {
      type: Object,
      required: true,
      default: () => {},
    },
    minimumAmountSelected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    // text: undefined,
    // progressLoading: false,
    // documentContent: undefined,
    persistent: true,
    dialog: false,
    accountHolder: '', // this.policyData?.owner?.name
    bankAcctNumber: '',
    verifyBankAcctNumber: '',
    routingNumber: '',
    accountHolderErrorMessages: [],
    showcheck: false,
    paymentMethod: -1,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies', 'currentPolicy', 'routeNameToNavigateBack', 'selectedPolicyIndex', 'bankNameResponse', 'paymentData']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    selectedAmountOverLimit() {
      return this.selectedAmount.replaceAll(',', '') >= 2500;
    },
    contractId() {
      return this.currentPolicy?.contractId;
    },
    zipCode() {
      return this.currentPolicy?.owner?.address._zip;
    },
    disableAccountHolderNameEdit() {
      return Config.get('payments.disableAccountHolderNameEdit');
    },
    bankName() {
      const name = this?.bankNameResponse ? this.bankNameResponse[0]?.name : null;
      return name || '';
    },
    accountOptions() {
      return [
        {
          label: this.$t('L_savings_account'),
          value: this.bankAccountType === 3 ? 0 : 1,
        },
        {
          label: this.$t(this.checkingAccount),
          value: this.bankAccountType === 3 ? 1 : 0,
        },
      ];
    },
    badForm() {
      if (this.paymentMethod === 2) {
        return false;
      } return this.$v.anyError || this.$v.$invalid;
    },
    isCanadaPolicy() {
      return this.policyData?.isCanadaPolicy;
    },
    isNyPolicy() {
      return this?.policyData?.isNewYorkPolicy;
    },
    checkingAccount() {
      return this.isCanadaPolicy ? 'L_chequing_account' : 'L_checking_account';
    },
    bankAccountType() {
      return this.policyData?.paymentInfo?.accountType;
    },
    formattedPaymentType() {
      return this.policyData?.billingMode && this.policyData?.billingForm ? stringValues.paymentMethod(this.policyData) : '';
    },
    futurePaidToDate() {
      const paidToDate = moment(this.policyData?.paymentInfo?.paidToDate);
      if (this.minimumAmountSelected) {
        const futurePaidToDate = paidToDate.add(1, 'months').format('YYYY-MM-DD');
        return formatDate(futurePaidToDate, this.dateFormat);
      }
      const futurePaidToDate = paidToDate.add(this.policyData?.billingMode, 'months').format('YYYY-MM-DD');
      return formatDate(futurePaidToDate, this.dateFormat);
    },
    accountDiff() {
      const currentAccount = [this.policyData?.paymentInfo?.bank?.name.trim(), this.policyData?.paymentInfo?.routingNumber, this.policyData?.paymentInfo?.accountNumber].toString();
      const paymentData = [this.bankName.trim(), this.routingNumber, this.hideNums(this.bankAcctNumber)].toString();
      return currentAccount !== paymentData;
    },
    hadNSF() {
      return this.policyData?.payNowLinkDesc?.indexOf('unable to process') >= 0;
    },
    errorMessages() {
      const errors = [];
      const validationMessages = Object.assign({}, messages.accountHolder, this.validationMessages);
      if (typeof this.formInput === 'undefined' || !this.formInput.$dirty) {
        return errors;
      }
      if (this.formInput.$error) {
        // If there is an error on the field
        Object.keys(validationMessages).forEach((type) => {
          if (this.formInput[type] === false && validationMessages && Object.prototype.hasOwnProperty.call(validationMessages, type)) {
            errors.push(validationMessages[type]);
          }
        });
        if (errors.length === 0) {
          // If no messages have been added for this error
          errors.push(messages.generic.invalid);
        }
      }
      return errors.map(msg => (typeof this.$t !== 'undefined' ? this.$t(msg, { length: this.minLengthInput }) : msg));
    },
    country() {
      return this.isCanadaPolicy ? 'ca' : 'us';
    },
    kubraLink() {
      return Config.get(`payments.${this.country}.kubraLink`);
    },
  },
  methods: {
    ...mapMutations({
      setPreLoadBank: 'policy/setPreLoadBank',
    }),
    // TODO: remove getOnePolicyDocument if unused
    ...mapActions('policy', ['getOnePolicyDocument']),
    setPaymentMethod(method) {
      if (method === 2 && this.selectedAmountOverLimit) {
        // Skip the emit
      } else {
        this.routingNumber = '';
        this.bankAcctNumber = '';
        this.verifyBankAcctNumber = '';
        if (this.bankNameResponse && this.bankNameResponse.length > 0) {
          this.bankNameResponse[0].name = '';
        }
        // this.$v.bankAcctNumber.$dirty = false;
        // this.$v.verifyBankAcctNumber.$dirty = false;
        // this.$v.routingNumber.$dirty = false;
        this.paymentMethod = method;
        this.$emit('paymentMethodSet', method);
      }
    },
    async goToNextStep() {
      this.dialog = false;
      this.resetData();
      const win1 = await this.openInNewWindow({ url: this.kubraLink, target: 'KubraWinName' });
      this.$emit('goToStepThreeCC', win1);
    },
    resetData() {
      this.bankAcctNumber = '';
      this.verifyBankAcctNumber = '';
      this.routingNumber = '';
      this.accountHolderErrorMessages = [];
      this.showcheck = false;
      this.paymentMethod = -1;
    },
    validateAccountHolder() {
      let error1 = '';
      const tempAccountHolder = this.accountHolder;
      const regex = /^[a-zA-Z]+[\sa-zA-Z-.]{0,}[a-zA-Z]+$/;
      this.accountHolderErrorMessages = [];
      if (tempAccountHolder.trim().length === 0) {
        error1 = this.$t('L_account_holder_name_is_required');
      } else if (!regex.test(tempAccountHolder)) {
        error1 = this.$t('L_account_holder_name_is_invalid');
      }
      if (error1.length > 0) {
        this.accountHolderErrorMessages.push(error1);
      }
    },
    checkRouting() {
      if (this.$v.routingNumber.$invalid) {
        this.clearBank();
        return;
      }
      const country = this.isCanadaPolicy ? 'CA' : 'US';
      const payload = {
        policyNo: this.policyData?.contractId,
        routingNumber: this.routingNumber,
        country,
      };
      this.getBankInfo(payload);
    },
    getBankInfo(payload) {
      this.$store.dispatch('policy/getBankInfo', payload).then((response) => {
        if (!response) {
          this.clearBank();
        }
      });
    },
    goToStepThree() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const method = this.paymentMethod === 2 ? 'Credit/Debit' : 'ACH';
        const i = this.$dynatrace.enterAction(`PaymentMethod_Selected: ${method})`);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      if (this.paymentMethod === 2) { // Credit Debit Card
        // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
        try {
          const i = this.$dynatrace.enterAction('OneTimePayment_CreditCard_YoureLeavingMyPrimerica_PageDisplayed');
          this.$dynatrace.leaveAction(i);
        } catch (error) { /*  */ }
        this.dialog = true;
      } else { // Checking and Savings
        const paymentData = {
          amount: this.selectedAmount, // this.policyData?.paymentInfo?._pACPremium,
          // Edit allowed as of MYP-2480
          accountHolder: this.accountHolder, // this.policyData?.owner?.name,
          accountNumber: this.bankAcctNumber,
          accountType: '',
          accountCode: -1,
          bankName: this.bankName,
          paymentType: this.$t(this.formattedPaymentType),
          routingNumber: this.routingNumber,
          differentAccount: this.accountDiff,
          hadNSF: this.hadNSF,
        };
        if (this.paymentMethod === 0) { // Checking
          paymentData.accountType = this.$t(this.checkingAccount);
          paymentData.accountCode = 0;
        } else if (this.paymentMethod === 1) { // Savings
          paymentData.accountType = this.$t('L_savings_account');
          paymentData.accountCode = 1;
        }

        this.$store.commit('policy/setPaymentData', paymentData);
        this.$emit('goToStepThree');
        this.setPreLoadBank(this.accountDiff);
      }
      this.resetData();
    },
    clearBank() {
      this.$store.commit('policy/clearBank');
    },
    cancel() {
      // * Dynatrace Tag (https://primerica.atlassian.net/wiki/spaces/PD/pages/19955873/Payments+-+Custom+Dynatrace+Tags)
      try {
        const i = this.$dynatrace.enterAction('PC2_PolicyMethod_Cancel');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      this.clearBank();
      this.$emit('cancelDialog');
    },
    hideNums(val) {
      return filters.filters.hideNums(val);
    },
    showCheckImage() {
      this.showcheck = true;
    },
    copyPolicyToClipboard() {
      navigator.clipboard.writeText(this.contractId);
    },
  },
  async mounted() {
    this.accountHolder = this.policyData?.owner?.name;
  },
  validations() {
    return {
      paymentMethod: {
        required,
      },
      // accountHolder: {
      //   required,
      //   letterSpaceLetter,
      // },
      bankName: {
        required,
      },
      bankAcctNumber: {
        required,
        notSameDigits,
        minLength: minLength(6),
        maxLength: maxLength(this.isCanadaPolicy ? 12 : 17),
      },
      routingNumber: {
        required,
        notSameDigits,
        minLength: minLength(this.isCanadaPolicy ? 8 : 9),
      },
      verifyBankAcctNumber: {
        required,
        notSameDigits,
        sameAs: sameAs('bankAcctNumber'),
      },
    };
  },
};
</script>

<style>
.bg-light-grey {
  background-color: #f5f5f5;
}
.blueBox {
  margin-left: 10px;
  padding: 16px 24px 24px 24px;
  border-top: 8px solid #3399ff;
  border-left: 2px solid #3399ff;
  border-right: 2px solid #3399ff;
  border-bottom: 2px solid #3399ff;
  background-color: #e2f0fe;
}
.gray-border-box {
  border: 2px solid #e3e3e3;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  background-color: #ffffff;
}
.green-border-box {
  border: 2px solid #39ac4b;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  background-color: #d8f8dd;
}
.greenCheckRight {
  float: right;
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.blueText {
  color: #0c6dca;
}
.LRBottom {
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}
.bottomBorder {
  border-bottom: 2px solid black;
}
.emptyLine {
  height: 50px;
}
.emptyLine2{
  height: 30px;
}
.checkCSS {
  padding-left: 50px;
  padding-right: 50px;
  background-image: url(./check-mobile.svg);
  height: 136px;
}
.checkBlueText {
  color: #385d81;
}
.pLpR50 {
  padding-left: 50px;
  padding-right: 50px;
}
.spaceBelow10 {
  margin-bottom: 10px;
}
.spaceToRight {
  margin-right: 15px !important;
}
.maxwidthButton {
  max-width: 300px;
}

.maxwidthButton2 {
  max-width: 225px;
}
.centerText {
  margin: auto;
}
#bank_name {
  color: #000000 !important;
}
label.v-label.v-label--active.v-label--is-disabled.theme--light {
  color: #000000 !important;
}
.mouseCursor {
  cursor: pointer;
}
.blackText{
  color: #000000 !important;
}
.checkImg {
  width: 500px;
  margin: auto;
}
.mobileCheckImg {
  width: 224px;
  margin: auto;
}
.centerButton {
  margin: auto;
}
.labelBankName {
  color: rgba(0,0,0,0.54);
  font-size: 12px;
}
.valueBankName {
  font-size: 16px;
  height: 24px;
  margin-bottom: 64px;
}
.clickable {
  cursor: pointer;
}
.blue_label{
  color: #02579e;
}
.gray-box {
  background-color: darkgray !important;
  cursor: default !important;
}
</style>

<i18n src="../locales.json"></i18n>
<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card tile flat class="pa-2" id="documents-note-text">
        <v-card-text id="please-note-verbiage">
          {{ $t('L_please_note_that_some_documents') }}
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-card tile align-stretch flat>
        <v-card flat v-if="docs.length > 0">
          <v-data-table :headers="headers" :items="docs" :hide-actions="true">
            <template v-slot:items="props">
              <td>{{ props.item.date }}</td>
              <td>{{ $t(`L_${props.item.type}`) }}</td>
              <td><a @click="getDocumentPDF(props.item)">{{ props.item.name }}</a></td>
              <td>
                <v-icon @click="downloadPDF(props.item)" color="#052f60">
                  download
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-card flat v-else>
          <v-card-text>
            <div class="py-5">
              <img id="documents-folder-img" height="180" width="180" src="@/assets/documentsFolder.png" alt="documents-folder" class="d-flex mx-auto">
            </div>
            <div id="you-have-no-available" class="font-weight-bold text-xs-center">
              {{ $t('L_no_documents_currently_available') }}
            </div>
          </v-card-text>
        </v-card>
      </v-card>
    </v-flex>
    <v-flex xs12 class="container" />
  </v-layout>
</template>

<script>
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapGetters } from 'vuex';
import { formatDate } from '@primerica/vue-components/src/util/formatters';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import moment from 'moment';

export default {
  name: 'PolicyDocuments',
  components: {
    LoadingIndicator,
  },
  mixins: [openInNewWindow],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['policyDocuments']),
    ...mapGetters('policy', ['base64String']),
    ...mapGetters({
      dateFormat: 'getDateFormat',
    }),
    headers() {
      return [
        {
          text: this.$t('L_date'),
          align: 'left',
          value: 'Date',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_type'),
          align: 'left',
          value: 'Type',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_name'),
          align: 'left',
          value: 'Name',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
        {
          text: this.$t('L_download'),
          value: 'Download',
          sortable: false,
          class: 'primarylight font-weight-black',
        },
      ];
    },
    docs() {
      const d = this.policyDocuments;
      const r = d && d.map(i => ({
        date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), this.dateFormat),
        name: `${this.$t(`L_document_name_${i.name.toLowerCase().replaceAll(' ', '_')}`)} - ${i.contract}`,
        type: i.type,
        doc: i,
      }));
      return r || [];
    },
  },
  methods: {
    getDocumentMetadata(item) {
      const policyDoc = item?.doc?.metadata;
      const date = item?.doc?.dateReceived;
      const formattedDate = date.slice(0, date.indexOf('T'));
      const requestObject = {
        opComp: policyDoc.policyCompanyNum,
        opArea: policyDoc.operationalArea,
        contract: policyDoc.policyNum,
        dateReceived: formattedDate,
        documentType: policyDoc.objectCodeType,
      };
      if (policyDoc.documentType === 'A') {
        requestObject.userData = policyDoc.userData || 'POLPAGEEVENT: DUPPOL';
      } else {
        requestObject.objectCode = policyDoc.objectCode || 'APP';
      }
      return requestObject;
    },
    async downloadPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      const requestObject = this.getDocumentMetadata(item);
      const policyDoc = item?.doc?.metadata;

      // * see https://dev.to/nombrekeff/download-file-from-blob-21ho
      // Create a link pointing to the ObjectURL containing the blob
      let blobURL;
      if (policyDoc.documentType === 'A') {
        // requestObject.userData = policyDoc.userData || 'POLPAGEEVENT: DUPPOL';
        blobURL = await this.getAllDocumentsUrl(requestObject);
      } else {
        // requestObject.objectCode = policyDoc.objectCode || 'APP';
        blobURL = await this.getSingleDocumentUrl(requestObject);
      }
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', item?.name);
      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
    async getDocumentPDF(item) {
      const requestObject = this.getDocumentMetadata(item);
      const policyDoc = item?.doc?.metadata;

      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replaceAll(' ', '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      let url = '';
      if (policyDoc.documentType === 'A') {
        url = await this.getAllDocumentsUrl(requestObject);
      } else {
        url = await this.getSingleDocumentUrl(requestObject);
      }
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url, inappbrowser: !isWeb });
    },
    // ***
    getSingleDocumentUrl(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )));
      } else {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject).then(() => this.base64String);
      }
    },
    // ***
    getAllDocumentsUrl(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject)
          .then(() => URL.createObjectURL(new Blob(
            [this.base64String],
            { type: 'application/pdf' },
          )));
      } else {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject).then(() => this.base64String);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      // access to component public instance via `vm`
      try {
        const i = vm.$dynatrace.enterAction('view_documentsPage');
        vm.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    });
  },
  beforeRouteUpdate() {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    try {
      const i = this.$dynatrace.enterAction('view_documentsPage');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
};
</script>

<style scoped lang="stylus">
.header-back {
  width: 32px;
  height: 19px;
  margin: 0 83px 0 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}
table.v-table tbody td {
  font-size: 14px !important;
  padding: 24px !important;
}
.v-datatable.v-table thead th:first-child {
  padding-left: 24px !important;
}
#documents-note-text
  border-bottom: solid 1px #ccc;
#please-note-verbiage {
  padding:16px;
}
</style>

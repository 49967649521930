<i18n src='../locales.json'></i18n>

<template>
  <v-dialog v-model="showNotificationDetail" persistent max-width="550">
    <v-card>
      <v-card-title>
        <h2 class="heading_two mt-4 mb-2">
          {{ selectedNotificationMessage.actionTitle }}
        </h2>
      </v-card-title>
      <v-card-text>
        <iframe
          id="content-stack-viewer"
          :src="selectedNotificationMessage.actionUrl"
          title="embeddedTitle"
          class="embedded-viewer"
          frameBorder="0"
        />
      </v-card-text>
      <v-card-actions>
        <v-container>
          <div>
            <v-btn color="primary" block @click="acceptActionClicked">
              {{ selectedNotificationMessage.actionAcceptLabel }}
            </v-btn>
          </div>
          <div v-if="selectedNotificationMessage.actionRejectLabel">
            <v-btn class="mt-2" color="primary" block @click="rejectActionClicked">
              {{ selectedNotificationMessage.actionRejectLabel }}
            </v-btn>
          </div>
          <div v-if="selectedNotification.allowDismiss">
            <v-btn class="mt-2" flat block @click="dismissActionClicked">
              {{ $t('L_skip_for_now') }}
            </v-btn>
          </div>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NotificationDetail',
  props: {
    showNotificationDetail: {
      type: Boolean,
      default: false,
    },
    notificationId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      locale: 'getLocale',
      alertsAndNotifications: 'alertsAndNotifications',
    }),
    selectedNotification() {
      const alertObj = this.alertsAndNotifications
        .filter(alert => alert.alertId === this.notificationId)[0];
      return alertObj;
    },
    selectedNotificationMessage() {
      const selectedNotificationMessages = this.selectedNotification?.messages
        .filter(message => message.language === this.locale)[0];
      return selectedNotificationMessages;
    },
  },
  methods: {
    ...mapActions(['getAlerts', 'dismissAlert', 'completeAlert']),
    async acceptActionClicked() {
      await this.completeAlert({ alertId: this.notificationId, payload: { acknowledgement: 'Y' } })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(`Error [NotificationDetail > acceptActionClicked] ${e}`);
        });
      this.$emit('accept-action-clicked');
    },
    async rejectActionClicked() {
      await this.completeAlert({ alertId: this.notificationId, payload: { acknowledgement: 'N' } })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(`Error [NotificationDetail > rejectActionClicked] ${e}`);
        });
      this.$emit('reject-action-clicked');
    },
    async dismissActionClicked() {
      await this.dismissAlert(this.notificationId)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(`Error [NotificationDetail > dismissActionClicked] ${e}`);
        });
      this.$emit('dismiss-action-clicked');
    },
  },
  // TODO: remove below
  created() {
    // eslint-disable-next-line no-console
    console.info(`showNotificationDetail: ${this.showNotificationDetail}`);
    // eslint-disable-next-line no-console
    console.info(`notificationId: ${this.notificationId}`);
  },
  // TODO: remove below
  updated() {
    // eslint-disable-next-line no-console
    console.info(`showNotificationDetail: ${this.showNotificationDetail}`);
    // eslint-disable-next-line no-console
    console.info(`notificationId: ${this.notificationId}`);
  },
};
</script>

<style lang="stylus">

#content-stack-viewer {
  min-height: 320px;
  width: 100%
}

</style>

import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=0a1ece14&scoped=true"
import script from "./DashboardLayout.vue?vue&type=script&lang=js"
export * from "./DashboardLayout.vue?vue&type=script&lang=js"
import style0 from "./DashboardLayout.vue?vue&type=style&index=0&id=0a1ece14&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a1ece14",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Flayouts%2FDashboardLayout.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports